import React from 'react';
import { Box, Button, Container, Paper } from '@mui/material';

interface IFooterProps {
  disabled?: boolean;
  onSave?(): Promise<void> | void;
  onExcluirChave?(): void;
  onCancel?(): void;
}

export const FooterBarBoleto: React.FC<IFooterProps> = ({
  disabled = false,
  onSave,
  onExcluirChave,
  onCancel,
}) => {
  return (
    <Box position="fixed" right={0} bottom={0} left={0} zIndex={999}>
      <Paper elevation={4}>
        <Box
          marginLeft={9}
          style={{
            transition: 'all 0.25s',
          }}
        >
          <Container>
            <Box
              height="78px"
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
            >
              <Box marginLeft={2} width={160}>
                <Button
                  fullWidth
                  size="large"
                  color="primary"
                  variant="outlined"
                  disabled={disabled}
                  onClick={onCancel}
                >
                  Voltar
                </Button>
              </Box>

              {onExcluirChave && (
                <Box marginLeft={2}>
                  <Button
                    fullWidth
                    size="large"
                    color="primary"
                    variant="outlined"
                    onClick={onExcluirChave}
                    style={{ borderColor: '#f61f1f', color: '#f61f1f' }}
                  >
                    Excluir
                  </Button>
                </Box>
              )}

              <Box marginLeft={2} width={160}>
                <Button
                  fullWidth
                  size="large"
                  color="primary"
                  variant="contained"
                  disabled={disabled}
                  onClick={onSave}
                >
                  Salvar
                </Button>
              </Box>
            </Box>
          </Container>
        </Box>
      </Paper>
    </Box>
  );
};
