import { Box, Grid, Typography, Skeleton } from '@mui/material';
import React from 'react';

export const SkeletonConsulta: React.FC = () => {
  return (
    <Grid direction="column" container>
      <Grid item>
        <Typography>
          <Box display="flex" alignItems="center" marginBottom={-4}>
            <Box marginRight={1}>
              <h4>CPF/CNPJ:</h4>
            </Box>
            <Box marginLeft={1} marginTop={0.5}>
              <Skeleton variant="text" width={180} height={40} />
            </Box>
          </Box>
        </Typography>
        <Typography>
          <Box display="flex" alignItems="center" marginBottom={-4}>
            <Box marginRight={1}>
              <h4>TxId:</h4>
            </Box>
            <Box marginLeft={1} marginTop={0.5}>
              <Skeleton variant="text" width={227} height={40} />
            </Box>
          </Box>
        </Typography>
        <Typography>
          <Box display="flex" alignItems="center" marginBottom={-4}>
            <Box marginRight={1}>
              <h4>Valor:</h4>
            </Box>
            <Box marginLeft={1} marginTop={0.5}>
              <Skeleton variant="text" width={221} height={40} />
            </Box>
          </Box>
        </Typography>
        <Typography>
          <Box display="flex" alignItems="center" marginBottom={-4}>
            <Box marginRight={1}>
              <h4>Vencimento:</h4>
            </Box>
            <Box marginLeft={1} marginTop={0.5}>
              <Skeleton variant="text" width={174} height={40} />
            </Box>
          </Box>
        </Typography>
        <Typography>
          <Box display="flex" alignItems="center">
            <Box marginRight={1}>
              <h4>Situação:</h4>
            </Box>
            <Box marginLeft={1} marginTop={0.5}>
              <Skeleton variant="text" width={197} height={40} />
            </Box>
          </Box>
        </Typography>
      </Grid>

      <Grid item>
        <Box marginTop={-13} marginBottom={-4} marginRight={4}>
          <Skeleton variant="text" width={575} height={500} />
        </Box>
      </Grid>
    </Grid>
  );
};

{
  /* <Skeleton variant="text" width={90} height={40} /> */
}
