import React, { useEffect, useState } from 'react';
import { Fade, Grid, Theme, Typography, LinearProgress } from '@mui/material';

import { makeStyles } from '@mui/styles';

import { GVSplashLogo } from 'shared/assets';
import { useUser } from 'shared/hooks';

const useStyles = makeStyles((theme: Theme) => {
  return {
    logo: {
      position: 'relative',
      width: '80px',
      transition: 'transform .2s',
      '&:hover': {
        transform: 'scale(1.25)',
      },
    },
    barra: {
      marginTop: theme.spacing(6),
      width: theme.spacing(35),
      color: theme.palette.background.paper,
    },
    texto: {
      color: `${theme.palette.text.primary} !important`,
      marginTop: theme.spacing(3),
    },
  };
});

interface ISplashScreen {
  setIsLoading: (isLoading: boolean) => void;
}

export const SplashScreen: React.FC<ISplashScreen> = ({ setIsLoading }) => {
  const classes = useStyles();
  const { isLogged } = useUser();

  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 100);
    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    if (isLogged && progress === 100) {
      setIsLoading(false);
    }
  }, [progress, setIsLoading, isLogged]);

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '100vh' }}
    >
      <Fade in>
        <Grid item>
          <img
            alt="Logo GVdasa"
            src={GVSplashLogo.default}
            className={classes.logo}
          />
        </Grid>
      </Fade>
      <Grid item>
        <Fade in>
          <LinearProgress
            color="primary"
            variant="determinate"
            value={progress}
            className={classes.barra}
          />
        </Fade>
      </Grid>
      <Fade in>
        <Grid item className={classes.texto}>
          <Typography variant="subtitle1">Carregando GVPay</Typography>
        </Grid>
      </Fade>
    </Grid>
  );
};
