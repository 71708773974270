import GenericException from 'shared/errors/GenericException';
import { Api } from 'shared/services/axios-config';

export interface IMenuOption {
  id?: string | any;
  key: string;
  path: string;
  label: string;
  icon: string;
}

export interface IFuncionalidade {
  FuncionalidadeCaminho: string;
  FuncionalidadeDescricao: string;
  FuncionalidadeIcone: string;
  FuncionalidadeId: number;
}

export interface IMenuOptions {
  Administrativo: boolean;
  Funcionalidades: IFuncionalidade[];
}

const getMenuOptions = async (): Promise<IMenuOptions | undefined> => {
  try {
    const { data } = await Api().get<IMenuOptions>(`/funcionalidades/menu`);
    return data;
  } catch (error: any) {
    throw new GenericException(error.response);
  }
};

export const MenuOptionService = {
  getMenuOptions,
};
