import { Api, ApiBoleto } from 'shared/services/axios-config';

export interface ITotal {
  Total: number;
  Label: string[];
  Data: number[];
}
export interface IPercentage30days {
  Label: string[];
  Data: number[];
}

export interface IMesTransacao {
  Label: string;
  Data: number[];
}

export interface IDashboardTransacoes {
  Label: string[];
  Data: IMesTransacao[];
}

export interface IValidadeCertificado {
  possuiCertificadoVencido: boolean;
  mensagemCertificadoVencido: string;
  possuiCertificadoAVencer: boolean;
  mensagemCertificadoAVencer: string;
}

export const colorByStatus = {
  capturada: {
    borderColor: 'rgba(75, 192, 192, 1)',
    backgroundColor: 'rgba(75, 192, 192, 0.2)',
  },
  autorizada: {
    borderColor: 'rgba(54, 162, 235, 1)',
    backgroundColor: 'rgba(54, 162, 235, 0.2)',
  },
  cancelada: {
    borderColor: 'rgba(255, 99, 132, 1)',
    backgroundColor: 'rgba(255, 99, 132, 0.2)',
  },
  emAutorizacao: {
    borderColor: 'rgba(255, 206, 86, 1)',
    backgroundColor: 'rgba(255, 206, 86, 0.2)',
  },
  naoAutorizado: {
    borderColor: 'rgba(255, 159, 64, 1)',
    backgroundColor: 'rgba(255, 159, 64, 0.2)',
  },
};

const getTotal = async (): Promise<ITotal | undefined> => {
  try {
    const { data } = await Api().get<ITotal>(`/dashboard/total`);
    if (data) {
      return data;
    }
    return undefined;
  } catch (error) {
    return undefined;
  }
};

const getPercentage30days = async (): Promise<
  IPercentage30days | undefined
> => {
  try {
    const { data } = await Api().get<IPercentage30days>(
      `/dashboard/porcentagemtrintadias`,
    );
    if (data) {
      return data;
    }
    return undefined;
  } catch (error) {
    return undefined;
  }
};

const getAllTransacoes = async (): Promise<
  IDashboardTransacoes | undefined
> => {
  try {
    const { data } = await Api().get<IDashboardTransacoes>(`/dashboard/meses`);

    if (data) {
      return data;
    }
    return undefined;
  } catch (error) {
    return undefined;
  }
};

const getStatusCertificado = async (): Promise<
  IValidadeCertificado | undefined
> => {
  try {
    const { data } = await ApiBoleto().get<IValidadeCertificado>(
      `/Configuracoes/ValidadeCertificados`,
    );

    if (data) {
      return data;
    }
    return undefined;
  } catch (error) {
    return undefined;
  }
};

const getStatusCertificadoPix = async (): Promise<
  IValidadeCertificado | undefined
> => {
  try {
    const { data } = await Api().get<IValidadeCertificado>(
      `/PixChaves/ValidadeCertificados`,
    );

    if (data) {
      return data;
    }
    return undefined;
  } catch (error) {
    return undefined;
  }
};

export const DashboardService = {
  getTotal,
  getAllTransacoes,
  getPercentage30days,
  getStatusCertificado,
  getStatusCertificadoPix,
};
