import React from 'react';
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Skeleton,
} from '@mui/material';

export const AdquirinteSkeleton: React.FC = () => {
  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width="5%">Ações</TableCell>
              <TableCell>Nome</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <IconButton style={{ position: 'relative', left: 6 }}>
                  <Skeleton variant="circular" width={24} height={24} />
                </IconButton>
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width={90} height={25} />
              </TableCell>
            </TableRow>{' '}
            <TableRow>
              <TableCell>
                <IconButton style={{ position: 'relative', left: 6 }}>
                  <Skeleton variant="circular" width={24} height={24} />
                </IconButton>
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width={90} height={25} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <IconButton style={{ position: 'relative', left: 6 }}>
                  <Skeleton variant="circular" width={24} height={24} />
                </IconButton>
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width={90} height={25} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
