import GenericException from 'shared/errors/GenericException';
import { Api } from 'shared/services/axios-config';
import {IErrorMessage, IResultOfAction, responseError} from 'shared/services/response-error/ResponseError';

export interface IListaPerfis {
  PerfilUsuarioId: string;
  Descricao: string;
}

export interface IFuncionalidades {
  PerfilUsuarioId: string;
  FuncionalidadeDescricao: string;
  Selecionado: boolean;
}

export interface IPerfisUsuarios {
  PerfilUsuarioId: string;
  Descricao: string;
  Administrativo: boolean;
  Funcionalidades: IFuncionalidades[];
}

export interface IPostFuncionalidade {
  FuncionalidadeId: number;
  FuncionalidadeDescricao: string;
  Selecionado: boolean;
}

export interface IRootPostFuncionalidade {
  Descricao: string;
  Administrativo: boolean;
  Funcionalidades: IPostFuncionalidade[];
}

export interface IPutFuncionalidades {
  FuncionalidadeId: number;
  FuncionalidadeDescricao: string;
  Selecionado: boolean;
}

export interface IRootPutFuncionalidades {
  PerfilUsuarioId: string;
  Descricao: string;
  Administrativo: boolean;
  Funcionalidades: IPutFuncionalidades[];
}

export interface IGetPerfilbyId {
  FuncionalidadeId: number;
  FuncionalidadeDescricao: string;
  Selecionado: boolean;
}

export interface IRootGetPerfilbyId {
  PerfilUsuarioId: string;
  Descricao: string;
  Administrativo: boolean;
  Funcionalidades: IGetPerfilbyId[];
}

const getListaPerfis = async (): Promise<IListaPerfis[]> => {
  try {
    const { data } = await Api().get<IListaPerfis[]>(`perfilUsuarios`);
    return data;
  } catch (error: any) {
    throw new GenericException(error.response);
  }
};

const getPerfisById = async (guid: string): Promise<IRootGetPerfilbyId> => {
  try {
    const { data } = await Api().get<IRootGetPerfilbyId>(
      `perfilusuarios/${guid}`,
    );
    return data;
  } catch (error: any) {
    throw new GenericException(error.response);
  }
};

const getFuncionalidadesById = async (
  guid: string,
): Promise<IGetPerfilbyId[]> => {
  try {
    const { data } = await Api().get<IGetPerfilbyId[]>(
      `perfilusuarios/${guid}`,
    );
    return data;
  } catch (error: any) {
    throw new GenericException(error.response);
  }
};

const getFuncionalidades = async (): Promise<IFuncionalidades[]> => {
  try {
    const { data } = await Api().get<IFuncionalidades[]>(`funcionalidades`);
    return data;
  } catch (error: any) {
    throw new GenericException(error.response);
  }
};

const postUsuario = async (body: IRootPostFuncionalidade): Promise<IResultOfAction<any>> => {
  try {
    const {data, status} = await Api().post(`perfilusuarios`, body);

    if (status === 200) {
      return { sucesso: true } as IResultOfAction<any>;
    }
    
    const errorResponse: IResultOfAction<any> = {
      mensagens: [] as IErrorMessage[],
      mensagensCombinadas: data.ResultadoDaAcao.MensagensCombinadas,
      severidade: data.ResultadoDaAcao.Severidade,
      severidadeId: data.ResultadoDaAcao.SeveridadeId,
      sucesso: data.ResultadoDaAcao.Sucesso
    }
    
    return errorResponse;

  } catch (error: any) {
    const response = responseError(error);

    return response;
  }
};

const putUsuario = async (body: IRootPutFuncionalidades): Promise<IResultOfAction<any>> => {
  try {
    const {data, status} = await Api().put(`perfilusuarios`, body);

    if (status === 200) {
      return { sucesso: true } as IResultOfAction<any>;
    }
    
    const errorResponse: IResultOfAction<any> = {
      mensagens: [] as IErrorMessage[],
      mensagensCombinadas: data.ResultadoDaAcao.MensagensCombinadas,
      severidade: data.ResultadoDaAcao.Severidade,
      severidadeId: data.ResultadoDaAcao.SeveridadeId,
      sucesso: data.ResultadoDaAcao.Sucesso
    }
    
    return errorResponse;
  } catch (error: any) {
    const response = responseError(error);

    return response;
  }
};

const deleteById = async (id: string): Promise<boolean> => {
  try {
    const data = await Api().delete(`perfilUsuarios/${id}`);

    if(data.status === 200){
      return true
    }else{
      return false;
    }
  } catch {
    return false
  }
};

export const PerfisService = {
  getListaPerfis,
  deleteById,
  getFuncionalidades,
  postUsuario,
  getPerfisById,
  getFuncionalidadesById,
  putUsuario,
};
