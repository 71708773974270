import React, { useEffect, useState } from 'react';
import { Box, Container, Grid } from '@mui/material';
import { useTitle } from 'shared/hooks';
import {
  PercentualTransacoes,
  Total,
  Transacoes,
  UltimasTransacoes,
} from './components';
import { feedback } from 'shared/services';
import { DashboardService, IValidadeCertificado } from 'shared/services/api';

export const Dashboard: React.FC = () => {
  const { setTitle } = useTitle();
  const [statusCertificado, setStatusCertifado] =
    useState<IValidadeCertificado>();

  useEffect(() => {
    setTitle('Página inicial');
  }, [setTitle]);

  useEffect(() => {
    async function getStatusCertificado() {
      try {
        const response = await DashboardService.getStatusCertificado();
        setStatusCertifado(response);
      } catch (error: any) {
        feedback(error.Mensagem, 'error');
      }
    }
    getStatusCertificado();
  }, []);

  return (
    <Container>
      {statusCertificado?.possuiCertificadoAVencer === true ? (
        <>
          {feedback(
            `${statusCertificado?.mensagemCertificadoAVencer} Acesse as configurções para visualizar. `,
            'warning',
          )}
        </>
      ) : (
        <></>
      )}

      {statusCertificado?.possuiCertificadoVencido === true ? (
        <>
          {feedback(
            `${statusCertificado?.mensagemCertificadoVencido} Acesse as configurções para visualizar. `,
            'error',
          )}
        </>
      ) : (
        <></>
      )}

      <Box flexGrow={1}>
        <Grid container spacing={1}>
          <Grid item xs={8}>
            <Transacoes />
          </Grid>
          <Grid item xs={4}>
            <Total />
          </Grid>
          <Grid item xs={8}>
            <UltimasTransacoes />
          </Grid>
          <Grid item xs={4}>
            <PercentualTransacoes />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};
