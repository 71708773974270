import {
  Button,
  DialogTitle,
  Dialog,
  DialogContent,
  Grid,
  FormControl,
  InputLabel,
  OutlinedInput,
  DialogActions,
  TextField,
  FormControlLabel,
  Checkbox,
  Tooltip,
  IconButton,
  Box,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import {
  IFuncionalidades,
  IRootPostFuncionalidade,
  PerfisService,
} from 'shared/services/api/perfil-de-usuario/PerfilDeUsuario';
import ErrorIcon from '@mui/icons-material/Error';
import { AutocompleteComponent } from 'shared/components';
import { feedback } from 'shared/services';

interface INovoPerfilProps {
  request: boolean;
  loadRequest: React.Dispatch<React.SetStateAction<boolean>>;
}

export const NovoPerfil: React.FC<INovoPerfilProps> = ({ request, loadRequest }) => {
  const [openNovo, setOpenNovo] = React.useState(false);
  const [nomePerfil, setNomePerfil] = useState<string>('');

  const [funcionalidades, setFuncionalidades] = useState<IFuncionalidades[]>(
    [],
  );
  const [prepararPost, setPrepararPost] = useState<
    IRootPostFuncionalidade | any
  >();

  const [perfisSel, setPerfisSel] = useState<IFuncionalidades[]>([]);

  const handleClickOpenNovo = () => {
    setOpenNovo(true);
  };

  const handleCloseNovo = () => {
    setOpenNovo(false);
  };

  const [administrativo, setAdministrativo] = React.useState({
    checkedA: false,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAdministrativo({
      ...administrativo,
      [event.target.name]: event.target.checked,
    });
  };

  const handleNomePefil = useCallback((e: any) => {
    setNomePerfil(e.target.value);
  }, []);

  useEffect(() => {
    async function getFuncionalidades() {
      try {
        const response = await PerfisService.getFuncionalidades();
        if (response) {
          setFuncionalidades([...response]);
        } else {
          setFuncionalidades([]);
        }
      } catch (error: any) {
        feedback(error.Mensagem, 'error');
      }
    }
    getFuncionalidades();
  }, []);

  const enviarPost = useCallback(async () => {
    const response = await PerfisService.postUsuario(prepararPost).finally(
      () => {
        setNomePerfil('');
        setPerfisSel([]);
      },
    );

    if (response.sucesso) {
      feedback('Perfil criado com sucesso!', 'success');
      loadRequest(!request);
    } else {
      feedback(
        response.mensagensCombinadas || 'Erro ao criar perfil.',
        'error',
      );
      loadRequest(!request);
    }
  }, [loadRequest, prepararPost, request]);

  useEffect(() => {
    funcionalidades &&
      setPrepararPost({
        Descricao: nomePerfil,
        Administrativo: administrativo.checkedA,
        Funcionalidades: perfisSel,
      });
  }, [nomePerfil, administrativo, perfisSel, funcionalidades]);

  const verificarNomePerfilValido = (pNomePerfil: string) => {
    // eslint-disable-next-line
    const caracteresEspeciais = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    let erro = false;

    if (!pNomePerfil.trim()) {
      erro = true;
    }

    if (pNomePerfil.trim().length === 0) {
      erro = true;
    }

    if (caracteresEspeciais.test(pNomePerfil)) {
      erro = true;
    }

    return erro;
  };

  return (
    <Box width="100%" display={'flex'} justifyContent={'flex-end'}>
      <Button
        size="large"
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        onClick={handleClickOpenNovo}
      >
        NOVO
      </Button>

      <Dialog
        maxWidth="md"
        open={openNovo}
        onClose={handleCloseNovo}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ display: 'block' }}
      >
        <DialogTitle id="Criar novo perfil de usuário">
          Criar novo perfil de usuário
        </DialogTitle>
        <DialogContent>
          <Grid direction="column" container style={{ width: 700 }}>
            <Grid container item style={{ marginBottom: 20 }}>
              <Grid item xs>
                <FormControlLabel
                  style={{ marginRight: 1 }}
                  control={
                    <Checkbox
                      checked={administrativo.checkedA}
                      onChange={handleChange}
                      color="primary"
                      name="checkedA"
                    />
                  }
                  label="Administrativo"
                />
                <Tooltip
                  placement="right-end"
                  title="Deve haver apenas um perfil administrativo"
                >
                  <IconButton>
                    <ErrorIcon color="primary" />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>

            <Grid container item style={{ marginBottom: 20 }}>
              <Grid item xs>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel>Perfil de usuário*</InputLabel>
                  <OutlinedInput
                    label="Perfil de usuário*"
                    type="string"
                    value={nomePerfil}
                    onChange={handleNomePefil}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

          <Grid container item style={{ marginBottom: 10, display: 'block' }}>
            <Grid item xs>
              <FormControl component="fieldset" fullWidth>
                {funcionalidades && (
                  <AutocompleteComponent
                    multiple
                    options={funcionalidades}
                    getOptionLabel={(option: IFuncionalidades) =>
                      option.FuncionalidadeDescricao
                    }
                    defaultValue={[]}
                    filterSelectedOptions
                    onChange={(evt, value) => {
                      setPerfisSel(value),
                        funcionalidades.map(
                          (item) => (item.Selecionado = true),
                        );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Funcionalidades"
                      />
                    )}
                  />
                )}
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions style={{ marginRight: 16, marginBottom: 18 }}>
          <Button variant="outlined" onClick={handleCloseNovo} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={() => {
              if (verificarNomePerfilValido(nomePerfil)) {
                feedback('Campo de perfil de usuário inválido.', 'error');
              } else {
                enviarPost(), handleCloseNovo();
              }
            }}
            variant="contained"
            color="primary"
            autoFocus
          >
            Adicionar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
