import React, { useCallback, useEffect, useState } from 'react';
import { Grid, TextField, Box, Skeleton } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { feedback, FormatService } from 'shared/services';
import {
  ILoja,
  IBandeira,
  IPrioridades,
  BandeirasService,
  IEstabelecimentoConfig,
  EstabelecimentoConfigService,
} from 'shared/services/api';
import { Footer } from 'shared/components/footer/Footer';
import { Lojas, Prioridade } from './components';
import { Section } from 'shared/components';
import { CentralWarning } from 'shared/components';
import { useTitle } from 'shared/hooks';

interface IEstabelecimentoParam {
  cnpj: string;
  codigoEstabelecimento: string;
}

export const EstabelecimentoConfig: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isValidEmailRemetente, setIsValidEmailRemetente] = useState(true);
  const [isValidEmailDestinatario, setIsValidEmailDestinatario] =
    useState(true);
  const { cnpj, codigoEstabelecimento } =
    useParams<keyof IEstabelecimentoParam>();
  const [lojas, setLojas] = useState<ILoja[]>([]);
  const [bandeiras, setBandeiras] = useState<IBandeira[]>([]);
  const [prioridades, setPrioridades] = useState<IPrioridades[]>([]);
  const [prioridadesCopy, setPrioridadesCopy] = useState<IPrioridades[]>([]);
  const [lojaTemp, setLojaTemp] = useState<ILoja>({} as ILoja);
  const navigate = useNavigate();
  const [cnpjValido, setCnpjValido] = useState(true);
  const { setTitle } = useTitle();
  const [estabelecimentoConfig, setEstabelecimentoConfig] =
    useState<IEstabelecimentoConfig>({
      IdConfigEstabelecimento: 0,
      CNPJEstabelecimento: cnpj || '',
      EmailRemetente: '',
      EmailDestinatario: '',
    } as IEstabelecimentoConfig);

  const ehCnpjValido = useCallback((pCnpj: string) => {
    const cnpjOnlyNumbers = pCnpj.replace(/[^\d]+/g, '');

    if (cnpjOnlyNumbers === '') return false;

    if (cnpjOnlyNumbers.length !== 14) return false;

    if (
      cnpjOnlyNumbers == '00000000000000' ||
      cnpjOnlyNumbers == '11111111111111' ||
      cnpjOnlyNumbers == '22222222222222' ||
      cnpjOnlyNumbers == '33333333333333' ||
      cnpjOnlyNumbers == '44444444444444' ||
      cnpjOnlyNumbers == '55555555555555' ||
      cnpjOnlyNumbers == '66666666666666' ||
      cnpjOnlyNumbers == '77777777777777' ||
      cnpjOnlyNumbers == '88888888888888' ||
      cnpjOnlyNumbers == '99999999999999'
    )
      return false;

    let size = cnpjOnlyNumbers.length - 2;
    let cnpjNumbers = cnpjOnlyNumbers.substring(0, size);
    const digits = cnpjOnlyNumbers.substring(size);
    let sum = 0;
    let pos = size - 7;
    for (let i = size; i >= 1; i--) {
      sum += Number(cnpjNumbers.charAt(size - i)) * pos--;
      if (pos < 2) pos = 9;
    }
    let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    if (result !== Number(digits.charAt(0))) return false;

    size = size + 1;
    cnpjNumbers = pCnpj.substring(0, size);
    sum = 0;
    pos = size - 7;
    for (let i = size; i >= 1; i--) {
      sum += Number(cnpjNumbers.charAt(size - i)) * pos--;
      if (pos < 2) pos = 9;
    }

    result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    if (result !== Number(digits.charAt(1))) return false;

    return true;
  }, []);

  useEffect(() => {
    if (!ehCnpjValido(cnpj || '')) {
      setCnpjValido(false);
    }
  }, [ehCnpjValido, cnpj]);

  useEffect(() => {
    setIsLoading(true);
    EstabelecimentoConfigService.getEstabelecimentoConfigByCNPJEstabelecimento(
      cnpj || '',
      codigoEstabelecimento || '',
    )
      .then((response) => {
        if (response.sucesso) {
          setTitle(`Estabelecimento: ${response.data.NomeEstabelecimento}`);

          setEstabelecimentoConfig(response.data);
          setLojas(response.data.Lojas);
          setPrioridades(response.data.Prioridades);
          setPrioridadesCopy(response.data.Prioridades);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [setEstabelecimentoConfig, cnpj, codigoEstabelecimento, setTitle]);

  useEffect(() => {
    BandeirasService.getBandeiras().then((data) => {
      data ? setBandeiras(data) : setBandeiras([]);
    });
  }, []);

  const validateEmail = useCallback((email: string) => {
    const emailFormat =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailFormat.test(String(email).toLowerCase());
  }, []);

  const validarCampos = useCallback(
    (estabelecimentoConfig: IEstabelecimentoConfig) => {
      let ehValido = true;

      setIsValidEmailRemetente(true);
      setIsValidEmailDestinatario(true);
      if (
        estabelecimentoConfig.EmailRemetente === '' ||
        !validateEmail(estabelecimentoConfig.EmailRemetente)
      ) {
        feedback('Email REMETENTE inválido.', 'error');
        setIsValidEmailRemetente(false);
        ehValido = false;
      }
      if (
        estabelecimentoConfig.EmailDestinatario === '' ||
        !validateEmail(estabelecimentoConfig.EmailDestinatario)
      ) {
        feedback('Email DESTINATÁRIO inválido.', 'error');
        setIsValidEmailDestinatario(false);
        ehValido = false;
      }
      if (estabelecimentoConfig.Lojas.length === 0) {
        feedback('Informe lojas para salvar as configurações.', 'error');
        ehValido = false;
      }

      return ehValido;
    },
    [validateEmail],
  );

  const formataLojas = (lojas: ILoja[]) => {
    const lojasTemp: ILoja[] = [];

    lojas.map((item) => {
      if (item.editavel) {
        item.IdLoja = null;
        lojasTemp.push(item);
      } else {
        lojasTemp.push(item);
      }
    });
    return lojasTemp;
  };

  const handleSave = useCallback(
    async (estabelecimentoConfig: IEstabelecimentoConfig) => {
      estabelecimentoConfig.Lojas = formataLojas(lojas);
      estabelecimentoConfig.Prioridades = prioridades;

      if (validarCampos(estabelecimentoConfig)) {
        if (estabelecimentoConfig.IdConfigEstabelecimento === 0) {
          try {
            const response =
              await EstabelecimentoConfigService.createEstabelecimentoConfig(
                estabelecimentoConfig,
              );
            if (response?.sucesso) {
              feedback('Configuração salva.', 'success');
            } else {
              feedback(
                response?.mensagensCombinadas ||
                  'Erro ao salvar configurações.',
                'error',
              );
            }
          } catch (error) {
            feedback('Erro na requisição', 'error');
          }
        } else {
          try {
            const response =
              await EstabelecimentoConfigService.updateEstabelecimentoConfig(
                estabelecimentoConfig,
              );
            if (response?.sucesso) {
              feedback('Configuração atualizada.', 'success');
            } else {
              feedback(response?.mensagensCombinadas || '', 'error');
            }
          } catch (error) {
            feedback('Erro na requisição', 'error');
          }
        }
      }
    },
    [lojas, validarCampos, prioridades],
  );

  useEffect(() => {
    let prioridadesAtualizadas: IPrioridades[] = prioridadesCopy;

    bandeiras.map((bandeira) => {
      const bandeiraLoja = lojaTemp.BandeirasLoja?.find(
        (x) => x.IdBandeira === bandeira.BandeiraId,
      );

      if (bandeiraLoja) {
        const indexPrioridade = prioridadesAtualizadas.findIndex(
          (x) => x.IdBandeira === bandeiraLoja.IdBandeira,
        );

        if (indexPrioridade === -1) {
          prioridadesAtualizadas.push({
            IdBandeira: bandeiraLoja.IdBandeira,
            BandeiraNome: bandeiraLoja.BandeiraNome,
            Prioridades: [
              {
                IdLoja: lojaTemp.LojaChaveTemp ? null : lojaTemp.IdLoja,
                LojaNome: lojaTemp.LojaNome,
                AdquirenteNome: lojaTemp.AdquirenteNome,
                Prioridade: 1,
                LojaChaveTemp: lojaTemp.LojaChaveTemp,
              },
            ],
          });
        } else {
          const lojaJaExiste = prioridadesAtualizadas[
            indexPrioridade
          ].Prioridades.findIndex((x) =>
            !lojaTemp.LojaChaveTemp
              ? x.IdLoja === lojaTemp.IdLoja
              : x.LojaChaveTemp === lojaTemp.LojaChaveTemp,
          );

          if (lojaJaExiste === -1) {
            prioridadesAtualizadas[indexPrioridade].Prioridades.push({
              IdLoja: lojaTemp.LojaChaveTemp ? null : lojaTemp.IdLoja,
              LojaNome: lojaTemp.LojaNome,
              AdquirenteNome: lojaTemp.AdquirenteNome,
              Prioridade:
                prioridadesAtualizadas[indexPrioridade].Prioridades.length + 1,
              LojaChaveTemp: lojaTemp.LojaChaveTemp,
            });
          } else {
            const indexLoja = prioridadesAtualizadas[
              indexPrioridade
            ].Prioridades.findIndex((x) =>
              !lojaTemp.LojaChaveTemp
                ? x.IdLoja === lojaTemp.IdLoja
                : x.LojaChaveTemp === lojaTemp.LojaChaveTemp,
            );

            prioridadesAtualizadas[indexPrioridade].Prioridades[indexLoja] = {
              IdLoja:
                prioridadesAtualizadas[indexPrioridade].Prioridades[indexLoja]
                  .IdLoja,
              LojaNome: lojaTemp.LojaNome,
              AdquirenteNome: lojaTemp.AdquirenteNome,
              Prioridade:
                prioridadesAtualizadas[indexPrioridade].Prioridades[indexLoja]
                  .Prioridade,
              LojaChaveTemp: lojaTemp.LojaChaveTemp,
            };
          }
        }
      } else {
        const index = prioridadesAtualizadas.findIndex(
          (x) => x.IdBandeira === bandeira.BandeiraId,
        );

        if (index !== -1) {
          const prioridadesFiltradas = prioridadesAtualizadas[
            index
          ].Prioridades.filter((item) =>
            !lojaTemp.LojaChaveTemp
              ? item.IdLoja !== lojaTemp.IdLoja
              : item.LojaChaveTemp !== lojaTemp.LojaChaveTemp,
          );
          prioridadesAtualizadas[index].Prioridades = prioridadesFiltradas;

          if (prioridadesAtualizadas[index].Prioridades.length) {
            Object.keys(prioridadesAtualizadas[index].Prioridades).forEach(
              (_, i) => {
                prioridadesAtualizadas[index].Prioridades[i].Prioridade = i + 1;
              },
            );
          } else {
            const bandeirasFiltradas = prioridadesAtualizadas.filter(
              (item) =>
                item.IdBandeira !== prioridadesAtualizadas[index].IdBandeira,
            );
            prioridadesAtualizadas = bandeirasFiltradas;
          }
        }
      }
    });

    setPrioridades([...prioridadesAtualizadas]);
  }, [bandeiras, lojaTemp, prioridadesCopy, setLojaTemp]);

  if (!cnpjValido) {
    return (
      <>
        <Box width={'100%'} marginBottom={5}>
          <CentralWarning
            variant={'error'}
            text={'CNPJ inválido. Não foi possível carregar informações.'}
          />
        </Box>
      </>
    );
  }

  return (
    <>
      <Section title="Dados do estabelecimento">
        {isLoading ? (
          <Grid container spacing={2}>
            <Grid item sm={4} xs={12}>
              <Skeleton
                style={{ borderRadius: '4px' }}
                variant="rectangular"
                width="100%"
                height={56}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <Skeleton
                style={{ borderRadius: '4px' }}
                variant="rectangular"
                width="100%"
                height={56}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <Skeleton
                style={{ borderRadius: '4px' }}
                variant="rectangular"
                width="100%"
                height={56}
              />
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={2}>
            <Grid item sm={4} xs={12}>
              <TextField
                disabled
                fullWidth
                label="CNPJ"
                variant="outlined"
                value={FormatService.formatCpfCnpj(
                  estabelecimentoConfig.CNPJEstabelecimento || cnpj || '',
                )}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <TextField
                fullWidth
                label="E-mail remetente"
                variant="outlined"
                value={estabelecimentoConfig.EmailRemetente}
                error={!isValidEmailRemetente}
                helperText={!isValidEmailRemetente && 'E-mail inválido!'}
                onChange={(e) => {
                  setEstabelecimentoConfig({
                    ...estabelecimentoConfig,
                    EmailRemetente: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <TextField
                fullWidth
                label="E-mail destinarário"
                variant="outlined"
                value={estabelecimentoConfig.EmailDestinatario}
                error={!isValidEmailDestinatario}
                helperText={!isValidEmailDestinatario && 'E-mail inválido'}
                onChange={(e) => {
                  setEstabelecimentoConfig({
                    ...estabelecimentoConfig,
                    EmailDestinatario: e.target.value,
                  });
                }}
              />
            </Grid>
          </Grid>
        )}
      </Section>
      <Lojas
        lojas={lojas}
        setLojas={setLojas}
        setLojaTemp={setLojaTemp}
        isLoading={isLoading}
      />

      <Prioridade
        isLoading={isLoading}
        prioridades={prioridades}
        setPrioridades={setPrioridades}
        bandeiras={bandeiras}
      />

      <Footer
        onCancel={() => navigate('/estabelecimentos')}
        onSave={() => handleSave(estabelecimentoConfig)}
      />
    </>
  );
};
