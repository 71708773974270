import React from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Skeleton,
} from '@mui/material';

export const TransacoesSkeleton: React.FC = () => {
  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width="15%">Doc. origem</TableCell>
              <TableCell>Origem</TableCell>
              <TableCell>Tipo de transação</TableCell>
              <TableCell>Estabelecimento</TableCell>
              <TableCell>Adquirente</TableCell>
              <TableCell align="right">Valor</TableCell>
              <TableCell width="15%">Nome cartão</TableCell>
              <TableCell>Cartão</TableCell>
              <TableCell width="12%">Atualizado em</TableCell>
              <TableCell width="5%" align="center">
                Situação
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <Skeleton variant="text" width={80} height={38} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width={80} height={38} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width={80} height={38} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width={80} height={38} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width={80} height={38} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width={80} height={38} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width={80} height={38} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width={80} height={38} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width={80} height={38} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width={80} height={38} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Skeleton variant="text" width={80} height={38} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width={80} height={38} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width={80} height={38} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width={80} height={38} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width={80} height={38} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width={80} height={38} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width={80} height={38} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width={80} height={38} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width={80} height={38} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width={80} height={38} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Skeleton variant="text" width={80} height={38} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width={80} height={38} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width={80} height={38} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width={80} height={38} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width={80} height={38} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width={80} height={38} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width={80} height={38} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width={80} height={38} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width={80} height={38} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width={80} height={38} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
