import React, { useEffect } from 'react';

import { Card } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { useTitle } from 'shared/hooks';
import { ListaDeAgendamentos } from 'shared/components/lista-de-agendamentos/ListaDeAgendamentos';

export const Recorrencia: React.FC = () => {
  const { setTitle } = useTitle();
  const navigate = useNavigate();

  useEffect(() => {
    setTitle('Recorrência');
  }, [setTitle]);

  return (
    <Card>
      <ListaDeAgendamentos
        idClient={'gvpay'}
        fuso={'GMT-3'}
        onEditById={(id) => {
          navigate(`/recorrencia/${id}`);
        }}
      />
    </Card>
  );
};
