import { Box, Grid, Skeleton } from '@mui/material';
import React from 'react';

export const SkeletonConsulta: React.FC = () => {
  return (
    <Grid direction="column" container>
     

      <Grid item>
        <Box marginTop={-18} marginLeft={7} marginRight={5} marginBottom={-12}>
          <Skeleton variant="text" width={500} height={700} />
        </Box>
      </Grid>
    </Grid>
  );
};

{
  /* <Skeleton variant="text" width={90} height={40} /> */
}
