import React, { useState } from 'react';
import { Box, Container } from '@mui/material';

import { useUser } from 'shared/hooks/index';
import { HeaderComponent, DrawerComponent } from './components';
import { Theme, useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const Layout: React.FC = ({ children }) => {
  const { menuOptions } = useUser();

  const [isOpen, setIsOpen] = useState(false);
  const [buttonActive, setButtonActive] = useState(false);

  const theme: Theme = useTheme();

  const useStyles = makeStyles({
    '@global': {
      '*::-webkit-scrollbar': {
        width: 6,
      },
      '*::-webkit-scrollbar-track': {
        '-webkit-box-shadow': `${theme.palette.primary.main} !important`,
      },
      '*::-webkit-scrollbar-thumb': {
        backgroundColor: '#E0E0E0',
        borderRadius: 50,
        width: 12,
        maxHeight: 100,
      },
    },
    content: {
      flexGrow: 1,
      margin: theme.spacing(12, 0),
    },
  });

  const classes = useStyles();

  return (
    <Box display="flex">
      <HeaderComponent
        isOpen={isOpen}
        buttonActive={buttonActive}
        setButtonActive={setButtonActive}
        handleDrawerOpen={() => {
          setIsOpen(!isOpen);
        }}
      />
      <DrawerComponent
        menuOptions={menuOptions}
        isOpen={isOpen}
        buttonActive={buttonActive}
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}
      />

      <main className={classes.content}>
        <Container>
          <>{children}</>
        </Container>
      </main>
    </Box>
  );
};
