import React, { useEffect, useState } from 'react';

import { Card, CardHeader, CardContent, Box, Typography } from '@mui/material';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import {
  colorByStatus,
  DashboardService,
  IPercentage30days,
} from 'shared/services/api';
import { PercentualTransacoesSkeleton } from './PercentualTransacoesSkeleton';

export const PercentualTransacoes: React.FC = () => {
  ChartJS.register(ArcElement, Tooltip, Legend);

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<IPercentage30days>({} as IPercentage30days);

  useEffect(() => {
    DashboardService.getPercentage30days().then((data) => {
      if (data) {
        setData(data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    });
  }, []);

  const dataGraph = {
    labels: data.Label,
    datasets: [
      {
        label: '# of Votes',
        data: data.Data,
        backgroundColor: [
          colorByStatus.autorizada.borderColor,
          colorByStatus.capturada.borderColor,
          colorByStatus.cancelada.borderColor,
          colorByStatus.emAutorizacao.borderColor,
          colorByStatus.naoAutorizado.borderColor,
        ],
        borderColor: [
          colorByStatus.autorizada.backgroundColor,
          colorByStatus.capturada.backgroundColor,
          colorByStatus.cancelada.backgroundColor,
          colorByStatus.emAutorizacao.backgroundColor,
          colorByStatus.naoAutorizado.backgroundColor,
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'right' as const,
      },
    },
  };

  return !isLoading ? (
    <Card>
      <CardHeader
        title={'Total transações (%)'}
        subheader={'Últimos 30 dias'}
      />
      <CardContent>
        {data?.Data?.length > 0 ? (
          <Doughnut
            width="400"
            height="250"
            options={options}
            data={dataGraph}
          />
        ) : (
          <Box padding={2}>
            <Typography color="secondary">
              Sem transações nos últimos 30 dias...
            </Typography>
          </Box>
        )}
      </CardContent>
    </Card>
  ) : (
    <PercentualTransacoesSkeleton />
  );
};
