import { AxiosError } from 'axios';

import { IResultOfAction } from 'shared/services/response-error/ResponseError';
import { ESeveridadeDeErro } from '../../../enums';
import { Environment } from '../../../env';

export const errorInterceptor = (
  error: AxiosError,
): Promise<IResultOfAction> => {
  const { data } = error.response || { data: undefined };

  if (error.response?.status === 401) {
    if (Environment.URL_ORIGIN_DEBUG.length > 0) {
      window.location.href = `${Environment.URL_GATEWAY}/SignIn?callback_url=${window.location.href}&origin_debug=${Environment.URL_ORIGIN_DEBUG}`;
    } else {
      window.location.href = `${Environment.URL_GATEWAY}/SignIn?callback_url=${window.location.href}`;
    }
  }

  if (data.resultadoDaAcao) {
    const resultadoDaAcao: IResultOfAction = {
      sucesso: !!data.resultadoDaAcao.sucesso,
      mensagens: data.resultadoDaAcao.mensagens || [],
      mensagensCombinadas: data.resultadoDaAcao.MensagensCombinadas,
      severidade: data.resultadoDaAcao.Severidade || ESeveridadeDeErro.error,
      severidadeId: 0,
    };

    return Promise.reject(resultadoDaAcao);
  }
  if (error.message === 'Network Error') {
    const resultadoDaAcao: IResultOfAction = {
      sucesso: false,
      severidade: ESeveridadeDeErro.error,
      mensagensCombinadas: 'Erro de conexão',
      severidadeId: 0,
      mensagens: [
        {
          Mensagem: 'Erro de conexão',
          MensagemId: 0,
          Severidade: ESeveridadeDeErro.error,
          SeveridadeId: 0,
        },
      ],
    };

    return Promise.reject(resultadoDaAcao);
  }

  return Promise.reject(error);
};
