import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  FormControl,
  Grid,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Select,
  MenuItem,
  Pagination,
} from '@mui/material';
import { format } from 'date-fns';
import { useTitle } from 'shared/hooks';
import { Search } from '@mui/icons-material';
import { Filtro } from './components/FiltroDePesquisa';
import {
  CobrancasService,
  ICobrancas,
  ISituacao,
} from 'shared/services/api/cobrancas-pix/cobrancasPix';
import { ModalCobrancas } from './components/ModalConsultas';
import { SkeletonCobrancas } from './components/SkeletonCobrancas';
import { feedback, FormatService } from 'shared/services';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => {
  return {
    pagadorHeader: {
      whiteSpace: 'nowrap',
      position: 'sticky',
      left: 1,
      background: '#e0e0e0',
    },
    pagadorBody: {
      whiteSpace: 'nowrap',
      position: 'sticky',
      left: 1,
      background: '#FFF',
    },
    noWrap: {
      whiteSpace: 'nowrap',
    },
  };
});

export const ListagemCobrancas: React.FC = () => {
  const classes = useStyles();
  const { setTitle } = useTitle();
  const [isTxId, setIsTxId] = useState('');
  const [aberto, setAberto] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isPagadorCpf, setIsPagadorCpf] = useState('');
  const [isPagadorCnpj, setIsPagadorCnpj] = useState('');
  const [isPagadorNome, setIsPagadorNome] = useState('');
  const [cobrancas, setCobrancas] = useState<ICobrancas[]>([]);
  const [isSituacao, setIsSituacao] = useState<ISituacao[]>([]);
  const [inicioVencimentoData, setInicioVencimentoData] = useState('');
  const [finalVencimentoData, setFinalVencimentoData] = useState('');
  const [incioPagamentoData, setInicioPagamentoData] = useState('');
  const [finalPagamentoData, setFinalPagamentoData] = useState('');
  const [estabelecimento, setEstabelecimento] = useState('');
  const [filter, setFilter] = useState(false);
  const [totalRegistros, setTotalRegistros] = useState(0);
  const [take, setTake] = useState(10);
  const [skip, setSkip] = useState(1);
  const [search, setSearch] = useState('');

  useEffect(() => {
    setTitle('Listagem de cobranças via Pix');
  }, [setTitle]);

  const formatoData = (date1: any, date2: any) => {
    if (date1 != null && date2 === null) {
      return `${format(new Date(date1), 'dd/MM/yyyy')}`;
    } else if (date1 === null && date2 != null) {
      return `${format(new Date(date2), 'dd/MM/yyyy')}`;
    } else if (date1 && date2) {
      return `${format(new Date(date1), 'dd/MM/yyyy')},
      ${format(new Date(date2), 'dd/MM/yyyy')}`;
    } else {
      return '';
    }
  };

  useEffect(() => {
    setIsLoading(true);
    CobrancasService.getCobrancas(
      estabelecimento,
      '',
      [isSituacao.map((item) => item.id).join(',')],
      isTxId,
      formatoData(inicioVencimentoData, finalVencimentoData),
      formatoData(incioPagamentoData, finalPagamentoData),
      isPagadorNome,
      isPagadorCpf,
      isPagadorCnpj,
      take,
      skip,
    )
      .then((response) => {
        if (response.sucesso) {
          setCobrancas(response.data?.cobrancas || []);
          setTotalRegistros(response.data?.qtdRegistros || 0);
          feedback('Listagem de cobranças carregadas!', 'success');
        } else {
          feedback(
            response.mensagensCombinadas || 'Erro ao buscar cobranças',
            'error',
          );
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [take, skip, filter]);

  useEffect(() => {
    if (filter) {
      setSkip(1);
    }
  }, [filter]);

  const valorFormat = useCallback((value: number) => {
    const valor = value.toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
    });
    return valor;
  }, []);

  const handleChangePage = (page: number) => {
    setSkip(page);
  };

  const handleSearch = useCallback(
    (searchText: string) => {
      setIsPagadorNome(searchText);
      setFilter(!filter);
    },
    [filter],
  );

  return (
    <Grid direction="column" container>
      <Grid container item>
        <Grid item xs={6}>
          <Paper>
            <Box>
              <TextField
                fullWidth
                variant="outlined"
                onChange={(e) => {
                  setSearch(e.target.value);
                  setTimeout(() => {
                    handleSearch(e.target.value);
                  }, 1000);
                }}
                onBlur={(e) => {
                  if (e.target.value !== '') {
                    handleSearch(e.target.value);
                  }
                }}
                placeholder="Pesquise por nome"
                value={search}
                InputProps={{
                  style: { height: 40 },
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search color="disabled" />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Paper>
        </Grid>

        <Grid container item>
          <Grid item xs>
            <Box marginTop={6}>
              <Box
                component={Paper}
                display="flex"
                justifyContent="space-between"
                alignItems={'center'}
                gap={2}
              >
                <Box p={2}>
                  <Typography variant={'h5'} color={'primary'}>
                    Listagem das cobranças
                  </Typography>
                </Box>
                <Box p={2}>
                  <Filtro
                    trigger={filter}
                    openFiltro={aberto}
                    setTxId={setIsTxId}
                    setFiltrar={setFilter}
                    setOpenFiltro={setAberto}
                    setSituacao={setIsSituacao}
                    setPagadorCpf={setIsPagadorCpf}
                    setPagadorNome={setIsPagadorNome}
                    setPagadorCnpj={setIsPagadorCnpj}
                    setEstabelecimento={setEstabelecimento}
                    setDataFinalPagamento={setFinalPagamentoData}
                    setDataFinalVencimento={setFinalVencimentoData}
                    setDataInicialPagamento={setInicioPagamentoData}
                    setDataInicioVencimento={setInicioVencimentoData}
                  />
                </Box>
              </Box>
              <Box>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.pagadorHeader}>
                          <Box>Pagador</Box>
                        </TableCell>

                        <TableCell className={classes.noWrap}>
                          CPF/CNPJ
                        </TableCell>
                        <TableCell className={classes.noWrap}>
                          Vencimento
                        </TableCell>
                        <TableCell className={classes.noWrap}>
                          Valor devido
                        </TableCell>
                        <TableCell className={classes.noWrap}>
                          Pagamento
                        </TableCell>
                        <TableCell className={classes.noWrap}>
                          Valor pago
                        </TableCell>
                        <TableCell className={classes.noWrap}>
                          Situação
                        </TableCell>
                        <TableCell className={classes.noWrap}>
                          Estabelecimento
                        </TableCell>
                        <TableCell className={classes.noWrap}>
                          Conta bancária
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    {isLoading ? (
                      <SkeletonCobrancas />
                    ) : (
                      <>
                        {cobrancas.length <= 0 ? (
                          <Box
                            width={250}
                            display="flex"
                            style={{ position: 'relative', left: 10 }}
                          >
                            <Typography color="secondary">
                              <p>Nenhum registro encontrado...</p>
                            </Typography>
                          </Box>
                        ) : (
                          <TableBody>
                            {cobrancas.map((item, index) => (
                              <TableRow key={index}>
                                <Paper
                                  elevation={5}
                                  className={classes.pagadorBody}
                                >
                                  <TableCell>
                                    <Box alignItems="center" display="flex">
                                      <Box marginLeft={-0.7} marginRight={1}>
                                        <ModalCobrancas id={item.id} />
                                      </Box>
                                      <Box>{item.pagador}</Box>
                                    </Box>
                                  </TableCell>
                                </Paper>

                                <TableCell className={classes.noWrap}>
                                  {FormatService.formatCpfCnpj(
                                    item.cpfCnpj || '',
                                  )}
                                </TableCell>
                                <TableCell className={classes.noWrap}>
                                  {item.vencimento
                                    .substring(0, 10)
                                    .split('-')
                                    .reverse()
                                    .join('/')}
                                </TableCell>

                                <TableCell className={classes.noWrap}>
                                  {valorFormat(Number(item.valorDevido))}
                                </TableCell>
                                <TableCell className={classes.noWrap}>
                                  {item.pagamento
                                    ? String(item.pagamento)
                                        .substring(0, 10)
                                        .split('-')
                                        .reverse()
                                        .join('/')
                                    : ''}
                                </TableCell>
                                <TableCell
                                  className={classes.noWrap}
                                ></TableCell>
                                <TableCell className={classes.noWrap}>
                                  {item.situacao}
                                </TableCell>
                                <TableCell className={classes.noWrap}>
                                  {item.estabelecimento}
                                </TableCell>
                                <TableCell className={classes.noWrap}>
                                  {item.contaBancaria.replaceAll(',', ' ')}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        )}
                      </>
                    )}
                  </Table>
                </TableContainer>
                <Box
                  marginTop={2}
                  display={'flex'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent={'flex-start'}
                  >
                    {cobrancas.length > 0 && (
                      <Pagination
                        page={skip}
                        count={Math.ceil(totalRegistros / take)}
                        onChange={(e, page) => {
                          handleChangePage(page);
                        }}
                      />
                    )}
                  </Box>
                  {take && (
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent={'flex-start'}
                      gap={1}
                    >
                      <FormControl component="fieldset">
                        <Paper>
                          <FormControl
                            variant="outlined"
                            size="small"
                            fullWidth
                          >
                            <Select
                              id="rowsperpage-select"
                              value={take}
                              onChange={(e) =>
                                setTake(e.target.value as number)
                              }
                            >
                              {[10, 15, 20, 50].map((item) => (
                                <MenuItem key={item} value={item}>
                                  {item}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Paper>
                      </FormControl>
                      <Box>Linhas por página</Box>
                      {cobrancas.length > 0 && (
                        <Typography
                          variant="caption"
                          style={{ color: '#9E9E9E' }}
                        >
                          {take > totalRegistros ? totalRegistros : take} do
                          total de {totalRegistros} linhas
                        </Typography>
                      )}
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
