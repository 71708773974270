import { Box, Grid, Skeleton } from '@mui/material';
import React from 'react';

export const SkeletonDetalhes: React.FC = () => {
  return (
    <Grid container direction="column" >
      <Grid style={{marginTop: 50}} container item spacing={3}>
        <Grid item xs>
          <Box marginTop={-22}>
            <Skeleton width={1240} height={500} />
          </Box>
        </Grid>
        
      </Grid>
      <Grid>
        <Box marginTop={-10}>
          <h1 style={{ color: '#018781' }}>Logs</h1>
        </Box>
      </Grid>
      <Grid>
        <Box marginTop={-25}>
          <Skeleton width={1236} height={800} />
        </Box>
      </Grid>
    </Grid>
  );
};
