import React, { useState } from 'react';
import {
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
} from '@mui/material';
interface IAmbiente {
  id: number;
  description: string;
}
interface ISelectEnvironment {
  value: number;
  label: string;
  onChangeAmbienteId: (value: number) => void;
  ambienteList: IAmbiente[] | undefined;
}

export const SelectEnvironment: React.FC<ISelectEnvironment> = (props) => {
  const [ambienteList] = useState(props.ambienteList);
  const [label] = useState(props.label);

  return (
    <>
      <Box paddingBottom={2} paddingTop={2}>
        <Typography variant="h5" color="primary">
          {label}
        </Typography>
      </Box>
      <FormControl required variant="outlined" fullWidth>
        <InputLabel>Ambiente {label}</InputLabel>
        <Select
          label={`Ambiente ${label}`}
          name={`Ambiente ${label}`}
          value={String(props.value)}
          defaultValue={'Selecione'}
          onChange={(e) => props.onChangeAmbienteId(Number(e.target.value))}
          required
        >
          <MenuItem key={0} value={0}>
            Selecione
          </MenuItem>
          {ambienteList?.map((ambiente) => {
            return (
              <MenuItem key={ambiente.id} value={ambiente.id}>
                {ambiente.description}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </>
  );
};
