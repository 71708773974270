import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import {
  Grid,
  Card,
  Typography,
  CardContent,
  CircularProgress,
  Box,
  Divider,
  Button,
  IconButton,
  Menu,
  MenuItem,
} from '@mui/material';
import {
  ITransacao,
  TransacoesService,
} from 'shared/services/api/transacoes/Transacoes';
import { useNavigate } from 'react-router-dom';
import { feedback } from 'shared/services/alert';
import { SkeletonDetalhes } from './components/detalhesSkeleton';
import { makeStyles } from '@mui/styles';
import { Theme, useTheme } from '@mui/material/styles';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import WarningIcon from '@mui/icons-material/Warning';
import { LiquidarParcela } from './components/LiquidarParcela';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import CheckIcon from '@mui/icons-material/Check';
import { MoreVertRounded } from '@mui/icons-material';
import { CancelarTransacao } from './components/CancelarTransacao';
import { CapturarTransacao } from './components/CapturarTrasacao';

interface IParamsTypes {
  idTransacao: string;
}

export const DetalheTransacao: React.FC = () => {
  const theme: Theme = useTheme();
  const useStyles = makeStyles({
    CardRed: {
      backgroundColor: `#f4d7d7 !important`,
      color: '#d35f5f !important',
    },
    CardGreen: {
      backgroundColor: `#00dea2 !important`,
      color: '#018781 !important',
    },

    CardYellow: {
      backgroundColor: `#ffe6d5 !important`,
      color: '#ff7f2a !important',
    },

    Button: {
      color: '#d35f5f !important',
      borderColor: '#d35f5f !important',
    },

    logs: {
      color: `${theme.palette.primary.main} !important`,
      marginTop: theme.spacing(-3),
    },

    transacao: {
      color: `${theme.palette.primary.main} !important`,
    },

    logMensagem: {
      color: 'gray',
      marginBottom: theme.spacing(1.5),
      overflow: 'hidden',
    },
  });
  const classes = useStyles();
  const navigate = useNavigate();
  const { idTransacao } = useParams<keyof IParamsTypes>();
  const [transacao, setTransacao] = useState<ITransacao>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [openCapturar, setOpenCapturar] = useState(false);
  const [openCancelar, setOpenCancelar] = useState(false);
  const [numeroDocumento, setNumeroDocumento] = useState<string>('');

  const handleClickOpenCapturar = () => {
    setOpenCapturar(true);
  };

  const handleClickOpenCancelar = () => {
    setOpenCancelar(true);
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const checkAutorizada = () => {
    if (transacao?.NomeSituacao === 'Autorizada') {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    async function getTransacao() {
      try {
        const response = await TransacoesService.getTransacoesDetalhes(
          idTransacao || '',
        );
        setTransacao(response);
        setNumeroDocumento(String(response?.DocumentoOrigem));
      } catch (error: any) {
        feedback(error.Mensagem, 'error');
      } finally {
        setIsLoading(false);
      }
    }
    setIsLoading(true);
    getTransacao();
  }, [idTransacao]);

  const syntaxHighlight = (stringToFormat: string) => {
    try {
      const stringfiedJson = JSON.stringify(stringToFormat, undefined, 2);
      let parsedString = JSON.parse(stringfiedJson);
      parsedString = parsedString
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/\\u[\dA-F]{4}/gi, function (match: any) {
          return String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16));
        });

      return parsedString.replace(
        //eslint-disable-next-line
        /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g,
        (match: string) => {
          return `${match}`;
        },
      );
    } catch (error: any) {
      return stringToFormat;
    }
  };

  const [openLiquidar, setOpenLiquidar] = useState(false);

  const handleClickOpenLiquidar = () => {
    setOpenLiquidar(true);
  };

  const valorFormat = useCallback((value: number) => {
    const valor = value.toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
    });

    return valor;
  }, []);

  return (
    <Grid container direction="column" spacing={1}>
      {isLoading ? (
        <SkeletonDetalhes />
      ) : (
        <>
          <Grid container direction={'column'}>
            <Grid item xs>
              <Box marginBottom={4} display={'flex'} alignItems={'center'}>
                <IconButton onClick={() => navigate('/transacoes')}>
                  <ArrowBackIcon fontSize="large" />
                </IconButton>

                <Typography
                  variant="h5"
                  color={'primary'}
                  style={{ marginLeft: 1 }}
                >
                  <b>Detalhes de transação</b>
                </Typography>
              </Box>
            </Grid>

            <Grid container item>
              <Grid item xs={2.5}>
                <Card elevation={2}>
                  <CardContent>
                    <Box marginBottom={1}>
                      <Box marginBottom={-0.4}>
                        <Typography fontSize={'11px'} color={'GrayText'}>
                          Nome Titular
                        </Typography>
                      </Box>

                      <Typography>
                        <b>{transacao?.NomeTitularCartao || ''} </b>
                      </Typography>
                    </Box>

                    <Box marginBottom={1}>
                      <Box marginBottom={-0.4}>
                        <Typography fontSize={'11px'} color={'GrayText'}>
                          Número do cartão
                        </Typography>
                      </Box>
                      <Box display={'flex'} alignItems={'center'}>
                        {transacao && (
                          <a href={transacao?.UrlBandeiraCartao}>
                            <img
                              width="35px"
                              height="35px"
                              src={transacao?.UrlBandeiraCartao}
                            />
                          </a>
                        )}

                        <Typography>{transacao?.NumeroCartao || ''}</Typography>
                      </Box>
                    </Box>

                    <Box marginBottom={1}>
                      <Box marginBottom={-0.4}>
                        <Typography fontSize={'11px'} color={'GrayText'}>
                          Estabelecimento
                        </Typography>
                      </Box>
                      <Box display={'flex'} alignItems={'center'}>
                        <AccountBalanceIcon fontSize="small" />
                        <Box marginLeft={0.6}>
                          <Typography>
                            {transacao?.Estabelecimento || ''}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs>
                <Box marginLeft={1.5}>
                  <Card elevation={2}>
                    <CardContent>
                      <Box marginBottom={2}>
                        <Box marginBottom={-0.4}>
                          <Typography fontSize={'11px'} color={'GrayText'}>
                            Origem
                          </Typography>
                        </Box>
                        <Typography variant="body1">
                          {transacao?.NomeOrigem || ''}
                        </Typography>
                      </Box>

                      <Box marginBottom={2}>
                        <Box marginBottom={-0.4}>
                          <Typography fontSize={'11px'} color={'GrayText'}>
                            Documento de origem
                          </Typography>
                        </Box>

                        <Typography>
                          {transacao?.DocumentoOrigem || ''}
                        </Typography>
                      </Box>

                      <Box marginBottom={1}>
                        <Box marginBottom={-0.4}>
                          <Typography fontSize={'11px'} color={'GrayText'}>
                            Adquirente
                          </Typography>
                        </Box>

                        <Typography>
                          {transacao?.NomeAdquirente || ''}
                        </Typography>
                      </Box>
                    </CardContent>
                  </Card>
                </Box>
              </Grid>

              <Grid item xs>
                <Box marginLeft={1.5}>
                  <Card elevation={2}>
                    <CardContent>
                      <Box marginBottom={2}>
                        <Box marginBottom={-0.4}>
                          <Typography fontSize={'11px'} color={'GrayText'}>
                            GUID
                          </Typography>
                        </Box>

                        <Typography>
                          {transacao?.Guid || <i>Não informado</i>}
                        </Typography>
                      </Box>

                      <Box marginBottom={1}>
                        <Box marginBottom={-0.4}>
                          <Typography fontSize={'11px'} color={'GrayText'}>
                            TID
                          </Typography>
                        </Box>

                        <Typography>
                          {transacao?.TID || <i>Não informado</i>}
                        </Typography>
                      </Box>

                      <Box marginBottom={2}>
                        <Box marginBottom={-0.4}>
                          <Typography fontSize={'11px'} color={'GrayText'}>
                            Payment ID
                          </Typography>
                        </Box>

                        <Typography>
                          {transacao?.PaymentId || <i>Não informado</i>}
                        </Typography>
                      </Box>
                    </CardContent>
                  </Card>
                </Box>
              </Grid>
            </Grid>

            <Grid container item>
              <Grid item xs>
                <Box marginTop={2} marginBottom={1}>
                  <Card
                    className={
                      transacao?.NomeSituacao === 'Capturada'
                        ? classes.CardGreen
                        : transacao?.NomeSituacao === 'Em Autorização'
                        ? classes.CardGreen
                        : transacao?.NomeSituacao === 'Autorizada'
                        ? classes.CardYellow
                        : classes.CardRed
                    }
                    elevation={2}
                  >
                    <CardContent>
                      <Grid container item>
                        <Grid item xs>
                          <Typography>Parcelas</Typography>
                        </Grid>
                        <Grid item xs>
                          <Typography>Situação</Typography>
                        </Grid>
                        <Grid item xs>
                          <Typography>Atualizado em:</Typography>
                        </Grid>
                        <Grid item xs>
                          <Typography>Valor total</Typography>
                        </Grid>

                        <Grid item>
                          <IconButton
                            disabled={checkAutorizada()}
                            aria-label="mais"
                            onClick={handleClick}
                          >
                            <MoreVertRounded />
                          </IconButton>
                          <Menu
                            anchorEl={anchorEl}
                            keepMounted
                            open={open}
                            onClose={handleClose}
                            PaperProps={{
                              style: {
                                maxHeight: 48 * 4.5,
                                width: '20ch',
                              },
                            }}
                          >
                            <MenuItem style={{ padding: 0 }}>
                              <Button
                                fullWidth
                                onClick={handleClickOpenCapturar}
                              >
                                Capturar
                              </Button>
                            </MenuItem>

                            <MenuItem style={{ padding: 0 }}>
                              <Button
                                fullWidth
                                onClick={handleClickOpenCancelar}
                              >
                                Cancelar
                              </Button>
                            </MenuItem>
                          </Menu>
                        </Grid>
                      </Grid>

                      <Grid container item>
                        <Grid item xs>
                          <Typography variant="h5">
                            {transacao?.NumeroParcelas}x
                          </Typography>
                        </Grid>
                        <Grid item xs>
                          <Typography variant="h5">
                            {transacao?.NomeSituacao}
                          </Typography>
                        </Grid>
                        <Grid item xs>
                          <Typography variant="h5">
                            {(transacao &&
                              transacao?.AtualizadoEm.substring(0, 10)
                                .split('-')
                                .reverse()
                                .join('/')) || <i>Não informado</i>}
                          </Typography>
                        </Grid>
                        <Grid item xs>
                          <Typography variant="h5">
                            {valorFormat(Number(transacao?.Valor))}
                          </Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Box>
              </Grid>
            </Grid>

            {transacao?.NomeSituacaoBaixa === 'Erro na baixa' ? (
              <>
                <Grid container item>
                  <Grid item xs>
                    <Box marginTop={2}>
                      <Card className={classes.CardRed}>
                        <CardContent>
                          <Grid item>
                            <Box
                              display={'flex'}
                              alignItems={'center'}
                              marginBottom={2}
                            >
                              <WarningIcon />
                              <Typography variant="h6">
                                Transação não liquidada no GVcollege
                              </Typography>
                            </Box>
                          </Grid>

                          <Grid item>
                            <Typography>
                              Esta transasão foi realizada com sucesso no GVpay,
                              ou seja, o valor foi capturado no cartäo de
                              credito do cliente entretanto, não conseguimos
                              realizar a liquidção automaticamente no GVcollege.
                              Verifique a situação da parcela diretamente no
                              ERP.
                            </Typography>
                          </Grid>

                          <Grid item>
                            <Box
                              marginTop={2}
                              display={'flex'}
                              justifyContent={'flex-end'}
                            >
                              <Button
                                size="large"
                                variant="outlined"
                                className={classes.Button}
                                onClick={handleClickOpenLiquidar}
                              >
                                Informar liquidação manual
                              </Button>
                            </Box>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Box>
                  </Grid>
                </Grid>
              </>
            ) : transacao?.NomeSituacaoBaixa &&
              transacao?.NomeSituacaoBaixa === 'Baixada' &&
              transacao.ObservacaoBaixaManual &&
              transacao?.ObservacaoBaixaManual.length === 0 ? (
              <>
                <Grid container item>
                  <Grid item xs>
                    <Box marginTop={2}>
                      <Card>
                        <CardContent>
                          <Grid item>
                            <Box
                              display={'flex'}
                              alignItems={'center'}
                              marginBottom={2}
                            >
                              <DoneAllIcon color="primary" />
                              <Typography variant="h6" color={'primary'}>
                                Transação liquidada automaticamente no GVcollege
                              </Typography>
                            </Box>
                          </Grid>

                          <Grid item>
                            <Typography>
                              A liquidação do débito foi realizada
                              automaticamente pelo GVpay em:
                              {transacao.ObservacaoBaixaManual}
                            </Typography>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Box>
                  </Grid>
                </Grid>
              </>
            ) : (transacao?.NomeSituacaoBaixa &&
                transacao?.NomeSituacaoBaixa === 'Liquidacao manual') ||
              (transacao?.ObservacaoBaixaManual &&
                transacao?.ObservacaoBaixaManual.length > 0) ? (
              <>
                <Grid container item>
                  <Grid item xs>
                    <Box marginTop={2}>
                      <Card>
                        <CardContent>
                          <Grid item>
                            <Box
                              display={'flex'}
                              alignItems={'center'}
                              marginBottom={2}
                            >
                              <CheckIcon color="primary" />
                              <Typography variant="h6" color={'primary'}>
                                Transação liquidada manualmente no GVcollege
                              </Typography>
                            </Box>
                          </Grid>

                          <Grid item>
                            <Typography>
                              {transacao.ObservacaoBaixaManual}
                            </Typography>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Box>
                  </Grid>
                </Grid>
              </>
            ) : (
              <></>
            )}
          </Grid>

          <Grid item xs>
            <Box marginTop={2}>
              <Typography color={'primary'} variant="h5">
                <b>Logs</b>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs>
            {isLoading ? (
              <CircularProgress
                style={{ marginLeft: 600, marginTop: -25, marginBottom: 20 }}
                color="secondary"
              />
            ) : transacao?.LogsTransacao ? (
              <Card
                elevation={3}
                style={{ maxHeight: '550px', overflow: 'auto' }}
              >
                <CardContent>
                  {transacao?.LogsTransacao.filter(
                    (item) => item.Etapa !== '',
                  ).map((user, index) => (
                    <Box key={`transacaolog-${index}`} width={'100%'}>
                      <Box
                        marginTop={1}
                        marginBottom={1}
                        display={'flex'}
                        alignItems={'baseline'}
                        justifyContent={'space-evenly'}
                      >
                        <Box width={'50%'}>
                          <Typography
                            gutterBottom
                            variant={'h6'}
                            color="primary"
                          >
                            Etapa:
                          </Typography>
                          <Box>
                            <Typography gutterBottom color="textSecondary">
                              {user.Etapa}
                            </Typography>
                            <Typography color="textSecondary">
                              {user.CriadoEm}
                            </Typography>
                          </Box>
                        </Box>
                        <Box width={'50%'}>
                          <Typography
                            gutterBottom
                            variant={'h6'}
                            color="primary"
                          >
                            Mensagem:
                          </Typography>
                          <Box width={'100%'} maxWidth={'600px'}>
                            <pre
                              style={{
                                whiteSpace: 'pre-wrap',
                                wordBreak: 'break-word',
                                color: 'gray',
                              }}
                            >
                              {syntaxHighlight(user.EstruturaTransacao)}
                            </pre>
                          </Box>
                        </Box>
                      </Box>
                      <Divider />
                    </Box>
                  ))}
                </CardContent>
              </Card>
            ) : (
              <Card
                style={{
                  maxHeight: 550,
                  display: 'flex',
                  overflow: 'auto',
                  borderRadius: '10px',
                  flexDirection: 'column',
                }}
              >
                <CardContent>
                  <Typography className={classes.transacao}>
                    Nenhum Log disponível para esta transação.
                  </Typography>
                </CardContent>
              </Card>
            )}
          </Grid>

          <LiquidarParcela
            IdTransacao={idTransacao}
            guid={transacao?.Guid}
            abrirCapturar={openLiquidar}
            setOpenCapturar={setOpenLiquidar}
          />

          <CancelarTransacao
            abrirCancelar={openCancelar}
            guid={transacao?.Guid}
            numeroDocumento={transacao?.DocumentoOrigem}
            setOpenCancelar={setOpenCancelar}
          />

          <CapturarTransacao
            abrirCapturar={openCapturar}
            guid={transacao?.Guid}
            numeroDocumento={numeroDocumento}
            setOpenCapturar={setOpenCapturar}
          />
        </>
      )}
    </Grid>
  );
};
