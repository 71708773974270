import { Edit, Search } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { feedback } from 'shared/services';
import {
  IDataChaves,
  myCredentialsService,
} from 'shared/services/api/my-credentials/MyCredentials';
import { SkeletonPixParameters } from './components/skeletons/SkeletonPixParameters';

export const PixParameters: React.FC = () => {
  const navigate = useNavigate();

  const [datachaves, setDataChaves] = useState<IDataChaves[]>([]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function getListaPerfis() {
      setIsLoading(true);
      try {
        const response = await myCredentialsService.getPixChaves();
        setDataChaves(response.data);
      } catch (error: any) {
        feedback(error.Mensagem, 'error');
      } finally {
        setIsLoading(false);
      }
    }
    getListaPerfis();
  }, []);

  return (
    <>
      <Grid container direction={'column'}>
        <Grid container item>
          <Box marginTop={3}>
            <Typography variant="h5" color="primary">
              Gerenciar Chaves PIX
            </Typography>
          </Box>
        </Grid>

        <Grid container item>
          <Box
            marginTop={6}
            width={'100%'}
            display={'flex'}
            justifyContent={'space-between'}
          >
            <Grid item xs={3.7}>
              <Paper>
                <TextField
                  fullWidth
                  style={{ padding: 0, margin: 0 }}
                  variant="outlined"
                  //   onChange={(e) => {
                  //     setFiltroPlaceholder(e.target.value);
                  //     setTimeout(() => {
                  //       setFiltro(e.target.value);
                  //     }, 1500);
                  //   }}
                  placeholder="Pesquise por qualquer campo"
                  //value={filtroPlaceholder}
                  InputProps={{
                    style: { height: 40 },
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search color="disabled" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Paper>
            </Grid>

            <Button
              onClick={() => navigate(`/nova-chave-pix`)}
              variant="contained"
              size="medium"
            >
              Nova chave pix
            </Button>
          </Box>
        </Grid>

        <Grid container item>
          <Box width={'100%'} marginTop={2}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell width="30%">Chaves PIX</TableCell>
                    <TableCell>Banco</TableCell>
                    <TableCell>Conta</TableCell>
                    <TableCell>CNPJ</TableCell>
                    <TableCell>Estabelecimento</TableCell>
                    <TableCell>Ações</TableCell>
                  </TableRow>
                </TableHead>
                {isLoading ? (
                  <SkeletonPixParameters />
                ) : (
                  <>
                    {datachaves.length <= 0 ? (
                      <Box
                        width={250}
                        display="flex"
                        style={{ position: 'relative', left: 10 }}
                      >
                        <Typography color="secondary">
                          <p>Nenhum registro encontrado...</p>
                        </Typography>
                      </Box>
                    ) : (
                      <TableBody>
                        {datachaves.map((item, index) => (
                          <TableRow key={index}>
                            <TableCell>{item.chavePixDoRecebedor}</TableCell>
                            <TableCell>{item.nomeBanco}</TableCell>
                            <TableCell>{item.contaBancariaDescricao}</TableCell>
                            <TableCell>{item.cnpjEstabelecimento}</TableCell>
                            <TableCell>{item.nomeEstabelecimento}</TableCell>
                            <TableCell>
                              {isLoading ? (
                                <CircularProgress
                                  style={{
                                    position: 'relative',
                                    left: 6,
                                    top: 7,
                                  }}
                                  size={18}
                                />
                              ) : (
                                <IconButton
                                  onClick={() =>
                                    navigate(`/editar-chave-pix/${item.id}`)
                                  }
                                  style={{ position: 'relative', left: 6 }}
                                >
                                  <Edit color="primary" />
                                </IconButton>
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    )}
                  </>
                )}
              </Table>
            </TableContainer>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
