import React, {
  Fragment,
  useCallback,
  useEffect,
  useState,
  useMemo,
} from 'react';
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { AutocompleteComponent } from 'shared/components';
import { FiltroDeBusca as Drawer } from 'shared/components/filtro-de-busca/FiltroDeBusca';
import {
  CobrancasService,
  IFiltro,
  ISituacao,
} from 'shared/services/api/cobrancas-pix/cobrancasPix';
import { feedback } from 'shared/services';
import InputMask from 'react-input-mask';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

interface IProps {
  loading?: boolean;
  openFiltro?: boolean;
  trigger?: boolean;
  setOpenFiltro(value: boolean): void;
  setTxId: React.Dispatch<React.SetStateAction<string>>;
  setSituacao: (situacao: ISituacao[]) => void;
  setFiltrar: (trigger: boolean) => void;
  setPagadorCpf: React.Dispatch<React.SetStateAction<string>>;
  setPagadorCnpj: React.Dispatch<React.SetStateAction<string>>;
  setPagadorNome: React.Dispatch<React.SetStateAction<string>>;
  setDataInicioVencimento: (newDate: string) => void;
  setDataFinalVencimento: (newDate: string) => void;
  setDataInicialPagamento: (newDate: string) => void;
  setDataFinalPagamento: (newDate: string) => void;
  setEstabelecimento: React.Dispatch<React.SetStateAction<string>>;
}

export const Filtro: React.FC<IProps> = ({
  loading,
  openFiltro,
  setOpenFiltro,
  setPagadorNome,
  setPagadorCnpj,
  setPagadorCpf,
  setTxId,
  trigger,
  setFiltrar,
  setSituacao,
  setDataInicioVencimento,
  setDataFinalVencimento,
  setDataInicialPagamento,
  setDataFinalPagamento,
  setEstabelecimento,
}) => {
  const [inicioVencimento, setInicioVencimento] = useState('');
  const [finalVencimento, setFinalVencimento] = useState('');
  const [inicioPagamento, setInicioPagamento] = useState('');
  const [finalPagamento, setFinalPagamento] = useState('');
  const [filtro, setFiltro] = useState<IFiltro>({} as IFiltro);
  const [estabelecimentoHolder, setEstabelecimentoHolder] = useState('');
  const [txIdHolder, setTxIdHolder] = useState('');
  const [pagadorHolder, setPagadorHolder] = useState('');
  const [cpfHolder, setCpfHolder] = useState('');
  const [cnpjHolder, setCnpjHolder] = useState('');
  const [situacaoHolder, setSituacaoHolder] = useState<string[]>([]);

  const handlePagadorNome = useCallback(
    (value: string) => {
      setPagadorNome(value);
      setPagadorHolder(value);
    },
    [setPagadorNome],
  );

  const handlePagadorCpf = useCallback(
    (value: string) => {
      setPagadorCpf(value);
      setCpfHolder(value);
    },
    [setPagadorCpf],
  );

  const handlePagadorCnpj = useCallback(
    (value: string) => {
      setPagadorCnpj(value);
      setCnpjHolder(value);
    },
    [setPagadorCnpj],
  );

  const handleTxId = useCallback(
    (value: string) => {
      setTxId(value);
      setTxIdHolder(value);
    },
    [setTxId],
  );

  const limparFiltro = useCallback(() => {
    setTxId('');
    setPagadorNome('');
    setPagadorCpf('');
    setPagadorCnpj('');
    setEstabelecimentoHolder('');
    setDataInicioVencimento('');
    setDataFinalVencimento('');
    setDataInicialPagamento('');
    setDataFinalPagamento('');
    setEstabelecimento('');
    setSituacao([]);
    setTxIdHolder('');
    setCpfHolder('');
    setCnpjHolder('');
    setPagadorHolder('');
    setSituacaoHolder([]);
    setInicioVencimento('');
    setFinalVencimento('');
    setInicioPagamento('');
    setFinalPagamento('');

    setTimeout(() => {
      setFiltrar(!trigger);
    }, 100);
  }, [
    setFiltrar,
    trigger,
    setTxId,
    setSituacaoHolder,
    setSituacao,
    setDataInicioVencimento,
    setDataFinalVencimento,
    setDataInicialPagamento,
    setDataFinalPagamento,
    setEstabelecimento,
    setPagadorNome,
    setPagadorCnpj,
    setPagadorCpf,
  ]);

  useEffect(() => {
    async function getFiltro() {
      try {
        const response = await CobrancasService.getFiltro();
        setFiltro(response);
      } catch (error: any) {
        feedback(error.Mensagem, 'error');
      }
    }
    getFiltro();
  }, []);

  const counterFilters = useMemo(() => {
    let count = 0;
    if (inicioVencimento !== '') {
      count++;
    }
    if (finalVencimento !== '') {
      count++;
    }
    if (inicioPagamento !== '') {
      count++;
    }
    if (finalPagamento !== '') {
      count++;
    }
    if (estabelecimentoHolder !== '') {
      count++;
    }
    if (txIdHolder !== '') {
      count++;
    }
    if (pagadorHolder !== '') {
      count++;
    }
    if (cpfHolder !== '') {
      count++;
    }
    if (cnpjHolder !== '') {
      count++;
    }
    if (situacaoHolder.length > 0) {
      count++;
    }

    return count;
  }, [
    inicioVencimento,
    situacaoHolder,
    cnpjHolder,
    cpfHolder,
    pagadorHolder,
    txIdHolder,
    estabelecimentoHolder,
    finalPagamento,
    inicioPagamento,
    finalVencimento,
  ]);

  return (
    <>
      <Drawer
        trigger={trigger}
        open={openFiltro}
        loading={loading}
        countFilter={counterFilters}
        setFilter={setFiltrar}
        setOpen={setOpenFiltro}
        setCleanFilter={limparFiltro}
      >
        <Grid item xs>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Estabelecimentos</InputLabel>
            <Select
              value={estabelecimentoHolder}
              label="Estabelecimentos"
              onChange={(e) => setEstabelecimentoHolder(String(e.target.value))}
            >
              {filtro.estabelecimentos?.map((user, index) => (
                <MenuItem
                  key={index}
                  value={index}
                  onClick={() => {
                    {
                      setEstabelecimento && setEstabelecimento(user.id);
                      setEstabelecimentoHolder(user.cnpj);
                    }
                  }}
                >
                  {user.cnpj}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* <Grid item xs>
          <Box marginTop={2}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Conta bancária - Chave Pix</InputLabel>
              <Select
                value=""
                label="Conta bancária - Chave Pix"
                onChange={(e) => ''}
              >
                <MenuItem value="2">teste</MenuItem>s
                <MenuItem value="1">teste</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid> */}

        <Grid item xs>
          <Box marginTop={2}>
            <FormControl fullWidth variant="outlined">
              <AutocompleteComponent
                multiple
                options={filtro.situacoes?.filter(
                  (situacao) => situacao.nome !== null,
                )}
                defaultValue={situacaoHolder}
                getOptionLabel={(option: ISituacao) => option.nome || ''}
                filterSelectedOptions
                onChange={(evt, value) => {
                  setSituacao(value);
                  setSituacaoHolder(value);
                }}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="Situações" />
                )}
              />
            </FormControl>
          </Box>
        </Grid>

        <Grid item xs>
          <Box marginTop={2}>
            <FormControl fullWidth variant="outlined">
              <TextField
                defaultValue={txIdHolder}
                variant="outlined"
                label="TxId"
                onChange={(e) => handleTxId(e.target.value)}
              ></TextField>
            </FormControl>
          </Box>
        </Grid>

        <Grid item xs>
          <Box marginTop={1} marginBottom={-1}>
            <p>Período de vencimento</p>
          </Box>
        </Grid>

        <Grid item xs>
          <Box display="flex">
            <Box>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Fragment>
                  <DatePicker
                    renderInput={(params) => <TextField {...params} />}
                    inputFormat="dd/MM/yyyy"
                    mask="__/__/____"
                    value={inicioVencimento ? inicioVencimento : null}
                    onChange={(newDate: any) => {
                      setInicioVencimento(newDate);
                      setDataInicioVencimento(newDate);
                    }}
                  />
                </Fragment>
              </LocalizationProvider>
            </Box>
            <Box alignSelf="center" marginLeft={1} marginRight={1}>
              <Typography>até</Typography>
            </Box>
            <Box>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Fragment>
                  <DatePicker
                    renderInput={(params) => <TextField {...params} />}
                    inputFormat="dd/MM/yyyy"
                    mask="__/__/____"
                    value={finalVencimento ? finalVencimento : null}
                    onChange={(newDate: any) => {
                      setFinalVencimento(newDate);
                      setDataFinalVencimento(newDate);
                    }}
                  />
                </Fragment>
              </LocalizationProvider>
            </Box>
          </Box>
        </Grid>

        <Grid item xs>
          <Box marginTop={1} marginBottom={-1}>
            <p>Período Pagamento</p>
          </Box>
        </Grid>

        <Grid item xs>
          <Box display="flex">
            <Box>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Fragment>
                  <DatePicker
                    renderInput={(params) => <TextField {...params} />}
                    inputFormat="dd/MM/yyyy"
                    mask="__/__/____"
                    value={inicioPagamento ? inicioPagamento : null}
                    onChange={(newDate: any) => {
                      setInicioPagamento(newDate);
                      setDataInicialPagamento(newDate);
                    }}
                  />
                </Fragment>
              </LocalizationProvider>
            </Box>
            <Box alignSelf="center" marginLeft={1} marginRight={1}>
              <Typography>até</Typography>
            </Box>
            <Box>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Fragment>
                  <DatePicker
                    inputFormat="dd/MM/yyyy"
                    renderInput={(params) => <TextField {...params} />}
                    mask="__/__/____"
                    value={finalPagamento ? finalPagamento : null}
                    onChange={(newDate: any) => {
                      setFinalPagamento(newDate);
                      setDataFinalPagamento(newDate);
                    }}
                  />
                </Fragment>
              </LocalizationProvider>
            </Box>
          </Box>
        </Grid>

        <Grid item xs>
          <Box marginTop={2}>
            <FormControl fullWidth variant="outlined">
              <TextField
                defaultValue={pagadorHolder}
                variant="outlined"
                label="Pagador - Nome"
                onChange={(e) => handlePagadorNome(e.target.value)}
              ></TextField>
            </FormControl>
          </Box>
        </Grid>

        <Grid item xs>
          <Box marginTop={2}>
            <FormControl fullWidth variant="outlined">
              <InputMask
                maskPlaceholder={null}
                mask={'999.999.999-99'}
                value={cpfHolder}
                onChange={(e) => handlePagadorCpf(e.target.value)}
              >
                <TextField variant="outlined" label={'Pagador - CPF'} />
              </InputMask>
            </FormControl>
          </Box>
        </Grid>

        <Grid item xs>
          <Box marginTop={2}>
            <FormControl fullWidth variant="outlined">
              <InputMask
                maskPlaceholder={null}
                mask={'99.999.999/9999-99'}
                value={cnpjHolder}
                onChange={(e) => handlePagadorCnpj(e.target.value)}
              >
                <TextField variant="outlined" label={'Pagador - CNPJ'} />
              </InputMask>
            </FormControl>
          </Box>
        </Grid>
      </Drawer>
    </>
  );
};
