import { Subject } from 'rxjs';

export type ConfirmationDialogTypes =
  | 'delete'
  | 'warning'
  | 'confirmation'
  | undefined;

interface IConfirmationDialog {
  message: string;
  onCancel?: () => void;
  onConfirm?: () => void;
  type: ConfirmationDialogTypes;
}

const confirmationDialogSubject = new Subject<IConfirmationDialog>();

export const ConfirmationDialogService =
  confirmationDialogSubject.asObservable();

export const confirm = (
  message: string,
  type: ConfirmationDialogTypes,
  onConfirm?: () => void,
  onCancel?: () => void,
) => {
  confirmationDialogSubject.next({
    type,
    message,
    onCancel,
    onConfirm,
  });
};
