import React, { useEffect } from 'react';
import { DetalheTransacao } from 'pages/transacoes/DetalheTransacao';
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
  Navigate,
} from 'react-router-dom';
import {
  Dashboard,
  Transacoes,
  Adquirentes,
  Estabelecimentos,
  EstabelecimentoConfig,
  Configuracoes,
  Recorrencia,
  PermissoesDeUsuario,
  RecorrenciaDetalhe,
  ListaDePerfil,
  ListagemCobrancas,
  FormularioCartao,
  Pix,
  ListagemBoletos,
  Mycredentials,
} from '../pages';
import { useUser } from 'shared/hooks';
import { PixKeyConfig } from 'pages/myCredentials/pixParameters/PixKeyConfig';
import { BoletoConfig } from 'pages/myCredentials/boletoParameters/BoletoConfig';
import { LinksPagamentos } from 'pages/links-pagamento/LinksPagamento';

export const AppRoutes: React.FC = () => {
  const { menuOptions } = useUser();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (menuOptions.Funcionalidades.length > 0) {
      if (
        menuOptions.Funcionalidades.filter(
          (funcionalidade) =>
            location.pathname.includes(funcionalidade.FuncionalidadeCaminho) ||
            location.pathname.includes('/formulario-cartao') ||
            location.pathname.includes('/formulario-pix'),
        ).length === 0
      ) {
        navigate('/pagina-inicial');
      }
    }
  }, [menuOptions, navigate, location.pathname]);

  return (
    <Routes>
      <Route path="/transacoes" element={<Transacoes />} />

      <Route path="/transacoes/:idTransacao" element={<DetalheTransacao />} />

      <Route path="/adquirentes" element={<Adquirentes />} />

      <Route path="/estabelecimentos" element={<Estabelecimentos />} />

      <Route path="/configuracoes" element={<Configuracoes />} />

      <Route path="/recorrencia" element={<Recorrencia />} />
      <Route
        path="/recorrencia/:idRecorrencia"
        element={<RecorrenciaDetalhe />}
      />

      <Route path="/permissoes-de-usuario" element={<PermissoesDeUsuario />} />

      <Route path="/novo-usuario" element={<ListaDePerfil />} />

      <Route
        path="/estabelecimentos/:cnpj/configuracao/:codigoEstabelecimento"
        element={<EstabelecimentoConfig />}
      />

      {/* <Route path="/configuracaoPix" element={<ContasPix />} />

      <Route path="/configuracaopix/nova-conta" element={<NovaConta />} />

      <Route
        path="/configuracaopix/editar-conta/:idPix"
        element={<EditarConta />}
      /> */}

      <Route path="/minhas-credenciais" element={<Mycredentials />} />

      <Route path="/nova-chave-pix" element={<PixKeyConfig />} />

      <Route path="/nova-configuracao" element={<BoletoConfig />} />

      <Route path="/editar-configuracao/:id" element={<BoletoConfig />} />

      <Route path="/editar-chave-pix/:id" element={<PixKeyConfig />} />

      <Route path="/cobrancaspix" element={<ListagemCobrancas />} />
      <Route path="/cobrancasboletos" element={<ListagemBoletos />} />
      <Route path="/formulario-cartao" element={<FormularioCartao />} />
      <Route path="/formulario-pix" element={<Pix />} />

      <Route path="/links-pagamento" element={<LinksPagamentos />} />

      <Route path={'/pagina-inicial'} element={<Dashboard />} />
      <Route path={'/dashboard'} element={<Dashboard />} />
      <Route path={'/links-pagamento'} element={<LinksPagamentos />} />
      <Route path="/" element={<Dashboard />} />
      <Route path={'*'} element={<Navigate to="/pagina-inicial" />} />
    </Routes>
  );
};
