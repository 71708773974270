/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { Edit } from '@mui/icons-material';
import {
  IPermissao,
  IPrepararPut,
  UsuariosService,
} from 'shared/services/api/permissoes-usuario/PermissoesUsuario';
import { feedback, FormatService } from 'shared/services';
import { EstabelecimentoArvore } from './components/EstabelecimentoArvore';

interface IProps {
  requestPut: boolean;
  loadRequestPut: React.Dispatch<React.SetStateAction<boolean>>;
  guid: string;
}

export const EditarPermissao: React.FC<IProps> = ({
  loadRequestPut,
  requestPut,
  guid,
}) => {
  const [openEdit, setOpenEdit] = React.useState(false);

  const handleClickOpenEdit = () => {
    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const [permissaoUsuario, setPermissaoUsuario] = useState<
    IPermissao | undefined
  >();

  const [mail, setMail] = useState('');
  const [perfilID, setPerfilID] = useState('');
  const [login, setLogin] = useState<string | undefined>();
  const [isCarregando, setIsCarregando] = useState(false);
  const [estabelecimentos, setEstabelecimentos] = useState<string[]>([]);
  const [idLoginService, setIdLoginService] = useState(0);

  const [prepararPut, setPrepararPut] = useState<IPrepararPut>(
    {} as IPrepararPut,
  );

  const getPermissaoCallback = useCallback(async () => {
    setIsCarregando(true);
    async function getPermissao() {
      try {
        const response = await UsuariosService.getPermissao(guid);
        setPermissaoUsuario(response);
        setMail(response?.PermissaoUsuario.Email);

        if(response?.PermissaoUsuario.Email) {
          UsuariosService.getValidarUsuario(response?.PermissaoUsuario.Email).then((response) => {
            if(response) {
              setIdLoginService(response.Codigo);
            }
          });
        }

        setLogin(response?.PermissaoUsuario.Login);
        setPerfilID(response?.PermissaoUsuario.PerfilUsuarioId);
        setEstabelecimentos(response?.PermissaoUsuario.EstabelecimentosDoUsuario);
      } catch (error: any) {
        feedback(error.Mensagem, 'error');
      } finally {
        setIsCarregando(false);
      }
    }
    getPermissao();
  }, [guid]);

  const handleMail = useCallback((e: string) => {
    setMail(e);
  }, []);

  useEffect(() => {
    permissaoUsuario &&
      setPrepararPut({
        Id: permissaoUsuario.PermissaoUsuario.Id,
        Login: login,
        Email: mail,
        IdLoginService: idLoginService,
        PerfilUsuarioId: perfilID,
        Estabelecimentos: [...estabelecimentos],
      });
  }, [permissaoUsuario, login, mail, perfilID, estabelecimentos, idLoginService]);

  const enviarForm = useCallback(async () => {
    UsuariosService.putUsuario(prepararPut).then((response) => {
      loadRequestPut(!requestPut);
      if (response) {
        feedback(response.mensagens[0].Mensagem, 'error');
      } else {
        feedback('Salvo com sucesso', 'success');
      }
    });
  }, [loadRequestPut, prepararPut, requestPut]);

  return (
    <>
      {isCarregando ? (
        <CircularProgress
          style={{ position: 'relative', left: 6, top: 7 }}
          size={18}
        />
      ) : (
        <IconButton
          onClick={() => {
            handleClickOpenEdit(), getPermissaoCallback();
          }}
          style={{ position: 'relative', left: 6 }}
        >
          <Edit color="primary" />
        </IconButton>
      )}

      <Dialog
        maxWidth="xl"
        open={openEdit}
        onClose={handleCloseEdit}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="Editar dados do usuário">
          Editar dados do usuário
        </DialogTitle>
        <DialogContent>
          <Grid direction="column" container style={{ width: 800 }}>
            <Grid container item style={{ marginBottom: 20 }}>
              <Grid item xs>
                <TextField
                  disabled={true}
                  variant="outlined"
                  fullWidth
                  label="Email"
                  value={mail}
                  onChange={(e) => handleMail(e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container item style={{ marginBottom: 10 }}>
              <Grid item xs style={{ marginRight: 20 }}>
                <TextField
                  disabled={true}
                  fullWidth
                  variant="outlined"
                  label="Login"
                  value={login || ''}
                />
              </Grid>
              <Grid item xs>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>Perfil</InputLabel>
                  <Select label="Perfil" value={perfilID}>
                    {permissaoUsuario?.PerfisUsuario.map((user, index) => (
                      <MenuItem
                        value={user.Id}
                        onClick={() => {
                          setPerfilID(user.Id);
                        }}
                        key={index}
                      >
                        {user.Descricao}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid style={{ marginBottom: 30 }}></Grid>
          </Grid>
          <EstabelecimentoArvore
            setEstabelecimentos={(estabs) => {
              setEstabelecimentos([
                ...estabs.map((cnpj) => cnpj.replace(/[^\w\s]/gi, '')),
              ]);
            }}
            selecionados={estabelecimentos.map((cnpj) =>
              cnpj ? FormatService.formatCpfCnpj(cnpj) : '',
            )}
          />
        </DialogContent>
        <DialogActions style={{ marginRight: 16, marginBottom: 18 }}>
          <Button variant="outlined" onClick={handleCloseEdit} color="primary">
            Cancelar
          </Button>
          <Button
            variant="contained"
            disabled={isCarregando}
            onClick={() => {
              handleCloseEdit(), enviarForm();
            }}
            color="primary"
            autoFocus
          >
            Salvar
          </Button>
        </DialogActions>
        {isCarregando && (
          <LinearProgress style={{ maxHeight: '15px' }} color="secondary" />
        )}
      </Dialog>
    </>
  );
};
