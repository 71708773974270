import React, { useCallback, useEffect, useState, useMemo } from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Chip,
  Typography,
} from '@mui/material';
import {
  IFuncionalidade,
  ITransacao,
  ITransacaoFiltro,
  TransacoesService,
} from 'shared/services/api';
import { useNavigate } from 'react-router';
import { UltimasTransacoesSkeleton } from './UltimasTransacoesSkeleton';
import { useUser } from 'shared/hooks';
import { makeStyles } from '@mui/styles';
import { Theme, useTheme } from '@mui/material/styles';

export const UltimasTransacoes: React.FC = () => {
  const theme: Theme = useTheme();

  const useStyles = makeStyles({
    chipRed: {
      backgroundColor: `${theme.palette.error.main} !important`,
      color: `${'white'} !important`,
    },
    chipGreen: {
      color: `${'white'} !important`,
      backgroundColor: `${theme.palette.success.main} !important`,
    },
    chipGreenOutLined: {
      color: `${theme.palette.success.main} !important`,
      borderColor: `${theme.palette.success.main} !important`,
    },
    chipRedOutLined: {
      color: `${theme.palette.error.main} !important`,
      borderColor: `${theme.palette.error.main} !important`,
    },
    tableRow: {
      cursor: 'pointer',
    },
    FDrawer: {
      paper: {
        top: 70,
        width: 400,
        height: '90%',
        marginRight: theme.spacing(1),
        borderRadius: theme.spacing(1),
      },
    },
  });

  const classes = useStyles();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [transacoes, setTransacoes] = useState<ITransacao[]>([]);
  const transacaoFiltros = useMemo<ITransacaoFiltro>(() => {
    return {
      documentoOrigem: '',
      origem: '',
      adquirente: '',
      nomeCartao: '',
      atualizadoEm: '',
      situacao: '',
      tid: '',
      valor: '',
      cartao: '',
      estabelecimento: '',
      tipoTransacao: '',
      somenteCapturadasNaoLiquidadas: false,
    };
  }, []);

  useEffect(() => {
    TransacoesService.getTransacoes(10, 1, transacaoFiltros).then(
      (response) => {
        if (response) {
          setTransacoes(response.data?.Transacoes || []);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      },
    );
  }, [transacaoFiltros]);

  const { menuOptions } = useUser();

  const checkIfHasPermission = useMemo(() => {
    const checkPermission = menuOptions?.Funcionalidades.map(
      (item: IFuncionalidade) => item.FuncionalidadeId,
    ).includes(3);
    return !checkPermission;
  }, [menuOptions?.Funcionalidades]);

  const valorFormat = useCallback((value: number) => {
    const valor = value.toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
    });

    return valor;
  }, []);

  return isLoading ? (
    <UltimasTransacoesSkeleton />
  ) : (
    <Card>
      <CardHeader title={'Últimas transações'}></CardHeader>
      <CardContent>
        <TableContainer component={Paper} style={{ maxHeight: 220 }}>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell align="left">Doc. origem</TableCell>
                <TableCell align="left">Valor</TableCell>
                <TableCell align="left">Nome cartão</TableCell>
                <TableCell align="left">Cartão</TableCell>
                <TableCell align="left">Atualizado em</TableCell>
                <TableCell align="left">Situação</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transacoes.length > 0 ? (
                transacoes.map((transacao) => (
                  <TableRow
                    key={transacao.Guid}
                    className={classes.tableRow}
                    hover
                    onClick={() => navigate(`/transacoes/${transacao.Guid}`)}
                  >
                    <TableCell align="left" component="th" scope="row">
                      {transacao.DocumentoOrigem}
                    </TableCell>
                    <TableCell align="left">
                      {valorFormat(parseFloat(transacao.Valor))}
                    </TableCell>
                    <TableCell align="left">
                      {transacao.NomeTitularCartao}
                    </TableCell>
                    <TableCell align="left">{transacao.NumeroCartao}</TableCell>
                    <TableCell align="left">{transacao.AtualizadoEm}</TableCell>
                    <TableCell align="center">
                      <Chip
                        className={
                          transacao.NomeSituacao === 'Autorizada'
                            ? classes.chipGreenOutLined
                            : transacao.NomeSituacao === 'Cancelada'
                            ? classes.chipRed
                            : transacao.NomeSituacao === 'Não Autorizada'
                            ? classes.chipRedOutLined
                            : classes.chipGreen
                        }
                        variant={
                          transacao.NomeSituacao === 'Autorizada'
                            ? 'outlined'
                            : transacao.NomeSituacao === 'Não Autorizada'
                            ? 'outlined'
                            : 'filled'
                        }
                        label={transacao.NomeSituacao}
                      />
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <Box padding={2}>
                  <Typography color="secondary">
                    Nenhum registro encontrado...
                  </Typography>
                </Box>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
      <CardActions>
        <Box paddingLeft={83} marginBottom={2}>
          <Button
            disabled={checkIfHasPermission}
            size="small"
            color={'primary'}
            variant={'contained'}
            onClick={() => {
              navigate(`/transacoes`);
            }}
          >
            Ver todos
          </Button>
        </Box>
      </CardActions>
    </Card>
  );
};
