import React from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Skeleton,
} from '@mui/material';

export const Loading: React.FC = () => {
  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Id origem</TableCell>
              <TableCell>Sistema de origem</TableCell>
              <TableCell>Forma de pagamento</TableCell>
              <TableCell>Descrição</TableCell>
              <TableCell>Adquirente</TableCell>
              <TableCell align="right">Valor</TableCell>
              <TableCell>Data de vencimento</TableCell>
              <TableCell>Situação</TableCell>
              <TableCell>Link</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <Skeleton variant="text" width={80} height={38} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width={80} height={38} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width={80} height={38} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width={80} height={38} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width={80} height={38} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width={80} height={38} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width={80} height={38} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width={80} height={38} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width={80} height={38} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Skeleton variant="text" width={80} height={38} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width={80} height={38} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width={80} height={38} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width={80} height={38} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width={80} height={38} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width={80} height={38} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width={80} height={38} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width={80} height={38} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width={80} height={38} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Skeleton variant="text" width={80} height={38} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width={80} height={38} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width={80} height={38} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width={80} height={38} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width={80} height={38} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width={80} height={38} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width={80} height={38} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width={80} height={38} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width={80} height={38} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
