import React, { useEffect, useState } from 'react';

import { Box, Card, CardContent, CardHeader, Grid } from '@mui/material';
import { TotalBullet } from './TotalBullet';
import { TotalSkeleton } from './TotalSkeleton';
import { colorByStatus, DashboardService, ITotal } from 'shared/services/api';

export const Total: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<ITotal>({} as ITotal);

  useEffect(() => {
    DashboardService.getTotal().then((data) => {
      if (data) {
        setData(data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    });
  }, []);

  return !isLoading ? (
    <Card>
      <CardHeader
        title={'Transações totais'}
        subheader={'Quantidade de transações por status'}
      ></CardHeader>
      <CardContent>
        <Box>
          <Box marginTop={2} marginBottom={0}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TotalBullet
                  color={''}
                  value={data?.Total || 0}
                  description={'Total geral'}
                />
              </Grid>
              <Grid item xs={6}>
                <TotalBullet
                  color={colorByStatus.capturada.borderColor}
                  value={data?.Data[1] || 0}
                  description={data?.Label[1] || ''}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TotalBullet
                  color={colorByStatus.autorizada.borderColor}
                  value={data?.Data[0] || 0}
                  description={data?.Label[0] || ''}
                />
              </Grid>
              <Grid item xs={6}>
                <TotalBullet
                  color={colorByStatus.cancelada.borderColor}
                  value={data?.Data[2] || 0}
                  description={data?.Label[2] || ''}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TotalBullet
                  color={colorByStatus.emAutorizacao.borderColor}
                  value={data?.Data[3] || 0}
                  description={data?.Label[3] || ''}
                />
              </Grid>
              <Grid item xs={6}>
                <TotalBullet
                  color={colorByStatus.naoAutorizado.borderColor}
                  value={data?.Data[4] || 0}
                  description={data?.Label[4] || ''}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </CardContent>
    </Card>
  ) : (
    <TotalSkeleton />
  );
};
