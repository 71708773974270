import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Grid,
  Paper,
  Theme,
  Table,
  Button,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  TableHead,
  TextField,
  IconButton,
  FormControl,
  TableContainer,
  FormControlLabel,
  Tooltip,
} from '@mui/material';
import { Delete, Edit, Add } from '@mui/icons-material/';
import { green, red } from '@mui/material/colors';
import { uuid } from 'uuidv4';
import { makeStyles } from '@mui/styles';

import {
  ILoja,
  IBandeiraLoja,
  IAdquirente,
  AdquirenteService,
  IBandeiras,
  AdquirenteConfigService,
} from 'shared/services/api';
import { feedback, confirm } from 'shared/services';
import {
  Modal,
  Section,
  TableSkeleton,
  AutocompleteComponent,
} from 'shared/components';

const useStyles = makeStyles((theme: Theme) => ({
  appbar: {
    marginTop: theme.spacing(2),
    backgroundColor: '#ecf5ff',
    boxShadow: 'none',
    borderLeft: `${theme.spacing(1 / 2)}px solid ${theme.palette.primary.main}`,
  },
  toolbarTitle: {
    flexGrow: 1,
    color: `${theme.palette.primary.main} !important`,
  },
  table: {
    marginTop: theme.spacing(1),
  },
  cellSmall: {
    width: theme.spacing(5),
    textAlign: 'center',
    whiteSpace: 'nowrap',
  },
  active: {
    width: theme.spacing(1 * 8),
    color: green[600],
    backgroundColor: '#ebf7ed',
    borderRadius: 50,
    textAlign: 'center',
  },
  inactive: {
    width: theme.spacing(1 * 8),
    color: red[600],
    backgroundColor: '#e5393530',
    borderRadius: 50,
    textAlign: 'center',
  },
  btnAction: {
    margin: theme.spacing(0, 1 / 2),
  },

  btnActionDisabled: {
    margin: theme.spacing(0, 1 / 2),
    cursor: 'default',
  },
}));

interface ILojasProps {
  isLoading: boolean;
  lojas: ILoja[];
  setLojas(value: ILoja[]): void;
  setLojaTemp(value: ILoja): void;
}

export const Lojas: React.FC<ILojasProps> = ({
  lojas,
  setLojas,
  isLoading,
  setLojaTemp,
}) => {
  const [edit, setEdit] = useState(false);
  const [adquirentes, setAdquirentes] = useState<IAdquirente[]>([]);
  const [bandeiras, setBandeiras] = useState<IBandeiras[]>([]);
  const [bandeirasSel, setBandeirasSel] = useState<IBandeiras[]>([]);
  const [lojaEstabelecimento, setLojaEstabelecimento] = useState<ILoja>(
    {} as ILoja,
  );
  const [modalAberto, setModalAberto] = useState(false);
  const [valido, setValido] = useState({
    nome: true,
    adquirente: true,
    merchantId: true,
    merchantKey: true,
    sellerId: true,
    clientId: true,
    clientSecret: true,
    pv: true,
    token: true,
    accountId: true,
    publicKey: true,
    secretKey: true,
  });

  const classes = useStyles();

  const handleNovoModal = useCallback(() => {
    setValido({
      nome: true,
      adquirente: true,
      merchantId: true,
      merchantKey: true,
      sellerId: true,
      clientId: true,
      clientSecret: true,
      pv: true,
      token: true,
      accountId: true,
      publicKey: true,
      secretKey: true,
    });
    setLojaEstabelecimento({
      editavel: true,
      LojaChaveTemp: uuid(),
      IdLoja: null,
      LojaNome: '',
      IdAdquirente: 0,
      LojaAtivo: true,
      AntifraudeHabilitado: false,
    } as ILoja);

    setEdit(false);
    setModalAberto(true);
  }, []);

  const validaInputsModal = useCallback(() => {
    let ehValido = true;

    if (!lojaEstabelecimento.LojaNome) {
      setValido(
        (state) =>
          (state = {
            ...state,
            nome: false,
          }),
      );
      ehValido = false;
    } else if (!lojaEstabelecimento.IdAdquirente) {
      setValido(
        (state) => (state = { ...state, nome: true, adquirente: false }),
      );
      ehValido = false;
    } else if (lojaEstabelecimento.IdAdquirente === 1) {
      if (!lojaEstabelecimento.LojaGetnet?.SellerId) {
        setValido(
          (state) =>
            (state = {
              ...state,
              nome: true,
              adquirente: true,
              sellerId: false,
            }),
        );
        ehValido = false;
      } else if (!lojaEstabelecimento.LojaGetnet?.ClientId) {
        setValido(
          (state) =>
            (state = {
              ...state,
              nome: true,
              adquirente: true,
              sellerId: true,
              clientId: false,
            }),
        );
        ehValido = false;
      } else if (!lojaEstabelecimento.LojaGetnet?.ClientSecret) {
        setValido(
          (state) =>
            (state = {
              ...state,
              nome: true,
              adquirente: true,
              sellerId: true,
              clientId: true,
              clientSecret: false,
            }),
        );
        ehValido = false;
      }
    } else if (lojaEstabelecimento.IdAdquirente === 2) {
      if (!lojaEstabelecimento.LojaCielo?.MerchantId) {
        setValido(
          (state) =>
            (state = {
              ...state,
              nome: true,
              adquirente: true,
              merchantId: false,
            }),
        );
        ehValido = false;
      } else if (!lojaEstabelecimento.LojaCielo?.MerchantKey) {
        setValido(
          (state) =>
            (state = {
              ...state,
              nome: true,
              adquirente: true,
              merchantId: true,
              merchantKey: false,
            }),
        );
        ehValido = false;
      }
    } else if (lojaEstabelecimento.IdAdquirente === 3) {
      if (!lojaEstabelecimento.LojaRede?.PV) {
        setValido(
          (state) =>
            (state = {
              ...state,
              nome: true,
              adquirente: true,
              pv: false,
            }),
        );
        ehValido = false;
      } else if (!lojaEstabelecimento.LojaRede?.Token) {
        setValido(
          (state) =>
            (state = {
              ...state,
              nome: true,
              adquirente: true,
              pv: true,
              token: false,
            }),
        );
        ehValido = false;
      }
    }
    return ehValido;
  }, [lojaEstabelecimento]);

  const handleEditaLoja = useCallback(
    (index?: number) => {
      setValido({
        nome: true,
        adquirente: true,
        merchantId: true,
        merchantKey: true,
        sellerId: true,
        clientId: true,
        clientSecret: true,
        pv: true,
        token: true,
        accountId: true,
        publicKey: true,
        secretKey: true,
      });
      const bandeirasSelecionadas: IBandeiras[] = [];

      if (lojas[Number(index)].BandeirasLoja) {
        lojas[Number(index)].BandeirasLoja.map((item) =>
          bandeirasSelecionadas.push({
            IdBandeira: item.IdBandeira,
            NomeBandeira: item.BandeiraNome,
            Selecionado: 1,
          }),
        );
      }
      setLojaEstabelecimento(lojas[Number(index)]);
      setBandeirasSel(bandeirasSelecionadas);
      setEdit(true);
      setModalAberto(true);
    },
    [lojas],
  );

  const handleDeleteLoja = useCallback(
    (indexTbl?: number) => {
      const onConfirm = () => {
        if (indexTbl !== undefined) {
          const lojaAtualizada: ILoja = lojas[indexTbl];
          setLojaTemp({ ...lojaAtualizada, BandeirasLoja: [] });
        }

        const lojasFiltradas = lojas.filter((_, index) => indexTbl !== index);

        setLojas(lojasFiltradas);
        feedback('A loja foi excluída com sucesso!', 'success');
      };

      confirm('Confirma a exclusão do registro?', 'delete', onConfirm);
    },
    [lojas, setLojaTemp, setLojas],
  );

  const handleConfirmaLoja = useCallback(() => {
    if (validaInputsModal()) {
      const index = lojas.findIndex((item) =>
        !lojaEstabelecimento.LojaChaveTemp
          ? item.IdLoja === lojaEstabelecimento.IdLoja
          : item.LojaChaveTemp === lojaEstabelecimento.LojaChaveTemp,
      );

      if (index !== -1) {
        lojas[index] = lojaEstabelecimento;
        setLojas(lojas);
        setLojaTemp(lojaEstabelecimento);
      } else {
        setLojas([...lojas, lojaEstabelecimento]);
        setLojaTemp(lojaEstabelecimento);
      }
      setModalAberto(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validaInputsModal, lojas, lojaEstabelecimento, setLojas, setLojaTemp]);

  const handleSetBandeiras = useCallback(
    (value: IBandeiras[]) => {
      const BandeirasLoja: IBandeiraLoja[] = [];

      value.map((item) =>
        BandeirasLoja.push({
          IdBandeira: item.IdBandeira,
          BandeiraNome: item.NomeBandeira,
        }),
      );

      setBandeirasSel(value);
      setLojaEstabelecimento({ ...lojaEstabelecimento, BandeirasLoja });
    },
    [lojaEstabelecimento],
  );

  const bandeirasInput = useCallback(() => {
    return (
      <Grid item xs={12}>
        <AutocompleteComponent
          loading={isLoading}
          multiple
          id="tags-outlined"
          options={bandeiras}
          filterSelectedOptions
          getOptionLabel={(option) => option.NomeBandeira}
          // getOptionSelected={(option: number, value : number) =>
          //   option.IdBandeira === value.IdBandeira
          // }
          value={bandeirasSel}
          onChange={(_, value) => handleSetBandeiras(value)}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label="Bandeiras *" />
          )}
        />
      </Grid>
    );
  }, [bandeiras, handleSetBandeiras, bandeirasSel, isLoading]);

  const cieloInputs = useCallback(
    (MerchantId: string, MerchantKey: string) => {
      return (
        <>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Merchant ID *"
              variant="outlined"
              error={!valido.merchantId}
              helperText={!valido.merchantId && 'Campo obrigatório!'}
              value={MerchantId ? MerchantId : ''}
              onChange={(e) =>
                setLojaEstabelecimento({
                  ...lojaEstabelecimento,
                  LojaCielo: {
                    ...lojaEstabelecimento.LojaCielo,
                    MerchantId: e.target.value,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Merchant Key *"
              variant="outlined"
              error={!valido.merchantKey}
              helperText={!valido.merchantKey && 'Campo obrigatório!'}
              value={MerchantKey ? MerchantKey : ''}
              onChange={(e) =>
                setLojaEstabelecimento({
                  ...lojaEstabelecimento,
                  LojaCielo: {
                    ...lojaEstabelecimento.LojaCielo,
                    MerchantKey: e.target.value,
                  },
                })
              }
            />
          </Grid>
        </>
      );
    },
    [lojaEstabelecimento, valido],
  );

  const getnetInputs = useCallback(
    (SellerId: string, ClientId: string, ClientSecret: string) => {
      return (
        <>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Seller ID *"
              variant="outlined"
              value={SellerId ? SellerId : ''}
              error={!valido.sellerId}
              helperText={!valido.sellerId && 'Campo obrigatório!'}
              onChange={(e) =>
                setLojaEstabelecimento({
                  ...lojaEstabelecimento,
                  LojaGetnet: {
                    ...lojaEstabelecimento.LojaGetnet,
                    SellerId: e.target.value,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Client ID *"
              variant="outlined"
              value={ClientId ? ClientId : ''}
              error={!valido.clientId}
              helperText={!valido.clientId && 'Campo obrigatório!'}
              onChange={(e) =>
                setLojaEstabelecimento({
                  ...lojaEstabelecimento,
                  LojaGetnet: {
                    ...lojaEstabelecimento.LojaGetnet,
                    ClientId: e.target.value,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Client Secret *"
              variant="outlined"
              value={ClientSecret ? ClientSecret : ''}
              error={!valido.clientSecret}
              helperText={!valido.clientSecret && 'Campo obrigatório!'}
              onChange={(e) =>
                setLojaEstabelecimento({
                  ...lojaEstabelecimento,
                  LojaGetnet: {
                    ...lojaEstabelecimento.LojaGetnet,
                    ClientSecret: e.target.value,
                  },
                })
              }
            />
          </Grid>
        </>
      );
    },
    [lojaEstabelecimento, valido],
  );

  const redeInputs = useCallback(
    (PV: string, Token: string) => {
      return (
        <>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="PV *"
              variant="outlined"
              error={!valido.pv}
              helperText={!valido.pv && 'Campo obrigatório!'}
              value={PV ? PV : ''}
              onChange={(e) =>
                setLojaEstabelecimento({
                  ...lojaEstabelecimento,
                  LojaRede: {
                    ...lojaEstabelecimento.LojaRede,
                    PV: e.target.value,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Token *"
              variant="outlined"
              error={!valido.token}
              helperText={!valido.token && 'Campo obrigatório!'}
              value={Token ? Token : ''}
              onChange={(e) =>
                setLojaEstabelecimento({
                  ...lojaEstabelecimento,
                  LojaRede: {
                    ...lojaEstabelecimento.LojaRede,
                    Token: e.target.value,
                  },
                })
              }
            />
          </Grid>
        </>
      );
    },
    [lojaEstabelecimento, valido],
  );

  const pagarmeInputs = useCallback(
    (PublicKey: string, SecretKey: string, AccountId: string) => {
      return (
        <>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Public Key *"
              variant="outlined"
              error={!valido.token}
              helperText={!valido.token && 'Campo obrigatório!'}
              value={PublicKey ? PublicKey : ''}
              onChange={(e) =>
                setLojaEstabelecimento({
                  ...lojaEstabelecimento,
                  LojaPagarme: {
                    ...lojaEstabelecimento.LojaPagarme,
                    PublicKey: e.target.value,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Secret Key *"
              variant="outlined"
              error={!valido.token}
              helperText={!valido.token && 'Campo obrigatório!'}
              value={SecretKey ? SecretKey : ''}
              onChange={(e) =>
                setLojaEstabelecimento({
                  ...lojaEstabelecimento,
                  LojaPagarme: {
                    ...lojaEstabelecimento.LojaPagarme,
                    SecretKey: e.target.value,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="AccountId *"
              variant="outlined"
              error={!valido.token}
              helperText={!valido.token && 'Campo obrigatório!'}
              value={AccountId ? AccountId : ''}
              onChange={(e) =>
                setLojaEstabelecimento({
                  ...lojaEstabelecimento,
                  LojaPagarme: {
                    ...lojaEstabelecimento.LojaPagarme,
                    AccountId: e.target.value,
                  },
                })
              }
            />
          </Grid>
        </>
      );
    },
    [lojaEstabelecimento, valido],
  );

  useEffect(() => {
    (async () => {
      const data = await AdquirenteService.getAdquirentes();

      data ? setAdquirentes(data) : setAdquirentes([]);
    })();
  }, []);

  useEffect(() => {
    if (lojaEstabelecimento.IdAdquirente) {
      (async () => {
        const data = await AdquirenteConfigService.getBandeirasAdquirente(
          lojaEstabelecimento.IdAdquirente,
        );

        if (data) {
          setBandeiras(data.filter((item) => item.Selecionado));
          if (!edit) setBandeirasSel([]);
        } else {
          setBandeiras([]);
        }
      })();
    }
  }, [lojaEstabelecimento.IdAdquirente, edit]);

  return (
    <>
      <Section
        title="Lojas"
        tools={
          <Box width={160}>
            <Button
              fullWidth
              color="primary"
              variant="contained"
              startIcon={<Add />}
              onClick={handleNovoModal}
            >
              Novo
            </Button>
          </Box>
        }
      >
        {isLoading ? (
          <TableSkeleton rectWidth="30%" columns={3} rowsBody={3} />
        ) : (
          <TableContainer component={Paper} className={classes.table}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.cellSmall}>Ações</TableCell>
                  <TableCell>Nome</TableCell>
                  <TableCell>Adquirente</TableCell>
                  <TableCell className={classes.cellSmall}>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {lojas && lojas.length > 0 ? (
                  lojas.map((item, index) => (
                    <TableRow hover key={item.IdLoja}>
                      <TableCell className={classes.cellSmall}>
                        {item.PossuiTransacoes ? (
                          <Tooltip
                            title="Loja não pode ser excluída pois possui transações vinculadas a ela"
                            aria-label="Loja não pode ser excluída pois possui transações vinculadas a ela"
                          >
                            <IconButton
                              size="small"
                              aria-label="delete"
                              className={classes.btnActionDisabled}
                            >
                              <Delete color="disabled" />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          <IconButton
                            size="small"
                            color="primary"
                            aria-label="delete"
                            className={classes.btnAction}
                            onClick={() => handleDeleteLoja(index)}
                          >
                            <Delete />
                          </IconButton>
                        )}
                        <IconButton
                          size="small"
                          color="primary"
                          aria-label="edit"
                          className={classes.btnAction}
                          onClick={() => handleEditaLoja(index)}
                        >
                          <Edit />
                        </IconButton>
                      </TableCell>
                      <TableCell>{item.LojaNome}</TableCell>
                      <TableCell>{item.AdquirenteNome}</TableCell>
                      <TableCell className={classes.cellSmall}>
                        {item.LojaAtivo ? 'Ativo' : 'Inativo'}
                        {/* <Box
                          className={
                            item.LojaAtivo ? classes.active : classes.inactive
                          }
                        >
                          <Box pt={0.5} pb={0.5}>
                            {item.LojaAtivo ? 'Ativo' : 'Inativo'}
                          </Box>
                        </Box> */}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableCell colSpan={4}>
                    Não há registros para exibir...
                  </TableCell>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Section>

      <Modal
        title="Loja"
        size="lg"
        isOpen={modalAberto}
        closeModal={() => setModalAberto(false)}
        handleConfirm={handleConfirmaLoja}
      >
        <Box mt={2}>
          <FormControl component="fieldset" fullWidth>
            <Grid container direction="column" spacing={2}>
              <Box display={'flex'} marginLeft={3} marginBottom={2}>
                <Grid item xs={1.1}>
                  <FormControlLabel
                    label="Ativo"
                    control={
                      <Checkbox
                        color="primary"
                        checked={!!lojaEstabelecimento.LojaAtivo}
                        onChange={(e) =>
                          setLojaEstabelecimento({
                            ...lojaEstabelecimento,
                            LojaAtivo: e.target.checked,
                          })
                        }
                      />
                    }
                  />
                </Grid>

                {lojaEstabelecimento.AdquirenteNome === 'Getnet' ? (
                  <>
                    <Grid item>
                      <FormControlLabel
                        label="Antifraude Habilitado"
                        control={
                          <Checkbox
                            color="primary"
                            checked={!!lojaEstabelecimento.AntifraudeHabilitado}
                            onChange={(e) =>
                              setLojaEstabelecimento({
                                ...lojaEstabelecimento,
                                AntifraudeHabilitado: e.target.checked,
                              })
                            }
                          />
                        }
                      />
                    </Grid>
                  </>
                ) : (
                  <></>
                )}

                {lojaEstabelecimento.AdquirenteNome === 'Pagar.me' ? (
                  <>
                    <Grid item>
                      <FormControlLabel
                        label="Antifraude Habilitado"
                        control={
                          <Checkbox color="primary" checked={true} disabled />
                        }
                      />
                    </Grid>
                  </>
                ) : (
                  <></>
                )}
              </Box>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Nome *"
                  variant="outlined"
                  value={lojaEstabelecimento.LojaNome}
                  error={!valido.nome}
                  helperText={!valido.nome && 'Campo obrigatório!'}
                  onChange={(e) =>
                    setLojaEstabelecimento({
                      ...lojaEstabelecimento,
                      LojaNome: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  select
                  disabled={!lojaEstabelecimento.editavel}
                  error={!valido.adquirente}
                  helperText={!valido.adquirente && 'Campo obrigatório!'}
                  fullWidth
                  label="Adquirente *"
                  variant="outlined"
                  value={lojaEstabelecimento.IdAdquirente}
                  onChange={(e) => {
                    setLojaEstabelecimento({
                      ...lojaEstabelecimento,
                      IdAdquirente: Number(e.target.value),
                      AdquirenteNome:
                        Number(e.target.value) === 1
                          ? 'Getnet'
                          : Number(e.target.value) === 2
                          ? 'Cielo'
                          : Number(e.target.value) === 3
                          ? 'Rede'
                          : 'Pagar.me',
                    });
                  }}
                >
                  <MenuItem key={-1} value={0} disabled>
                    Selecionar
                  </MenuItem>
                  {adquirentes?.map((item, index) => (
                    <MenuItem key={index} value={item.IdAdquirente}>
                      {item.NomeAdquirente}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              {lojaEstabelecimento.IdAdquirente === 1 &&
                getnetInputs(
                  lojaEstabelecimento.LojaGetnet?.SellerId,
                  lojaEstabelecimento.LojaGetnet?.ClientId,
                  lojaEstabelecimento.LojaGetnet?.ClientSecret,
                )}

              {lojaEstabelecimento.IdAdquirente === 2 &&
                cieloInputs(
                  lojaEstabelecimento.LojaCielo?.MerchantId,
                  lojaEstabelecimento.LojaCielo?.MerchantKey,
                )}

              {lojaEstabelecimento.IdAdquirente === 3 &&
                redeInputs(
                  lojaEstabelecimento.LojaRede?.PV,
                  lojaEstabelecimento.LojaRede?.Token,
                )}

              {lojaEstabelecimento.IdAdquirente === 4 &&
                pagarmeInputs(
                  lojaEstabelecimento.LojaPagarme?.PublicKey,
                  lojaEstabelecimento.LojaPagarme?.SecretKey,
                  lojaEstabelecimento.LojaPagarme?.AccountId,
                )}

              {lojaEstabelecimento.IdAdquirente !== 0 && bandeirasInput()}
            </Grid>
          </FormControl>
        </Box>
      </Modal>
    </>
  );
};
