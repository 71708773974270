/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import {
  IDadosUsuario,
  IPerfisUsuario,
  UsuariosService,
  IPrepararPost,
} from 'shared/services/api/permissoes-usuario/PermissoesUsuario';
import { feedback, FormatService } from 'shared/services';
import { EstabelecimentoArvore } from './components/EstabelecimentoArvore';

interface IProps {
  request: boolean;
  loadRequest: React.Dispatch<React.SetStateAction<boolean>>;
}

export const NovoUsuario: React.FC<IProps> = ({ request, loadRequest }) => {
  const handleClickOpenNovo = () => {
    setOpenNovo(true);
  };

  const handleCloseNovo = () => {
    setOpenNovo(false);
    setEmail('');
    setPerfil('');
    setPerfilBlock(true);
  };

  const handleEmail = useCallback((e: any) => {
    setEmail(e.target.value);
  }, []);

  const [email, setEmail] = useState<string>('');
  const [perfilID, setPerfilID] = useState<string>();
  const [openNovo, setOpenNovo] = React.useState(false);
  const [login, setLogin] = useState<string | undefined>();
  const [isValido, setIsValido] = useState<boolean>(false);
  const [perfil, setPerfil] = useState<string | undefined>();
  const [perfilBlock, setPerfilBlock] = useState<boolean>(true);
  const [idLoginService, setIdLoginService] = useState<number>();
  const [perfis, setPerfis] = useState<IPerfisUsuario[] | undefined>();
  const [dadosDoUsuario, setDadosDoUsuario] = useState<IDadosUsuario>();
  const [prepararPost, setPrepararPost] = useState<IPrepararPost | any>({
    Login: '',
    Email: '',
    IdLoginService: 0,
    PerfilUsuarioId: '',
    Estabelecimentos: []
  });

  const validar = useCallback(async () => {
    async function getValidarUsuario() {
      try {
        const response = await UsuariosService.getValidarUsuario(email);
        setDadosDoUsuario(response);
        setLogin(response?.Login);
        setIdLoginService(response?.Codigo);

        if (response?.ContaConfirmada === true) {
          feedback('Usuário válido', 'success');
        }
      } catch (error: any) {
        feedback('Não foi possivel encontrar o usuário', 'error');
      } finally {
        setPerfilBlock(false);
        setIsValido(true);
      }
    }
    getValidarUsuario();
  }, [email]);

  useEffect(() => {
    async function getNovoPermissao() {
      try {
        const response = await UsuariosService.getNovoPermissao();
        setPerfis(response.PerfisUsuario);
      } catch (error: any) {
        feedback(error.Mensagem, 'error');
      }
    }
    getNovoPermissao();
  }, []);

  const enviarPost = useCallback(async () => {
    try {
      const response = await UsuariosService.postUsuario(prepararPost);
      loadRequest(!request);
      setEmail('');
      setPerfil('');

      if (response) {
        feedback(response.mensagens[0].Mensagem, 'error');
      } else {
        feedback('Salvo com sucesso', 'success');
      }
    } finally {
      setIsValido(false);
    }
  }, [prepararPost, loadRequest, request]);

  useEffect(() => {
    dadosDoUsuario &&
      setPrepararPost({
        Login: login,
        Email: email,
        IdLoginService: idLoginService,
        PerfilUsuarioId: perfilID,
      });
  }, [login, email, perfilID, idLoginService, dadosDoUsuario]);

  return (
    <>
      <Button
        size="large"
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        onClick={handleClickOpenNovo}
      >
        NOVO
      </Button>
      <Dialog maxWidth="xl" open={openNovo} onClose={handleCloseNovo}>
        <DialogTitle id="Dados do usuário">Dados do usuário</DialogTitle>
        <DialogContent>
          <Grid direction="column" container style={{ width: 800 }}>
            <Grid container item style={{ marginBottom: 20 }}>
              <Grid item xs>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel>Email ou usuário</InputLabel>
                  <OutlinedInput
                    onChange={handleEmail}
                    label="Email ou usuário"
                    endAdornment={
                      <InputAdornment position="end">
                        <Button
                          disabled={email?.length === 0}
                          onClick={() => validar()}
                          color="primary"
                          variant="contained"
                        >
                          <SearchIcon />
                          Validar
                        </Button>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container item style={{ marginBottom: 10 }}>
              <Grid item xs style={{ marginRight: 20 }}>
                <TextField
                  //defaultValue={dadosDoUsuario?.Resultado.Login}
                  disabled
                  fullWidth
                  variant="outlined"
                  label="Login"
                ></TextField>
              </Grid>
              <Grid item xs>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>Perfil</InputLabel>
                  <Select disabled={perfilBlock} label="Perfil" value={perfil}>
                    {perfis?.map((perfil, index) => (
                      <MenuItem
                        key={index}
                        value={perfil.Id}
                        onClick={() => {
                          setPerfilID(perfil.Id);
                          setPerfil(perfil.Id);
                        }}
                      >
                        {perfil.Descricao}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid style={{ marginBottom: 30 }}></Grid>
          </Grid>
          <EstabelecimentoArvore setEstabelecimentos={(estabs) => {
              setPrepararPost({...prepararPost, Estabelecimentos: [...estabs.map(cnpj => cnpj.replace(/[^\w\s]/gi, ''))]})
            }} 
            selecionados={prepararPost.Estabelecimentos ? prepararPost.Estabelecimentos.map((cnpj: any) => cnpj ? FormatService.formatCpfCnpj(cnpj) : '') : []}
          />
        </DialogContent>
        <DialogActions style={{ marginRight: 16, marginBottom: 18 }}>
          <Button variant="outlined" onClick={handleCloseNovo} color="primary">
            Cancelar
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              if (isValido === false) {
                feedback('Valide o Email', 'error');
              } else if (perfil === '') {
                feedback('Selecione um perfil', 'error');
              } else {
                handleCloseNovo(), enviarPost();
              }
            }}
            color="primary"
            autoFocus
          >
            Adicionar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
