import React from 'react';

import { Card, CardHeader, CardContent, Box, Skeleton } from '@mui/material';

export const PercentualTransacoesSkeleton: React.FC = () => {
  return (
    <Card>
      <CardHeader
        title={<Skeleton variant="text" width={180} height={30} />}
        subheader={<Skeleton variant="text" width={80} height={30} />}
      />
      <CardContent>
        <Box paddingBottom={1}>
          <Skeleton variant="circular" width={250} height={250} />
        </Box>
      </CardContent>
    </Card>
  );
};
