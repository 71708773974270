import React, { useEffect, useState } from 'react';
import { Card, CardContent, CardHeader } from '@mui/material';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import {

  IMesTransacao,
  colorByStatus,
  IDashboardTransacoes,
  DashboardService,
} from 'shared/services/api';
import { TransacoesSkeleton } from './TransacoesSkeleton';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

export const Transacoes: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [dashboardTransacaoData, setDashboardTransacaoData] =
    useState<IDashboardTransacoes>({} as IDashboardTransacoes);
  const [datasets, setDatasets] = useState<IMesTransacao[]>(
    {} as IMesTransacao[],
  );

  useEffect(() => {
    DashboardService.getAllTransacoes().then((response) => {
      if (response) {
        setDashboardTransacaoData(response);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    });
  }, []);

  useEffect(() => {
    setDatasets(dashboardTransacaoData.Data);
  }, [dashboardTransacaoData]);

  const labels = dashboardTransacaoData.Label;

  const getLabel = (index: number) => {
    if (datasets !== undefined) {
      return datasets[index]?.Label || '';
    } else {
      return '';
    }
  };
  const getData = (index: number) => {
    if (datasets !== undefined) {
      return datasets[index]?.Data || [];
    } else {
      return [];
    }
  };

  const dataGraph = {
    labels,
    datasets: [
      {
        label: getLabel(0),
        data: getData(0),
        borderColor: colorByStatus.autorizada.borderColor,
        backgroundColor: colorByStatus.autorizada.borderColor,
        yAxisID: 'y',
      },
      {
        label: getLabel(1),
        data: getData(1),
        borderColor: colorByStatus.capturada.borderColor,
        backgroundColor: colorByStatus.capturada.borderColor,
        yAxisID: 'y',
      },
      {
        label: getLabel(2),
        data: getData(2),
        borderColor: colorByStatus.cancelada.borderColor,
        backgroundColor: colorByStatus.cancelada.borderColor,
        yAxisID: 'y',
      },
      {
        label: getLabel(3),
        data: getData(3),
        borderColor: colorByStatus.emAutorizacao.borderColor,
        backgroundColor: colorByStatus.emAutorizacao.borderColor,
        yAxisID: 'y',
      },
      {
        label: getLabel(4),
        data: getData(4),
        borderColor: colorByStatus.naoAutorizado.borderColor,
        backgroundColor: colorByStatus.naoAutorizado.borderColor,
        yAxisID: 'y',
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: 'index' as const,
      intersect: false,
    },
    stacked: false,
    plugins: {
      legend: {
        position: 'top' as const,
      },
    },
    scales: {
      y: {
        type: 'linear' as const,
        display: true,
        position: 'left' as const,
      },
    },
  };

  return !isLoading ? (
    <Card>
      <CardHeader title={'Transações '} />
      <CardContent>
        <Line width="400" height="300" options={options} data={dataGraph} />
      </CardContent>
    </Card>
  ) : (
    <TransacoesSkeleton />
  );
};
