import GenericException from 'shared/errors/GenericException';
import { ApiBoleto } from 'shared/services/axios-config';
import {
  IErrorMessage,
  IResultOfAction,
  responseError,
} from 'shared/services/response-error/ResponseError';

export interface IBeneficiario {
  agencia: string;
  contaCorrente: string;
  tipoEndereco: number;
  logradouro: string;
  bairro: string;
  cidade: string;
  codigoCidade: string;
  uf: string;
  cep: string;
  indicadorComprovacao: string;
}

export interface IQrCode {
  url: string;
  txId: string;
  emv: string;
}

export interface IData {
  idBoleto: string;
  idContaBancaria: number;
  numeroTituloCliente: string;
  numeroConvenio: number;
  situacao: string;
  recebidoGVPayEm: Date;
  registradoEm: Date;
  dataRegristroPay: Date;
  dataEmissao: Date;
  dataVencimento: Date;
  numeroDocumento: string;
  codigoCliente: string;
  linhaDigitavel: string;
  codigoBarraNumerico: string;
  numeroContratoCobranca: string;
  beneficiario: IBeneficiario;
  pagador: IPagador;
  qrCode: IQrCode;
  nomeBanco: string;
  valorOriginal: string;
}

export interface IPagador {
  nome: string;
  numeroInscricao: string;
}

export interface IBoletos {
  qtdTotalDeRegistros: number;
  data: IData[];
}

export interface IVencimentos {
  primeiro: string;
  ultimo: string;
}

export interface IEmissoes {
  primeiro: string;
  ultimo: string;
}

export interface ISituacaoBoelto {
  id: number;
  descricao: string;
}

export interface IFiltroBoelto {
  vencimentos: IVencimentos;
  emissoes: IEmissoes;
  situacoes: ISituacaoBoelto[];
}

export interface IHistoricoBoleto {
  dataHora: Date;
  etapa: string;
  request: string;
  response: string;
  statusCode: string;
  observacao: string;
}

const getBoletos = async (
  dataVctoInicial: string,
  dataVctoFinal: string,
  dataEmissaoInicial: string,
  dataEmissaoFinal: string,
  situacao: string[],
  numeroTituloCliente: string,
  pagadorInscricao: string,
  take: number,
  skip: number,
): Promise<IResultOfAction<IBoletos>> => {
  try {
    const { data, status } = await ApiBoleto().get(
      `/boletos?take=${take || ''}&skip=${
        skip || ''
      }&dataVctoInicial=${dataVctoInicial}&dataVctoFinal=${dataVctoFinal}&dataEmissaoInicial=${dataEmissaoInicial}&dataEmissaoFinal=${dataEmissaoFinal}&situacao=${situacao}&numeroTituloCliente=${numeroTituloCliente}&pagadorInscricao=${pagadorInscricao}`,
    );

    if (data && status === 200) {
      const BoeltoReturn: IBoletos = {
        data: data.data.map(
          (data: IData) =>
            ({
              ...data,
              valorOriginal: data.valorOriginal.toString()
            } as IData),
        ),
        qtdTotalDeRegistros: data.qtdTotalDeRegistros || 0,
      };
      const response: any = {
        sucesso: true,
        data: BoeltoReturn,
      };
      return response as IResultOfAction<any>;
    }

    const errorResponse: IResultOfAction<any> = {
      mensagens: [] as IErrorMessage[],
      mensagensCombinadas: data.ResultadoDaAcao.MensagensCombinadas,
      severidade: data.ResultadoDaAcao.Severidade,
      severidadeId: data.ResultadoDaAcao.SeveridadeId,
      sucesso: data.ResultadoDaAcao.Sucesso,
    };

    return errorResponse;
  } catch (error: any) {
    const result = responseError(error);
    return result;
  }
};

const getFiltro = async (): Promise<IFiltroBoelto> => {
  try {
    const { data } = await ApiBoleto().get<IFiltroBoelto>(`Boletos/Filtro`);
    return data;
  } catch (error: any) {
    throw new GenericException(error.response);
  }
};

const getHistorico = async (id: string): Promise<IHistoricoBoleto[]> => {
  try {
    const { data } = await ApiBoleto().get<IHistoricoBoleto[]>(
      `boletos/${id}/historico`,
    );
    return data;
  } catch (error: any) {
    throw new GenericException(error.response);
  }
};

export const BoletoService = {
  getBoletos,
  getFiltro,
  getHistorico,
};
