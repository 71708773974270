import GenericException from 'shared/errors/GenericException';
import { ApiPix } from 'shared/services/axios-config';
import {
  IErrorMessage,
  IResultOfAction,
  responseError,
} from 'shared/services/response-error/ResponseError';

export interface ICobrancas {
  id: string;
  pagador: string;
  cpfCnpj: string;
  vencimento: string;
  valorDevido: number;
  pagamento: Date;
  situacaoId: number;
  situacao: string;
  configEstabelecimentoId: string;
  estabelecimento: string;
  contaBancaria: string;
  txId: string;
  itensCobranca: string[];
}

export interface IListagemCobranca {
  cobrancas: ICobrancas[];
  qtdRegistros: number;
}

export interface IHistorico {
  dataHoraRequest: string;
  descricao: string;
  payload: string;
  resposta: string;
}

export interface IConsulta {
  id: string;
  pagador: string;
  cpfCnpj: string;
  vencimento: string;
  valorDevido: number;
  pagamento: Date;
  situacaoId: number;
  situacao: string;
  configEstabelecimentoId: string;
  estabelecimento: string;
  contaBancaria: string;
  txId: string;
  itensCobranca: string[];
  historico: IHistorico[];
}

export interface IEstabelecimento {
  id: string;
  cnpj: string;
}

export interface ISituacao {
  id: number;
  nome: string;
}

export interface Vencimentos {
  primeiro: string;
  ultimo: string;
}

export interface Pagamentos {
  primeiro: string;
  ultimo: string;
}

export interface IFiltro {
  estabelecimentos: IEstabelecimento[];
  situacoes: ISituacao[];
  vencimentos: Vencimentos;
  pagamentos: Pagamentos;
}

const getCobrancas = async (
  estabelecimento: string,
  chavesPix: string,
  situacao: string[] | null,
  txId: string,
  vencimento: string | null,
  pagamento: string | null,
  devedor: string,
  cpf: string | null,
  cnpj: string | null,
  take: number,
  skip: number,
): Promise<IResultOfAction<IListagemCobranca>> => {
  try {
    const { data, status } = await ApiPix().get(
      `pixcobrancas?take=${take || ''}&skip=${
        skip || ''
      }&Estabelecimento=${estabelecimento}&ChavePix=${chavesPix}&Situacoes=${situacao}&TxId=${txId}&Vencimentos=${vencimento}&Pagamentos=${pagamento}&DevedorNome=${devedor}&DevedorCPF=${cpf}&DevedorCNPJ=${cnpj}`,
    );

    if (data && status === 200) {
      const cobrancasReturn: IListagemCobranca = {
        cobrancas: data.data.map((cobranca: any) => ({
          ...cobranca,
        })),
        qtdRegistros: data.qtdTotalDeRegistros || 0,
      };

      const response: any = {
        sucesso: true,
        data: cobrancasReturn,
      };

      return response as IResultOfAction<any>;
    }

    const errorResponse: IResultOfAction<any> = {
      mensagens: [] as IErrorMessage[],
      mensagensCombinadas: data.ResultadoDaAcao.MensagensCombinadas,
      severidade: data.ResultadoDaAcao.Severidade,
      severidadeId: data.ResultadoDaAcao.SeveridadeId,
      sucesso: data.ResultadoDaAcao.Sucesso,
    };

    return errorResponse;
  } catch (error: any) {
    const result = responseError(error);
    return result;
  }
};

const getHistorico = async (id: string): Promise<IConsulta> => {
  try {
    const { data } = await ApiPix().get<IConsulta>(`pixcobrancas/${id}`);
    return data;
  } catch (error: any) {
    throw new GenericException(error.response);
  }
};

const getFiltro = async (): Promise<IFiltro> => {
  try {
    const { data } = await ApiPix().get<IFiltro>(`pixcobrancas/filtro`);
    return data;
  } catch (error: any) {
    throw new GenericException(error.response);
  }
};

export const CobrancasService = {
  getCobrancas,
  getHistorico,
  getFiltro,
};
