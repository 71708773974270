import React, { useEffect, useRef } from 'react';
import { useTheme } from '@mui/material';

export interface IComponentePixProps {
  idTenant: string;
  token: string;
  contaBancariaId: string;
  dataVencimento: string;
  valorOriginal: string;
  cpfDevedor: string;
  cnpjDevedor: string;
  nomeDevedor: string;
  descricaoPagamento: string;
  itensCobranca: IItemCobranca[];
}

export interface IItemCobranca {
  NumeroDocumento: string;
  valor: number;
  vencimento: string;
}

export const PixComponent: React.FC<IComponentePixProps> = ({
  idTenant,
  token,
  contaBancariaId,
  dataVencimento,
  valorOriginal,
  cpfDevedor,
  cnpjDevedor,
  nomeDevedor,
  descricaoPagamento,
  itensCobranca,
}) => {
  const elementRef = useRef<any>(null);
  const theme = useTheme();

  useEffect(() => {
    if (elementRef.current) {
      elementRef.current.itensCobranca = itensCobranca;
    }
  }, [itensCobranca]);

  return React.createElement('pix-component', {
    ref: elementRef,
    'id-tenant': idTenant,
    token: token,
    'conta-bancaria-id': contaBancariaId,
    'data-vencimento': dataVencimento,
    'valor-original': valorOriginal,
    'cpf-devedor': cpfDevedor,
    'cnpj-devedor': cnpjDevedor,
    'nome-devedor': nomeDevedor,
    'descricao-pagamento': descricaoPagamento,
    style: {
      '--color-primary': `${theme.palette.primary.main} !important`,
    },
  });
};
