import React, {
  useEffect,
  useState,
  useContext,
  createContext,
  useCallback,
} from 'react';
import { ThemeProvider, Theme } from '@mui/material';

import { LightTheme } from '../themes';

interface IThemeContextData {
  toggleTheme(): void;
  isDark: boolean;
  theme: Theme;
}

export const useThemesContext = () => useContext(ThemeContext);

const ThemeContext = createContext<IThemeContextData>({} as IThemeContextData);

export const ThemesProvider: React.FC = ({ children }) => {
  useEffect(() => {
    setThemeData((oldState) => ({
      ...oldState,
      theme: LightTheme,
      isDark: false,
    }));
  }, []);

  const handleToggleTheme = useCallback(() => {
    setThemeData((oldState) => ({
      ...oldState,
      theme: LightTheme,
      isDark: false,
    }));
  }, []);

  const [themeData, setThemeData] = useState<IThemeContextData>({
    toggleTheme: handleToggleTheme,
    theme: LightTheme,
    isDark: false,
  });

  return (
    <ThemeContext.Provider value={themeData}>
      <ThemeProvider theme={themeData.theme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};
