import React, { useState } from 'react';
import clsx from 'clsx';
import {
  Box,
  Icon,
  List,
  Drawer,
  ListItem,
  TextField,
  ListItemIcon,
  ListItemText,
  InputAdornment,
} from '@mui/material';
import { NavLink } from 'react-router-dom';
import { useAuth } from 'shared/hooks';
import { IMenuOptions } from 'shared/services/api';
import { Theme, useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

interface IDrawerProps {
  menuOptions: IMenuOptions;
  isOpen: boolean;
  buttonActive: boolean;
  onMouseEnter: () => void | undefined;
  onMouseLeave: () => void | undefined;
}

export const DrawerComponent: React.FC<IDrawerProps> = ({
  menuOptions,
  isOpen,
  buttonActive,
  onMouseEnter,
  onMouseLeave,
}) => {
  const [filtro, setFiltro] = useState('');

  const { signOut } = useAuth();

  const theme: Theme = useTheme();

  const useStyles = makeStyles({
    drawer: {
      width: theme.spacing(35),
      flexShrink: 0,
      whiteSpace: 'nowrap',
      position: 'sticky',
      zIndex: '1000 !important',
    },
    drawerOpen: {
      backgroundColor: `${theme.palette.primary.main} !important`,
      width: theme.spacing(35),
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      backgroundColor: `${theme.palette.primary.main} !important`,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      overflowY: 'hidden',
      width: theme.spacing(9),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    },
    list: {
      maxHeight: 900,
      position: 'sticky',
      height: 'calc(100% - 50px)',
      overflowX: 'hidden',
      overflowY: 'scroll',
    },
    listItem: {
      height: theme.spacing(7),
      color: `${theme.palette.secondary.contrastText} !important`,
      '&:hover': {
        backgroundColor: `${theme.palette.secondary.main} !important`,
      },
    },
    icons: {
      color: `${theme.palette.secondary.contrastText} !important`,
      marginLeft: theme.spacing(0.9),
    },
    iconSearch: {
      marginTop: 3.5,
    },
    iconInputSearch: {
      color: theme.palette.secondary.contrastText,
      marginLeft: -6.5,
    },
    inputSearch: {
      backgroundColor: theme.palette.secondary.main,
      borderRadius: 8,
    },
    input: {
      '&::placeholder': {
        color: `${theme.palette.primary.contrastText} !important`,
        opacity: 2,
      },
    },
    inputBoxSearch: {
      height: theme.spacing(11.25),
      color: `${theme.palette.secondary.contrastText} !important`,
      position: 'sticky',
      backgroundColor: theme.palette.primary.main,
      zIndex: theme.zIndex.drawer + 2,
      boxShadow:
        '0px 0px 0px 0px rgb(0 50 0 / 0%), 0px 3px 4px 0px rgb(220 13 13 / 14%), 0px 0px 0px 0px rgb(200 40 93 / 12%)',
      top: theme.spacing(7),
    },
    inputBoxSearchOpen: {
      width: theme.spacing(35),
      zIndex: theme.zIndex.drawer + 2,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    inputBoxSearchClose: {
      zIndex: theme.zIndex.drawer + 2,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(9),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    },
    listItemLogout: {
      height: theme.spacing(7),
      color: `${theme.palette.secondary.contrastText} !important`,
      backgroundColor: `${theme.palette.primary.main} !important`,
      bottom: 0,
      zIndex: '900 !important',

      boxShadow: theme.shadows[6],
      '&:hover': {
        backgroundColor: `${theme.palette.secondary.main} !important`,
      },
    },
    listItemLogoutOpen: {
      opacity: 1,
      backgroundColor: theme.palette.primary.main,
      width: `${theme.spacing(35)} !important`,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    listItemLogoutClose: {
      opacity: 1,
      backgroundColor: theme.palette.primary.main,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: `${theme.spacing(9)} !important`,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    },
  });

  const classes = useStyles();

  return (
    <Drawer
      onMouseEnter={buttonActive ? undefined : onMouseEnter}
      onMouseLeave={buttonActive ? undefined : onMouseLeave}
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: isOpen,
        [classes.drawerClose]: !isOpen,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: isOpen,
          [classes.drawerClose]: !isOpen,
        }),
      }}
    >
      <ListItem
        style={{ position: 'sticky' }}
        className={clsx(classes.inputBoxSearch, {
          [classes.inputBoxSearchOpen]: isOpen,
          [classes.inputBoxSearchClose]: !isOpen,
        })}
        classes={{
          root: clsx({
            [classes.inputBoxSearchOpen]: isOpen,
            [classes.inputBoxSearchClose]: !isOpen,
          }),
        }}
      >
        {isOpen ? (
          <TextField
            fullWidth
            value={filtro}
            onChange={(e) => setFiltro(e.target.value)}
            variant="outlined"
            className={classes.inputSearch}
            placeholder="Buscar"
            size="small"
            InputProps={{
              classes: {
                root: classes.inputSearch,
                input: classes.input,
              },
              startAdornment: (
                <InputAdornment position="start">
                  <Icon className={classes.iconInputSearch}>search</Icon>
                </InputAdornment>
              ),
            }}
          />
        ) : (
          <ListItemIcon className={classes.iconSearch}>
            <Icon className={classes.icons}>search</Icon>
          </ListItemIcon>
        )}
      </ListItem>
      <List className={classes.list}>
        <Box marginTop={6.8} />

        {menuOptions.Funcionalidades?.filter(
          (item) => item.FuncionalidadeCaminho !== '',
        ).map((item) => (
          <ListItem
            to={{ pathname: item.FuncionalidadeCaminho }}
            key={item.FuncionalidadeId}
            button
            component={NavLink as any}
            className={classes.listItem}
          >
            <ListItemIcon>
              <Icon className={classes.icons}>{item.FuncionalidadeIcone}</Icon>
            </ListItemIcon>
            <ListItemText primary={item.FuncionalidadeDescricao} />
          </ListItem>
        ))}

        <ListItem
          style={{ position: 'fixed' }}
          button
          onClick={signOut}
          className={clsx(classes.listItemLogout, {
            [classes.listItemLogoutOpen]: isOpen,
            [classes.listItemLogoutClose]: !isOpen,
          })}
          classes={{
            root: clsx({
              [classes.listItemLogoutOpen]: isOpen,
              [classes.listItemLogoutClose]: !isOpen,
            }),
          }}
        >
          <ListItemIcon>
            <Icon className={classes.icons}>logout</Icon>
          </ListItemIcon>
          <ListItemText primary="Sair" />
        </ListItem>
      </List>
    </Drawer>
  );
};
