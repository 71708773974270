import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Table,
  Paper,
  TableRow,
  TableBody,
  TableCell,
  TextField,
  Typography,
  TableContainer,
  InputAdornment,
  Chip,
} from '@mui/material';
import { TreeItem, TreeView } from '@mui/lab';
import { Search, ExpandMore, ChevronRight } from '@mui/icons-material';
import { Link } from 'react-router-dom';

import {
  IEstabelecimento,
  EstabelecimentoService,
} from 'shared/services/api/estabelecimentos/EstabelecimentosService';
import { useTitle, useDebounce } from 'shared/hooks';
import { TableSkeleton } from 'shared/components';
import { feedback } from 'shared/services';

export const Estabelecimentos: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [filtro, setFiltro] = useState('');
  const [estabelecimentos, setEstabelecimentos] = useState<IEstabelecimento[]>(
    [],
  );
  const [expanded, setExpanded] = useState<string[]>([]);
  const { setTitle } = useTitle();
  const { debounce } = useDebounce();

  useEffect(() => {
    setTitle('Estabelecimentos');
  }, [setTitle]);

  const setTreeViewExpanded = useCallback(
    (estabelecimentos: IEstabelecimento[]) => {
      const lista: string[] = [];

      if (estabelecimentos.length > 0) {
        estabelecimentos.map((estabelecimento) => {
          if (estabelecimento.EstabelecimentosFilhos.length > 0) {
            lista.push(estabelecimento.CodigoEstruturado);
            estabelecimento.EstabelecimentosFilhos.map((item) => {
              if (item.EstabelecimentosFilhos.length > 0) {
                lista.push(item.CodigoEstruturado);
              }
            });
          }
        });
      }
      setExpanded([...lista]);
    },
    [],
  );

  const renderizarEstabelecimentos = (estabelecimento: IEstabelecimento) => (
    <TreeItem
      key={estabelecimento.CodigoEstabelecimento}
      nodeId={estabelecimento.CodigoEstruturado}
      label={
        <Box
          paddingTop={0.5}
          paddingBottom={0.5}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'flex-start'}
          gap={1}
        >
          <Typography
            style={{ textDecoration: 'none' }}
            color="primary"
            variant="body2"
            component={Link}
            onClick={() => {
              estabelecimento.CNPJ !== '' &&
              estabelecimento.CNPJ !== undefined &&
              estabelecimento.CNPJ !== null
                ? setTitle(`Estabelecimento: ${estabelecimento.Nome}`)
                : feedback(
                    'Não é permitido configurar estabelecimentos que não possuem CNPJ.',
                    'error',
                  );
            }}
            to={
              estabelecimento.CNPJ !== '' &&
              estabelecimento.CNPJ !== undefined &&
              estabelecimento.CNPJ !== null
                ? `${estabelecimento.CNPJ.replace(
                    /\.|\/|-/g,
                    '',
                  )}/configuracao/${estabelecimento.CodigoEstruturado}`
                : '#'
            }
          >
            {estabelecimento.CodigoEstruturado} - {estabelecimento.Nome}
          </Typography>
          {estabelecimento.PossuiLojas && (
            <Chip
              variant="outlined"
              size="small"
              color={'primary'}
              label={'Possui lojas'}
            />
          )}
        </Box>
      }
    >
      {estabelecimento.EstabelecimentosFilhos.length > 0
        ? estabelecimento.EstabelecimentosFilhos.map((item) =>
            renderizarEstabelecimentos(item),
          )
        : null}
    </TreeItem>
  );

  useEffect(() => {
    setIsLoading(true);
    EstabelecimentoService.getEstabelecimentos(filtro).then((result) => {
      if (result.sucesso) {
        setEstabelecimentos(result.data);
        setTreeViewExpanded(result.data);
        setTimeout(() => {
          setIsLoading(false);
        }, 500);
      } else {
        setIsLoading(false);
        feedback(
          result.mensagensCombinadas || 'Erro ao carregar estabelecimentos.',
          'error',
        );
      }
    });
  }, [debounce, filtro, setTreeViewExpanded]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={4}>
        <Paper>
          <TextField
            style={{ padding: 0, margin: 0 }}
            fullWidth
            variant="outlined"
            placeholder="Pesquise por nome..."
            onChange={(e) => {
              setTimeout(() => {
                setFiltro(e.target.value);
              }, 1000);
            }}
            InputProps={{
              style: { height: 40 },
              startAdornment: (
                <InputAdornment position="start">
                  <Search color="disabled" />
                </InputAdornment>
              ),
            }}
          />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        {isLoading ? (
          <TableSkeleton rectWidth="30%" rowsBody={3} />
        ) : (
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell align="left">
                    <TreeView
                      defaultExpanded={expanded}
                      defaultCollapseIcon={<ExpandMore />}
                      defaultExpandIcon={<ChevronRight />}
                      disableSelection
                    >
                      {estabelecimentos.length ? (
                        estabelecimentos.map((estabelecimento) =>
                          renderizarEstabelecimentos(estabelecimento),
                        )
                      ) : (
                        <Box padding={2}>
                          <Typography color="secondary">
                            Nenhum registro encontrado...
                          </Typography>
                        </Box>
                      )}
                    </TreeView>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Grid>
    </Grid>
  );
};
