import React, { useEffect, useState } from 'react';
import {
  Container,
  Box,
  Grid,
  TextField,
  Button,
  Icon,
  Dialog,
  DialogContent,
  DialogTitle,
  Paper,
  FormControl,
  Divider,
} from '@mui/material';
import { useTitle } from 'shared/hooks';
import { IComponentePixProps, PixComponent } from 'shared/components';

export const Pix: React.FC = () => {
  const { setTitle } = useTitle();
  const [pixParams, setPixParams] = useState<IComponentePixProps>({
    cnpjDevedor: '',
    contaBancariaId: '',
    cpfDevedor: '',
    dataVencimento: '',
    descricaoPagamento: '',
    idTenant: '',
    token: '',
    itensCobranca: [
      {
        NumeroDocumento: '1425',
        valor: 10.0,
        vencimento: '2022-09-01',
      },
    ],
    nomeDevedor: '',
    valorOriginal: '',
  });
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    setTitle('Formulário Pix');
  }, [setTitle]);

  return (
    <Container>
      <Grid container>
        <Grid container item>
          <Paper style={{ padding: 20, marginBottom: 20, width: '100%' }}>
            <Grid item xs>
              <Box>
                <h2 style={{ color: '#018781' }}>QR code para Pix</h2>
              </Box>
              <Box display="flex" alignItems={'center'} gap={1}>
                <Box
                  display="flex"
                  alignItems={'center'}
                  gap={1}
                  justifyContent={'space-between'}
                >
                  <FormControl fullWidth variant="outlined">
                    <TextField
                      value={pixParams.cnpjDevedor}
                      variant="outlined"
                      label="CNPJ"
                      onChange={(e) =>
                        setPixParams({
                          ...pixParams,
                          cnpjDevedor: e.target.value,
                        })
                      }
                    ></TextField>
                  </FormControl>
                  ou
                  <FormControl fullWidth variant="outlined">
                    <TextField
                      value={pixParams.cpfDevedor}
                      variant="outlined"
                      label="CPF"
                      onChange={(e) =>
                        setPixParams({
                          ...pixParams,
                          cpfDevedor: e.target.value,
                        })
                      }
                    ></TextField>
                  </FormControl>
                </Box>
                <Box>
                  <FormControl fullWidth variant="outlined">
                    <TextField
                      variant="outlined"
                      label="ID Conta Bancária"
                      onChange={(e) =>
                        setPixParams({
                          ...pixParams,
                          contaBancariaId: e.target.value,
                        })
                      }
                    ></TextField>
                  </FormControl>
                </Box>
                <Box>
                  <TextField
                    variant="outlined"
                    label="Valid.: aaaa-mm-dd HH:mm"
                    onChange={(e) =>
                      setPixParams({
                        ...pixParams,
                        dataVencimento: e.target.value,
                      })
                    }
                  ></TextField>
                </Box>
              </Box>
              <Grid item xs>
                <Box display="flex" alignItems={'center'} gap={5}>
                  <Box marginTop={3}>
                    <TextField
                      defaultValue={pixParams.valorOriginal}
                      variant="outlined"
                      label="Valor original: xx.xx"
                      onChange={(e) =>
                        setPixParams({
                          ...pixParams,
                          valorOriginal: e.target.value,
                          itensCobranca: [
                            {
                              ...pixParams.itensCobranca[0],
                              valor: Number(e.target.value),
                            },
                          ],
                        })
                      }
                    ></TextField>
                  </Box>
                  <Box marginTop={3}>
                    <TextField
                      defaultValue={pixParams.descricaoPagamento}
                      variant="outlined"
                      label="Descrição"
                      onChange={(e) =>
                        setPixParams({
                          ...pixParams,
                          descricaoPagamento: e.target.value,
                        })
                      }
                    ></TextField>
                  </Box>
                  <Box marginTop={3}>
                    <TextField
                      defaultValue={pixParams.idTenant}
                      variant="outlined"
                      label="IdTenant"
                      onChange={(e) =>
                        setPixParams({
                          ...pixParams,
                          idTenant: e.target.value,
                        })
                      }
                    ></TextField>
                  </Box>
                  <Box marginTop={3}>
                    <TextField
                      defaultValue={pixParams.token}
                      variant="outlined"
                      label="token JWT"
                      onChange={(e) =>
                        setPixParams({
                          ...pixParams,
                          token: e.target.value,
                        })
                      }
                    ></TextField>
                  </Box>
                  <Box marginTop={3}>
                    <TextField
                      defaultValue={pixParams.nomeDevedor}
                      variant="outlined"
                      label="Nome devedor"
                      onChange={(e) =>
                        setPixParams({
                          ...pixParams,
                          nomeDevedor: e.target.value,
                        })
                      }
                    ></TextField>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <Box>
            <Button
              color="primary"
              variant="contained"
              onClick={() => setOpenDialog(true)}
            >
              Gerar QR code para pix
            </Button>
          </Box>
          <Dialog
            fullScreen
            open={openDialog}
            onClose={() => setOpenDialog(false)}
          >
            <DialogTitle>
              <Box
                gap={1}
                display="flex"
                alignItems="center"
                marginTop={1}
                justifyContent={'end'}
              >
                <Icon
                  onClick={() => setOpenDialog(false)}
                  style={{ cursor: 'pointer' }}
                >
                  close
                </Icon>
              </Box>
            </DialogTitle>
            <Divider />
            <DialogContent>
              <PixComponent
                idTenant={pixParams.idTenant || 'gvdasa'}
                token={pixParams.token || ''}
                contaBancariaId={pixParams.contaBancariaId || '1426'}
                dataVencimento={pixParams.dataVencimento || '2022-09-07 12:32'}
                valorOriginal={pixParams.valorOriginal || '20.00'}
                cpfDevedor={pixParams.cpfDevedor || ''}
                cnpjDevedor={pixParams.cnpjDevedor || ''}
                nomeDevedor={pixParams.nomeDevedor || ''}
                descricaoPagamento={
                  pixParams.descricaoPagamento ||
                  'Descrição padrão: Pagamento Pix'
                }
                itensCobranca={[...pixParams.itensCobranca]}
              />
            </DialogContent>
          </Dialog>
        </Grid>
      </Grid>
    </Container>
  );
};
