import { Api } from 'shared/services/axios-config';
import {
  IResultOfAction,
  responseError,
} from 'shared/services/response-error/ResponseError';
export interface ITipoLicenca {
  TipoLicencaId: number;
  TipoLicencaDescricao: string;
}
export interface IAmbienteAdquirentes {
  AmbienteId: number;
  AmbienteDescricao: string;
}
export interface IAmbienteBancos {
  AmbienteId: number;
  AmbienteDescricao: string;
}

//Os valores das chaves são retornados pelo backend, levando em conta o id do registro no BD
export enum AmbienteAdquirentes {
  SandBox = 1,
  Producao = 3,
}

//Os valores das chaves são retornados pelo backend, levando em conta o id do registro no BD
export enum AmbienteBancos {
  SandBox = 1,
  Producao = 2,
}

export interface IConfiguracoes {
  Id: string;
  UrlGetnet?: string;
  UrlRede?: string;
  UrlCieloPost?: string;
  UrlCieloGet?: string;
  AmbienteCieloId: number;
  AmbienteGetnetId: number;
  AmbienteRedeId: number;
  TipoLicencaId: string;
  ClientIdCentris: string;
  ClientSecretCentris: string;
  AmbienteBancoDoBrasilId: number;
  AmbienteAdquirentes: IAmbienteAdquirentes[];
  AmbienteBancos: IAmbienteBancos[];
  UrlBancoDoBrasil: string;
  UrlOAuthBancoDoBrasil: string;
  TipoLicenca: ITipoLicenca[];
}

export const urlsEnv = {
  cielo: {
    sandbox: {
      get: 'https://apiquerysandbox.cieloecommerce.cielo.com.br/',
      post: 'https://apisandbox.cieloecommerce.cielo.com.br/',
    },
    production: {
      get: 'https://apiquery.cieloecommerce.cielo.com.br/',
      post: 'https://api.cieloecommerce.cielo.com.br/',
    },
  },
  rede: {
    sandbox: 'https://api.userede.com.br/desenvolvedores/',
    production: 'https://api.userede.com.br/erede/',
  },
  getnet: {
    sandbox: 'https://api-sandbox.getnet.com.br/',
    production: 'https://api.getnet.com.br/',
  },
  bancoDoBrasil: {
    sandbox: {
      Url: 'https://api.sandbox.bb.com.br',
      UrlOAuth: 'https://oauth.sandbox.bb.com.br',
    },
    production: {
      Url: 'https://api.bb.com.br',
      UrlOAuth: 'https://oauth.bb.com.br',
    },
  },
};

const getAll = async (): Promise<IConfiguracoes | undefined> => {
  try {
    const { data } = await Api().get<IConfiguracoes>(`configuracao`);
    return data;
  } catch (error) {
    return undefined;
  }
};

const create = async (
  body: Omit<
    IConfiguracoes,
    'Id' | 'AmbienteAdquirentes' | 'AmbienteBancos' | 'TipoLicenca'
  >,
): Promise<IResultOfAction<any>> => {
  try {
    const response = await Api().post<IResultOfAction<any>>(
      `/configuracao`,
      body,
    );
    return response.data;
  } catch (error: any) {
    const result = responseError(error);
    return result;
  }
};

const update = async (
  body: Omit<
    IConfiguracoes,
    'AmbienteAdquirentes' | 'AmbienteBancos' | 'TipoLicenca'
  >,
): Promise<IResultOfAction<any>> => {
  try {
    const response = await Api().put<IResultOfAction<any>>(
      `/configuracao`,
      body,
    );
    return response.data;
  } catch (error: any) {
    const result = responseError(error);
    return result;
  }
};

const save = async (
  configuracoes: Omit<
    IConfiguracoes,
    'AmbienteAdquirentes' | 'AmbienteBancos' | 'TipoLicenca'
  >,
): Promise<IResultOfAction<any>> => {
  if (configuracoes.Id === null) {
    const response = await create(configuracoes);
    return response;
  } else {
    const response = await update(configuracoes);
    return response;
  }
};

export const ConfiguracoesService = {
  getAll,
  save,
};
