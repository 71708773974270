export class FormatService {
    static formatCpfCnpj(value: string) {
        const cnpjCpf = value.replace(/\D/g, '');
  
        if (cnpjCpf.length === 11) {
          return cnpjCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4");
        } 
        
        return cnpjCpf.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "$1.$2.$3/$4-$5");
    }

    static formatPhone(value: string) {
      const phoneNumber = value.replace(/\D/g, '');

      return phoneNumber.replace(/^\s*(\d{2}|\d{0})[-. ]?(\d{5}|\d{4})[-. ]?(\d{4})[-. ]?\s*$/, "");
    }
}
  