/* eslint-disable no-undef */
import { Api } from 'shared/services/axios-config';
import {
  IErrorMessage,
  IResultOfAction,
  responseError,
} from 'shared/services/response-error/ResponseError';

export interface IBandeiras {
  IdBandeira: number;
  NomeBandeira: string;
  Selecionado: number;
}

const getBandeirasAdquirente = async (
  IdAdquirente: number,
): Promise<IBandeiras[] | undefined> => {
  try {
    const { data } = await Api().get<IBandeiras[]>(
      `/Adquirentes/${IdAdquirente}`,
    );
    if (data) {
      return data;
    }
    return undefined;
  } catch (error) {
    return undefined;
  }
};

const postBandeirasAdquirente = async (
  IdAdquirente: number,
  idBandeiras: number[],
): Promise<IResultOfAction<any>> => {
  try {
    const {data} = await Api().post(`/Adquirentes/${IdAdquirente}`, idBandeiras);

    if (data.Sucesso || data) {
      return { sucesso: true } as IResultOfAction<any>;
    }
    
    const errorResponse: IResultOfAction<any> = {
      mensagens: [] as IErrorMessage[],
      mensagensCombinadas: data.ResultadoDaAcao.MensagensCombinadas,
      severidade: data.ResultadoDaAcao.Severidade,
      severidadeId: data.ResultadoDaAcao.SeveridadeId,
      sucesso: data.ResultadoDaAcao.Sucesso
    }
    
    return errorResponse;
  } catch (error) {
    return responseError(error);
  }
};

export const AdquirenteConfigService = {
  getBandeirasAdquirente,
  postBandeirasAdquirente,
};
