import React, { useCallback, useEffect, useState, useMemo } from 'react';
import {
  Box,
  Grid,
  Paper,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Typography,
  TableContainer,
  Chip,
  FormControl,
  Select,
  MenuItem,
  TextField,
  InputAdornment,
  Container,
  Pagination,
  Tooltip,
  IconButton,
} from '@mui/material';
import { useTitle } from 'shared/hooks';
import {
  ITransacao,
  ITransacaoFiltro,
  ITransacoesValoresFiltros,
  TransacoesService,
} from 'shared/services/api/transacoes/Transacoes';
import { useNavigate } from 'react-router';
import { TransacoesSkeleton } from './components/TransacoesSkeleton';
import { feedback, FormatService } from 'shared/services';
import { makeStyles } from '@mui/styles';
import { FiltroPesquisa } from './components/FiltroPesquisa';
import { Search } from '@mui/icons-material';
import { Theme, useTheme } from '@mui/material/styles';
import ErrorIcon from '@mui/icons-material/Error';
import CloseIcon from '@mui/icons-material/Close';

export const Transacoes: React.FC = () => {
  const theme: Theme = useTheme();
  const useStyles = makeStyles({
    chipRed: {
      backgroundColor: `${theme.palette.error.main} !important`,
      color: `${'white'} !important`,
    },
    chipGreen: {
      color: `${'white'} !important`,
      backgroundColor: `${theme.palette.success.main} !important`,
    },
    chipGreenOutLined: {
      color: `${theme.palette.success.main} !important`,
      borderColor: `${theme.palette.success.main} !important`,
    },
    chipRedOutLined: {
      color: `${theme.palette.error.main} !important`,
      borderColor: `${theme.palette.error.main} !important`,
    },
    tableRow: {
      cursor: 'pointer',
    },
    chipLiquidada: {
      backgroundColor: `${theme.palette.error.main} !important`,
      color: `${'white'} !important`,
    },
    tagLiquidada: {
      borderRadius: '100%',
      cursor: 'pointer',
      maxHeight: '20px',
    },
  });
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [transacoesData, setTransacoesData] = useState<ITransacao[]>([]);
  const navigate = useNavigate();
  const { setTitle } = useTitle();
  const [transacaoFiltros, setTransacaoFiltros] = useState<ITransacaoFiltro>({
    documentoOrigem: '',
    origem: '',
    adquirente: '',
    nomeCartao: '',
    atualizadoEm: '',
    situacao: '',
    tid: '',
    valor: '',
    cartao: '',
    estabelecimento: '',
    tipoTransacao: '',
    somenteCapturadasNaoLiquidadas: false,
  });
  const [take, setTake] = useState<number>(10);
  const [skip, setSkip] = useState(1);
  const [totalRegistros, setTotalRegistros] = useState(0);
  const [qtdLiquidada, setQtdLiquidada] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [nomeCartaoValue, setNomeCartaoValue] = useState('');
  const [valoresFiltros, setValoresFiltros] =
    useState<ITransacoesValoresFiltros>({
      Estabelecimentos: [
        {
          Id: '0',
          Cnpj: '',
        },
      ],
      Situacoes: [
        {
          Id: 0,
          Nome: '',
        },
      ],
      Adquirentes: [
        {
          Id: 0,
          Nome: '',
        },
      ],
      Origens: [
        {
          Id: 0,
          Nome: '',
        },
      ],
      Tipos: [
        {
          Id: 0,
          Nome: '',
        },
      ],
    });

  const filtrosAplicados = useMemo(() => {
    return transacaoFiltros;
  }, [transacaoFiltros]);

  useEffect(() => {
    setTitle('Transações');
  }, [setTitle]);

  const valorFormat = useCallback((value: number) => {
    const valor = value.toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
    });

    return valor;
  }, []);

  useEffect(() => {
    TransacoesService.getTransacoesFiltros().then((response) => {
      if (response.sucesso) {
        setValoresFiltros(response.data || ({} as ITransacoesValoresFiltros));
      }
    });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    TransacoesService.getTransacoes(take, skip, transacaoFiltros)
      .then((response) => {
        if (response.sucesso) {
          setTransacoesData(response.data?.Transacoes || []);
          setTotalRegistros(response.data?.QtdRegistros || 0);
          setQtdLiquidada(response.data?.QtdCapturadaNaoLiquidada || 0);
          setIsLoading(false);
          feedback('Transações carregadas com sucesso.', 'success');
        } else {
          feedback(
            response.mensagensCombinadas || 'Erro ao carregar transações',
            'error',
          );
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [take, skip, transacaoFiltros]);

  const handleChangePage = (page: number) => {
    setSkip(page);
  };

  const handleFilterOnlyNotliquidated = useCallback(
    (isOnlyNotliquidated: boolean) => {
      setIsLoading(true);
      setTransacaoFiltros((prevState) => {
        return {
          ...prevState,
          somenteCapturadasNaoLiquidadas: isOnlyNotliquidated,
        };
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [qtdLiquidada],
  );

  const handleSearch = useCallback(
    (searchText: string) => {
      setNomeCartaoValue(searchText);
      setTimeout(() => {
        setTransacaoFiltros({ ...transacaoFiltros, nomeCartao: searchText });
      }, 1000);
    },
    [transacaoFiltros],
  );

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Box marginBottom={3} width={'100%'}>
            <FormControl variant="outlined" fullWidth>
              <Paper>
                <TextField
                  style={{ padding: 0, margin: 0 }}
                  fullWidth
                  variant="outlined"
                  placeholder="Pesquisar por nome no cartão"
                  value={nomeCartaoValue}
                  onChange={(e: any) => {
                    handleSearch(e.target.value);
                  }}
                  onBlur={(e: any) => {
                    if (e.target.value !== '') {
                      setTransacaoFiltros({
                        ...transacaoFiltros,
                        nomeCartao: e.target.value,
                      });
                    }
                  }}
                  InputProps={{
                    style: {
                      height: 40,
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search color="disabled" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Paper>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            width={'100%'}
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            padding={2}
            component={Paper}
          >
            <Box display={'flex'} alignItems={'center'}>
              <Typography variant={'h5'} color={'primary'}>
                Listagem das transações
              </Typography>
              <Box
                marginLeft={2}
                onClick={() => {
                  if (!filtrosAplicados.somenteCapturadasNaoLiquidadas) {
                    handleFilterOnlyNotliquidated(true);
                  }
                }}
              >
                {qtdLiquidada === 1 ? (
                  <Chip
                    className={classes.chipLiquidada}
                    size="small"
                    style={{ cursor: 'pointer' }}
                    label={`Encontramos ${qtdLiquidada} pagamento capturado e não liquidado no ERP. `}
                  />
                ) : qtdLiquidada >= 2 ? (
                  <Chip
                    className={classes.chipLiquidada}
                    size="small"
                    style={{ cursor: 'pointer' }}
                    label={`Encontramos ${qtdLiquidada} pagamentos capturados e não liquidados no ERP. `}
                  />
                ) : qtdLiquidada === 0 ? (
                  <></>
                ) : (
                  <></>
                )}
              </Box>
              {filtrosAplicados.somenteCapturadasNaoLiquidadas &&
              !isLoading &&
              transacoesData.length > 0 ? (
                <Box display="flex" alignItems="center">
                  <CloseIcon
                    className={classes.tagLiquidada}
                    onClick={() => {
                      handleFilterOnlyNotliquidated(false);
                    }}
                  />
                </Box>
              ) : (
                <></>
              )}
            </Box>
            <Box display={'flex'} alignItems={'center'}>
              <FiltroPesquisa
                filterValues={valoresFiltros}
                isOpen={isOpen}
                filters={filtrosAplicados}
                setFilters={(filters) => {
                  setTransacaoFiltros(filters);
                }}
                setIsOpen={(isOpen) => {
                  setIsOpen(isOpen);
                }}
              />
            </Box>
          </Box>
          {isLoading ? (
            <TransacoesSkeleton />
          ) : (
            <>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Doc. origem</TableCell>
                      <TableCell>Origem</TableCell>
                      <TableCell>Tipo de transação</TableCell>
                      <TableCell>Estabelecimento</TableCell>
                      <TableCell>Adquirente</TableCell>
                      <TableCell align="right">Valor</TableCell>
                      <TableCell>Nome cartão</TableCell>
                      <TableCell>Cartão</TableCell>
                      <TableCell>Atualizado em</TableCell>
                      <TableCell align="center">Situação</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {transacoesData.length > 0 ? (
                      transacoesData.map((transacao) => (
                        <TableRow
                          className={classes.tableRow}
                          onClick={() =>
                            navigate(`/transacoes/${transacao.Guid}`)
                          }
                          hover
                          key={transacao.Guid}
                        >
                          <TableCell>
                            <Box display={'flex'} alignItems={'center'}>
                              <Typography>
                                {transacao.DocumentoOrigem || ''}
                              </Typography>

                              {transacao.NomeSituacaoBaixa ===
                              'Erro na baixa' ? (
                                <>
                                  <Tooltip
                                    placement="right-end"
                                    title="Não conseguimos liquidar este pagamento no ERP. Clique aqui para mais detalhes."
                                  >
                                    <IconButton>
                                      <ErrorIcon
                                        fontSize="small"
                                        color="error"
                                      />
                                    </IconButton>
                                  </Tooltip>
                                </>
                              ) : (
                                <></>
                              )}
                            </Box>
                          </TableCell>
                          <TableCell>{transacao.NomeOrigem || ''}</TableCell>
                          <TableCell>{transacao.TipoTransacao || ''}</TableCell>
                          <TableCell>
                            {FormatService.formatCpfCnpj(
                              transacao.Estabelecimento || '',
                            )}
                          </TableCell>
                          <TableCell>
                            {transacao.NomeAdquirente || ''}
                          </TableCell>
                          <TableCell align="right">
                            {valorFormat(parseFloat(transacao.Valor))}
                          </TableCell>
                          <TableCell>
                            {transacao.NomeTitularCartao || ''}
                          </TableCell>
                          <TableCell>{transacao.NumeroCartao || ''}</TableCell>
                          <TableCell>{transacao.AtualizadoEm || ''}</TableCell>
                          <TableCell align="center">
                            <Chip
                              className={
                                transacao.NomeSituacao === 'Autorizada'
                                  ? classes.chipGreenOutLined
                                  : transacao.NomeSituacao === 'Cancelada'
                                  ? classes.chipRed
                                  : transacao.NomeSituacao === 'Não Autorizada'
                                  ? classes.chipRedOutLined
                                  : classes.chipGreen
                              }
                              variant={
                                transacao.NomeSituacao === 'Autorizada'
                                  ? 'outlined'
                                  : transacao.NomeSituacao === 'Não Autorizada'
                                  ? 'outlined'
                                  : 'filled'
                              }
                              label={transacao.NomeSituacao || ''}
                            />
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={5}>
                          Nenhum registro encontrado...
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box
                marginTop={2}
                display={'flex'}
                justifyContent={'space-between'}
                alignItems={'center'}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent={'flex-start'}
                >
                  {transacoesData.length > 0 && (
                    <Pagination
                      page={skip}
                      count={Math.ceil(totalRegistros / take)}
                      onChange={(e: any, page: any) => {
                        handleChangePage(page);
                      }}
                    />
                  )}
                </Box>
                {take && (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent={'flex-start'}
                    gap={1}
                  >
                    <FormControl component="fieldset">
                      <Paper>
                        <FormControl variant="outlined" size="small" fullWidth>
                          <Select
                            id="rowsperpage-select"
                            value={take}
                            onChange={(e: any) =>
                              setTake(e.target.value as number)
                            }
                          >
                            {[10, 15, 20, 50].map((item) => (
                              <MenuItem key={item} value={item}>
                                {item}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Paper>
                    </FormControl>
                    <Box>Linhas por página</Box>
                    {transacoesData.length > 0 && (
                      <Typography
                        variant="caption"
                        style={{ color: '#9E9E9E' }}
                      >
                        {take > totalRegistros ? totalRegistros : take} do total
                        de {totalRegistros} linhas
                      </Typography>
                    )}
                  </Box>
                )}
              </Box>
            </>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};
