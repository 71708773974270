import React from 'react';
import { Card, CardHeader, CardContent, Box, Skeleton } from '@mui/material';

export const TransacoesSkeleton: React.FC = () => {
  return (
    <Card>
      <CardHeader title={<Skeleton variant="text" width={180} height={30} />} />
      <CardContent>
        <Box justifyContent={'center'} display={'flex'} padding={0}>
          <Box paddingLeft={1}>
            <Skeleton variant="text" width={80} height={30} />
          </Box>
          <Box paddingLeft={1}>
            <Skeleton variant="text" width={80} height={30} />
          </Box>
          <Box paddingLeft={1}>
            <Skeleton variant="text" width={80} height={30} />
          </Box>
          <Box paddingLeft={1}>
            <Skeleton variant="text" width={80} height={30} />
          </Box>
        </Box>
        <Box padding={0}>
          <Skeleton variant="rectangular" width={750} height={270} />
        </Box>
      </CardContent>
    </Card>
  );
};
