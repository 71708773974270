import { Api } from 'shared/services/axios-config';

export interface IBandeira {
  BandeiraId: number;
  Nome: string;
  UrlImagem?: string;
}

const getBandeiras = async (): Promise<IBandeira[] | undefined> => {
  try {
    const { data } = await Api().get<IBandeira[]>('/bandeiras');
    if (data) {
      return data;
    }
    return undefined;
  } catch (error) {
    return undefined;
  }
};

export const BandeirasService = {
  getBandeiras,
};
