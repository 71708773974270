import React, { useRef, useEffect } from 'react';
import { useTheme } from '@mui/material';

interface IListaDeAgendamentosProps {
  idClient: string;
  onEditById: (id: string) => void;
  fuso: string;
}

export const ListaDeAgendamentos: React.FC<IListaDeAgendamentosProps> = ({
  idClient,
  onEditById,
  fuso,
}) => {
  const elementRef = useRef<HTMLElement>(null);
  const theme = useTheme();

  useEffect(() => {
    const onAgendaSalvaListener = (event: any) => onEditById(event.detail);
    const ref = elementRef.current;

    if (ref) {
      ref.addEventListener('editById', onAgendaSalvaListener);

      return () => ref?.removeEventListener('editById', onAgendaSalvaListener);
    }
  }, [onEditById]);
  return React.createElement('lista-de-agendamentos', {
    ref: elementRef,
    'id-client': idClient,
    fuso,

    style: {
      '--color-primary': theme.palette.primary.main,
      '--background': theme.palette.background.paper,
      '--table-body-time-tag-background-color': '#84bebc',
      '--table-body-date-tag-background-color': '#84bebc',
      '--table-body-button-hover-background': '#84bebc',
    },
  });
};
