import React from 'react';
import { feedback } from 'shared/services';
import { FeedBack } from '../FeedBack';
import { Box, Button, Dialog, DialogContent } from '@mui/material';
import CommentIcon from '@mui/icons-material/Comment';

export const ModalFeedBack: React.FC = () => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        variant="text"
        size="small"
        onClick={handleClickOpen}
        style={{
          display: 'flex',
          color: '#F8F8F8',
        }}
      >
        <Box>
          <Box marginBottom={-1}>
            <CommentIcon style={{ marginTop: '5px', color: 'white' }} />
          </Box>
          <Box> Feedback</Box>
        </Box>
      </Button>

      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <Box padding={0}>
            <FeedBack
              idProduto="pay"
              textoTitulo="Envia seu feedback sobre o GVpay"
              onErro={(id) => feedback(id, 'error')}
              onEnviarFeedback={(id) => {
                feedback(id, 'success'), handleClose();
              }}
              onFecharModal={() => handleClose()}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};
