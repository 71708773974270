import {
  Box,
  FormControl,
  Grid,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FiltroDeBusca as Drawer } from 'shared/components/filtro-de-busca/FiltroDeBusca';
import {
  BoletoService,
  IFiltroBoelto,
  ISituacaoBoelto,
} from 'shared/services/api/cobrancas-boletos/cobrancasBoletos';
import { feedback } from 'shared/services';
import { AutocompleteComponent } from 'shared/components';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

interface IListagemBoletosProps {
  loading?: boolean;
  openFiltro?: boolean;
  trigger?: boolean;
  setFiltrar: (trigger: boolean) => void;
  setOpenFiltro(value: boolean): void;
  setSituacao: (situacao: ISituacaoBoelto[]) => void;
  setDataInicioVencimento: (dateToSet: string) => void;
  setDataFinalVencimento: (dateToSet: string) => void;
  setDataInicioEmissao: (dateToSet: string) => void;
  setDataFinalEmissao: (dateToSet: string) => void;
  setNumeroTitulo(value: string): void;
  setCpfPesquisa(value: string): void;
}

export const FiltroBoleto: React.FC<IListagemBoletosProps> = ({
  loading,
  openFiltro,
  trigger,
  setFiltrar,
  setOpenFiltro,
  setSituacao,
  setDataInicioVencimento,
  setDataFinalVencimento,
  setDataInicioEmissao,
  setDataFinalEmissao,
  setNumeroTitulo,
  setCpfPesquisa,
}) => {
  const [situacaoHolder, setSituacaoHolder] = useState<string[]>([]);
  const [finalEmissao, setFinalEmissao] = useState('');
  const [inicioEmissao, setIncioEmissao] = useState('');
  const [incioVencimento, setIncioVencimento] = useState('');
  const [finalVencimento, setFinalVencimento] = useState('');
  const [filtro, setFiltro] = useState<IFiltroBoelto>({} as IFiltroBoelto);
  const [cpfCliente, setCpfCliente] = useState('');
  const [tituloCliente, setTituloCliente] = useState('');

  useEffect(() => {
    async function getFiltro() {
      try {
        const response = await BoletoService.getFiltro();
        setFiltro(response);
      } catch (error: any) {
        feedback(error.Mensagem, 'error');
      }
    }
    getFiltro();
  }, []);

  const limparFiltro = useCallback(() => {
    setSituacao([]);
    setSituacaoHolder([]);
    setIncioEmissao('');
    setFinalEmissao('');
    setIncioVencimento('');
    setFinalVencimento('');
    setDataInicioEmissao('');
    setDataFinalEmissao('');
    setDataInicioVencimento('');
    setDataFinalVencimento('');
    setNumeroTitulo('');
    setCpfCliente('');
    setCpfPesquisa('');
    setTimeout(() => {
      setFiltrar(!trigger);
    }, 100);
  }, [
    trigger,
    setSituacao,
    setDataFinalEmissao,
    setDataInicioEmissao,
    setFinalEmissao,
    setIncioEmissao,
    setDataInicioVencimento,
    setDataFinalVencimento,
    setIncioVencimento,
    setFinalVencimento,
    setFiltrar,
    setNumeroTitulo,
    setCpfCliente,
    setCpfPesquisa,
  ]);

  const counterFilters = useMemo(() => {
    let count = 0;
    if (incioVencimento !== '') {
      count++;
    }
    if (finalVencimento !== '') {
      count++;
    }
    if (inicioEmissao !== '') {
      count++;
    }
    if (finalEmissao !== '') {
      count++;
    }
    if (situacaoHolder.length > 0) {
      count++;
    }

    return count;
  }, [
    incioVencimento,
    situacaoHolder,
    finalEmissao,
    inicioEmissao,
    finalVencimento,
  ]);

  return (
    <>
      <Drawer
        trigger={trigger}
        open={openFiltro}
        loading={loading}
        countFilter={counterFilters}
        setFilter={setFiltrar}
        setOpen={setOpenFiltro}
        setCleanFilter={limparFiltro}
      >
        <Grid item xs>
          <Box>
            <FormControl fullWidth variant="outlined">
              <AutocompleteComponent
                multiple
                options={filtro.situacoes?.filter(
                  (situacao) => situacao.descricao !== null,
                )}
                defaultValue={situacaoHolder}
                getOptionLabel={(option: ISituacaoBoelto) =>
                  option.descricao || ''
                }
                filterSelectedOptions
                onChange={(evt, value) => {
                  setSituacao(value);
                  setSituacaoHolder(value);
                }}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="Situações" />
                )}
              />
            </FormControl>
          </Box>
        </Grid>

        <Grid item xs>
          <Box marginTop={1} marginBottom={-1}>
            <p>Data vencimento</p>
          </Box>
        </Grid>

        <Grid item xs>
          <Box display="flex" marginTop={2}>
            <Box>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <>
                  <DatePicker
                    renderInput={(params) => <TextField {...params} />}
                    mask="__/__/____"
                    inputFormat="dd/MM/yyyy"
                    value={incioVencimento ? incioVencimento : null}
                    onChange={(newDate) => {
                      setIncioVencimento(newDate as string);
                      setDataInicioVencimento(newDate as string);
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CalendarTodayIcon color="disabled" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </>
              </LocalizationProvider>
            </Box>
            <Box alignSelf="center" marginLeft={1} marginRight={1}>
              <Typography>até</Typography>
            </Box>
            <Box>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <>
                  <DatePicker
                    renderInput={(params) => <TextField {...params} />}
                    mask="__/__/____"
                    inputFormat="dd/MM/yyyy"
                    value={finalVencimento ? finalVencimento : null}
                    onChange={(newDate) => {
                      setFinalVencimento(newDate as string);
                      setDataFinalVencimento(newDate as string);
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CalendarTodayIcon color="disabled" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </>
              </LocalizationProvider>
            </Box>
          </Box>
        </Grid>

        <Grid item xs>
          <Box marginTop={1} marginBottom={-1}>
            <p>Data Emissão</p>
          </Box>
        </Grid>

        <Grid item xs>
          <Box display="flex" marginTop={2}>
            <Box>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <>
                  <DatePicker
                    renderInput={(params) => <TextField {...params} />}
                    mask="__/__/____"
                    inputFormat="dd/MM/yyyy"
                    value={inicioEmissao ? inicioEmissao : null}
                    onChange={(newDate) => {
                      setIncioEmissao(newDate as string);
                      setDataInicioEmissao(newDate as string);
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CalendarTodayIcon color="disabled" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </>
              </LocalizationProvider>
            </Box>
            <Box alignSelf="center" marginLeft={1} marginRight={1}>
              <Typography>até</Typography>
            </Box>
            <Box>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <>
                  <DatePicker
                    renderInput={(params) => <TextField {...params} />}
                    mask="__/__/____"
                    inputFormat="dd/MM/yyyy"
                    value={finalEmissao ? finalEmissao : null}
                    onChange={(newDate) => {
                      setFinalEmissao(newDate as string);
                      setDataFinalEmissao(newDate as string);
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CalendarTodayIcon color="disabled" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </>
              </LocalizationProvider>
            </Box>
          </Box>
          <Grid item xs={12}>
            <Box marginTop={3}>
              <Paper>
                <TextField
                  style={{ padding: 0, margin: 0 }}
                  fullWidth
                  variant="outlined"
                  placeholder={'CPF/CNPJ'}
                  value={tituloCliente}
                  onChange={(e) => {
                    setTituloCliente(e.target.value);
                    setNumeroTitulo(e.target.value);
                  }}
                  InputProps={{
                    style: {
                      height: 40,
                    },
                  }}
                />
              </Paper>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box marginTop={3}>
              <Paper>
                <TextField
                  style={{ padding: 0, margin: 0 }}
                  fullWidth
                  variant="outlined"
                  placeholder={'Número Título Cliente'}
                  value={cpfCliente}
                  onChange={(e) => {
                    setCpfCliente(e.target.value);
                    setCpfPesquisa(e.target.value);
                  }}
                  InputProps={{
                    style: {
                      height: 40,
                    },
                  }}
                />
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </Drawer>
    </>
  );
};
