import React, { useCallback, useEffect, useState } from 'react';

import { ContentCopyRounded, Search } from '@mui/icons-material';
import {
  Container,
  Grid,
  Box,
  Paper,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Pagination,
  InputAdornment,
  FormControl,
  TextField,
  Tooltip,
  IconButton,
} from '@mui/material';
import { feedback } from 'shared/services';
import {
  ILinkPagamento,
  LinksPagamentosService,
} from 'shared/services/api/links-pagamentos/LinksPagamentosService';
import { SituacaoPagamento } from './SituacaoPagamento';
import { useDebounce, useTitle } from 'shared/hooks';
import { Loading } from './Loading';

export const LinksPagamentos = () => {
  const { setTitle } = useTitle();
  const { debounce } = useDebounce();

  const rowsPerPage = 10;

  const [links, setLinks] = useState<ILinkPagamento[]>();
  const [isLoading, setIsLoading] = useState(false);
  const [skip, setSkip] = useState(1);
  const [totalRegistros, setTotalRegistros] = useState(0);
  const [search, setSearch] = useState('');
  const [filtro, setFiltro] = useState('');

  useEffect(() => {
    setTitle('Links de pagamento');
  }, [setTitle]);

  useEffect(() => {
    setIsLoading(true);
    LinksPagamentosService.getAll(rowsPerPage, skip, filtro)
      .then((result) => {
        if (result) {
          setLinks(result.links);
          setTotalRegistros(result.qtdRegistros);
        }
      })
      .catch(() => {
        feedback('Erro ao carregar links de pagamento.', 'error');
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [rowsPerPage, skip, filtro]);

  const valorFormat = useCallback((value: number) => {
    const valor = value.toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
    });

    return valor;
  }, []);

  const handleChangePage = (page: number) => {
    setSkip(page);
  };

  const handleSearch = useCallback(
    (searchText: string) => {
      setSearch(searchText);
      debounce(() => {
        setFiltro(searchText);
      });
    },
    [debounce],
  );

  const handleCopyLink = (link: string) => {
    navigator.clipboard.writeText(link).then(
      () => {
        feedback('Link copiado para a área de transferência', 'success');
      },
      () => {
        feedback(
          'Não foi possível copiar o link para a área de transferência',
          'error',
        );
      },
    );
  };

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Box marginBottom={3} width={'100%'}>
            <FormControl variant="outlined" fullWidth>
              <Paper>
                <TextField
                  style={{ padding: 0, margin: 0 }}
                  fullWidth
                  variant="outlined"
                  placeholder="Pesquisar"
                  value={search}
                  onChange={(e: any) => {
                    handleSearch(e.target.value);
                  }}
                  InputProps={{
                    style: {
                      height: 40,
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search color="disabled" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Paper>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            width={'100%'}
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            padding={2}
            component={Paper}
          >
            <Box display={'flex'} alignItems={'center'}>
              <Typography variant={'h5'} color={'primary'}>
                Links de pagamentos
              </Typography>
            </Box>
            <Box display={'flex'} alignItems={'center'}></Box>
          </Box>
          {isLoading ? (
            <Loading />
          ) : (
            <>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Id origem</TableCell>
                      <TableCell>Sistema de origem</TableCell>
                      <TableCell>Forma de pagamento</TableCell>
                      <TableCell>Descrição</TableCell>
                      <TableCell align="right">Valor</TableCell>
                      <TableCell>Data de vencimento</TableCell>
                      <TableCell>Situação</TableCell>
                      <TableCell>Link</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {links && links.length > 0 ? (
                      links.map((link) => (
                        <TableRow key={link.IdIntegracaoOrigem}>
                          <TableCell>
                            <Typography>{link.IdIntegracaoOrigem}</Typography>
                          </TableCell>
                          <TableCell>{link.NomeOrigem}</TableCell>
                          <TableCell>{link.FormaPagamento}</TableCell>
                          <TableCell>{link.TituloPagamento}</TableCell>
                          <TableCell align="right">
                            {valorFormat(link.Valor)}
                          </TableCell>
                          <TableCell>
                            {link.Vencimento.substring(0, 10)
                              .split('-')
                              .reverse()
                              .join('/')}
                          </TableCell>
                          <TableCell>
                            <SituacaoPagamento situacao={link.Situacao} />
                          </TableCell>
                          <TableCell align="left">
                            <Tooltip placement="top" title="Copiar link">
                              <IconButton
                                onClick={() => handleCopyLink(link.Link)}
                              >
                                <ContentCopyRounded fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={5}>
                          Nenhum registro encontrado...
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box
                marginTop={2}
                display={'flex'}
                justifyContent={'space-between'}
                alignItems={'center'}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent={'flex-start'}
                >
                  {links && links.length > 0 && (
                    <Pagination
                      page={skip}
                      count={Math.ceil(totalRegistros / rowsPerPage)}
                      onChange={(e: any, page: any) => {
                        handleChangePage(page);
                      }}
                    />
                  )}
                </Box>
              </Box>
            </>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};
