import React, { useCallback, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Typography,
  Skeleton,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PersonIcon from '@mui/icons-material/Person';
import {
  CobrancasService,
  IConsulta,
  IHistorico,
} from 'shared/services/api/cobrancas-pix/cobrancasPix';
import { SkeletonConsulta } from './SkeletonConsulta';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from '@mui/lab';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { feedback, FormatService } from 'shared/services';
import { makeStyles } from '@mui/styles';

interface IProps {
  id: string;
}

const useStyles = makeStyles(() => {
  return {
    timeLineItem: {
      '&:before': {
        flex: `${0} !important`,
      },
    },
  };
});

export const ModalCobrancas: React.FC<IProps> = ({ id }) => {
  const classes = useStyles();

  const [openConsulta, setOpenConsulta] = React.useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [historico, setHistorico] = useState<IConsulta>({} as IConsulta);
  const [vencimento, setVencimento] = useState('');

  const [timeLine, setTimeLine] = useState<IHistorico[]>([]);

  const handleOpenConsulta = () => {
    setOpenConsulta(true);
  };

  const handleCloseConsulta = () => {
    setOpenConsulta(false);
  };

  const getCobrancasbyId = useCallback(async () => {
    try {
      const response = await CobrancasService.getHistorico(id);
      setHistorico(response);
      if (response.historico === undefined) {
        setTimeLine([]);
      } else {
        setTimeLine(response.historico);
      }
      if (response.vencimento === undefined) {
        setVencimento('');
      } else {
        setVencimento(response.vencimento);
      }
    } catch (error) {
      feedback('Erro ao carregar histórico', 'error');
    } finally {
      setIsLoading(false);
    }
  }, [id]);

  const valorFormat = useCallback((value: number) => {
    const valor = value.toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
    });
    return valor;
  }, []);

  const syntaxHighlight = (json: string) => {
    json = json
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/\\u[\dA-F]{4}/gi, function (match: any) {
        return String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16));
      });
    return json.replace(
      //eslint-disable-next-line
      /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g,
      (match: string) => {
        return `${match}`;
      },
    );
  };

  return (
    <>
      <IconButton
        onClick={() => {
          handleOpenConsulta(), getCobrancasbyId();
        }}
      >
        <Box>
          <VisibilityIcon color="primary" />
        </Box>
      </IconButton>

      <Dialog
        maxWidth="xl"
        open={openConsulta}
        onClose={handleCloseConsulta}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          <Box display="flex" marginBottom={-2.5}>
            <Box alignSelf="center" marginRight={1}>
              <PersonIcon color="primary" style={{ width: 30, height: 30 }} />
            </Box>
            <Box alignSelf="center" marginTop={-0.5}>
              {isLoading ? (
                <Skeleton style={{ width: 200, height: 55 }} />
              ) : (
                <Typography color="primary">
                  <h3>{historico.pagador}</h3>
                </Typography>
              )}
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          {isLoading ? (
            <SkeletonConsulta />
          ) : (
            <Grid direction="column" container style={{ width: 600 }}>
              <Grid container item style={{ marginLeft: 15 }}>
                <Grid item xs>
                  <Typography>
                    <Box display="flex" alignItems="center" marginBottom={-4}>
                      <Box marginRight={1}>
                        <h4>CPF/CNPJ:</h4>
                      </Box>
                      <Box>
                        {historico?.cpfCnpj
                          ? FormatService.formatCpfCnpj(historico?.cpfCnpj)
                          : ''}
                      </Box>
                    </Box>
                  </Typography>
                  <Typography>
                    <Box display="flex" alignItems="center" marginBottom={-4}>
                      <Box marginRight={1}>
                        <h4>TxId:</h4>
                      </Box>
                      <Box> {historico?.txId}</Box>
                    </Box>
                  </Typography>
                  <Typography>
                    <Box display="flex" alignItems="center" marginBottom={-4}>
                      <Box marginRight={1}>
                        <h4>Código do Título:</h4>
                      </Box>
                      <Box> {historico?.itensCobranca}</Box>
                    </Box>
                  </Typography>

                  <Typography>
                    <Box display="flex" alignItems="center" marginBottom={-4}>
                      <Box marginRight={1}>
                        <h4>Valor:</h4>
                      </Box>
                      <Box>{valorFormat(Number(historico?.valorDevido))}</Box>
                    </Box>
                  </Typography>
                  <Typography>
                    <Box display="flex" alignItems="center" marginBottom={-4}>
                      <Box marginRight={1}>
                        <h4>Vencimento:</h4>
                      </Box>
                      <Box>
                        {vencimento
                          .substring(0, 10)
                          .split('-')
                          .reverse()
                          .join('/')}
                      </Box>
                    </Box>
                  </Typography>
                  <Typography>
                    <Box display="flex" alignItems="center">
                      <Box marginRight={1}>
                        <h4>Situação:</h4>
                      </Box>
                      <Box> {historico?.situacao}</Box>
                    </Box>
                  </Typography>
                </Grid>
              </Grid>

              <Grid container item>
                <Grid item xs style={{ marginLeft: -32, marginTop: -15 }}>
                  <Timeline>
                    {timeLine.map((item, index) => (
                      <Box
                        key={index}
                        style={{ alignContent: 'flex-start', display: 'flex' }}
                      >
                        <TimelineItem className={classes.timeLineItem}>
                          <TimelineSeparator>
                            <TimelineDot />
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent>
                            <Accordion style={{ display: 'contents' }}>
                              <AccordionSummary
                                style={{
                                  display: 'flex',
                                  alignItems: 'initial',
                                }}
                                expandIcon={<ExpandMoreIcon />}
                              >
                                <Typography style={{ whiteSpace: 'nowrap' }}>
                                  {item.dataHoraRequest
                                    .substring(0, 10)
                                    .split('-')
                                    .reverse()
                                    .join('/')}
                                  {item.dataHoraRequest.substring(10, 20)}
                                  <Typography>{item.descricao}</Typography>
                                  <Typography>{item.resposta}</Typography>
                                </Typography>
                              </AccordionSummary>

                              <AccordionDetails>
                                <Typography style={{ fontSize: 13 }}>
                                  <Paper
                                    style={{
                                      background: '#e0e0e0',
                                      color: '#000',
                                      borderRadius: 8,
                                    }}
                                  >
                                    <pre
                                      style={{
                                        whiteSpace: 'pre-wrap',
                                        wordBreak: 'break-word',
                                      }}
                                    >
                                      {syntaxHighlight(
                                        JSON.stringify(
                                          JSON.parse(item.payload),
                                          undefined,
                                          2,
                                        ),
                                      )}
                                    </pre>
                                  </Paper>
                                </Typography>
                              </AccordionDetails>
                            </Accordion>
                          </TimelineContent>
                        </TimelineItem>
                      </Box>
                    ))}
                  </Timeline>
                </Grid>
              </Grid>
            </Grid>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};
