import { Box, Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';

import { useDropzone } from 'react-dropzone';
import { encodeToBase64 } from '../encode64/encode64';
import { grey } from '@mui/material/colors';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

interface IUploadFilesProps {
  setFile: React.Dispatch<React.SetStateAction<string>>;
  id: boolean;
}

export const UploadFiles: React.FC<IUploadFilesProps> = ({ setFile, id }) => {
  const [namefile, setNameFile] = useState('');

  const onDrop = useCallback(
    (accFiles: File[]) => {
      accFiles.map(async (file) => {
        const content = await encodeToBase64(file);

        const content64 = content.replace(/^data:.*?;base64,/, '');

        setFile(`${file.name}/${content64}`);
        setNameFile(file.name);
      });
    },
    [setFile],
  );
  const { getRootProps, getInputProps, isDragAccept, isDragReject } =
    useDropzone({
      onDrop,
      accept: { 'application/pfx': ['.pfx'] },
    });

  return (
    <>
      <div {...getRootProps()}>
        <input {...getInputProps()} />

        <Box
          border={2}
          borderColor={isDragAccept ? 'secondary.main' : grey[200]}
          borderRadius={10}
          height={60}
          display="flex"
          alignItems="center"
          justifyContent="center"
          style={{
            cursor: 'pointer',
            borderStyle: 'dashed',
            transition: 'all 0.2s ease',
          }}
        >
          <Box marginTop={1}>
            {namefile ? (
              <>
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  justifyItems={'center'}
                >
                  <Typography
                    color={isDragAccept ? 'secondary' : 'textSecondary'}
                    align="center"
                    style={{
                      transition: 'all 0.2s ease',
                    }}
                  >
                    {namefile}
                  </Typography>
                  <CloudUploadIcon
                    color="primary"
                    style={{ marginLeft: 10 }}
                    fontSize="large"
                  />
                </Box>
              </>
            ) : (
              <>
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  justifyItems={'center'}
                >
                  {id ? (
                    <Typography
                      color={isDragAccept ? 'secondary' : 'textSecondary'}
                      align="center"
                      style={{
                        transition: 'all 0.2s ease',
                      }}
                    >
                      <i>
                        O certificado já está armazenado no GVpay. Por
                        segurança, não permitimos o download. <br />
                        Para atualizar o certificado, clique no ícone ao lado ou
                        solte o arquivo .PFX aqui.
                      </i>
                    </Typography>
                  ) : (
                    <Typography
                      color={isDragAccept ? 'secondary' : 'textSecondary'}
                      align="center"
                      style={{
                        transition: 'all 0.2s ease',
                      }}
                    >
                      <i>
                        Arraste o certificado aqui, ou clique para selecionar...
                      </i>
                    </Typography>
                  )}

                  <CloudUploadIcon
                    color="primary"
                    style={{ marginLeft: 10 }}
                    fontSize="large"
                  />
                </Box>
              </>
            )}

            {isDragReject && (
              <Typography
                color={'error'}
                align="center"
                style={{
                  transition: 'all 0.2s ease',
                }}
              >
                Apenas arquivos .pfx são permitidos
              </Typography>
            )}
          </Box>
        </Box>
      </div>
    </>
  );
};
