import { Autocomplete, AutocompleteProps } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { Theme, useTheme } from '@mui/material/styles';



export const AutocompleteComponent: React.FC<
  AutocompleteProps<any, any, any, any>
> = ({ ...props }) => {
  const theme: Theme = useTheme();
  const useStyles = makeStyles({
    tag: {
      backgroundColor: `${theme.palette.primary.main} !important`,
      color: `${'white'} !important`,
      '& svg': {
        color: `${theme.palette.secondary.main} !important`,
      },
      '& svg:hover': {
        color: `${theme.palette.secondary.light} !important`,
      },
    },
    clearIndicator: {
      color: `${theme.palette.primary.main} !important`,
    },
  });
  const classes = useStyles();

  return <Autocomplete {...props} classes={classes} />;
};
