import { Api } from 'shared/services/axios-config';

export interface IAdquirente {
  IdAdquirente: number;
  NomeAdquirente: string;
}

const getAdquirentes = async (): Promise<IAdquirente[] | undefined> => {
  try {
    const { data } = await Api().get<IAdquirente[]>('/adquirentes');
    if (data) {
      return data;
    }
    return undefined;
  } catch (error) {
    return undefined;
  }
};

export const AdquirenteService = {
  getAdquirentes,
};
