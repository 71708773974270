import React, { createContext, useCallback } from 'react';

import { Environment } from '../env';
import { SignOutService } from 'shared/services/api/index';

interface IAuthContextData {
  signOut: () => void;
}
export const AuthContext = createContext<IAuthContextData>(
  {} as IAuthContextData,
);

export const AuthProvider: React.FC = ({ children }) => {
  const handleSignOut = useCallback(() => {
    SignOutService.signOut().then(() => {
      location.href = `${Environment.URL_GATEWAY}/SignOut?callback_url=${location.href}`;
    });
  }, []);

  return (
    <AuthContext.Provider value={{ signOut: handleSignOut }}>
      {children}
    </AuthContext.Provider>
  );
};
