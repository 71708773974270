/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from 'react';
import { useTitle } from 'shared/hooks';
import {
  Grid,
  Paper,
  Table,
  TableRow,
  TableBody,
  TextField,
  TableCell,
  TableHead,
  IconButton,
  TableContainer,
  Button,
  InputAdornment,
  DialogContent,
  DialogActions,
  DialogTitle,
  Dialog,
  Box,
} from '@mui/material';
import { Search } from '@mui/icons-material';
import { Delete } from '@mui/icons-material';
import {
  IUsuario,
  UsuariosService,
} from 'shared/services/api/permissoes-usuario/PermissoesUsuario';
import { EditarPermissao } from './EditarPermissao';
import { NovoUsuario } from './NovoUsuario';
import { PermissoesSkeleton } from './PermissoesSkeleton';
import { feedback } from 'shared/services';
import { makeStyles } from '@mui/styles';
import { Theme, useTheme } from '@mui/material/styles';

export const PermissoesDeUsuario: React.FC = () => {
  const { setTitle } = useTitle();
  const [guid, setGuid] = useState<string>('');
  const [deletado, setDeletado] = useState(false);
  const [filtro, setFiltro] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>();
  const [openDelete, setOpenDelete] = React.useState(false);
  const [isLoadRequest, setIsLoadRequest] = useState<boolean>(false);
  const [isLoadRequestPut, setIsLoadRequestPut] = useState<boolean>(false);
  const [usuario, setUsuario] = useState<IUsuario[] | undefined>([]);

  const handleClickOpenDelete = () => {
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  useEffect(() => {
    setTitle('Permissões de usuário');
  }, [setTitle]);

  useEffect(() => {
    async function getUsuario() {
      setIsLoading(true);
      try {
        if (filtro.length) {
          const response = await UsuariosService.getUsuario(
            0,
            1000,
            filtro,
          );
          setUsuario(response);
        } else {
          const response = await UsuariosService.getUsuario(
            0,
            1000,
            filtro,
          );
          setUsuario(response);
        }
      } catch (error: any) {
        feedback(error.Mensagem, 'error');
      } finally {
        setIsLoading(false);
      }
    }
    getUsuario();
  }, [filtro, deletado, isLoadRequest, isLoadRequestPut]);

  const handleFiltro = useCallback((e: any) => {
    setFiltro(e.target.value);
  }, []);

  const DeletePerfil = useCallback(async () => {
    try {
      UsuariosService.deletePerfil(guid);
    } catch (error: any) {
      feedback(error.Mensagem, 'error');
    } finally {
      setDeletado(true);
      feedback('Deletado com sucesso', 'success');
    }
  }, [guid]);

  const theme: Theme = useTheme();

  const useStyles = makeStyles({
    ativos: {
      outlineColor: theme.palette.primary.main,
    },
    gridList: {
      marginTop: theme.spacing(5),
    },
    editMail: {
      height: theme.spacing(7),
    },
  });

  const classes = useStyles();

  return (
    <Grid direction="column" container>
      <Box marginBottom={5}>
        <Grid container item xs={12}>
          <Grid item xs={6}>
            <Paper>
              <TextField
                fullWidth
                style={{ padding: 0, margin: 0 }}
                variant="outlined"
                onChange={handleFiltro}
                placeholder="Pesquisar na tabela..."
                InputProps={{
                  style: { height: 40 },
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search color="disabled" />
                    </InputAdornment>
                  ),
                }}
              />
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Box width={'100%'} textAlign="right">
              <NovoUsuario
                request={isLoadRequest}
                loadRequest={setIsLoadRequest}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Grid className={classes.gridList} item xs={12}>
        {isLoading ? (
          <PermissoesSkeleton />
        ) : (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell width="10%">Ações</TableCell>
                  <TableCell>Login</TableCell>
                  <TableCell width="40%">E-mail</TableCell>
                  <TableCell width="15%">Perfil de usuário</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {usuario?.map((user, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <IconButton
                        onClick={() => {
                          setGuid(user.Id), handleClickOpenDelete();
                        }}
                      >
                        <Delete color="primary" />
                      </IconButton>
                      <EditarPermissao
                        requestPut={isLoadRequestPut}
                        loadRequestPut={setIsLoadRequestPut}
                        guid={user.Id}
                      />
                    </TableCell>
                    <TableCell>{user.Login}</TableCell>
                    <TableCell>{user.Email}</TableCell>
                    <TableCell>{user.Perfil}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Grid>

      <Dialog
        maxWidth="xl"
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Deletar</DialogTitle>
        <DialogContent>
          <Grid direction="column" container style={{ width: 700 }}>
            Tem certeza que deseja deletar?
          </Grid>
        </DialogContent>
        <DialogActions style={{ marginRight: 16, marginBottom: 10 }}>
          <Button
            variant="outlined"
            onClick={handleCloseDelete}
            color="primary"
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              handleCloseDelete();
              DeletePerfil();
            }}
            color="primary"
            autoFocus
          >
            Deletar
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};
