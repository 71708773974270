import React from 'react';
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Skeleton,
} from '@mui/material';

export const PermissoesSkeleton: React.FC = () => {
  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width="10%">Ações</TableCell>
              <TableCell>Login</TableCell>
              <TableCell width="40%">E-mail</TableCell>
              <TableCell width="15%">Perfil de usuário</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <IconButton style={{ position: 'relative' }}>
                  <Skeleton variant="circular" width={24} height={24} />
                </IconButton>

                <IconButton style={{ position: 'relative', left: 6 }}>
                  <Skeleton variant="circular" width={24} height={24} />
                </IconButton>
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width={165} height={25} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width={165} height={25} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width={165} height={25} />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <IconButton style={{ position: 'relative' }}>
                  <Skeleton variant="circular" width={24} height={24} />
                </IconButton>

                <IconButton style={{ position: 'relative', left: 6 }}>
                  <Skeleton variant="circular" width={24} height={24} />
                </IconButton>
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width={165} height={25} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width={165} height={25} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width={165} height={25} />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <IconButton style={{ position: 'relative' }}>
                  <Skeleton variant="circular" width={24} height={24} />
                </IconButton>

                <IconButton style={{ position: 'relative', left: 6 }}>
                  <Skeleton variant="circular" width={24} height={24} />
                </IconButton>
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width={165} height={25} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width={165} height={25} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width={165} height={25} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
