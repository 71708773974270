import React from 'react';
import { List, ListItem, ListItemText, Box, Skeleton } from '@mui/material';

export const EstabelecimentoArvoreSkeleton: React.FC = () => {
  return (
    <>
      <List dense component="div" role="list">
        <ListItem>
          <ListItemText>
            <Box width={400}>
              <Skeleton variant="text" width="50" />
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            <Box width={300}>
              <Skeleton variant="text" width="50" />
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            <Box width={200}>
              <Skeleton variant="text" width="50" />
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            <Box width={200}>
              <Skeleton variant="text" width="50" />
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            <Box width={300}>
              <Skeleton variant="text" width="50" />
            </Box>
          </ListItemText>
        </ListItem>
      </List>
    </>
  );
};
