import React from 'react';

import { Box, Typography } from '@mui/material';
import LensIcon from '@mui/icons-material/Lens';

interface ITotalBulletProps {
  value: number;
  description: string;
  color: string;
  isPrimary?: boolean;
}

export const TotalBullet: React.FC<ITotalBulletProps> = ({
  value,
  description,
  color,
  isPrimary = false,
}) => {
  return (
    <Box display="flex" alignItems={'center'}>
      <Box marginRight={2}>
        <LensIcon style={{ color: color }} fontSize={'small'} />
      </Box>
      {isPrimary ? (
        <Box
          alignItems={'center'}
          marginTop={2}
          style={{ backgroundColor: '' }}
        >
          <Typography variant={'h4'} color="primary">
            {value}
          </Typography>
          <Typography variant={'body1'} color="primary">
            {description}
          </Typography>
        </Box>
      ) : (
        <Box alignItems={'center'} marginTop={2}>
          <Typography variant={'h4'}>{value}</Typography>
          <Typography variant={'body1'}>{description}</Typography>
        </Box>
      )}
    </Box>
  );
};
