import React, { useEffect, useImperativeHandle, useRef } from 'react';
import { FormControl, Grid, TextField } from '@mui/material';
import { Box } from '@mui/system';
import { IChaveConfig } from 'shared/services/api/my-credentials/BoletoList';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';

export type IRefMethod = {
  validateTime: (time: string) => Dayjs | null;
};

interface IProps {
  isEdit: boolean;
  habilitado: boolean;
  backGround: string;
  newConfigKey: IChaveConfig;
  setNewConfigKey: (key: IChaveConfig) => void;
  timeValidation: {
    isNewConfig: boolean;
    isChanged: boolean;
    isError: boolean;
  };
  setTimeValidation: (newTimeValidation: any) => void;
  timeValue: Dayjs | null;
  setTimeValue: (newTimeValue: Dayjs | null) => void;
}

export const TimerSelect = React.forwardRef<IRefMethod, IProps>(
  (
    {
      isEdit,
      habilitado,
      backGround,
      newConfigKey,
      setNewConfigKey,
      timeValidation,
      setTimeValidation,
      timeValue,
      setTimeValue,
    },
    ref,
  ) => {
    const processTimeRef = useRef<HTMLInputElement | null>(null);
    useImperativeHandle(ref, () => ({
      validateTime: (time: string) => {
        return handleValidateTime(time);
      },
    }));

    const handleValidateTime = (timeValue?: string) => {
      const dateNow = new Date().toISOString();
      return dayjs(
        dateNow.split('T')[0].concat(timeValue ? timeValue : 'T00:00'),
      );
    };

    const handleTimeChange = () => {
      if (processTimeRef?.current?.value?.length !== 5) {
        setTimeValidation((prev: any) => ({
          ...prev,
          isError: true,
        }));
      } else {
        setNewConfigKey({
          ...newConfigKey,
          credenciaisConfig: {
            ...newConfigKey.credenciaisConfig,
            horaProcessamento: {
              habilitado: true,
              valor: processTimeRef.current.value.toString(),
            },
          },
        });
      }
    };

    useEffect(() => {
      if (isEdit) {
        setTimeValidation((prev: any) => ({
          ...prev,
          isNewConfig: true,
        }));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (processTimeRef.current?.value?.length === 5) {
        const timer = processTimeRef.current?.value.split(':');
        if (Number(timer[0]) > 23 || Number(timer[1]) > 59) {
          setTimeValue(handleValidateTime());
        } else {
          const dateNow = new Date().toISOString();
          const response = dayjs(
            dateNow.split('T')[0].concat(`T${processTimeRef.current?.value}`),
          );
          setTimeValue(response);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [processTimeRef.current?.value]);

    return (
      <Grid container item xs>
        <Grid item xs>
          <Box
            paddingX={7}
            marginTop={2}
            display={'flex'}
            alignItems={'center'}
          >
            {habilitado ? (
              <FormControl
                variant="outlined"
                fullWidth
                className={backGround}
                onClick={handleTimeChange}
                onBlur={handleTimeChange}
                onFocus={() => {
                  setTimeValidation({
                    ...timeValidation,
                    isChanged: true,
                    isError: false,
                  });
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    onClose={handleTimeChange}
                    renderInput={(props: any) => (
                      <TextField {...props} error={timeValidation.isError} />
                    )}
                    label={'Hora de processamento'}
                    inputFormat="HH:mm"
                    inputRef={processTimeRef}
                    value={timeValue ? timeValue : handleValidateTime()}
                    onChange={(newValue: any) => {
                      setTimeValue(newValue);
                    }}
                  />
                </LocalizationProvider>
              </FormControl>
            ) : (
              <></>
            )}
          </Box>
        </Grid>
      </Grid>
    );
  },
);

TimerSelect.displayName = 'TimerSelect';
