import React from 'react';
import {
    Box,
    Paper,
    TableBody,
    TableCell,
    TableRow,
  } from '@mui/material';
  import { Skeleton } from '@mui/lab';
  import { makeStyles } from '@mui/styles';
  
  export const SkeletonBoletos: React.FC = () => {
    const useStyles = makeStyles({
      noWrap: {
        whiteSpace: 'nowrap',
      },
      pagadorBody: {
        whiteSpace: 'nowrap',
        position: 'sticky',
        left: 1,
        background: '#FFF',
      },
    });
    const classes = useStyles();
  
    return (
      <TableBody>
        <TableRow>
          <Paper elevation={5} className={classes.pagadorBody}>
            <TableCell>
              <Box display="flex" padding={1}>
                <Box marginRight={1}>
                  <Skeleton variant="circular" width={24} height={24} />
                </Box>
                <Skeleton variant="text" width={165} height={25} />
              </Box>
            </TableCell>
          </Paper>
          <TableCell>
            <Skeleton variant="text" width={165} height={25} />
          </TableCell>
          <TableCell>
            <Skeleton variant="text" width={165} height={25} />
          </TableCell>
          <TableCell>
            <Skeleton variant="text" width={165} height={25} />
          </TableCell>
        </TableRow>

        <TableRow>
          <Paper elevation={5} className={classes.pagadorBody}>
            <TableCell>
              <Box display="flex" padding={1}>
                <Box marginRight={1}>
                  <Skeleton variant="circular" width={24} height={24} />
                </Box>
                <Skeleton variant="text" width={165} height={25} />
              </Box>
            </TableCell>
          </Paper>
          <TableCell>
            <Skeleton variant="text" width={165} height={25} />
          </TableCell>
          <TableCell>
            <Skeleton variant="text" width={165} height={25} />
          </TableCell>
          <TableCell>
            <Skeleton variant="text" width={165} height={25} />
          </TableCell>
        </TableRow>

        <TableRow>
          <Paper elevation={5} className={classes.pagadorBody}>
            <TableCell>
              <Box display="flex" padding={1}>
                <Box marginRight={1}>
                  <Skeleton variant="circular" width={24} height={24} />
                </Box>
                <Skeleton variant="text" width={165} height={25} />
              </Box>
            </TableCell>
          </Paper>
          <TableCell>
            <Skeleton variant="text" width={165} height={25} />
          </TableCell>
          <TableCell>
            <Skeleton variant="text" width={165} height={25} />
          </TableCell>
          <TableCell>
            <Skeleton variant="text" width={165} height={25} />
          </TableCell>
        </TableRow>

        <TableRow>
          <Paper elevation={5} className={classes.pagadorBody}>
            <TableCell>
              <Box display="flex" padding={1}>
                <Box marginRight={1}>
                  <Skeleton variant="circular" width={24} height={24} />
                </Box>
                <Skeleton variant="text" width={165} height={25} />
              </Box>
            </TableCell>
          </Paper>
          <TableCell>
            <Skeleton variant="text" width={165} height={25} />
          </TableCell>
          <TableCell>
            <Skeleton variant="text" width={165} height={25} />
          </TableCell>
          <TableCell>
            <Skeleton variant="text" width={165} height={25} />
          </TableCell>
        </TableRow>
      </TableBody>
    );
  };
  