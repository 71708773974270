import React from 'react';
import {
  Box,
  Theme,
  Button,
  Dialog,
  Typography,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  actionButton: {
    textTransform: 'none',
    minWidth: '120px',
  },
  title: {
    padding: '32px 24px 0px',
  },
  dialogActions: {
    padding: '48px 24px 32px',
  },
}));

interface IModalComponent {
  isOpen?: boolean;
  title?: string;
  btnText?: string;
  size?: false | 'sm' | 'xs' | 'md' | 'lg' | 'xl' | undefined;
  disableBackdrop?: boolean;
  closeModal(): void;
  handleConfirm(): void;
}

export const Modal: React.FC<IModalComponent> = ({
  children,
  isOpen = true,
  title = 'testes',
  size = 'md',
  disableBackdrop = true,
  closeModal,
  handleConfirm,
  btnText,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      disableEscapeKeyDown={disableBackdrop}
      onClose={closeModal}
      fullWidth
      maxWidth={size}
      open={isOpen}
    >
      <Typography color="primary" variant="h6" className={classes.title}>
        <strong>{title}</strong>
      </Typography>
      <DialogContent>{children}</DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          size="medium"
          color="primary"
          variant="outlined"
          className={classes.actionButton}
          onClick={closeModal}
        >
          Cancelar
        </Button>
        <Box marginLeft={1} />
        <Button
          size="medium"
          color="primary"
          variant="contained"
          className={classes.actionButton}
          onClick={handleConfirm}
        >
          {btnText ? btnText : 'Confirmar'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
