/* eslint-disable react/react-in-jsx-scope */
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';

interface IProps {
  isRegister: boolean;
  isSantander: boolean;
  openModal: boolean;
  handleCloseModal: () => void;
}

export const RegisteredSantanderModal: React.FC<IProps> = ({
  isRegister,
  isSantander,
  openModal,
  handleCloseModal,
}) => {
  const componentResponse = () => {
    if (isRegister && isSantander) {
      return (
        <Dialog open={openModal} onClose={handleCloseModal} maxWidth="xl">
          <DialogTitle marginBottom={1}>
            <Box display="flex" marginBottom={-2.5}>
              <Box alignSelf="center" marginTop={-0.5}>
                <Typography color="primary">
                  <h3>Configuração registrada com sucesso!</h3>
                </Typography>
              </Box>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Box style={{ width: 400 }}>
              <Typography>
                Para finalizar, você precisa selecionar um workspace. O
                workspace é ambiente virtual imposto pelo Banco Santander
                responsáveis por agrupar as requisições.
                <Box marginTop={3} />
                Uma vez criado e associado à um convenio, nenhum outra
                configuração é necessária.
                <Box marginTop={3} />
                Selecione um dos workspaces disponíveis na lista ou crie um
                novo, acionando o botão (+) ao lado do campo correspondente.{' '}
                <Box marginTop={3} />
                Em seguida, não esqueça de salvar :)
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions style={{ marginRight: 16, marginBottom: 10 }}>
            <Button
              onClick={handleCloseModal}
              variant="contained"
              color="primary"
              style={{ height: 40 }}
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      );
    }
  };

  return <>{componentResponse()}</>;
};
