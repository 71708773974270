import React, { useState, useEffect } from 'react';
import {
  Grid,
  Paper,
  Container,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Typography,
  Icon,
  TextField,
  FormControl,
} from '@mui/material';
import { FormCardPayment } from 'shared/components';
import { feedback } from 'shared/services';
import { useTitle } from 'shared/hooks';

export const FormularioCartao: React.FC = () => {
  const { setTitle } = useTitle();

  const [cnpj, setCnpj] = useState('');
  const [origemId, setOrigemId] = useState(0);
  const [descricao, setDescricao] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [origemDocument, setOrigemDocument] = useState('');
  const [idTenant, setIdTenant] = useState('');
  const [token, setIdToken] = useState('');
  const [numeroDeParcelas, setNumeroDeParcelas] = useState(0);
  const [liquidarParcelaCollege, setLiquidarParcelaCollege] = useState('false');

  useEffect(() => {
    setTitle('Formulário de cartão de crédito');
  }, [setTitle]);

  return (
    <Container>
      <Grid container>
        <Grid container item>
          <Paper style={{ padding: 20, marginBottom: 20, width: '100%' }}>
            <Grid item xs>
              <Box>
                <h2 style={{ color: '#018781' }}>
                  Dados de integração com o cartão
                </h2>
              </Box>
              <Box display="flex">
                <Box>
                  <FormControl fullWidth variant="outlined">
                    <TextField
                      style={{ marginRight: 10 }}
                      value={cnpj}
                      variant="outlined"
                      label="CNPJ"
                      onChange={(e) => setCnpj(e.target.value)}
                    ></TextField>
                  </FormControl>
                </Box>
                <Box>
                  <FormControl fullWidth variant="outlined">
                    <TextField
                      style={{ marginRight: 10 }}
                      variant="outlined"
                      label="OrigemID"
                      onChange={(e) => setOrigemId(Number(e.target.value))}
                    ></TextField>
                  </FormControl>
                </Box>
                <Box>
                  <TextField
                    style={{ marginRight: 10 }}
                    variant="outlined"
                    label="Preço Total"
                    onChange={(e) => setTotalPrice(Number(e.target.value))}
                  ></TextField>
                </Box>
                <Box>
                  <TextField
                    variant="outlined"
                    label="TokenJWT"
                    onChange={(e) => setIdToken(e.target.value)}
                  ></TextField>
                </Box>
              </Box>
              <Grid item xs>
                <Box marginTop={3} display="flex">
                  <Box>
                    <TextField
                      style={{ marginRight: 10 }}
                      defaultValue={origemDocument}
                      variant="outlined"
                      label="OrigemDocument"
                      onChange={(e) => setOrigemDocument(e.target.value)}
                    ></TextField>
                  </Box>
                  <Box>
                    <TextField
                      style={{ marginRight: 10 }}
                      defaultValue={descricao}
                      variant="outlined"
                      label="Descrição"
                      onChange={(e) => setDescricao(e.target.value)}
                    ></TextField>
                  </Box>
                  <Box>
                    <TextField
                      style={{ marginRight: 10 }}
                      variant="outlined"
                      label="Numero de parcelas"
                      onChange={(e) =>
                        setNumeroDeParcelas(Number(e.target.value))
                      }
                    ></TextField>
                  </Box>
                  <Box>
                    <TextField
                      variant="outlined"
                      label="IdTenant"
                      onChange={(e) => setIdTenant(e.target.value)}
                    ></TextField>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box>
                  <TextField
                    variant="outlined"
                    label="Liquidar parcela (passe true para habilitar)"
                    onChange={(e) => setLiquidarParcelaCollege(e.target.value)}
                  ></TextField>
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <Box padding={3} component={Paper}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => setOpenDialog(true)}
            >
              Informar dados do cartão de crédito
            </Button>
          </Box>
          <Dialog
            maxWidth="md"
            open={openDialog}
            onClose={() => setOpenDialog(false)}
          >
            <DialogTitle>
              <Box gap={1} display="flex" alignItems="center" marginTop={1}>
                <Icon color="primary" fontSize="large">
                  credit_card
                </Icon>
                <Typography color="primary" variant="h5">
                  Dados do cartão de crédito
                </Typography>
              </Box>
            </DialogTitle>
            <DialogContent>
              <FormCardPayment
                cnpj={cnpj}
                origemId={origemId}
                totalPrice={totalPrice}
                originDocument={origemDocument}
                idTenant={idTenant}
                token={token}
                collegePortion={liquidarParcelaCollege}
                optionsPayment={[
                  { descricao: descricao, numeroDeParcelas: numeroDeParcelas },
                ]}
                onCancelOperation={() => setOpenDialog(false)}
                onPaymentError={(e) => feedback(e.toString(), 'error')}
                onPaymentSuccess={(e) => feedback(e.toString(), 'success')}
              />
            </DialogContent>
          </Dialog>
        </Grid>
      </Grid>
    </Container>
  );
};
