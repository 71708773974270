import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Tooltip,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import { useTitle } from 'shared/hooks';
import { makeStyles } from '@mui/styles';
import { confirm } from 'shared/services';
import { feedback } from 'shared/services';
import {
  BoletoListService,
  IChaveConfig,
  IContasBancarias,
  ICredenciaisConfig,
} from 'shared/services/api/my-credentials/BoletoList';
import { useNavigate, useParams } from 'react-router-dom';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import { IEstabelcimento } from 'shared/services/api/my-credentials/MyCredentials';
import { UploadFiles } from '../pixParameters/components/uploadFiles/uploadFiles';
import { WorkspaceContent } from './components/WorkspaceContent';
import { RegisteredSantanderModal } from './components/RegisteredSantanderModal';
import { FooterBarBoleto } from './components/footerBar/FooterBarBoleto';
import { produce } from 'immer';
import { Dayjs } from 'dayjs';
import {
  IRefMethod,
  TimerSelect,
} from 'shared/components/timer-select/TimerSelect';
import GenericException from 'shared/errors/GenericException';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const useStyles = makeStyles(() => {
  return {
    paperBackGround: {
      paddingBottom: '3rem !important',
      backgroundColor: `#ccf1ef !important`,
    },
    backGround: {
      background: `#ffff !important`,
    },

    backGroundCertificate: {
      border: '2px',
      borderRadius: '30px',
      background: `#ffff !important`,
    },
  };
});

export const BoletoConfig: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const boletoNavigateState = {
    state: '1',
  };

  const handleBack = () => {
    navigate(`/configuracoes-boleto`, boletoNavigateState);
  };

  const { id = '' } = useParams<'id'>();
  const SANTANDER_CODIGO_BANCO = '033';
  const [isLoading, setIsLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [isIncompleteError, setIsIncompleteError] = useState({
    showErrors: false,
    convenio: false,
    descricao: false,
    nomeEstabelecimento: false,
    clientId: false,
    clientSecret: false,
  });
  const [Certificatefile, setCertificateFile] = useState('');
  const [estabelecimentos, setEstabelecimento] = useState<IEstabelcimento[]>(
    [],
  );
  const [contasBancarias, setContasBancarias] = useState<IContasBancarias[]>(
    [],
  );
  const certificadoVencido =
    'Certificado Dinâmico vencido, solicite as credenciais novamente ao banco e depois de informadas e salvas no GVPay, gere novamente o Certificado Dinâmico.';
  const certificadoNaoGerado =
    ' Para a realização de transações por boleto híbrido Itaú, o certificado dinâmico deve ser gerado antes de começar a transação de boletos.';
  const certificadoGerado =
    'Renovar o Certificado Dinâmico mantém ele sempre atualizado, renove-o antes de vencer.';

  const [timeValidation, setTimeValidation] = useState({
    isNewConfig: false,
    isChanged: false,
    isError: false,
  });
  const [timeValue, setTimeValue] = useState<Dayjs | null>(null);
  const timeSelectRef = useRef<IRefMethod>(null);

  const [credentials, setCredentials] = useState<ICredenciaisConfig>();
  const [newBoletoKey, setNewBoletoKey] = useState<IChaveConfig>({
    id: '',
    convenio: '',
    contaBancaria: '',
    contaBancariaId: 0,
    agencia: '',
    email: '',
    descricao: '',
    descricaoContaBancaria: '',
    nomeBanco: '',
    codigoBanco: '',
    cnpjEstabelecimento: '',
    nomeEstabelecimento: '',
    idContaErp: 0,
    numeroCarteira: 0,
    dataVencimentoCertificado: new Date(),
    dataVencimentoCertificadoDinamico: new Date(),
    possuiCertificadoDinamico: false,
    credenciaisConfig: {
      developerApplicationId: {
        valor: '',
        habilitado: false,
      },
      clientId: {
        valor: '',
        habilitado: false,
      },
      clientSecret: {
        valor: '',
        habilitado: false,
      },
      tokenTemporario: {
        valor: '',
        habilitado: false,
      },
      basic: {
        valor: '',
        habilitado: false,
      },
      xApiKey: {
        valor: '',
        habilitado: false,
      },
      username: {
        valor: '',
        habilitado: false,
      },
      password: {
        valor: '',
        habilitado: false,
      },
      clientCertificate: {
        valor: '',
        habilitado: false,
      },
      passPhrase: {
        valor: '',
        habilitado: false,
      },
      horaProcessamento: {
        valor: '',
        habilitado: false,
      },
      chavePix: {
        valor: '',
        habilitado: false,
      },
      tipoChavePix: {
        valor: '',
        habilitado: false,
      },
      workspace: {
        habilitado: false,
        opcoes: [],
      },
      posto: {
        valor: '',
        habilitado: false,
      },
      cooperativa: {
        valor: '',
        habilitado: false,
      },
      clientIdBolecode: {
        habilitado: false,
        valor: '',
      },
      clientSecretBolecode: {
        habilitado: false,
        valor: '',
      },
      tokenTemporarioBolecode: {
        habilitado: false,
        valor: '',
      },
      clientIdBoleto: {
        habilitado: false,
        valor: '',
      },
      clientSecretBoleto: {
        habilitado: false,
        valor: '',
      },
      tokenTemporarioBoleto: {
        habilitado: false,
        valor: '',
      },
    },
  });
  const tiposChavePix = ['CPF', 'CNPJ', 'CELULAR', 'EMAIL', 'EVP'];

  const handleErrorSetup = (showErrors?: boolean) => {
    const { convenio, descricao, nomeEstabelecimento } = newBoletoKey;
    const { clientId, clientSecret } = newBoletoKey.credenciaisConfig;
    setIsIncompleteError({
      showErrors: !!showErrors,
      convenio: convenio === '',
      descricao: descricao?.length === 0,
      nomeEstabelecimento: nomeEstabelecimento?.length === 0,
      clientId: clientId.valor?.length === 0,
      clientSecret: clientSecret.valor?.length === 0,
    });
  };

  const handleErrorCheck = () => {
    handleErrorSetup(true);
    feedback(
      'Antes de adicionar um workspace, salve as informações das credenciais',
      'error',
    );
    window.scrollTo(0, 0);
  };

  const getCredentials = useCallback(async () => {
    try {
      const response = await BoletoListService.getCredenciais(
        newBoletoKey.codigoBanco,
      );

      setCredentials(response);
      if (
        response?.horaProcessamento.valor !== '' &&
        response?.horaProcessamento.habilitado
      ) {
        setTimeValidation((prev) => ({ ...prev, isNewConfig: true }));
      }
    } catch (error: any) {
      ('');
    }
  }, [newBoletoKey.codigoBanco]);

  const submitBoletoConfig = useCallback(async () => {
    feedback('Salvando...', 'success');
    if (id) {
      try {
        const response = await BoletoListService.putBoletoKey(
          newBoletoKey,
          id || '',
        );
        if (response.sucesso) {
          feedback('Salvo com sucesso', 'success');
          navigate(`/configuracoes-boleto`, boletoNavigateState);
        }
      } catch (error: any) {
        throw new GenericException(error.response);
      }
    } else {
      if (
        timeValidation.isError ||
        (!timeValidation.isChanged &&
          timeValidation.isNewConfig &&
          newBoletoKey.credenciaisConfig.horaProcessamento.habilitado)
      ) {
        setTimeValidation((prev) => ({ ...prev, isError: true }));
        feedback('Selecione a hora de processamento', 'error');
      } else {
        const response = await BoletoListService.postBoletokey(newBoletoKey);
        if (
          response.success &&
          newBoletoKey.credenciaisConfig.clientSecret.valor !== ''
        ) {
          feedback('Configuração criada com sucesso!', 'success');
        }
        if (
          response.success &&
          newBoletoKey.credenciaisConfig.tokenTemporarioBoleto.valor !== ''
        ) {
          feedback(
            'Configuração criada com sucesso! Você pode gerar o Certificado Dinâmico.',
            'success',
          );
          navigate(`/configuracoes-boleto/detalhes/${response.data.id}`);
        }
        if (response.success) {
          feedback('Configuração criada com sucesso!', 'success');
          newBoletoKey.codigoBanco === SANTANDER_CODIGO_BANCO
            ? setOpenModal(true)
            : navigate(`/configuracoes-boleto`, boletoNavigateState);
        } else {
          feedback(response.message || 'Erro ao salvar configuração', 'error');
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newBoletoKey, navigate, id, timeValidation]);

  const getParametrosEdit = useCallback(async () => {
    try {
      const response = await BoletoListService.getBoletosParametros(id);
      setNewBoletoKey(response);

      if (timeSelectRef.current?.validateTime) {
        setTimeValue(
          timeSelectRef.current?.validateTime(
            response.credenciaisConfig.horaProcessamento.valor,
          ),
        );
      }
      setIsLoading(false);
    } catch (error: any) {
      feedback(error.Mensagem, 'error');
    }
  }, [id]);

  const handleOperation = useCallback(async () => {
    try {
      if (id) {
        getParametrosEdit();
      } else {
        getCredentials();
      }
    } catch (error: any) {
      feedback(error.Mensagem, 'error');
    } finally {
      ('');
    }
  }, [id, getParametrosEdit, getCredentials]);

  const resetCredenciaisConfig = (cred?: ICredenciaisConfig | null) => {
    setNewBoletoKey((prevState) =>
      produce(prevState, (draft) => {
        const fields: (keyof ICredenciaisConfig)[] = [
          'basic',
          'chavePix',
          'tipoChavePix',
          'clientCertificate',
          'clientId',
          'clientSecret',
          'developerApplicationId',
          'horaProcessamento',
          'passPhrase',
          'password',
          'username',
          'workspace',
          'xApiKey',
          'posto',
          'cooperativa',
        ];

        for (const field of fields) {
          if (field === 'workspace') {
            draft.credenciaisConfig.workspace.opcoes = [];
          } else {
            draft.credenciaisConfig[field].valor = '';

            cred
              ? (draft.credenciaisConfig[field].habilitado =
                  cred[field]?.habilitado)
              : (draft.credenciaisConfig[field].habilitado = false);
          }
        }
      }),
    );
  };

  const DeleteConfiguracaoKey = useCallback(async () => {
    feedback('Deletando...', 'success');
    try {
      BoletoListService.deleteBoletoKey(id || '');
    } catch (error: any) {
      feedback(error.Mensagem, 'error');
    } finally {
      feedback('Deletado com sucesso', 'success');
      navigate(`/minhas-credenciais`, boletoNavigateState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, navigate]);

  const handleDeleteConfig = useCallback(async () => {
    confirm(
      'Tem certeza que deseja excluir este registro?',
      'delete',
      DeleteConfiguracaoKey,
    );
  }, [DeleteConfiguracaoKey]);

  const handleBoletoSubmit = () => {
    if (
      isIncompleteError.clientId ||
      isIncompleteError.convenio ||
      isIncompleteError.descricao ||
      isIncompleteError.nomeEstabelecimento
    ) {
      handleErrorCheck();
    } else {
      setIsIncompleteError({
        showErrors: false,
        convenio: false,
        descricao: false,
        nomeEstabelecimento: false,
        clientId: false,
        clientSecret: false,
      });
      submitBoletoConfig();
    }
  };

  useEffect(() => {
    resetCredenciaisConfig();
  }, [newBoletoKey.contaBancariaId]);

  useEffect(() => {
    resetCredenciaisConfig(credentials);
  }, [credentials]);

  useEffect(() => {
    handleOperation();
  }, [handleOperation]);

  useEffect(() => {
    handleErrorSetup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newBoletoKey]);

  useEffect(() => {
    async function getContasBancarias() {
      try {
        const response = await BoletoListService.getContasBancarias();
        setContasBancarias(response);
        setIsLoading(false);
      } catch (error: any) {
        feedback(error.message, 'error');
      }
    }
    getContasBancarias();
  }, []);

  useEffect(() => {
    if (Certificatefile) {
      setNewBoletoKey({
        ...newBoletoKey,
        credenciaisConfig: {
          ...newBoletoKey.credenciaisConfig,
          clientCertificate: {
            ...newBoletoKey.credenciaisConfig.clientCertificate,
            valor: Certificatefile,
            habilitado: true,
          },
        },
      });
    }

    // eslint-disable-next-line
  }, [Certificatefile]);

  return (
    <Grid container>
      <Grid item>
        {id ? (
          <Typography variant="h5" color="primary">
            Editar Configuração
          </Typography>
        ) : (
          <Typography variant="h5" color="primary">
            Nova Configuração
          </Typography>
        )}
      </Grid>

      <Grid container item>
        <Grid item xs>
          <Box marginTop={5}>
            <FormControl
              fullWidth
              variant="outlined"
              className={classes.backGround}
            >
              <InputLabel>Descrição</InputLabel>
              <OutlinedInput
                label="Descrição"
                error={
                  isIncompleteError.descricao && isIncompleteError.showErrors
                }
                type="string"
                value={newBoletoKey.descricao}
                onChange={(e) => {
                  setNewBoletoKey(
                    (state) =>
                      (state = {
                        ...state,
                        descricao: e.target.value,
                      }),
                  );
                }}
              />
            </FormControl>
          </Box>
        </Grid>

        <Grid item xs>
          <Box marginTop={5} marginLeft={2}>
            <FormControl
              variant="outlined"
              fullWidth
              className={classes.backGround}
            >
              <InputLabel>Email</InputLabel>
              <OutlinedInput
                label="Email"
                value={newBoletoKey.email}
                onChange={(e) => {
                  setNewBoletoKey({
                    ...newBoletoKey,
                    email: e.target.value,
                  });
                }}
              />
            </FormControl>
          </Box>
        </Grid>

        <Grid item xs={2}>
          <Box marginTop={5} marginLeft={2}>
            <FormControl
              variant="outlined"
              fullWidth
              className={classes.backGround}
            >
              <InputLabel>Convênio</InputLabel>
              <OutlinedInput
                label="Convênio"
                error={
                  isIncompleteError.convenio && isIncompleteError.showErrors
                }
                value={newBoletoKey.convenio}
                onChange={(e) => {
                  if (!isNaN(Number(e.target.value))) {
                    setNewBoletoKey({
                      ...newBoletoKey,
                      convenio: String(e.target.value),
                    });
                  }
                }}
              />
            </FormControl>
          </Box>
        </Grid>
      </Grid>

      <Grid container item>
        <Grid item xs={3}>
          <Box marginTop={2.5}>
            <FormControl
              fullWidth
              variant="outlined"
              error={
                isIncompleteError.nomeEstabelecimento &&
                isIncompleteError.showErrors
              }
              disabled={id ? true : false}
              className={classes.backGround}
            >
              <InputLabel>Conta Bancária</InputLabel>
              <Select
                disabled={id ? true : false || isLoading}
                value={
                  newBoletoKey.idContaErp === 0 ? '' : newBoletoKey.idContaErp
                }
                label="Conta Bancária"
                onChange={(e) =>
                  setNewBoletoKey(
                    (state) =>
                      (state = {
                        ...state,
                        idContaErp: Number(e.target.value),
                        contaBancariaId: Number(e.target.value),
                      }),
                  )
                }
              >
                {contasBancarias?.map((item) => (
                  <MenuItem
                    key={item.IdContaBancaria}
                    value={item.IdContaBancaria}
                    onClick={() => {
                      if (!id) {
                        getCredentials;
                      }
                      setEstabelecimento(item.Estabelcimentos);
                      setNewBoletoKey(
                        (state) =>
                          (state = {
                            ...state,
                            contaBancaria: item.NumeroConta,
                            agencia: item.NumeroAgencia,
                            codigoBanco: item.CodigoBanco,
                            descricaoContaBancaria: item.DescricaoConta,
                            nomeEstabelecimento: item.DescricaoConta,
                            nomeBanco: item.NomeBanco,
                          }),
                      );
                    }}
                  >
                    {item.DescricaoConta}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {isLoading ? <LinearProgress /> : <></>}
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box marginTop={2.5} marginLeft={2}>
            <FormControl
              disabled
              variant="outlined"
              fullWidth
              className={classes.backGround}
            >
              <InputLabel>Instituição Bancária</InputLabel>
              <OutlinedInput
                defaultValue={newBoletoKey.nomeBanco}
                value={newBoletoKey.nomeBanco || ''}
                label="Instituição Bancária"
                type="string"
              />
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs>
          <Box marginTop={2.5} marginLeft={2}>
            {id ? (
              <>
                <FormControl
                  disabled
                  variant="outlined"
                  fullWidth
                  className={classes.backGround}
                >
                  <InputLabel>Estabelecimento</InputLabel>
                  <OutlinedInput
                    defaultValue={`${newBoletoKey.nomeEstabelecimento} - ${newBoletoKey.cnpjEstabelecimento}`}
                    value={
                      `${newBoletoKey.nomeEstabelecimento} - ${newBoletoKey.cnpjEstabelecimento}` ||
                      ''
                    }
                    label="Estabelecimento"
                    type="string"
                  />
                </FormControl>
              </>
            ) : (
              <>
                <FormControl
                  fullWidth
                  variant="outlined"
                  className={classes.backGround}
                >
                  <InputLabel>Estabelecimento</InputLabel>
                  <Select label="Estabelecimento">
                    {estabelecimentos?.map((item) => (
                      <MenuItem
                        key={item.Id}
                        value={item.Id}
                        onClick={() => {
                          setNewBoletoKey(
                            (state) =>
                              (state = {
                                ...state,
                                cnpjEstabelecimento: item.CNPJ,
                                nomeEstabelecimento: item.Nome,
                              }),
                          );
                        }}
                      >
                        {item.Nome}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            )}
          </Box>
        </Grid>
      </Grid>
      {credentials || id ? (
        <Box marginTop={5} width={'100%'} justifySelf={'center'}>
          <Paper elevation={0} className={classes.paperBackGround}>
            <Box paddingX={4} paddingY={2}>
              <Typography variant="h6" color={'primary'}>
                {newBoletoKey.codigoBanco === '341'
                  ? 'Credenciais Boleto'
                  : 'Credenciais'}
              </Typography>
            </Box>

            {newBoletoKey.credenciaisConfig.developerApplicationId.habilitado ||
            credentials?.developerApplicationId.habilitado ? (
              <Grid container item xs>
                <Grid item xs>
                  <Box
                    paddingX={7}
                    marginTop={1}
                    display={'flex'}
                    alignItems={'center'}
                  >
                    <FormControl
                      variant="outlined"
                      fullWidth
                      className={classes.backGround}
                    >
                      <InputLabel>DeveloperApplicationKey</InputLabel>
                      <OutlinedInput
                        label="DeveloperApplicationKey"
                        type="string"
                        value={
                          newBoletoKey.credenciaisConfig.developerApplicationId
                            .valor
                        }
                        onChange={(e) =>
                          setNewBoletoKey({
                            ...newBoletoKey,
                            credenciaisConfig: {
                              ...newBoletoKey.credenciaisConfig,
                              developerApplicationId: {
                                ...newBoletoKey.credenciaisConfig
                                  .developerApplicationId,
                                valor: e.target.value,
                                habilitado: true,
                              },
                            },
                          })
                        }
                      />
                    </FormControl>
                    <IconButton
                      onClick={() =>
                        navigator.clipboard.readText().then((text) => {
                          setNewBoletoKey({
                            ...newBoletoKey,
                            credenciaisConfig: {
                              ...newBoletoKey.credenciaisConfig,
                              developerApplicationId: {
                                ...newBoletoKey.credenciaisConfig
                                  .developerApplicationId,
                                valor: text,
                                habilitado: true,
                              },
                            },
                          });
                        })
                      }
                    >
                      <ContentPasteIcon color="primary" />
                    </IconButton>
                  </Box>
                </Grid>
              </Grid>
            ) : (
              <></>
            )}

            {newBoletoKey.credenciaisConfig.clientId.habilitado ||
            credentials?.clientId.habilitado ? (
              <Grid container item xs>
                <Grid item xs>
                  <Box
                    paddingX={7}
                    marginTop={2}
                    display={'flex'}
                    alignItems={'center'}
                  >
                    <FormControl
                      variant="outlined"
                      fullWidth
                      className={classes.backGround}
                    >
                      <InputLabel>ClientIdBoleto</InputLabel>
                      <OutlinedInput
                        label="ClientId"
                        type="string"
                        error={
                          isIncompleteError.clientId &&
                          isIncompleteError.showErrors
                        }
                        value={newBoletoKey.credenciaisConfig.clientId.valor}
                        onChange={(e) =>
                          setNewBoletoKey({
                            ...newBoletoKey,
                            credenciaisConfig: {
                              ...newBoletoKey.credenciaisConfig,
                              clientId: {
                                ...newBoletoKey.credenciaisConfig.clientId,
                                valor: e.target.value,
                                habilitado: true,
                              },
                            },
                          })
                        }
                      />
                    </FormControl>

                    <IconButton
                      onClick={() =>
                        navigator.clipboard.readText().then((text) => {
                          setNewBoletoKey({
                            ...newBoletoKey,
                            credenciaisConfig: {
                              ...newBoletoKey.credenciaisConfig,
                              clientId: {
                                ...newBoletoKey.credenciaisConfig.clientId,
                                valor: text,
                                habilitado: true,
                              },
                            },
                          });
                        })
                      }
                    >
                      <ContentPasteIcon color="primary" />
                    </IconButton>
                  </Box>
                </Grid>
              </Grid>
            ) : (
              <></>
            )}

            {newBoletoKey.credenciaisConfig.clientIdBoleto.habilitado ||
            credentials?.clientIdBoleto.habilitado ? (
              <Grid container item xs>
                <Grid item xs>
                  <Box
                    paddingX={7}
                    marginTop={2}
                    display={'flex'}
                    alignItems={'center'}
                  >
                    <FormControl
                      variant="outlined"
                      fullWidth
                      className={classes.backGround}
                    >
                      <InputLabel>ClientIdBoleto</InputLabel>
                      <OutlinedInput
                        label="ClientIdBoleto"
                        type="string"
                        error={
                          isIncompleteError.clientId &&
                          isIncompleteError.showErrors
                        }
                        value={
                          newBoletoKey.credenciaisConfig.clientIdBoleto.valor
                        }
                        onChange={(e) =>
                          setNewBoletoKey({
                            ...newBoletoKey,
                            credenciaisConfig: {
                              ...newBoletoKey.credenciaisConfig,
                              clientIdBoleto: {
                                ...newBoletoKey.credenciaisConfig
                                  .clientIdBoleto,
                                valor: e.target.value,
                                habilitado: true,
                              },
                            },
                          })
                        }
                      />
                    </FormControl>

                    <IconButton
                      onClick={() =>
                        navigator.clipboard.readText().then((text) => {
                          setNewBoletoKey({
                            ...newBoletoKey,
                            credenciaisConfig: {
                              ...newBoletoKey.credenciaisConfig,
                              clientIdBoleto: {
                                ...newBoletoKey.credenciaisConfig
                                  .clientIdBoleto,
                                valor: text,
                                habilitado: true,
                              },
                            },
                          });
                        })
                      }
                    >
                      <ContentPasteIcon color="primary" />
                    </IconButton>
                  </Box>
                </Grid>
              </Grid>
            ) : (
              <></>
            )}

            {newBoletoKey.credenciaisConfig.clientSecretBoleto.habilitado ||
            credentials?.clientSecretBoleto.habilitado ? (
              <Grid container item xs>
                <Grid item xs>
                  <Box
                    paddingX={7}
                    marginTop={2}
                    display={'flex'}
                    alignItems={'center'}
                  >
                    <FormControl
                      variant="outlined"
                      fullWidth
                      className={classes.backGround}
                    >
                      <InputLabel>ClientSecretBoleto</InputLabel>
                      <OutlinedInput
                        error={
                          isIncompleteError.clientSecret &&
                          isIncompleteError.showErrors
                        }
                        label="ClientSecretBoleto"
                        type="string"
                        disabled={newBoletoKey.codigoBanco === '341'}
                        value={
                          newBoletoKey.credenciaisConfig.clientSecretBolecode
                            .valor
                        }
                        onChange={(e) =>
                          setNewBoletoKey({
                            ...newBoletoKey,
                            credenciaisConfig: {
                              ...newBoletoKey.credenciaisConfig,
                              clientSecretBoleto: {
                                ...newBoletoKey.credenciaisConfig
                                  .clientSecretBoleto,
                                valor: e.target.value,
                                habilitado: true,
                              },
                            },
                          })
                        }
                      />
                    </FormControl>
                    <IconButton
                      disabled={newBoletoKey.codigoBanco === '341'}
                      onClick={() =>
                        navigator.clipboard.readText().then((text) => {
                          setNewBoletoKey({
                            ...newBoletoKey,
                            credenciaisConfig: {
                              ...newBoletoKey.credenciaisConfig,
                              clientSecretBoleto: {
                                ...newBoletoKey.credenciaisConfig
                                  .clientSecretBoleto,
                                valor: text,
                                habilitado: true,
                              },
                            },
                          });
                        })
                      }
                    >
                      <ContentPasteIcon
                        color={
                          newBoletoKey.codigoBanco === '341'
                            ? 'disabled'
                            : 'primary'
                        }
                      />
                    </IconButton>
                  </Box>
                </Grid>
              </Grid>
            ) : (
              <></>
            )}

            {newBoletoKey.credenciaisConfig.tokenTemporarioBoleto.habilitado ||
            credentials?.tokenTemporarioBoleto.habilitado ? (
              <Grid container item xs>
                <Grid item xs>
                  <Box
                    paddingX={7}
                    marginTop={2}
                    display={'flex'}
                    alignItems={'center'}
                  >
                    <FormControl
                      variant="outlined"
                      fullWidth
                      className={classes.backGround}
                    >
                      <Tooltip title="Informar o token temporário gerado pelo Itaú.">
                        <InputLabel>TokenTemporarioBoleto</InputLabel>
                      </Tooltip>

                      <OutlinedInput
                        error={
                          //isIncompleteError.tokenTemporario &&
                          isIncompleteError.showErrors
                        }
                        label="TokenTemporario"
                        type="string"
                        value={
                          newBoletoKey.credenciaisConfig.tokenTemporarioBoleto
                            .valor
                        }
                        onChange={(e) =>
                          setNewBoletoKey({
                            ...newBoletoKey,
                            credenciaisConfig: {
                              ...newBoletoKey.credenciaisConfig,
                              tokenTemporarioBoleto: {
                                ...newBoletoKey.credenciaisConfig
                                  .tokenTemporarioBoleto,
                                valor: e.target.value,
                                habilitado: true,
                              },
                            },
                          })
                        }
                      />
                    </FormControl>
                    <IconButton
                      onClick={() =>
                        navigator.clipboard.readText().then((text) => {
                          setNewBoletoKey({
                            ...newBoletoKey,
                            credenciaisConfig: {
                              ...newBoletoKey.credenciaisConfig,
                              tokenTemporarioBoleto: {
                                ...newBoletoKey.credenciaisConfig
                                  .tokenTemporarioBoleto,
                                valor: text,
                                habilitado: true,
                              },
                            },
                          });
                        })
                      }
                    >
                      <ContentPasteIcon color="primary" />
                    </IconButton>
                  </Box>
                </Grid>
              </Grid>
            ) : (
              <></>
            )}
            {newBoletoKey.codigoBanco === '341' ? (
              <Box paddingX={4} paddingY={2} marginTop={1.5}>
                <Typography variant="h6" color={'primary'}>
                  Credenciais Bolecode
                </Typography>
              </Box>
            ) : (
              <></>
            )}

            {newBoletoKey.credenciaisConfig.clientIdBolecode.habilitado ||
            credentials?.clientIdBolecode.habilitado ? (
              <Grid container item xs>
                <Grid item xs>
                  <Box
                    paddingX={7}
                    marginTop={2}
                    display={'flex'}
                    alignItems={'center'}
                  >
                    <FormControl
                      variant="outlined"
                      fullWidth
                      className={classes.backGround}
                    >
                      <InputLabel>ClientIdBolecode</InputLabel>
                      <OutlinedInput
                        label="ClientIdBolecode"
                        type="string"
                        error={
                          isIncompleteError.clientId &&
                          isIncompleteError.showErrors
                        }
                        value={
                          newBoletoKey.credenciaisConfig.clientIdBolecode.valor
                        }
                        onChange={(e) =>
                          setNewBoletoKey({
                            ...newBoletoKey,
                            credenciaisConfig: {
                              ...newBoletoKey.credenciaisConfig,
                              clientIdBolecode: {
                                ...newBoletoKey.credenciaisConfig
                                  .clientIdBolecode,
                                valor: e.target.value,
                                habilitado: true,
                              },
                            },
                          })
                        }
                      />
                    </FormControl>

                    <IconButton
                      onClick={() =>
                        navigator.clipboard.readText().then((text) => {
                          setNewBoletoKey({
                            ...newBoletoKey,
                            credenciaisConfig: {
                              ...newBoletoKey.credenciaisConfig,
                              clientIdBolecode: {
                                ...newBoletoKey.credenciaisConfig
                                  .clientIdBolecode,
                                valor: text,
                                habilitado: true,
                              },
                            },
                          });
                        })
                      }
                    >
                      <ContentPasteIcon color="primary" />
                    </IconButton>
                  </Box>
                </Grid>
              </Grid>
            ) : (
              <></>
            )}

            {newBoletoKey.credenciaisConfig.clientSecret.habilitado ||
            credentials?.clientSecret.habilitado ? (
              <Grid container item xs>
                <Grid item xs>
                  <Box
                    paddingX={7}
                    marginTop={2}
                    display={'flex'}
                    alignItems={'center'}
                  >
                    <FormControl
                      variant="outlined"
                      fullWidth
                      className={classes.backGround}
                    >
                      <InputLabel>ClientSecret</InputLabel>
                      <OutlinedInput
                        error={
                          isIncompleteError.clientSecret &&
                          isIncompleteError.showErrors
                        }
                        label="ClientSecret"
                        type="string"
                        disabled={newBoletoKey.codigoBanco === '341'}
                        value={
                          newBoletoKey.credenciaisConfig.clientSecret.valor
                        }
                        onChange={(e) =>
                          setNewBoletoKey({
                            ...newBoletoKey,
                            credenciaisConfig: {
                              ...newBoletoKey.credenciaisConfig,
                              clientSecret: {
                                ...newBoletoKey.credenciaisConfig.clientSecret,
                                valor: e.target.value,
                                habilitado: true,
                              },
                            },
                          })
                        }
                      />
                    </FormControl>
                    <IconButton
                      disabled={newBoletoKey.codigoBanco === '341'}
                      onClick={() =>
                        navigator.clipboard.readText().then((text) => {
                          setNewBoletoKey({
                            ...newBoletoKey,
                            credenciaisConfig: {
                              ...newBoletoKey.credenciaisConfig,
                              clientSecret: {
                                ...newBoletoKey.credenciaisConfig.clientSecret,
                                valor: text,
                                habilitado: true,
                              },
                            },
                          });
                        })
                      }
                    >
                      <ContentPasteIcon
                        color={
                          newBoletoKey.codigoBanco === '341'
                            ? 'disabled'
                            : 'primary'
                        }
                      />
                    </IconButton>
                  </Box>
                </Grid>
              </Grid>
            ) : (
              <></>
            )}

            {newBoletoKey.credenciaisConfig.clientSecretBolecode.habilitado ||
            credentials?.clientSecretBolecode.habilitado ? (
              <Grid container item xs>
                <Grid item xs>
                  <Box
                    paddingX={7}
                    marginTop={2}
                    display={'flex'}
                    alignItems={'center'}
                  >
                    <FormControl
                      variant="outlined"
                      fullWidth
                      className={classes.backGround}
                    >
                      <InputLabel>ClientSecretBocode</InputLabel>
                      <OutlinedInput
                        error={
                          isIncompleteError.clientSecret &&
                          isIncompleteError.showErrors
                        }
                        label="ClientSecretBolecode"
                        type="string"
                        disabled={newBoletoKey.codigoBanco === '341'}
                        value={
                          newBoletoKey.credenciaisConfig.clientSecretBolecode
                            .valor
                        }
                        onChange={(e) =>
                          setNewBoletoKey({
                            ...newBoletoKey,
                            credenciaisConfig: {
                              ...newBoletoKey.credenciaisConfig,
                              clientSecretBoleto: {
                                ...newBoletoKey.credenciaisConfig
                                  .clientSecretBoleto,
                                valor: e.target.value,
                                habilitado: true,
                              },
                            },
                          })
                        }
                      />
                    </FormControl>
                    <IconButton
                      disabled={newBoletoKey.codigoBanco === '341'}
                      onClick={() =>
                        navigator.clipboard.readText().then((text) => {
                          setNewBoletoKey({
                            ...newBoletoKey,
                            credenciaisConfig: {
                              ...newBoletoKey.credenciaisConfig,
                              clientSecretBoleto: {
                                ...newBoletoKey.credenciaisConfig
                                  .clientSecretBoleto,
                                valor: text,
                                habilitado: true,
                              },
                            },
                          });
                        })
                      }
                    >
                      <ContentPasteIcon
                        color={
                          newBoletoKey.codigoBanco === '341'
                            ? 'disabled'
                            : 'primary'
                        }
                      />
                    </IconButton>
                  </Box>
                </Grid>
              </Grid>
            ) : (
              <></>
            )}

            {newBoletoKey.credenciaisConfig.tokenTemporarioBolecode
              .habilitado || credentials?.tokenTemporarioBolecode.habilitado ? (
              <Grid container item xs>
                <Grid item xs>
                  <Box
                    paddingX={7}
                    marginTop={2}
                    display={'flex'}
                    alignItems={'center'}
                  >
                    <FormControl
                      variant="outlined"
                      fullWidth
                      className={classes.backGround}
                    >
                      <Tooltip title="Informar o token temporário gerado pelo Itaú.">
                        <InputLabel>TokenTemporarioBolecode</InputLabel>
                      </Tooltip>

                      <OutlinedInput
                        error={
                          //isIncompleteError.tokenTemporario &&
                          isIncompleteError.showErrors
                        }
                        label="TokenTemporario"
                        type="string"
                        value={
                          newBoletoKey.credenciaisConfig.tokenTemporarioBolecode
                            .valor
                        }
                        onChange={(e) =>
                          setNewBoletoKey({
                            ...newBoletoKey,
                            credenciaisConfig: {
                              ...newBoletoKey.credenciaisConfig,
                              tokenTemporarioBolecode: {
                                ...newBoletoKey.credenciaisConfig
                                  .tokenTemporarioBolecode,
                                valor: e.target.value,
                                habilitado: true,
                              },
                            },
                          })
                        }
                      />
                    </FormControl>
                    <IconButton
                      onClick={() =>
                        navigator.clipboard.readText().then((text) => {
                          setNewBoletoKey({
                            ...newBoletoKey,
                            credenciaisConfig: {
                              ...newBoletoKey.credenciaisConfig,
                              tokenTemporarioBolecode: {
                                ...newBoletoKey.credenciaisConfig
                                  .tokenTemporarioBolecode,
                                valor: text,
                                habilitado: true,
                              },
                            },
                          });
                        })
                      }
                    >
                      <ContentPasteIcon color="primary" />
                    </IconButton>
                  </Box>
                </Grid>
              </Grid>
            ) : (
              <></>
            )}

            {(newBoletoKey.credenciaisConfig.chavePix.habilitado ||
              credentials?.chavePix.habilitado) &&
            (newBoletoKey.credenciaisConfig.tipoChavePix.habilitado ||
              credentials?.tipoChavePix.habilitado) ? (
              <Box display={'flex'}>
                <Grid container item xs={3}>
                  <Grid item xs>
                    <Box
                      paddingLeft={7}
                      marginTop={2}
                      display={'flex'}
                      alignItems={'center'}
                    >
                      <FormControl
                        variant="outlined"
                        fullWidth
                        className={classes.backGround}
                      >
                        <InputLabel>pixKey type</InputLabel>
                        <Select
                          disabled={isLoading}
                          label="Tipo chave pix"
                          defaultValue={
                            newBoletoKey.credenciaisConfig.tipoChavePix.valor
                          }
                          value={
                            newBoletoKey.credenciaisConfig.tipoChavePix?.valor
                          }
                        >
                          {tiposChavePix.map((chave) => (
                            <MenuItem
                              value={chave}
                              key={chave}
                              onClick={() =>
                                setNewBoletoKey({
                                  ...newBoletoKey,
                                  credenciaisConfig: {
                                    ...newBoletoKey.credenciaisConfig,
                                    tipoChavePix: {
                                      valor: chave,
                                      habilitado: true,
                                    },
                                  },
                                })
                              }
                            >
                              {chave}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                </Grid>

                <Grid container item xs>
                  <Grid item xs>
                    <Box
                      paddingX={7}
                      marginTop={2}
                      display={'flex'}
                      alignItems={'center'}
                    >
                      <FormControl
                        variant="outlined"
                        fullWidth
                        className={classes.backGround}
                      >
                        <InputLabel>pixKey</InputLabel>
                        <OutlinedInput
                          label="pixKey"
                          type="string"
                          value={newBoletoKey.credenciaisConfig.chavePix.valor}
                          onChange={(e) =>
                            setNewBoletoKey({
                              ...newBoletoKey,
                              credenciaisConfig: {
                                ...newBoletoKey.credenciaisConfig,
                                chavePix: {
                                  ...newBoletoKey.credenciaisConfig.chavePix,
                                  valor: e.target.value,
                                  habilitado: true,
                                },
                              },
                            })
                          }
                        />
                      </FormControl>
                      <IconButton
                        onClick={() =>
                          navigator.clipboard.readText().then((text) => {
                            setNewBoletoKey({
                              ...newBoletoKey,
                              credenciaisConfig: {
                                ...newBoletoKey.credenciaisConfig,
                                clientSecret: {
                                  ...newBoletoKey.credenciaisConfig.chavePix,
                                  valor: text,
                                  habilitado: true,
                                },
                              },
                            });
                          })
                        }
                      >
                        <ContentPasteIcon color="primary" />
                      </IconButton>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            ) : (
              <></>
            )}
            <WorkspaceContent
              convenio={newBoletoKey.convenio}
              isError={
                isIncompleteError.clientId ||
                isIncompleteError.clientSecret ||
                isIncompleteError.convenio ||
                isIncompleteError.descricao ||
                isIncompleteError.nomeEstabelecimento
              }
              handleErrorCheck={handleErrorCheck}
              backgroudStyle={classes.backGround}
              workspace={newBoletoKey.credenciaisConfig.workspace}
              isEdit={!!(id?.length > 0)}
              isActive={
                !!(
                  newBoletoKey.credenciaisConfig.workspace.habilitado ||
                  credentials?.workspace.habilitado
                )
              }
            />

            {newBoletoKey.credenciaisConfig.basic.habilitado ||
            credentials?.basic.habilitado ? (
              <Grid container item xs>
                <Grid item xs>
                  <Box
                    paddingX={7}
                    marginTop={2}
                    display={'flex'}
                    alignItems={'center'}
                  >
                    <FormControl
                      variant="outlined"
                      fullWidth
                      className={classes.backGround}
                    >
                      <InputLabel>Basic</InputLabel>
                      <OutlinedInput
                        label="Basic"
                        type="string"
                        value={newBoletoKey.credenciaisConfig.basic.valor}
                        onChange={(e) =>
                          setNewBoletoKey({
                            ...newBoletoKey,
                            credenciaisConfig: {
                              ...newBoletoKey.credenciaisConfig,
                              basic: {
                                ...newBoletoKey.credenciaisConfig.basic,
                                valor: e.target.value,
                                habilitado: true,
                              },
                            },
                          })
                        }
                      />
                    </FormControl>

                    <IconButton
                      onClick={() =>
                        navigator.clipboard.readText().then((text) => {
                          setNewBoletoKey({
                            ...newBoletoKey,
                            credenciaisConfig: {
                              ...newBoletoKey.credenciaisConfig,
                              basic: {
                                ...newBoletoKey.credenciaisConfig.basic,
                                valor: text,
                                habilitado: true,
                              },
                            },
                          });
                        })
                      }
                    >
                      <ContentPasteIcon color="primary" />
                    </IconButton>
                  </Box>
                </Grid>
              </Grid>
            ) : (
              <></>
            )}

            <TimerSelect
              ref={timeSelectRef}
              isEdit={!id}
              backGround={classes.backGround}
              habilitado={
                newBoletoKey.credenciaisConfig.horaProcessamento.habilitado
              }
              timeValue={timeValue}
              setTimeValue={setTimeValue}
              newConfigKey={newBoletoKey}
              setNewConfigKey={setNewBoletoKey}
              timeValidation={timeValidation}
              setTimeValidation={setTimeValidation}
            />

            {newBoletoKey.credenciaisConfig.xApiKey.habilitado ||
            credentials?.xApiKey.habilitado ? (
              <Grid container item xs>
                <Grid item xs>
                  <Box
                    paddingX={7}
                    marginTop={2}
                    display={'flex'}
                    alignItems={'center'}
                  >
                    <FormControl
                      variant="outlined"
                      fullWidth
                      className={classes.backGround}
                    >
                      <InputLabel>xApiKey</InputLabel>
                      <OutlinedInput
                        label="xApiKey"
                        type="string"
                        value={newBoletoKey.credenciaisConfig.xApiKey.valor}
                        onChange={(e) =>
                          setNewBoletoKey({
                            ...newBoletoKey,
                            credenciaisConfig: {
                              ...newBoletoKey.credenciaisConfig,
                              xApiKey: {
                                ...newBoletoKey.credenciaisConfig.xApiKey,
                                valor: e.target.value,
                                habilitado: true,
                              },
                            },
                          })
                        }
                      />
                    </FormControl>

                    <IconButton
                      onClick={() =>
                        navigator.clipboard.readText().then((text) => {
                          setNewBoletoKey({
                            ...newBoletoKey,
                            credenciaisConfig: {
                              ...newBoletoKey.credenciaisConfig,
                              xApiKey: {
                                ...newBoletoKey.credenciaisConfig.xApiKey,
                                valor: text,
                                habilitado: true,
                              },
                            },
                          });
                        })
                      }
                    >
                      <ContentPasteIcon color="primary" />
                    </IconButton>
                  </Box>
                </Grid>
              </Grid>
            ) : (
              <></>
            )}
            {newBoletoKey.credenciaisConfig.xApiKey.habilitado ||
            credentials?.xApiKey.habilitado ? (
              <Grid container item xs>
                <Grid item xs>
                  <Box
                    paddingX={7}
                    marginTop={2}
                    display={'flex'}
                    alignItems={'center'}
                  >
                    <FormControl
                      variant="outlined"
                      fullWidth
                      className={classes.backGround}
                    >
                      <InputLabel>Username</InputLabel>
                      <OutlinedInput
                        label="Username"
                        type="string"
                        value={newBoletoKey.credenciaisConfig.username.valor}
                        onChange={(e) =>
                          setNewBoletoKey({
                            ...newBoletoKey,
                            credenciaisConfig: {
                              ...newBoletoKey.credenciaisConfig,
                              username: {
                                ...newBoletoKey.credenciaisConfig.username,
                                valor: e.target.value,
                                habilitado: true,
                              },
                            },
                          })
                        }
                      />
                    </FormControl>

                    <IconButton
                      onClick={() =>
                        navigator.clipboard.readText().then((text) => {
                          setNewBoletoKey({
                            ...newBoletoKey,
                            credenciaisConfig: {
                              ...newBoletoKey.credenciaisConfig,
                              username: {
                                ...newBoletoKey.credenciaisConfig.username,
                                valor: text,
                                habilitado: true,
                              },
                            },
                          });
                        })
                      }
                    >
                      <ContentPasteIcon color="primary" />
                    </IconButton>
                  </Box>
                </Grid>
              </Grid>
            ) : (
              <></>
            )}

            {newBoletoKey.credenciaisConfig.clientCertificate.habilitado ||
            credentials?.clientCertificate.habilitado ? (
              <>
                <Box paddingX={4} paddingY={2}>
                  <Typography variant="h6" color={'primary'}>
                    Certificado
                  </Typography>
                </Box>
                <Grid container item xs>
                  <Grid item xs>
                    <Box
                      width={'97.1%'}
                      paddingX={7}
                      marginTop={2}
                      display={'flex'}
                      alignItems={'center'}
                    >
                      <FormControl
                        variant="outlined"
                        fullWidth
                        className={classes.backGroundCertificate}
                      >
                        <UploadFiles
                          setFile={setCertificateFile}
                          id={id ? true : false}
                        />
                      </FormControl>
                    </Box>
                  </Grid>
                </Grid>
              </>
            ) : (
              <></>
            )}

            {newBoletoKey.credenciaisConfig.passPhrase.habilitado ||
            credentials?.passPhrase.habilitado ? (
              <Grid container item xs>
                <Grid item xs>
                  <Box
                    paddingX={7}
                    marginTop={2}
                    display={'flex'}
                    alignItems={'center'}
                  >
                    <FormControl
                      variant="outlined"
                      fullWidth
                      className={classes.backGround}
                    >
                      <InputLabel>passPhrase</InputLabel>
                      <OutlinedInput
                        label="passPhrase"
                        type="string"
                        value={newBoletoKey.credenciaisConfig.passPhrase.valor}
                        onChange={(e) =>
                          setNewBoletoKey({
                            ...newBoletoKey,
                            credenciaisConfig: {
                              ...newBoletoKey.credenciaisConfig,
                              passPhrase: {
                                ...newBoletoKey.credenciaisConfig.passPhrase,
                                valor: e.target.value,
                                habilitado: true,
                              },
                            },
                          })
                        }
                      />
                    </FormControl>

                    <IconButton
                      onClick={() =>
                        navigator.clipboard.readText().then((text) => {
                          setNewBoletoKey({
                            ...newBoletoKey,
                            credenciaisConfig: {
                              ...newBoletoKey.credenciaisConfig,
                              passPhrase: {
                                ...newBoletoKey.credenciaisConfig.passPhrase,
                                valor: text,
                                habilitado: true,
                              },
                            },
                          });
                        })
                      }
                    >
                      <ContentPasteIcon color="primary" />
                    </IconButton>
                  </Box>
                </Grid>
              </Grid>
            ) : (
              <></>
            )}

            {newBoletoKey.credenciaisConfig.posto.habilitado ||
            credentials?.posto.habilitado ? (
              <Grid container item xs>
                <Grid item xs>
                  <Box
                    paddingX={7}
                    marginTop={2}
                    display={'flex'}
                    alignItems={'center'}
                  >
                    <FormControl
                      variant="outlined"
                      fullWidth
                      className={classes.backGround}
                    >
                      <InputLabel>Posto</InputLabel>
                      <OutlinedInput
                        label="posto"
                        type="string"
                        value={newBoletoKey.credenciaisConfig.posto.valor}
                        onChange={(e) =>
                          setNewBoletoKey({
                            ...newBoletoKey,
                            credenciaisConfig: {
                              ...newBoletoKey.credenciaisConfig,
                              posto: {
                                ...newBoletoKey.credenciaisConfig.posto,
                                valor: e.target.value,
                                habilitado: true,
                              },
                            },
                          })
                        }
                      />
                    </FormControl>

                    <IconButton
                      onClick={() =>
                        navigator.clipboard.readText().then((text) => {
                          setNewBoletoKey({
                            ...newBoletoKey,
                            credenciaisConfig: {
                              ...newBoletoKey.credenciaisConfig,
                              posto: {
                                ...newBoletoKey.credenciaisConfig.posto,
                                valor: text,
                                habilitado: true,
                              },
                            },
                          });
                        })
                      }
                    >
                      <ContentPasteIcon color="primary" />
                    </IconButton>
                  </Box>
                </Grid>
              </Grid>
            ) : (
              <></>
            )}

            {newBoletoKey.credenciaisConfig.cooperativa.habilitado ||
            credentials?.cooperativa.habilitado ? (
              <Grid container item xs>
                <Grid item xs>
                  <Box
                    paddingX={7}
                    marginTop={2}
                    display={'flex'}
                    alignItems={'center'}
                  >
                    <FormControl
                      variant="outlined"
                      fullWidth
                      className={classes.backGround}
                    >
                      <InputLabel>Cooperativa</InputLabel>
                      <OutlinedInput
                        label="cooperativa"
                        type="string"
                        value={newBoletoKey.credenciaisConfig.cooperativa.valor}
                        onChange={(e) =>
                          setNewBoletoKey({
                            ...newBoletoKey,
                            credenciaisConfig: {
                              ...newBoletoKey.credenciaisConfig,
                              cooperativa: {
                                ...newBoletoKey.credenciaisConfig.cooperativa,
                                valor: e.target.value,
                                habilitado: true,
                              },
                            },
                          })
                        }
                      />
                    </FormControl>

                    <IconButton
                      onClick={() =>
                        navigator.clipboard.readText().then((text) => {
                          setNewBoletoKey({
                            ...newBoletoKey,
                            credenciaisConfig: {
                              ...newBoletoKey.credenciaisConfig,
                              cooperativa: {
                                ...newBoletoKey.credenciaisConfig.cooperativa,
                                valor: text,
                                habilitado: true,
                              },
                            },
                          });
                        })
                      }
                    >
                      <ContentPasteIcon color="primary" />
                    </IconButton>
                  </Box>
                </Grid>
              </Grid>
            ) : (
              <></>
            )}

            {newBoletoKey.credenciaisConfig.password.habilitado ||
            credentials?.password.habilitado ? (
              <Grid container item xs>
                <Grid item xs>
                  <Box
                    paddingX={7}
                    marginTop={2}
                    display={'flex'}
                    alignItems={'center'}
                  >
                    <FormControl
                      variant="outlined"
                      fullWidth
                      className={classes.backGround}
                    >
                      <InputLabel>Password</InputLabel>
                      <OutlinedInput
                        label="Password"
                        type="string"
                        value={newBoletoKey.credenciaisConfig.password.valor}
                        onChange={(e) =>
                          setNewBoletoKey({
                            ...newBoletoKey,
                            credenciaisConfig: {
                              ...newBoletoKey.credenciaisConfig,
                              password: {
                                ...newBoletoKey.credenciaisConfig.password,
                                valor: e.target.value,
                                habilitado: true,
                              },
                            },
                          })
                        }
                      />
                    </FormControl>

                    <IconButton
                      onClick={() =>
                        navigator.clipboard.readText().then((text) => {
                          setNewBoletoKey({
                            ...newBoletoKey,
                            credenciaisConfig: {
                              ...newBoletoKey.credenciaisConfig,
                              password: {
                                ...newBoletoKey.credenciaisConfig.password,
                                valor: text,
                                habilitado: true,
                              },
                            },
                          });
                        })
                      }
                    >
                      <ContentPasteIcon color="primary" />
                    </IconButton>
                  </Box>
                </Grid>
              </Grid>
            ) : (
              <></>
            )}

            {newBoletoKey.codigoBanco === '341' && newBoletoKey.id ? (
              <Grid container item xs>
                <Grid item xs>
                  <Box
                    paddingX={7}
                    marginTop={2}
                    display={'flex'}
                    alignItems={'center'}
                  >
                    <Box
                      display={'flex'}
                      justifyContent={'space-evenly'}
                      alignContent={'space-between'}
                    >
                      <Box minWidth={'250px'}>
                        <Button
                          variant="contained"
                          onClick={() =>
                            BoletoListService.gerarCertificado(newBoletoKey.id)
                          }
                        >
                          {newBoletoKey.dataVencimentoCertificadoDinamico >=
                          new Date()
                            ? 'Renovar certificado dinâmico'
                            : 'Gerar certificado dinâmico'}
                        </Button>
                      </Box>
                      <Box marginLeft={2} display="flex" alignItems="center">
                        <InfoOutlinedIcon
                          color="primary"
                          style={{ marginRight: '4px' }}
                        />
                        <Typography variant="subtitle2" align="inherit">
                          {newBoletoKey.possuiCertificadoDinamico === false &&
                            certificadoNaoGerado}
                          {newBoletoKey.possuiCertificadoDinamico &&
                            newBoletoKey.dataVencimentoCertificadoDinamico >=
                              new Date() &&
                            certificadoGerado}
                          {newBoletoKey.possuiCertificadoDinamico &&
                            !(
                              newBoletoKey.dataVencimentoCertificadoDinamico >=
                              new Date()
                            ) &&
                            certificadoVencido}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            ) : (
              <></>
            )}
          </Paper>
        </Box>
      ) : (
        <Box marginTop={3}>
          <Typography variant="h6" color={'primary'}>
            <i>Selecione uma conta bancária para visualizar as credenciais.</i>
          </Typography>
        </Box>
      )}

      {id ? (
        <FooterBarBoleto
          onCancel={handleBack}
          onSave={handleBoletoSubmit}
          onExcluirChave={handleDeleteConfig}
        />
      ) : (
        <FooterBarBoleto onCancel={handleBack} onSave={submitBoletoConfig} />
      )}

      <RegisteredSantanderModal
        isRegister={!id}
        isSantander={SANTANDER_CODIGO_BANCO === newBoletoKey.codigoBanco}
        openModal={openModal}
        handleCloseModal={() => {
          setOpenModal(false);
          navigate(`/minhas-credenciais`, boletoNavigateState);
        }}
      />
    </Grid>
  );
};
