import { Api } from 'shared/services/axios-config';
import { IErrorMessage, IResultOfAction, responseError } from 'shared/services/response-error/ResponseError';

export interface IEstabelecimento {
  CodigoEstabelecimento: string;
  Nome: string;
  CodigoEstruturado: string;
  CNPJ: string;
  CodigoPai: string;
  PossuiLojas: boolean;
  EstabelecimentosFilhos: IEstabelecimento[];
}

const getEstabelecimentos = async (filtroNomeEstabelecimento: string): Promise<IResultOfAction<any>> => {
  try {
    const { data } = await Api().get(`/estabelecimentos?filtro=${filtroNomeEstabelecimento || ''}`);

    if (data) {
      return {data: data, sucesso: true} as IResultOfAction<any>;
    }
    
    const errorResponse: IResultOfAction<any> = {
      mensagens: [] as IErrorMessage[],
      mensagensCombinadas: data.ResultadoDaAcao.MensagensCombinadas,
      severidade: data.ResultadoDaAcao.Severidade,
      severidadeId: data.ResultadoDaAcao.SeveridadeId,
      sucesso: data.ResultadoDaAcao.Sucesso
    }

    return errorResponse;

  } catch (error:any) {
    const result = responseError(error);
    return result;
  }
};

export const EstabelecimentoService = {
  getEstabelecimentos
};
