import React, { useCallback, useState } from 'react';
import {
  Accordion as MuiAccordion,
  Table,
  Paper,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  IconButton,
  Typography,
  AccordionDetails,
  AccordionSummary,
  Skeleton,
} from '@mui/material';
import { ExpandMore, HeightSharp } from '@mui/icons-material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { IPrioridades, ILojaPrioridades, IBandeira } from 'shared/services/api';
import { Section } from 'shared/components';

const Accordion = MuiAccordion;

interface IProps {
  isLoading: boolean;
  bandeiras: IBandeira[];
  prioridades: IPrioridades[];
  setPrioridades(value: IPrioridades[]): void;
}

export const Prioridade: React.FC<IProps> = ({
  isLoading,
  bandeiras,
  prioridades,
  setPrioridades,
}) => {
  const [expanded, setExpanded] = useState<number | false>(false);
  const removeBlanks = useCallback((texto: string) => {
    return texto.replace(/\s/g, '');
  }, []);

  const handleAccordion =
    (id: number) => (event: React.ChangeEvent<any>, isExpanded: boolean) => {
      setExpanded(isExpanded ? id : false);
    };

  const reorder = (
    list: ILojaPrioridades[],
    startIndex: number,
    endIndex: number,
  ): ILojaPrioridades[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const handleDnd = (result: any) => {
    if (!result.destination) return;

    const idBandeira = Number(result.source.droppableId.split('-')[1]);

    const prioridade: IPrioridades | undefined = prioridades.find(
      (x) => x.IdBandeira === idBandeira,
    );

    if (prioridade) {
      const items = reorder(
        prioridade.Prioridades,
        result.source.index,
        result.destination.index,
      );

      Object.keys(items).forEach((item, i) => {
        items[i].Prioridade = i + 1;
      });

      setPrioridades(
        [...prioridades].map((bandeira) => {
          if (bandeira.IdBandeira === idBandeira) {
            return {
              ...bandeira,
              Prioridades: items,
            };
          }
          return bandeira;
        }),
      );
    }
  };

  function obterPrioridadesDaBandeira(bandeira: IBandeira) {
    const prioridade = prioridades.find(
      (x) => x.IdBandeira === bandeira.BandeiraId,
    );

    return prioridade ? (
      <Accordion
        key={bandeira.BandeiraId}
        expanded={expanded === bandeira.BandeiraId}
        // className={classes.accordion}
        onChange={handleAccordion(bandeira.BandeiraId)}
      >
        <AccordionSummary expandIcon={<ExpandMore color="primary" />}>
          <Typography variant="subtitle2">
            {expanded === bandeira.BandeiraId ? (
              <strong>{prioridade.BandeiraNome}</strong>
            ) : (
              prioridade.BandeiraNome
            )}
          </Typography>
        </AccordionSummary>

        <AccordionDetails>
          <Droppable
            type={`${prioridade.IdBandeira}`}
            droppableId={`droppable-${prioridade.IdBandeira}`}
          >
            {(provided) => (
              <Table
                ref={provided.innerRef}
                {...provided.droppableProps}
                style={{ borderCollapse: 'separate' }}
                component={Paper}
              >
                <TableHead>
                  <TableRow>
                    <TableCell width="5%" align="center">
                      Ações
                    </TableCell>
                    <TableCell width="45%" align="left">
                      Loja
                    </TableCell>
                    <TableCell width="50%" align="right">
                      Ordem
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {prioridade.Prioridades.map((loja, index) => (
                    <Draggable
                      key={`key${removeBlanks(
                        prioridade.BandeiraNome,
                      )}${index}`}
                      index={index}
                      draggableId={`draggable-${removeBlanks(
                        prioridade.BandeiraNome,
                      )}-${index}`}
                    >
                      {(provided) => (
                        <TableRow
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <TableCell width="5%" align="center">
                            <div style={{ width: '45px' }}>
                              <IconButton>
                                <HeightSharp
                                  color="primary"
                                  style={{ cursor: 'grab' }}
                                />
                              </IconButton>
                            </div>
                          </TableCell>
                          <TableCell width="45%" align="left">
                            {loja.LojaNome}
                          </TableCell>
                          <TableCell width="50%" align="right">
                            {loja.Prioridade}
                          </TableCell>
                        </TableRow>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </TableBody>
              </Table>
            )}
          </Droppable>
        </AccordionDetails>
      </Accordion>
    ) : (
      <Accordion
        disabled
        key={bandeira.BandeiraId}
        expanded={false}
        // className={classes.accordion}
        onChange={handleAccordion(bandeira.BandeiraId)}
      >
        <AccordionSummary expandIcon={<ExpandMore color="disabled" />}>
          <Typography variant="subtitle2">{bandeira.Nome}</Typography>
        </AccordionSummary>
      </Accordion>
    );
  }

  return (
    <Section title="Bandeiras">
      {isLoading ? (
        <>
          <Skeleton variant="rectangular" width="100%" height={100} />
        </>
      ) : (
        <Paper>
          <DragDropContext onDragEnd={handleDnd}>
            {bandeiras.map((bandeira) => obterPrioridadesDaBandeira(bandeira))}
          </DragDropContext>
        </Paper>
      )}
    </Section>
  );
};
