import React from 'react';
import { Box, Button, IconButton, Typography, Drawer } from '@mui/material';
import { Close, FilterList } from '@mui/icons-material';
import { Theme, useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';



interface IProps {
  open?: boolean;
  loading?: boolean;
  setCleanFilter: () => void;
  setFilter(value: boolean): void;
  trigger?: boolean;
  countFilter?: number;
  setOpen(value: boolean): void;
}

export const FiltroDeBusca: React.FC<IProps> = ({
  open,
  setOpen,
  children,
  loading,
  setCleanFilter,
  setFilter,
  trigger,
  countFilter,
}) => {
  const theme: Theme = useTheme();

const useStyles = makeStyles({
  filterHeader: {
    position: 'sticky',
    top: 0,
    display: 'flex',
    padding: theme.spacing(0, 2),
    justifyContent: 'space-between',
    boxShadow: '0px 0px 5px 0px #cccccc',
    background: '#FFF',
    zIndex: 1600,
  },
  filterFooter: {
    display: 'flex',
    padding: theme.spacing(2),
    justifyContent: 'flex-end',
    alignItems: 'center',
    boxShadow: '0px 0px 5px 0px #cccccc',
    gap: 20,
  },
  btnClose: {
    alignItems: 'center',
    display: 'flex',
  },
  body: {
    padding: theme.spacing(3),
    marginBottom: 'auto',
  },

  FDrawer: {
    top: 70,
    maxWidth: 400,
    height: '90%',
    marginRight: theme.spacing(1),
    borderRadius: theme.spacing(1),
  },
});
  const { filterHeader, filterFooter, btnClose, body, FDrawer } = useStyles();

  return (
    <>
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
        gap={1}
      >
        <IconButton
          color={countFilter && countFilter > 0 ? 'primary' : 'default'}
          disabled={loading}
          onClick={() => setOpen(!open)}
        >
          <FilterList style={{ margin: 0 }} />
        </IconButton>
        <Typography
          color={countFilter && countFilter > 0 ? 'primary' : 'textSecondary'}
        >
          {countFilter}
        </Typography>
      </Box>

      <Drawer className={FDrawer} anchor="right" open={open}>
        <Box className={filterHeader}>
          <h2 style={{ color: '#018781' }}>Filtros</h2>
          <Box className={btnClose}>
            <IconButton color="primary" onClick={() => setOpen(!open)}>
              <Close />
            </IconButton>
          </Box>
        </Box>

        <Box className={body}>{children}</Box>

        <Box className={filterFooter}>
          <Button
            size="medium"
            disabled={loading}
            variant="outlined"
            onClick={() => {
              setOpen(!open), setCleanFilter();
            }}
            color="primary"
          >
            Limpar
          </Button>
          <Button
            size="medium"
            disabled={loading}
            variant="contained"
            color="primary"
            onClick={() => {
              setOpen(!open), setFilter(!trigger);
            }}
          >
            Filtrar
          </Button>
        </Box>
      </Drawer>
    </>
  );
};
