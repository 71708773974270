import GenericException from 'shared/errors/GenericException';
import { ApiPix } from 'shared/services/axios-config';

export interface IDataChaves {
  id: string;
  chavePixDoRecebedor: string;
  contaBancariaDescricao: string;
  nomeBanco: string;
  nomeEstabelecimento: string;
  cnpjEstabelecimento: string;
}

export interface IPixChaves {
  qtdTotalDeRegistros: number;
  data: IDataChaves[];
}

export interface IEstabelcimento {
  Id: number;
  Nome: string;
  CodigoEstruturado: string;
  CNPJ: string;
}

const getPixChaves = async (): Promise<IPixChaves> => {
  try {
    const { data } = await ApiPix().get<IPixChaves>(`pixchaves`);
    return data;
  } catch (error: any) {
    throw new GenericException(error.response);
  }
};

export const myCredentialsService = {
  getPixChaves,
};
