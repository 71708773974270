import React from 'react';
import { Typography, Box, Grid } from '@mui/material';

interface ISectionProps {
  title?: string;
  tools?: React.ReactNode;
}
export const Section: React.FC<ISectionProps> = ({
  title,
  tools,
  children,
}) => {
  return (
    <>
      <Box marginBottom={4}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5" color="primary">
              <strong>{title}</strong>
            </Typography>
          </Grid>
          {tools && (
            <Grid item xs={12}>
              <Box display="flex" justifyContent="flex-end">
                {tools}
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
      <Box marginBottom={7}>{children}</Box>
    </>
  );
};
