import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import {
  ConfiguracoesService,
  IAmbienteAdquirentes,
  ITipoLicenca,
  IConfiguracoes,
  urlsEnv,
  AmbienteAdquirentes,
  AmbienteBancos,
} from 'shared/services/api/configuracoes/Configuracoes';
import { useTitle } from 'shared/hooks';
import { SelectEnvironment } from './SelectEnvironment';
import { ConfiguracoesSkeleton } from './ConfiguracoesSkeleton';
import { feedback } from 'shared/services';

export const Configuracoes: React.FC = () => {
  const { setTitle } = useTitle();

  useEffect(() => {
    setTitle('Configurações');
  }, [setTitle]);

  const [isLoading, setIsLoading] = useState(true);
  const [id, setId] = useState('');
  const [ambienteCieloId, setAmbienteCieloId] = useState(0);
  const [ambienteRedeId, setAmbienteRedeId] = useState(0);
  const [ambienteGetnetId, setAmbienteGetnetId] = useState(0);
  const [urlCieloConsulta, setUrlCieloConsulta] = useState('');
  const [urlCieloPersistir, setUrlCieloPersistir] = useState('');
  const [urlRede, setUrlRede] = useState('');
  const [urlGetnet, setUrlGetnet] = useState('');
  const [ambienteAdquirentes, setAmbienteAdquirentes] =
    useState<IAmbienteAdquirentes[]>();
  const [tiposLicenca, setTiposLicenca] = useState<ITipoLicenca[]>();
  const [tipoLicencaId, setTipoLicencaId] = useState('0');
  const [clientCentrisId, setClientCentrisId] = useState('');
  const [clientCentrisSecret, setClientCentrisSecret] = useState('');
  const [ambienteBancoDoBrasilId, setAmbienteBancoDoBrasilId] = useState(0);
  // const [ambienteBancos, setAmbienteBancos] = useState<IAmbienteBancos[]>();
  const [urlBancoDoBrasil, setUrlBancoDoBrasil] = useState('');
  const [urlOauthBancoDoBrasil, setUrlOauthBancoDoBrasil] = useState('');

  useEffect(() => {
    if (ambienteCieloId === AmbienteAdquirentes.SandBox) {
      setUrlCieloConsulta(urlsEnv.cielo.sandbox.get);
      setUrlCieloPersistir(urlsEnv.cielo.sandbox.post);
    } else if (ambienteCieloId === AmbienteAdquirentes.Producao) {
      setUrlCieloConsulta(urlsEnv.cielo.production.get);
      setUrlCieloPersistir(urlsEnv.cielo.production.post);
    }
  }, [ambienteCieloId]);

  useEffect(() => {
    if (ambienteRedeId === AmbienteAdquirentes.SandBox) {
      setUrlRede(urlsEnv.rede.sandbox);
    } else if (ambienteRedeId === AmbienteAdquirentes.Producao) {
      setUrlRede(urlsEnv.rede.production);
    }
  }, [ambienteRedeId]);

  useEffect(() => {
    if (ambienteGetnetId === AmbienteAdquirentes.SandBox) {
      setUrlGetnet(urlsEnv.getnet.sandbox);
    } else if (ambienteGetnetId === AmbienteAdquirentes.Producao) {
      setUrlGetnet(urlsEnv.getnet.production);
    }
  }, [ambienteGetnetId]);

  useEffect(() => {
    if (
      ambienteCieloId !== AmbienteAdquirentes.SandBox &&
      ambienteCieloId !== AmbienteAdquirentes.Producao
    ) {
      setUrlCieloConsulta('');
      setUrlCieloPersistir('');
    }
    if (
      ambienteRedeId !== AmbienteAdquirentes.SandBox &&
      ambienteRedeId !== AmbienteAdquirentes.Producao
    ) {
      setUrlRede('');
    }
    if (
      ambienteGetnetId !== AmbienteAdquirentes.SandBox &&
      ambienteGetnetId !== AmbienteAdquirentes.Producao
    ) {
      setUrlGetnet('');
    }
  }, [ambienteCieloId, ambienteGetnetId, ambienteRedeId]);

  useEffect(() => {
    if (ambienteBancoDoBrasilId === AmbienteBancos.SandBox) {
      setUrlBancoDoBrasil(urlsEnv.bancoDoBrasil.sandbox.Url);
      setUrlOauthBancoDoBrasil(urlsEnv.bancoDoBrasil.sandbox.UrlOAuth);
    } else if (ambienteBancoDoBrasilId === AmbienteBancos.Producao) {
      setUrlBancoDoBrasil(urlsEnv.bancoDoBrasil.production.Url);
      setUrlOauthBancoDoBrasil(urlsEnv.bancoDoBrasil.production.UrlOAuth);
    }
  }, [ambienteBancoDoBrasilId]);

  const loadConfigData = (configuracoes: IConfiguracoes) => {
    setId(configuracoes?.Id);
    setAmbienteCieloId(
      configuracoes?.AmbienteCieloId === null
        ? 0
        : configuracoes.AmbienteCieloId,
    );
    setAmbienteRedeId(
      configuracoes?.AmbienteRedeId === null ? 0 : configuracoes.AmbienteRedeId,
    );
    setAmbienteGetnetId(
      configuracoes?.AmbienteGetnetId === null
        ? 0
        : configuracoes.AmbienteGetnetId,
    );

    setUrlCieloConsulta(
      configuracoes?.UrlCieloGet === undefined ? '' : configuracoes.UrlCieloGet,
    );
    setUrlCieloPersistir(
      configuracoes?.UrlCieloPost === undefined
        ? ''
        : configuracoes.UrlCieloPost,
    );
    setUrlRede(
      configuracoes?.UrlRede === undefined ? '' : configuracoes?.UrlRede,
    );
    setUrlGetnet(
      configuracoes?.UrlGetnet === undefined ? '' : configuracoes.UrlGetnet,
    );
    setAmbienteAdquirentes(configuracoes?.AmbienteAdquirentes);
    setTiposLicenca(configuracoes?.TipoLicenca);
    setTipoLicencaId(
      configuracoes?.TipoLicencaId === null
        ? '0'
        : String(configuracoes.TipoLicencaId),
    );
    setClientCentrisSecret(configuracoes?.ClientSecretCentris);
    setClientCentrisId(configuracoes?.ClientIdCentris);

    setAmbienteBancoDoBrasilId(
      configuracoes?.AmbienteBancoDoBrasilId === null
        ? 0
        : configuracoes.AmbienteBancoDoBrasilId,
    );

    setUrlBancoDoBrasil(
      configuracoes?.UrlBancoDoBrasil === undefined
        ? ''
        : configuracoes?.UrlBancoDoBrasil,
    );
    setUrlOauthBancoDoBrasil(
      configuracoes?.UrlOAuthBancoDoBrasil === undefined
        ? ''
        : configuracoes?.UrlOAuthBancoDoBrasil,
    );
  };

  useEffect(() => {
    ConfiguracoesService.getAll().then((data) => {
      if (data) {
        loadConfigData(data);
        setIsLoading(false);
      } else {
        feedback('Não foi possível carregar os dados...', 'error');
        setIsLoading(false);
      }
    });
  }, []);

  const onChangeAmbienteCieloId = useCallback((value: number) => {
    setAmbienteCieloId(value);
  }, []);

  const onChangeAmbienteRedeId = useCallback((value: number) => {
    setAmbienteRedeId(value);
  }, []);

  const onChangeAmbienteGetnetId = useCallback((value: number) => {
    setAmbienteGetnetId(value);
  }, []);

  const onChangeTipoLicencaId = useCallback((value: string) => {
    setTipoLicencaId(value);
  }, []);

  const onChangeClientCentrisId = useCallback((value: string) => {
    setClientCentrisId(value);
  }, []);

  const onChangeClientCentrisSecret = useCallback((value: string) => {
    setClientCentrisSecret(value);
  }, []);

  // const onChangeAmbienteBancoDoBrasilId = useCallback((value: number) => {
  //   setAmbienteBancoDoBrasilId(value);
  // }, []);

  const handleSave = useCallback(() => {
    ConfiguracoesService.save({
      Id: id,
      UrlGetnet: urlGetnet,
      UrlRede: urlRede,
      UrlCieloPost: urlCieloPersistir,
      UrlCieloGet: urlCieloConsulta,
      AmbienteCieloId: ambienteCieloId,
      AmbienteGetnetId: ambienteGetnetId,
      AmbienteRedeId: ambienteRedeId,
      TipoLicencaId: tipoLicencaId,
      ClientIdCentris: clientCentrisId,
      ClientSecretCentris: clientCentrisSecret,
      AmbienteBancoDoBrasilId: ambienteBancoDoBrasilId,
      UrlBancoDoBrasil: urlBancoDoBrasil,
      UrlOAuthBancoDoBrasil: urlOauthBancoDoBrasil,
    }).then((response) => {
      if (response?.mensagens?.length > 0) {
        feedback(
          response.mensagens[0].Mensagem,
          response.sucesso ? 'success' : 'error',
        );
      }
    });
  }, [
    ambienteCieloId,
    ambienteGetnetId,
    ambienteRedeId,
    ambienteBancoDoBrasilId,
    clientCentrisId,
    clientCentrisSecret,
    id,
    tipoLicencaId,
    urlBancoDoBrasil,
    urlCieloConsulta,
    urlCieloPersistir,
    urlGetnet,
    urlOauthBancoDoBrasil,
    urlRede,
  ]);

  const valideData = useCallback(() => {
    if (
      ambienteCieloId === 0 ||
      ambienteRedeId === 0 ||
      ambienteGetnetId === 0 ||
      urlCieloConsulta === '' ||
      urlCieloPersistir === '' ||
      urlRede === '' ||
      urlGetnet === '' ||
      tipoLicencaId === '0' ||
      clientCentrisId === '' ||
      clientCentrisSecret === ''
      // ambienteBancoDoBrasilId === 0 ||
      // urlBancoDoBrasil === '' ||
      // urlOauthBancoDoBrasil === ''
    ) {
      feedback('Preencha todos os campos...', 'error');
    } else {
      handleSave();
      feedback('Salvando dados...', 'loading');
    }
  }, [
    ambienteCieloId,
    ambienteGetnetId,
    ambienteRedeId,
    clientCentrisId,
    clientCentrisSecret,
    handleSave,
    tipoLicencaId,
    urlCieloConsulta,
    urlCieloPersistir,
    urlGetnet,
    urlRede,
  ]);

  const canSave = useMemo(() => {
    return id === null;
  }, [id]);

  return (
    <>
      {!isLoading ? (
        <Grid container spacing={2}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <FormControl required variant="outlined" fullWidth>
                <InputLabel>Tipo de licença</InputLabel>
                <Select
                  label={'Tipo de licença'}
                  name={'Tipo de licença'}
                  value={tipoLicencaId}
                  defaultValue={'Selecione'}
                  onChange={(e) => onChangeTipoLicencaId(e.target.value)}
                  required
                >
                  <MenuItem key={0} value={0}>
                    Selecione
                  </MenuItem>
                  {tiposLicenca?.map((tipo) => {
                    return (
                      <MenuItem
                        key={tipo.TipoLicencaId}
                        value={tipo.TipoLicencaId}
                      >
                        {tipo.TipoLicencaDescricao}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <TextField
                label="Client Centris Id"
                variant="outlined"
                value={clientCentrisId}
                onChange={(e) => onChangeClientCentrisId(e.target.value)}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label="Client Centris Secret"
                variant="outlined"
                value={clientCentrisSecret}
                onChange={(e) => onChangeClientCentrisSecret(e.target.value)}
                fullWidth
                required
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid item xs={3}>
              <SelectEnvironment
                label="Cielo"
                value={ambienteCieloId}
                onChangeAmbienteId={onChangeAmbienteCieloId}
                ambienteList={ambienteAdquirentes?.map((ambiente) => ({
                  id: ambiente.AmbienteId,
                  description: ambiente.AmbienteDescricao,
                }))}
              />
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Box marginTop={2}>
                  <TextField
                    id="url-cielo-consulta"
                    label={urlCieloConsulta ? '' : 'URL Cielo consulta'}
                    variant="outlined"
                    value={urlCieloConsulta}
                    fullWidth
                    disabled
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box marginTop={2}>
                  <TextField
                    id="url-cielo-persistir"
                    label={urlCieloPersistir ? '' : 'URL Cielo persistir'}
                    variant="outlined"
                    value={urlCieloPersistir}
                    fullWidth
                    disabled
                  />
                </Box>
              </Grid>
            </Grid>
            <Box marginTop={5}>
              <Divider variant={'fullWidth'} />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Grid item xs={3}>
              <SelectEnvironment
                label="Rede"
                value={ambienteRedeId}
                onChangeAmbienteId={onChangeAmbienteRedeId}
                ambienteList={ambienteAdquirentes?.map((ambiente) => ({
                  id: ambiente.AmbienteId,
                  description: ambiente.AmbienteDescricao,
                }))}
              />
            </Grid>
            <Grid item xs={6}>
              <Box marginTop={2}>
                <TextField
                  id="url-rede"
                  label={urlRede ? '' : 'URL Rede'}
                  variant="outlined"
                  value={urlRede}
                  fullWidth
                  disabled
                />
              </Box>
            </Grid>
            <Box marginTop={5}>
              <Divider variant={'fullWidth'} />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Grid item xs={3}>
              <SelectEnvironment
                label="Getnet"
                value={ambienteGetnetId}
                onChangeAmbienteId={onChangeAmbienteGetnetId}
                ambienteList={ambienteAdquirentes?.map((ambiente) => ({
                  id: ambiente.AmbienteId,
                  description: ambiente.AmbienteDescricao,
                }))}
              />
            </Grid>
            <Grid item xs={6}>
              <Box marginTop={2}>
                <TextField
                  id="url-getnet"
                  label={urlGetnet ? '' : 'URL Getnet'}
                  variant="outlined"
                  value={urlGetnet}
                  fullWidth
                  disabled
                />
              </Box>
            </Grid>
          </Grid>
          <Box marginTop={5}>
            <Divider variant={'fullWidth'} />
          </Box>
          {/* <Grid item xs={12}>
            <Grid item xs={3}>
              <SelectEnvironment
                label="Banco do Brasil"
                value={ambienteBancoDoBrasilId}
                onChangeAmbienteId={onChangeAmbienteBancoDoBrasilId}
                ambienteList={ambienteBancos?.map((ambiente) => ({
                  id: ambiente.AmbienteId,
                  description: ambiente.AmbienteDescricao,
                }))}
              />
            </Grid> */}
          {/* <Grid container spacing={2}>
              <Grid item xs={6}>
                <Box marginTop={2}>
                  <TextField
                    id="url-banco-do-brasil"
                    label={urlBancoDoBrasil ? '' : 'URL Banco do Brasil'}
                    variant="outlined"
                    value={urlBancoDoBrasil}
                    fullWidth
                    disabled
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box marginTop={2}>
                  <TextField
                    id="url-banco-do-brasil-oauth"
                    label={
                      urlOauthBancoDoBrasil ? '' : 'URL Banco do Brasil Oauth'
                    }
                    variant="outlined"
                    value={urlOauthBancoDoBrasil}
                    fullWidth
                    disabled
                  />
                </Box>
              </Grid>
            </Grid> */}
          {/* <Box marginTop={5}>
              <Divider variant={'fullWidth'} />
            </Box>
          </Grid> */}
          <Grid container item justifyContent="flex-end">
            <Grid item xs={2}>
              <Box marginTop={2}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                  disabled={!canSave}
                  onClick={valideData}
                >
                  Salvar
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <ConfiguracoesSkeleton />
      )}
    </>
  );
};
