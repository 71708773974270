import { TableBody, TableCell, TableRow, Skeleton } from '@mui/material';
import React from 'react';

export const SkeletonPixParameters: React.FC = () => {
  return (
    <TableBody>
      <TableRow>
        <TableCell>
          <Skeleton variant="rounded" />
        </TableCell>
        <TableCell>
          <Skeleton variant="rounded" />
        </TableCell>
        <TableCell>
          <Skeleton variant="rounded" />
        </TableCell>
        <TableCell>
          <Skeleton variant="rounded" />
        </TableCell>
        <TableCell>
          <Skeleton variant="rounded" />
        </TableCell>
        <TableCell>
          <Skeleton variant="circular" width={24} height={24} />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
          <Skeleton variant="rounded" />
        </TableCell>
        <TableCell>
          <Skeleton variant="rounded" />
        </TableCell>
        <TableCell>
          <Skeleton variant="rounded" />
        </TableCell>
        <TableCell>
          <Skeleton variant="rounded" />
        </TableCell>
        <TableCell>
          <Skeleton variant="rounded" />
        </TableCell>
        <TableCell>
          <Skeleton variant="circular" width={24} height={24} />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
          <Skeleton variant="rounded" />
        </TableCell>
        <TableCell>
          <Skeleton variant="rounded" />
        </TableCell>
        <TableCell>
          <Skeleton variant="rounded" />
        </TableCell>
        <TableCell>
          <Skeleton variant="rounded" />
        </TableCell>
        <TableCell>
          <Skeleton variant="rounded" />
        </TableCell>
        <TableCell>
          <Skeleton variant="circular" width={24} height={24} />
        </TableCell>
      </TableRow>
    </TableBody>
  );
};
