import React, { useEffect, useState, useCallback } from 'react';
import { Box, Typography, Checkbox, Tooltip, Icon } from '@mui/material';
import { TreeItem, TreeView } from '@mui/lab';
import { feedback } from 'shared/services';
import { ExpandMore, ChevronRight } from '@mui/icons-material';
import { EstabelecimentoArvoreSkeleton } from './EstabelecimentoArvoreSkeleton';
import { EstabelecimentoService, IEstabelecimento } from 'shared/services/api';

interface IEstabelecimentoArvoreProps {
  setEstabelecimentos: (estabs: string[]) => void;
  selecionados: string[];
}

export const EstabelecimentoArvore: React.FC<IEstabelecimentoArvoreProps> = ({
  setEstabelecimentos,
  selecionados,
}) => {
  const [estabsFromDatabase, setEstabsFromDatabase] = useState<
    IEstabelecimento[]
  >([]);
  const [estabsFromUserChoice, setEstabsFromUserChoice] = useState<string[]>(
    selecionados as string[],
  );
  const [isLoading, setIsLoading] = useState(true);
  const [expanded, setExpanded] = useState<string[]>([]);
  const [selected, setSelected] = useState<string[]>([]);

  const handleToggle = (event: any, nodeIds: string[]) => {
    setExpanded(nodeIds);
  };

  const handleSelect = (event: any, nodeIds: string[]) => {
    setSelected(nodeIds);
  };

  useEffect(() => {
    setEstabsFromUserChoice(selecionados);
  }, [selecionados]);

  useEffect(() => {
    EstabelecimentoService.getEstabelecimentos('').then((result) => {
      if (result.sucesso) {
        setEstabsFromDatabase(result.data);

        const allNodes: string[] = getChildById(
          result.data[0],
          result.data[0].CodigoEstruturado,
        );
        setExpanded(allNodes.map((node) => (node ? node.toString() : '')));

        setIsLoading(false);
      } else {
        feedback(
          result.mensagensCombinadas ||
            'Erro ao carregar os estabelecimentos...',
          'error',
        );
        setEstabsFromDatabase([]);
        setIsLoading(false);
      }
    });
  }, []);

  function getChildById(
    estabelecimentos: IEstabelecimento,
    id: string,
  ): string[] {
    let array: string[] = [];

    function getAllChild(estabelecimentos: IEstabelecimento | null) {
      if (estabelecimentos === null) return [];

      if(estabelecimentos.PossuiLojas) {
        array.push(estabelecimentos.CNPJ);
      }

      if (Array.isArray(estabelecimentos.EstabelecimentosFilhos)) {
        estabelecimentos.EstabelecimentosFilhos.forEach((estabelecimento) => {
          array = [...array, ...getAllChild(estabelecimento)];
          array = array.filter((v, i) => array.indexOf(v) === i);
        });
      }
      return array;
    }

    function getNodeById(estabelecimentos: IEstabelecimento, id: string) {
      if (estabelecimentos.CodigoEstruturado === id) {
        return estabelecimentos;
      } else if (Array.isArray(estabelecimentos.EstabelecimentosFilhos)) {
        let result = null;
        estabelecimentos.EstabelecimentosFilhos.forEach((estabelecimento) => {
          if (!getNodeById(estabelecimento, id)) {
            result = getNodeById(estabelecimento, id);
          }
        });
        return result;
      }

      return null;
    }

    return getAllChild(getNodeById(estabelecimentos, id));
  }

  const getOnChange = useCallback(
    (checked: boolean, estabelecimentos: IEstabelecimento) => {
      const allNode: string[] = getChildById(
        estabelecimentos,
        estabelecimentos.CodigoEstruturado,
      );
      let array = checked
        ? [...estabsFromUserChoice, ...allNode]
        : estabsFromUserChoice.filter((value) => !allNode.includes(value));

      array = array.filter((v, i) => array.indexOf(v) === i);

      setEstabsFromUserChoice(array.filter((cnpj) => cnpj !== null));
      setEstabelecimentos(array.filter((cnpj) => cnpj !== null));
    },
    [setEstabsFromUserChoice, setEstabelecimentos, estabsFromUserChoice],
  );

  const renderizarEstabelecimentos = (estabelecimentos: IEstabelecimento) => (
    <TreeItem
      key={estabelecimentos.CodigoEstruturado}
      nodeId={`${estabelecimentos.CodigoEstruturado}`}
      label={
        <Box>
          <Checkbox
            edge="start"
            checked={estabsFromUserChoice.includes(estabelecimentos.CNPJ)}
            disabled={
              estabelecimentos.CNPJ === null || !estabelecimentos.PossuiLojas
            }
            onChange={(event) => {
              getOnChange(event.currentTarget.checked, estabelecimentos);
            }}
            onClick={(e) => e.stopPropagation()}
          />
          {`${estabelecimentos.CodigoEstruturado} - ${estabelecimentos.Nome} `}
        </Box>
      }
    >
      {Array.isArray(estabelecimentos.EstabelecimentosFilhos)
        ? estabelecimentos.EstabelecimentosFilhos.map((estabelecimento) =>
            renderizarEstabelecimentos(estabelecimento),
          )
        : null}
    </TreeItem>
  );

  return isLoading ? (
    <Box width={700}>
      <EstabelecimentoArvoreSkeleton />
    </Box>
  ) : (
    <Box p={1}>
      <Box width={'100%'}>
        <Box display="flex" alignItems={'center'} gap={1}>
          <Typography variant="body1" color="textPrimary">
            <strong>Estabelecimentos</strong>
          </Typography>
          <Tooltip title="Estabelecimentos sem lojas configuradas serão desabilitados.">
            <Icon color="primary">error_rounded</Icon>
          </Tooltip>
        </Box>
        <TreeView
          expanded={expanded}
          selected={selected}
          onNodeToggle={handleToggle}
          onNodeSelect={handleSelect}
          defaultCollapseIcon={<ExpandMore style={{ marginRight: 10 }} />}
          defaultExpandIcon={<ChevronRight />}
        >
          {estabsFromDatabase !== undefined ? (
            estabsFromDatabase.map((estabelecimento) =>
              renderizarEstabelecimentos(estabelecimento),
            )
          ) : (
            <Box padding={2}>
              <Typography color="secondary">
                Nenhum registro encontrado...
              </Typography>
            </Box>
          )}
        </TreeView>
      </Box>
    </Box>
  );
};
