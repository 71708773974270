import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  OutlinedInput,
  Typography,
} from '@mui/material';
import { IPatch, TransacoesService } from 'shared/services/api';
import { feedback } from 'shared/services';

interface Iprops {
  abrirCapturar: boolean;
  setOpenCapturar(value: boolean): void;
  guid: string | undefined;
  IdTransacao: string | undefined;
}

export const LiquidarParcela: React.FC<Iprops> = ({
  guid,
  abrirCapturar,
  setOpenCapturar,
  IdTransacao,
}) => {
  const [observacao, setObservacao] = useState('');
  const [patch, setPatch] = useState<IPatch>({
    Guid: '',
    Observacao: '',
  });

  const handleObservacao = (value: string) => {
    setObservacao(value);
  };

  const handleCloseCapturar = () => {
    setOpenCapturar(false);
    setObservacao('');
  };

  const enviarPatch = useCallback(async () => {
    feedback('Salvando...', 'loading');
    try {
      const response = await TransacoesService.patchLiquidar(
        String(IdTransacao),
        patch,
      );
      if (response.sucesso) {
        feedback('Salvo com sucesso', 'success');
        handleCloseCapturar();
      } else {
        feedback('Algo saiu errado', 'error');
      }
    } finally {
      ('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patch, IdTransacao, observacao]);

  const emptyField = useCallback(() => {
    if (observacao === '') {
      feedback('Preencha o campo de observação', 'error');
    } else {
      enviarPatch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [observacao, IdTransacao, patch]);

  useEffect(() => {
    observacao &&
      setPatch({
        Guid: String(guid),
        Observacao: observacao,
      });
  }, [guid, observacao]);

  return (
    <>
      <Dialog
        maxWidth="md"
        open={abrirCapturar}
        onClose={handleCloseCapturar}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>Informar Liquidação Manual</DialogTitle>
        <DialogContent>
          <Grid item>
            <Typography>
              <p>
                Ao salvar, você confirma que esta transação já está liquidada no
                GVcollege. Esta ação é irreversível. A data e hora da operação,
                a observação e o seu usuário ficaram armazenados no GVpay e
                podem ser consultadas posteriormente.
              </p>
            </Typography>
          </Grid>

          <Grid item xs={11.5}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel>Observações*</InputLabel>
              <OutlinedInput
                value={observacao}
                label="Observações*"
                type="string"
                onChange={(e) => handleObservacao(e.target.value)}
              />
            </FormControl>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => {
              handleCloseCapturar();
            }}
            color="primary"
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              emptyField();
            }}
            color="primary"
            autoFocus
          >
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
