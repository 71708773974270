import React, { useState, useCallback, useMemo, useRef } from 'react';
import {
  Box,
  FormControl,
  Grid,
  TextField,
  Paper,
  InputAdornment,
  Typography,
  Popper,
  PopperProps,
} from '@mui/material';
import { FiltroDeBusca } from 'shared/components/filtro-de-busca/FiltroDeBusca';
import InputMask from 'react-input-mask';
import {
  ITransacaoFiltro,
  ITransacoesValoresFiltros,
} from 'shared/services/api';
import { AutocompleteComponent } from 'shared/components';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

interface IFiltroPesquisaProps {
  loading?: boolean;
  isOpen?: boolean;
  setIsOpen: (isOpen: boolean) => void;
  setFilters: (filters: ITransacaoFiltro) => void;
  filters: ITransacaoFiltro;
  filterValues: ITransacoesValoresFiltros;
}

export const FiltroPesquisa: React.FC<IFiltroPesquisaProps> = ({
  loading,
  isOpen,
  setIsOpen,
  setFilters,
  filters,
  filterValues,
}) => {
  const documentoOrigemRef = useRef<HTMLInputElement>(null);
  const nomeCartaoRef = useRef<HTMLInputElement>(null);
  const valorRef = useRef<HTMLInputElement>(null);
  const cartaoRef = useRef<HTMLInputElement>(null);
  const estabelecimentoRef = useRef<HTMLInputElement>(null);
  const tidRef = useRef<HTMLInputElement>(null);

  const [adquirente, setAdquirente] = useState<string>(filters.adquirente);
  const [origem, setOrigem] = useState<string>(filters.origem);
  const [situacao, setSituacao] = useState(filters.situacao);
  const [tipoTransacao, setTipoTransacao] = useState(filters.tipoTransacao);

  const [dataInicio, setDataInicio] = useState(
    filters.atualizadoEm ? filters.atualizadoEm.split(',')[0] : '',
  );
  const [dataFim, setDataFim] = useState(
    filters.atualizadoEm ? filters.atualizadoEm.split(',')[1] : '',
  );

  const formatDate = (date: string) => {
    const convertedDate = new Date(date);

    let month = '' + (convertedDate.getMonth() + 1);

    let day = '' + convertedDate.getDate();

    const year = convertedDate.getFullYear();

    if (month.length < 2) month = '0' + month;

    if (day.length < 2) day = '0' + day;

    return [day, month, year].join('/');
  };

  const handleSetFilters = useCallback(
    (triggerSearch: boolean) => {
      if (triggerSearch) {
        setFilters({
          adquirente: adquirente,
          atualizadoEm:
            dataInicio && dataFim
              ? `${formatDate(dataInicio)},${formatDate(dataFim)}`
              : '',
          cartao: cartaoRef.current?.value ? cartaoRef.current.value : '',
          documentoOrigem: documentoOrigemRef.current?.value
            ? documentoOrigemRef.current.value
            : '',
          estabelecimento: estabelecimentoRef.current?.value
            ? estabelecimentoRef.current.value
            : '',
          nomeCartao: nomeCartaoRef.current?.value
            ? nomeCartaoRef.current?.value
            : '',
          tid: tidRef.current?.value ? tidRef.current.value : '',
          origem: origem,
          situacao: situacao,
          tipoTransacao: tipoTransacao,
          valor: valorRef.current?.value ? valorRef.current.value : '',
          somenteCapturadasNaoLiquidadas:
            filters.somenteCapturadasNaoLiquidadas,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      setFilters,
      dataInicio,
      dataFim,
      adquirente,
      cartaoRef,
      documentoOrigemRef,
      estabelecimentoRef,
      nomeCartaoRef,
      origem,
      situacao,
      tipoTransacao,
      valorRef,
    ],
  );

  const handleClearFilters = useCallback(() => {
    setFilters({
      adquirente: '',
      atualizadoEm: '',
      cartao: '',
      documentoOrigem: '',
      estabelecimento: '',
      nomeCartao: '',
      origem: '',
      tid: '',
      situacao: '',
      tipoTransacao: '',
      valor: '',
      somenteCapturadasNaoLiquidadas: filters.somenteCapturadasNaoLiquidadas,
    });
    setOrigem('');
    setAdquirente('');
    setSituacao('');
    setTipoTransacao('');
    setDataInicio('');
    setDataFim('');
  }, [setFilters, filters]);

  const CustomPopper = (props: PopperProps) => {
    return (
      <Popper
        placement={'top'}
        {...props}
        disablePortal
        modifiers={[
          {
            name: 'flip',
            enabled: true,
          },
          {
            name: 'preventOverflow',
            enabled: true,
            options: {
              altAxis: true,
              altBoundary: true,
              tether: true,
              rootBoundary: 'document',
              padding: 8,
            },
          },
        ]}
      />
    );
  };

  const countFilters = useMemo(() => {
    return Object.values(filters).filter((values) => values !== '' && values)
      .length;
  }, [filters]);

  return (
    <>
      <FiltroDeBusca
        open={isOpen}
        countFilter={countFilters}
        loading={loading}
        setFilter={handleSetFilters}
        setOpen={setIsOpen}
        setCleanFilter={handleClearFilters}
      >
        <Box width={'400px'}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box>
                <FormControl variant="outlined" fullWidth>
                  <Paper>
                    <TextField
                      style={{ padding: 0, margin: 0 }}
                      fullWidth
                      variant="outlined"
                      placeholder="Documento de origem"
                      defaultValue={filters.documentoOrigem}
                      inputRef={documentoOrigemRef}
                      InputProps={{
                        style: {
                          height: 40,
                        },
                      }}
                    />
                  </Paper>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box>
                <FormControl variant="outlined" fullWidth>
                  <Paper>
                    <TextField
                      style={{ padding: 0, margin: 0 }}
                      fullWidth
                      variant="outlined"
                      placeholder="Tid"
                      defaultValue={filters.tid}
                      inputRef={tidRef}
                      InputProps={{
                        style: {
                          height: 40,
                        },
                      }}
                    />
                  </Paper>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box>
                <Paper>
                  <FormControl variant="outlined" size="small" fullWidth>
                    <AutocompleteComponent
                      PopperComponent={CustomPopper}
                      multiple
                      options={filterValues.Origens?.filter(
                        (origem) => origem.Nome !== null,
                      )}
                      value={
                        origem
                          ? filterValues.Origens?.filter((origemFiltro) =>
                              origem
                                .split(',')
                                .includes(String(origemFiltro.Id)),
                            )
                          : []
                      }
                      getOptionLabel={(option: any) => option.Nome || ''}
                      filterSelectedOptions
                      onChange={(evt, value) => {
                        setOrigem(value.map((val: any) => val.Id).join(','));
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Origem"
                        />
                      )}
                    />
                  </FormControl>
                </Paper>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box>
                <Paper>
                  <FormControl variant="outlined" size="small" fullWidth>
                    <AutocompleteComponent
                      PopperComponent={CustomPopper}
                      multiple
                      options={filterValues.Adquirentes?.filter(
                        (adq) => adq.Nome !== null,
                      )}
                      value={
                        adquirente
                          ? filterValues.Adquirentes?.filter((adqFiltro) =>
                              adquirente
                                .split(',')
                                .includes(String(adqFiltro.Id)),
                            )
                          : []
                      }
                      getOptionLabel={(option: any) => option.Nome || ''}
                      filterSelectedOptions
                      onChange={(evt, value) => {
                        setAdquirente(
                          value.map((val: any) => val.Id).join(','),
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Adquirentes"
                        />
                      )}
                    />
                  </FormControl>
                </Paper>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box>
                <Paper>
                  <TextField
                    style={{ padding: 0, margin: 0 }}
                    fullWidth
                    variant="outlined"
                    placeholder={'Nome no cartão'}
                    defaultValue={filters.nomeCartao}
                    inputRef={nomeCartaoRef}
                    InputProps={{
                      style: {
                        height: 40,
                      },
                    }}
                  />
                </Paper>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'body2'} color={'textSecondary'}>
                Atualizado em:
              </Typography>
              <Box display="flex">
                <Box>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      inputFormat="dd/MM/yyyy"
                      mask="__/__/____"
                      value={dataInicio ? dataInicio : null}
                      onChange={(newDate: any) => {
                        setDataInicio(newDate);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <CalendarTodayIcon color="disabled" />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </LocalizationProvider>
                </Box>
                <Box alignSelf="center" marginLeft={1} marginRight={1}>
                  <Typography>até</Typography>
                </Box>
                <Box>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <>
                      <DatePicker
                        renderInput={(params) => <TextField {...params} />}
                        mask="__/__/____"
                        inputFormat="dd/MM/yyyy"
                        value={dataFim ? dataFim : null}
                        onChange={(newDate: any) => {
                          setDataFim(newDate);
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <CalendarTodayIcon color="disabled" />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </>
                  </LocalizationProvider>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box>
                <Paper>
                  <FormControl variant="outlined" size="small" fullWidth>
                    <AutocompleteComponent
                      PopperComponent={CustomPopper}
                      multiple
                      options={filterValues.Situacoes?.filter(
                        (situacao) => situacao.Nome !== null,
                      )}
                      getOptionLabel={(option: any) => option.Nome || ''}
                      filterSelectedOptions
                      value={
                        situacao
                          ? filterValues.Situacoes?.filter((sitFiltros) =>
                              situacao
                                .split(',')
                                .includes(String(sitFiltros.Id)),
                            )
                          : []
                      }
                      onChange={(evt, value) => {
                        setSituacao(value.map((val: any) => val.Id).join(','));
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Situações"
                        />
                      )}
                    />
                  </FormControl>
                </Paper>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box>
                <Paper>
                  <TextField
                    style={{ padding: 0, margin: 0 }}
                    fullWidth
                    variant="outlined"
                    placeholder={'Valor na cobrança'}
                    defaultValue={filters.valor}
                    inputRef={valorRef}
                    InputProps={{
                      style: {
                        height: 40,
                      },
                    }}
                  />
                </Paper>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box>
                <Paper>
                  <TextField
                    style={{ padding: 0, margin: 0 }}
                    fullWidth
                    variant="outlined"
                    placeholder={'Número do cartão'}
                    defaultValue={filters.cartao}
                    inputRef={cartaoRef}
                    InputProps={{
                      style: {
                        height: 40,
                      },
                    }}
                  />
                </Paper>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box>
                <Paper>
                  <InputMask
                    placeholder={'CNPJ de um estabelecimento(apenas números).'}
                    defaultValue={filters.estabelecimento}
                    inputRef={estabelecimentoRef}
                    mask={'99.999.999/9999-99'}
                    maskPlaceholder={null}
                  >
                    <TextField
                      style={{ padding: 0, margin: 0 }}
                      fullWidth
                      variant="outlined"
                      InputProps={{
                        style: {
                          height: 40,
                        },
                      }}
                    />
                  </InputMask>
                </Paper>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box>
                <Paper>
                  <FormControl variant="outlined" size="small" fullWidth>
                    <AutocompleteComponent
                      PopperComponent={CustomPopper}
                      multiple
                      options={filterValues.Tipos?.filter(
                        (tipo) => tipo.Nome !== null,
                      )}
                      getOptionLabel={(option: any) => option.Nome || ''}
                      filterSelectedOptions
                      value={
                        tipoTransacao
                          ? filterValues.Tipos?.filter((tipoFiltro) =>
                              tipoTransacao
                                .split(',')
                                .includes(String(tipoFiltro.Id)),
                            )
                          : []
                      }
                      onChange={(evt, value) => {
                        setTipoTransacao(
                          value.map((val: any) => val.Id).join(','),
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Tipo de transação"
                        />
                      )}
                    />
                  </FormControl>
                </Paper>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </FiltroDeBusca>
    </>
  );
};
