import React from 'react';

import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Skeleton,
} from '@mui/material';

export const TotalSkeleton: React.FC = () => {
  return (
    <Card>
      <CardHeader
        title={<Skeleton variant="rectangular" width={80} height={30} />}
      ></CardHeader>
      <CardContent>
        <Box>
          <Box marginTop={5}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Skeleton variant="rectangular" width={150} height={80} />
              </Grid>
              <Grid item xs={6}>
                <Skeleton variant="rectangular" width={150} height={80} />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Skeleton variant="rectangular" width={150} height={80} />
              </Grid>
              <Grid item xs={6}>
                <Skeleton variant="rectangular" width={150} height={80} />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Skeleton variant="rectangular" width={150} height={80} />
              </Grid>
              <Grid item xs={6}>
                <Skeleton variant="rectangular" width={150} height={80} />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};
