import React from 'react';
import { Chip } from '@mui/material';

interface IProps {
  situacao: string;
}

export const SituacaoPagamento: React.FC<IProps> = ({ situacao }) => {
  return (
    <Chip
      size="small"
      sx={{
        backgroundColor:
          situacao === 'Pago'
            ? '#00e676'
            : situacao === 'Vencido'
            ? '#f44336'
            : '',
        color: situacao === 'Pago' || situacao === 'Vencido' ? '#fff' : '',
      }}
      label={situacao}
    />
  );
};
