import React from 'react';

import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Box,
  Skeleton,
} from '@mui/material';
import { TableSkeleton } from 'shared/components';

export const UltimasTransacoesSkeleton: React.FC = () => {
  return (
    <Card>
      <CardHeader
        title={<Skeleton variant="rectangular" width={150} height={30} />}
      ></CardHeader>
      <CardContent>
        <TableSkeleton rectWidth="50%" rowsBody={6} columns={6} />
      </CardContent>
      <CardActions>
        <Box paddingLeft={83} marginBottom={2}>
          <Skeleton variant="rectangular" width={90} height={30} />
        </Box>
      </CardActions>
    </Card>
  );
};
