import React, { createContext, useEffect, useState } from 'react';
import { feedback } from 'shared/services';
import { UserService } from 'shared/services/api/index';
import {
  IMenuOptions,
  MenuOptionService,
} from 'shared/services/api/menu-options/MenuOptionService';

interface IUserContext {
  isLogged: boolean;
  menuOptions: IMenuOptions;
}

export const UserContext = createContext<IUserContext>({} as IUserContext);

export const UserProvider: React.FC = ({ children }) => {
  const [isLogged, setIsLogged] = useState(false);
  const [menuOptions, setMenuOptions] = useState<IMenuOptions>({} as IMenuOptions);

  useEffect(() => {
    UserService.getUser().then((data) => {
      if (data) {
        setTimeout(() => {
          setIsLogged(true);
        }, 1000);
      } else {
        feedback('Erro ao carregar dados', 'error');
      }
    });

    MenuOptionService.getMenuOptions().then((response) => {
      if (response) {
        setMenuOptions(response);
      }
    });
  }, []);

  return (
    <UserContext.Provider value={{ isLogged: isLogged, menuOptions }}>
      {children}
    </UserContext.Provider>
  );
};
