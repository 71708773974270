/* eslint-disable no-plusplus */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Paper,
  Skeleton,
} from '@mui/material';

interface TableSkeletonProps {
  columns?: number;
  rowsBody?: number;
  rowHeader?: number;
  rectHeight?: number;
  rectWidth?: number | string;
}
export const TableSkeleton: React.FC<TableSkeletonProps> = ({
  columns = 1,
  rowHeader = 1,
  rowsBody = 1,
  rectHeight = 20,
  rectWidth = '100%',
}) => {
  const _rowsBody: number[] = [];
  const _columns: number[] = [];
  const _rows: number[] = [];

  for (let index = 0; index < rowsBody; index++) {
    _rowsBody.push(index);
  }

  for (let index = 0; index < columns; index++) {
    _columns.push(index);
  }

  for (let index = 0; index < rowHeader; index++) {
    _rows.push(index);
  }

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          {_rows.map((_, index) => (
            <TableRow key={index}>
              {_columns.map((_, cellIndex) => (
                <TableCell key={cellIndex}>
                  <Skeleton
                    variant="text"
                    width={rectWidth}
                    height={rectHeight}
                  />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody>
          {_rowsBody.map((_, index) => (
            <TableRow key={index}>
              {_columns.map((_, cellIndex) => (
                <TableCell key={cellIndex}>
                  <Skeleton
                    variant="text"
                    width={rectWidth}
                    height={rectHeight}
                  />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
