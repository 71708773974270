import React, { useCallback, useEffect, useState, useMemo } from 'react';
import {
  Grid,
  TextField,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  IconButton,
  Box,
  Paper,
  InputAdornment,
} from '@mui/material';
import { Delete, Search } from '@mui/icons-material';
import { confirm, feedback } from 'shared/services';
import { useTitle } from 'shared/hooks';
import { NovoPerfil } from './NovoPerfil';
import { EditarPerfis } from './EditarPerfis';
import { ListaSkeleton } from './ListaSkeleton';
import {
  IListaPerfis,
  PerfisService,
} from 'shared/services/api/perfil-de-usuario/PerfilDeUsuario';

export const ListaDePerfil: React.FC = () => {
  const { setTitle } = useTitle();
  const [filtro, setFiltro] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [perfis, setPerfis] = useState<IListaPerfis[]>([]);
  const [isLoadRequestPost, setIsLoadRequestPost] = useState(false);
  const [isLoadRequestPut, setIsLoadRequestPut] = useState(false);

  useEffect(() => {
    setTitle('Perfil de Usuário');
  }, [setTitle]);

  useEffect(() => {
    async function getListaPerfis() {
      setIsLoading(true);
      try {
        const response = await PerfisService.getListaPerfis();
        setPerfis(response);
      } catch (error: any) {
        feedback(error.Mensagem, 'error');
      } finally {
        setIsLoading(false);
      }
    }
    getListaPerfis();
  }, [
    isLoadRequestPost,
    setIsLoadRequestPost,
    isLoadRequestPut,
    setIsLoadRequestPut,
  ]);

  const handleDelete = useCallback((id: string) => {
    const handleConfirmDelete = () => {
      PerfisService.deleteById(id).then((result) => {
        if (result) {
          setPerfis((oldRows) =>
            oldRows?.filter((row) => row.PerfilUsuarioId !== id),
          );
          feedback('Perfil excluído com sucesso.', 'success');
        } else {
          feedback('Erro ao excluir perfil.', 'error');
        }
      });
    };

    confirm(
      'Tem certeza que deseja excluir este registro?',
      'delete',
      handleConfirmDelete,
    );
  }, []);

  const normalizeText = useCallback((value: string) => {
    const resultFilter = value
      .toLocaleLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');

    return resultFilter;
  }, []);

  const perfisLista = useMemo(() => {
    const result = perfis.filter(
      (perfil) =>
        perfil &&
        normalizeText(perfil.Descricao).includes(normalizeText(filtro)),
    );

    return result;
  }, [filtro, normalizeText, perfis]);

  return (
    <Grid container spacing={1}>
      <Grid container spacing={3} alignItems={'center'}>
        <Grid item xs={6}>
          <Paper>
            <TextField
              style={{ padding: 0, margin: 0 }}
              fullWidth
              variant="outlined"
              onChange={(e) => setFiltro(e.target.value)}
              value={filtro}
              placeholder="Pesquisar na tabela"
              InputProps={{
                style: { height: 40 },
                startAdornment: (
                  <InputAdornment position="start">
                    <Search color="disabled" />
                  </InputAdornment>
                ),
              }}
            />
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <NovoPerfil
            request={isLoadRequestPost}
            loadRequest={setIsLoadRequestPost}
          />
        </Grid>
      </Grid>
      <Box width={'100%'} marginTop={5}>
        <Grid container>
          <Grid item xs={12}>
            {isLoading ? (
              <ListaSkeleton />
            ) : (
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell width="10%">Ações</TableCell>
                      <TableCell>Perfil de usuário</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {perfisLista?.map((user, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <IconButton
                            onClick={() => handleDelete(user.PerfilUsuarioId)}
                          >
                            <Delete color="primary" />
                          </IconButton>
                          <EditarPerfis
                            request={isLoadRequestPut}
                            loadRequest={setIsLoadRequestPut}
                            id={user.PerfilUsuarioId}
                          />
                        </TableCell>
                        <TableCell>{user.Descricao}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};
