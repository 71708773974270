import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  OutlinedInput,
  TextField,
  Tooltip,
} from '@mui/material';
import { Edit } from '@mui/icons-material';
import { AutocompleteComponent } from 'shared/components';
import ErrorIcon from '@mui/icons-material/Error';

import {
  IGetPerfilbyId,
  IRootGetPerfilbyId,
  IRootPutFuncionalidades,
  PerfisService,
} from 'shared/services/api/perfil-de-usuario/PerfilDeUsuario';
import { feedback } from 'shared/services';

interface IProps {
  id: string;
  request: boolean;
  loadRequest: React.Dispatch<React.SetStateAction<boolean>>;
}

export const EditarPerfis: React.FC<IProps> = ({
  id,
  request,
  loadRequest,
}) => {
  const [guid, setGuid] = useState<string>('');
  const [nomePerfil, setNomePerfil] = useState<string>('');
  const [openEditar, setOpenEditar] = React.useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [perfisSel, setPerfisSel] = useState<IGetPerfilbyId[]>();
  const [administrativoSel, setAdministrativoSel] = useState<
    boolean | undefined
  >();
  const [prepararPut, setPrepararPut] = useState<
    IRootPutFuncionalidades | any
  >();

  const [funcionalidadesSel, setFuncionalidadesSel] =
    useState<IRootGetPerfilbyId>({} as IRootGetPerfilbyId);

  const handleCloseEditar = () => {
    setOpenEditar(false);
  };

  const getPefilbyId = useCallback(async () => {
    async function getPefilbyId() {
      setIsLoading(true);
      try {
        const response = await PerfisService.getPerfisById(id);
        setFuncionalidadesSel(response);
        setNomePerfil(response.Descricao);
        setAdministrativoSel(response.Administrativo);
        setPerfisSel(
          response.Funcionalidades.filter((item) => item.Selecionado),
        );
      } catch (error: any) {
        feedback(error.Mensagem, 'error');
      } finally {
        setIsLoading(false);
      }
    }
    getPefilbyId();
  }, [id]);

  const handleClickOpenEditar = useCallback(() => {
    setOpenEditar(true);
    getPefilbyId();
  }, [getPefilbyId]);

  const handleNomePefil = useCallback((e: any) => {
    setNomePerfil(e.target.value);
  }, []);

  const enviarPut = useCallback(async () => {
    const response = await PerfisService.putUsuario(prepararPut);

    if (response.sucesso) {
      feedback('Perfil atualizado com sucesso!', 'success');
      loadRequest(!request);
    } else {
      feedback(
        response.mensagensCombinadas || 'Erro ao editar perfil',
        'error',
      );
      loadRequest(!request);
    }
  }, [loadRequest, prepararPut, request]);

  const [administrativo, setAdministrativo] = React.useState({
    checkedA: administrativoSel,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAdministrativo({
      ...administrativo,
      [event.target.name]: event.target.checked,
    });
  };

  useEffect(() => {
    funcionalidadesSel &&
      setPrepararPut({
        PerfilUsuarioId: guid,
        Descricao: nomePerfil,
        Administrativo: administrativo.checkedA,
        Funcionalidades: perfisSel,
      });
  }, [guid, nomePerfil, perfisSel, administrativo, funcionalidadesSel]);

  const verificarNomePerfilValido = (pNomePerfil: string) => {
    // eslint-disable-next-line
    const caracteresEspeciais = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    let erro = false;

    if (!pNomePerfil.trim()) {
      erro = true;
    }

    if (pNomePerfil.trim().length === 0) {
      erro = true;
    }

    if (caracteresEspeciais.test(pNomePerfil)) {
      erro = true;
    }

    return erro;
  };

  return (
    <>
      {isLoading ? (
        <CircularProgress
          style={{ position: 'relative', left: 6, top: 7 }}
          size={18}
        />
      ) : (
        <IconButton
          style={{ position: 'relative', left: 6 }}
          onClick={() => {
            handleClickOpenEditar(), setGuid(id);
          }}
        >
          <Edit color="primary" />
        </IconButton>
      )}

      <Dialog
        maxWidth="md"
        open={openEditar}
        onClose={handleCloseEditar}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="Criar novo perfil de usuário">
          Dados do {nomePerfil}
        </DialogTitle>

        <DialogContent>
          <Grid direction="column" container style={{ width: 700 }}>
            <Grid container item style={{ marginBottom: 20 }}>
              <Grid item xs>
                <FormControlLabel
                  style={{ marginRight: 1 }}
                  control={
                    <Checkbox
                      checked={administrativo.checkedA}
                      onChange={handleChange}
                      color="primary"
                      name="checkedA"
                    />
                  }
                  label="Administrativo"
                />
                <Tooltip
                  placement="right-end"
                  title="Deve haver apenas um perfil administrativo"
                >
                  <IconButton>
                    <ErrorIcon color="primary" />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>

            <Grid container item style={{ marginBottom: 20 }}>
              <Grid item xs>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel>Perfil de usuário*</InputLabel>
                  <OutlinedInput
                    value={nomePerfil}
                    label="Perfil de usuário*"
                    type="string"
                    onChange={handleNomePefil}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

          <Grid container item style={{ marginBottom: 10, display: 'block' }}>
            <Grid>
              <FormControl component="fieldset" fullWidth>
                {isLoading ? (
                  <CircularProgress
                    style={{ position: 'relative', left: 6, top: 7 }}
                    size={18}
                  />
                ) : (
                  <AutocompleteComponent
                    multiple
                    options={funcionalidadesSel.Funcionalidades}
                    getOptionLabel={(option: IGetPerfilbyId) =>
                      option.FuncionalidadeDescricao
                    }
                    defaultValue={perfisSel}
                    filterSelectedOptions
                    onChange={(evt, value) => {
                      funcionalidadesSel.Funcionalidades.map(
                        (item) => (item.Selecionado = true),
                      ),
                        setPerfisSel(value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Funcionalidades"
                      />
                    )}
                  />
                )}
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions style={{ marginRight: 16, marginBottom: 18 }}>
          <Button
            variant="outlined"
            onClick={handleCloseEditar}
            color="primary"
          >
            Cancelar
          </Button>
          <Button
            onClick={() => {
              if (verificarNomePerfilValido(nomePerfil)) {
                feedback('Campo de perfil de usuário inválido.', 'error');
              } else {
                enviarPut(), handleCloseEditar();
              }
            }}
            variant="contained"
            color="primary"
            autoFocus
          >
            Adicionar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
