import { Api } from 'shared/services/axios-config/AxiosConfig';
import {
  IErrorMessage,
  IResultOfAction,
  responseError,
} from 'shared/services/response-error/ResponseError';
import { isUuid } from 'uuidv4';

export interface IBandeiraLoja {
  IdBandeira: number;
  BandeiraNome: string;
}

export interface ILoja {
  editavel?: boolean;
  LojaChaveTemp: string;
  IdLoja: number | null;
  LojaNome: string;
  IdAdquirente: number;
  AdquirenteNome: string;
  LojaAtivo: boolean;
  AntifraudeHabilitado: boolean;
  LojaCielo: {
    MerchantId: string;
    MerchantKey: string;
  };
  LojaGetnet: {
    SellerId: string;
    ClientId: string;
    ClientSecret: string;
  };
  LojaRede: {
    PV: string;
    Token: string;
  };
  LojaPagarme: {
    PublicKey: string;
    SecretKey: string;
    AccountId: string;
  };
  BandeirasLoja: IBandeiraLoja[];
  PossuiTransacoes: boolean;
}

export interface ILojaPrioridades {
  IdLoja: number | null;
  LojaNome: string;
  AdquirenteNome: string;
  Prioridade: number;
  LojaChaveTemp: string;
}

export interface IPrioridades {
  IdBandeira: number;
  BandeiraNome: string;
  Prioridades: ILojaPrioridades[];
}

export interface IEstabelecimentoConfig {
  IdConfigEstabelecimento: number;
  NomeEstabelecimento: string | null;
  CNPJEstabelecimento: string;
  EmailRemetente: string;
  EmailDestinatario: string;
  Lojas: ILoja[];
  Prioridades: IPrioridades[];
}

const getEstabelecimentoConfigByCNPJEstabelecimento = async (
  CNPJEstabelecimento: string,
  codigoEstruturadoEstabelecimento: string,
): Promise<IResultOfAction<any>> => {
  try {
    const { data } = await Api().get(
      `/estabelecimentos/${CNPJEstabelecimento}/configuracao?codigoEstruturadoEstabelecimento=${
        codigoEstruturadoEstabelecimento || ''
      }`,
    );

    if (data) {
      return { data: data, sucesso: true } as IResultOfAction<any>;
    }

    const errorResponse: IResultOfAction<any> = {
      mensagens: [] as IErrorMessage[],
      mensagensCombinadas: data.ResultadoDaAcao.MensagensCombinadas,
      severidade: data.ResultadoDaAcao.Severidade,
      severidadeId: data.ResultadoDaAcao.SeveridadeId,
      sucesso: data.ResultadoDaAcao.Sucesso,
    };

    return errorResponse;
  } catch (error: any) {
    const result = responseError(error);
    return result;
  }
};

const createEstabelecimentoConfig = async (
  estabelecimentoConfig: IEstabelecimentoConfig,
): Promise<IResultOfAction<any>> => {
  try {
    const { data } = await Api().post(
      '/estabelecimentos',
      estabelecimentoConfig,
    );

    if (isUuid(data)) {
      return { sucesso: true } as IResultOfAction<any>;
    }

    const errorResponse: IResultOfAction<any> = {
      mensagens: [] as IErrorMessage[],
      mensagensCombinadas: data.ResultadoDaAcao.MensagensCombinadas,
      severidade: data.ResultadoDaAcao.Severidade,
      severidadeId: data.ResultadoDaAcao.SeveridadeId,
      sucesso: data.ResultadoDaAcao.Sucesso,
    };

    return errorResponse;
  } catch (error: any) {
    return error as IResultOfAction<any>;
  }
};

const updateEstabelecimentoConfig = async (
  estabelecimentoConfig: IEstabelecimentoConfig,
): Promise<IResultOfAction<any>> => {
  try {
    const { data } = await Api().put(
      `/estabelecimentos`,
      estabelecimentoConfig,
    );
    if (data.Sucesso) {
      return { sucesso: true } as IResultOfAction<any>;
    }

    const errorResponse: IResultOfAction<any> = {
      mensagens: [] as IErrorMessage[],
      mensagensCombinadas: data.ResultadoDaAcao.MensagensCombinadas,
      severidade: data.ResultadoDaAcao.Severidade,
      severidadeId: data.ResultadoDaAcao.SeveridadeId,
      sucesso: data.ResultadoDaAcao.Sucesso,
    };

    return errorResponse;
  } catch (error: any) {
    return error as IResultOfAction<any>;
  }
};

export const EstabelecimentoConfigService = {
  getEstabelecimentoConfigByCNPJEstabelecimento,
  createEstabelecimentoConfig,
  updateEstabelecimentoConfig,
};
