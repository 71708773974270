import React, { useEffect, useState, useCallback } from 'react';
import {
  Box,
  Grid,
  Paper,
  Table,
  TableRow,
  TableBody,
  TextField,
  TableCell,
  TableHead,
  IconButton,
  Typography,
  TableContainer,
  FormControl,
} from '@mui/material';
import { Edit } from '@mui/icons-material/';

import { useTitle } from 'shared/hooks';
import { feedback } from 'shared/services';
import { AutocompleteComponent, Modal } from 'shared/components';

import {
  IAdquirente,
  AdquirenteService,
} from 'shared/services/api/adquirentes/AdquirentesService';
import {
  IBandeiras,
  AdquirenteConfigService,
} from 'shared/services/api/adquirentes/AdquirenteConfiguracaoService';
import { AdquirinteSkeleton } from './AdquirintesSkeleton';

export const Adquirentes: React.FC = () => {
  const { setTitle } = useTitle();
  const [isLoading, setIsLoading] = useState(true);
  const [modalAberto, setModalAberto] = useState(false);
  const [modalTitle, setModalTitle] = useState('Adquirente:');
  const [bandeiras, setBandeiras] = useState<IBandeiras[]>([]);
  const [bandeirasSel, setBandeirasSel] = useState<IBandeiras[]>([]);
  const [adquirenteData, setAdquirenteData] = useState<IAdquirente[]>([]);
  const [adquirenteSel, setAdquirenteSel] = useState<IAdquirente | undefined>();

  useEffect(() => {
    setTitle('Adquirentes');
  }, [setTitle]);

  useEffect(() => {
    (async () => {
      const data = await AdquirenteService.getAdquirentes();

      data ? setAdquirenteData(data) : setAdquirenteData([]);
      setIsLoading(false);
    })();
  }, []);

  const handleAdquirente = useCallback(async (adquirente: IAdquirente) => {
    const data = await AdquirenteConfigService.getBandeirasAdquirente(
      adquirente.IdAdquirente,
    );
    if (data) {
      setBandeiras(data);
      setBandeirasSel(data.filter((item) => item.Selecionado));
      setAdquirenteSel(adquirente);
      setModalTitle(`Adquirente: ${adquirente.NomeAdquirente}`);
      setModalAberto(true);
    } else {
      setBandeiras([]);
    }
    setIsLoading(false);
  }, []);

  const handleSave = useCallback(async () => {
    const idsBandeiras: number[] = [];
    bandeirasSel?.map((item) => idsBandeiras.push(item.IdBandeira));

    if (adquirenteSel) {
      const response = await AdquirenteConfigService.postBandeirasAdquirente(
        adquirenteSel.IdAdquirente,
        idsBandeiras,
      );

      if (response.sucesso) {
        feedback(
          `O adquirente ${adquirenteSel.NomeAdquirente} foi salvo.`,
          'success',
        );
        setModalAberto(false);
      } else {
        feedback(
          response.mensagensCombinadas ||
            'Erro ao salvar. Verifique se as bandeiras removidas não estão em uso.',
          'error',
        );
      }
    }
  }, [adquirenteSel, bandeirasSel]);

  return (
    <>
      {isLoading ? (
        <AdquirinteSkeleton />
      ) : adquirenteData.length ? (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center" width="5%">
                  Ações
                </TableCell>
                <TableCell>Nome</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {adquirenteData.map((adquirente) => (
                <TableRow hover key={adquirente.IdAdquirente}>
                  <TableCell align="center" width="5%">
                    <IconButton
                      color="primary"
                      onClick={() => {
                        handleAdquirente(adquirente);
                      }}
                    >
                      <Edit />
                    </IconButton>
                  </TableCell>
                  <TableCell>{adquirente.NomeAdquirente}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box padding={2}>
          <Typography color="secondary">
            Nenhum registro encontrado...
          </Typography>
        </Box>
      )}

      <Modal
        title={modalTitle}
        size="md"
        disableBackdrop={false}
        btnText="Salvar"
        isOpen={modalAberto}
        closeModal={() => setModalAberto(false)}
        handleConfirm={handleSave}
      >
        <Box mt={2}>
          <FormControl component="fieldset" fullWidth>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <AutocompleteComponent
                  multiple
                  options={bandeiras}
                  getOptionLabel={(option) => option.NomeBandeira}
                  defaultValue={bandeiras.filter((item) => item.Selecionado)}
                  filterSelectedOptions
                  onChange={(evt, value) => setBandeirasSel(value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Bandeiras"
                    />
                  )}
                />
              </Grid>
            </Grid>
          </FormControl>
        </Box>
      </Modal>
    </>
  );
};
