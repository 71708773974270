import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Skeleton,
  Typography,
} from '@mui/material';
import React, { useCallback, useState, useEffect } from 'react';

import VisibilityIcon from '@mui/icons-material/Visibility';
import PersonIcon from '@mui/icons-material/Person';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import JSONPretty from 'react-json-pretty';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from '@mui/lab';
import { SkeletonConsulta } from './SkeletonConsulta';
import {
  BoletoService,
  IData,
  IHistoricoBoleto,
} from 'shared/services/api/cobrancas-boletos/cobrancasBoletos';
import { feedback } from 'shared/services';
import { makeStyles } from '@mui/styles';

interface IProps {
  id: string;
  item: IData;
}

const useStyles = makeStyles(() => {
  return {
    TimeLineContent: {
      '&::before': {
        flex: `${0} !important`,
      },
    },
  };
});

export const ModalConsultaBoletos: React.FC<IProps> = ({ id, item }) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [openConsulta, setOpenConsulta] = useState(false);
  const [historico, setHistorico] = useState<IHistoricoBoleto[]>([]);
  const [expandedLogs, setExpandedLogs] = useState<boolean[]>([]);
  const NOT_PAID = 'NAO_PAGO';
  const NOT_PAID_FORMATTED = 'Não Pago';

  const handleCloseConsulta = () => {
    setOpenConsulta(false);
  };

  const handleOpenConsulta = () => {
    setOpenConsulta(true);
  };

  const handlePriceFormatter = (price: string) => {
    if (!price.includes('.')) {
      return price.concat(',00');
    } else {
      const arrayValue = price.split('.');
      const cents =
        parseInt(arrayValue[1]) < 10 ? arrayValue[1] + '0' : arrayValue[1];
      return `${arrayValue[0]},${cents}`;
    }
  };

  const handleNotPaidSituationFormatter = (situation: string) => {
    return situation === NOT_PAID ? NOT_PAID_FORMATTED : situation;
  };

  const formatDate = (date: Date) => {
    const convertedDate = new Date(date);
    let month = '' + (convertedDate.getMonth() + 1);
    let day = '' + convertedDate.getDate();
    const year = convertedDate.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [day, month, year].join('/');
  };

  const getBoletosbyId = useCallback(async () => {
    try {
      const response = await BoletoService.getHistorico(id);

      if (response === undefined) {
        setHistorico([]);
      } else {
        setHistorico(response);
      }

      setHistorico(response);
    } catch (error) {
      feedback('Erro ao carregar histórico', 'error');
    } finally {
      setIsLoading(false);
    }
  }, [id]);

  const handleAccordionChange = (expanded: boolean) => {
    if (expanded) {
      setExpandedLogs((prev) => [...prev, expanded]);
    } else {
      expandedLogs.length === 1
        ? setExpandedLogs([])
        : setExpandedLogs((prev) => prev.filter((_, id) => id === 0));
    }
  };

  const syntaxHighlight = (stringToFormat: string) => {
    if (stringToFormat !== null) {
      const stringfiedJson = JSON?.stringify(stringToFormat, undefined, 2);
      let parsedString = JSON?.parse(stringfiedJson);
      parsedString = parsedString
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/\\u[\dA-F]{4}/gi, function (match: any) {
          return String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16));
        });

      return parsedString.replace(
        //eslint-disable-next-line
        /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g,
        (match: string) => {
          return `${match}`;
        },
      );
    }
  };

  useEffect(() => {
    setExpandedLogs([]);
  }, [openConsulta]);

  return (
    <>
      <IconButton
        onClick={() => {
          handleOpenConsulta(), getBoletosbyId();
        }}
      >
        <Box>
          <VisibilityIcon color="primary" />
        </Box>
      </IconButton>

      <Dialog
        maxWidth="xl"
        open={openConsulta}
        onClose={handleCloseConsulta}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          <Box display="flex" justifyContent={'space-between'}>
            <Box>
              <Box display="flex" marginBottom={-1.5}>
                <Box alignSelf="center" marginRight={1}>
                  <PersonIcon
                    color="primary"
                    style={{ width: 30, height: 30 }}
                  />
                </Box>
                <Box alignSelf="center" marginTop={-0.5}>
                  {isLoading ? (
                    <Skeleton style={{ width: 300, height: 55 }} />
                  ) : (
                    <Typography color="primary">
                      <h3>{item.pagador?.nome}</h3>
                    </Typography>
                  )}
                </Box>
              </Box>
              {item ? (
                <Box display={'flex'}>
                  <Typography
                    fontWeight={'bold'}
                    variant="body1"
                    marginRight={0.5}
                  >
                    CPF/CNPJ:
                  </Typography>
                  <Typography variant="body1">
                    {item.beneficiario.cep}
                  </Typography>
                </Box>
              ) : (
                <Skeleton style={{ width: 300, height: 55 }} />
              )}
            </Box>
            <Box
              width={140}
              height={60}
              borderRadius={2}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              border={'2px solid #018781'}
            >
              <Typography variant="body1" fontWeight={'bold'} color={'primary'}>
                {handleNotPaidSituationFormatter(item.situacao)}
              </Typography>
            </Box>
          </Box>
          <Box
            display={'flex'}
            justifyContent={'space-betweenm'}
            flexWrap={'wrap'}
            maxWidth={'100%'}
            marginTop={2}
          >
            <Box display={'flex'}>
              <Typography
                fontWeight={'bold'}
                marginBottom={1}
                variant="body1"
                marginRight={0.5}
              >
                Número Convênio:
              </Typography>
              <Typography variant="body1" marginRight={3}>
                {item.numeroConvenio}
              </Typography>
            </Box>
            <Box display={'flex'}>
              <Typography
                fontWeight={'bold'}
                marginBottom={1}
                variant="body1"
                marginRight={0.5}
              >
                Vencimento:
              </Typography>
              <Typography variant="body1" marginRight={3}>
                {formatDate(item.dataVencimento)}
              </Typography>
            </Box>
            <Box display={'flex'}>
              <Typography
                fontWeight={'bold'}
                marginBottom={1}
                variant="body1"
                marginRight={0.5}
              >
                Valor:
              </Typography>
              <Typography variant="body1" marginRight={3}>
                R${handlePriceFormatter(item.valorOriginal)}
              </Typography>
            </Box>
            <Box display={'flex'}>
              <Typography
                fontWeight={'bold'}
                marginBottom={1}
                variant="body1"
                marginRight={0.5}
              >
                Número Documento:
              </Typography>
              <Typography variant="body1" marginRight={3}>
                {item.numeroDocumento}
              </Typography>
            </Box>
            <Box display={'flex'}>
              <Typography
                fontWeight={'bold'}
                marginBottom={1}
                variant="body1"
                marginRight={0.5}
              >
                Número do Título do Cliente:
              </Typography>
              <Typography variant="body1" marginRight={3}>
                {item.numeroTituloCliente}
              </Typography>
            </Box>
            {item.linhaDigitavel ? (
              <Box display={'flex'}>
                <Typography
                  fontWeight={'bold'}
                  variant="body1"
                  marginBottom={1}
                  marginRight={0.5}
                >
                  Linha Digitavel:
                </Typography>
                <Typography variant="body1" marginRight={3}>
                  {item.linhaDigitavel}
                </Typography>
              </Box>
            ) : (
              <></>
            )}
          </Box>
        </DialogTitle>

        <DialogContent>
          {isLoading || !item ? (
            <SkeletonConsulta />
          ) : (
            <Grid direction="column" container>
              <Grid container item>
                <Grid item xs style={{ marginLeft: -32, marginTop: -15 }}>
                  <Timeline>
                    {historico.map((item, index) => (
                      <Box
                        key={index}
                        style={{
                          marginLeft: 25,
                          alignContent: 'flex-start',
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <TimelineItem
                          classes={{ root: classes.TimeLineContent }}
                        >
                          <TimelineSeparator>
                            <TimelineDot />
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent>
                            <Accordion
                              style={{ display: 'contents' }}
                              onChange={(_, expanded) => {
                                handleAccordionChange(expanded);
                              }}
                            >
                              <AccordionSummary
                                style={{
                                  display: 'flex',
                                  alignItems: 'initial',
                                }}
                                expandIcon={<ExpandMoreIcon />}
                              >
                                <Typography style={{ whiteSpace: 'nowrap' }}>
                                  {String(item.dataHora)
                                    .substring(0, 10)
                                    .split('-')
                                    .reverse()
                                    .join('/')}
                                  {String(item.dataHora)
                                    .substring(10, 19)
                                    .replaceAll('T', ' - ')}
                                  <Typography>{item.etapa}</Typography>
                                  <Typography>{item.observacao}</Typography>
                                </Typography>
                              </AccordionSummary>

                              <AccordionDetails
                                style={
                                  expandedLogs.length > 0
                                    ? { display: 'flex', width: 700 }
                                    : { display: 'none' }
                                }
                              >
                                <Box>
                                  <Typography variant="button">
                                    Requisição
                                  </Typography>
                                  <Typography
                                    style={{
                                      fontSize: 13,
                                      marginRight: '10px',
                                    }}
                                  >
                                    <Paper
                                      style={{
                                        background: '#e0e0e0',
                                        color: '#000',
                                        borderRadius: 8,
                                      }}
                                    >
                                      <JSONPretty
                                        style={{
                                          whiteSpace: 'pre-wrap',
                                          wordBreak: 'break-word',
                                        }}
                                        data={syntaxHighlight(item.request)}
                                      />
                                    </Paper>
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography variant="button">
                                    Resposta
                                  </Typography>
                                  <Typography style={{ fontSize: 13 }}>
                                    <Paper
                                      style={{
                                        background: '#e0e0e0',
                                        color: '#000',
                                        borderRadius: 8,
                                      }}
                                    >
                                      <JSONPretty
                                        style={{
                                          whiteSpace: 'pre-wrap',
                                          wordBreak: 'break-word',
                                        }}
                                        data={syntaxHighlight(item.response)}
                                      />
                                    </Paper>
                                  </Typography>
                                </Box>
                              </AccordionDetails>
                            </Accordion>
                          </TimelineContent>
                        </TimelineItem>
                      </Box>
                    ))}
                  </Timeline>
                </Grid>
              </Grid>
            </Grid>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};
