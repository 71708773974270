import { Api } from 'shared/services/axios-config/AxiosConfig';
import { IResultOfAction } from 'shared/services/response-error/ResponseError';

const getUser = async (): Promise<IResultOfAction<any> | undefined> => {
  try {
    const { data } = await Api().get<IResultOfAction<any>>('/bandeiras');

    if (data) {
      return data;
    }
  } catch (error) {
    return undefined;
  }
};

export const UserService = {
  getUser,
};
