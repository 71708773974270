import { Api } from 'shared/services/axios-config';

export interface ILinkPagamento {
  FormaPagamento: string;
  IdIntegracaoOrigem: string;
  Link: string;
  NomeOrigem: string;
  Situacao: string;
  TituloPagamento: string;
  Valor: number;
  Vencimento: string;
}

export interface ILinksResult {
  links: ILinkPagamento[] | undefined;
  qtdRegistros: number;
}

const getAll = async (
  take: number,
  skip: number,
  search: string,
): Promise<ILinksResult> => {
  try {
    const { data } = await Api().get(
      `/linkpagamentos?filtro=${search}&take=${take}&skip=${skip}`,
    );

    if (data) {
      return {
        links: data.Data,
        qtdRegistros: data.QtdTotalDeRegistros,
      };
    }

    return {
      links: undefined,
      qtdRegistros: 0,
    };
  } catch (error) {
    return {
      links: undefined,
      qtdRegistros: 0,
    };
  }
};

export const LinksPagamentosService = {
  getAll,
};
