import GenericException from 'shared/errors/GenericException';
import { Api, ApiPix } from 'shared/services/axios-config';
import {
  IResultOfAction,
  responseError,
} from 'shared/services/response-error/ResponseError';

export interface Estabelcimento {
  Id: number;
  Nome: string;
  CodigoEstruturado: string;
  CNPJ: string;
}

export interface IContasBancarias {
  IdContaBancaria: number;
  Ativo: boolean;
  CodigoBanco: string;
  NomeBanco: string;
  NumeroAgencia: string;
  NumeroConta: string;
  DescricaoConta: string;
  Estabelcimentos: Estabelcimento[];
}

export interface IChavePix {
  id?: string;
  contaBancariaId: number;
  contaBancaria: string;
  contaBancariaDescricao: string;
  nomeBanco?: string;
  email?: string;
  codigoBanco: string;
  chavePixTipoId: number;
  cnpjEstabelecimento: string;
  nomeEstabelecimento: string;
  chavePixDoRecebedor: string;
  chavePixConfigs: IChavePixConfig;
}

export interface IChavePixConfig {
  basic: Basic;
  clientCertificate: ClientCertificate;
  clientId: ClientId;
  clientSecret: ClientSecret;
  developerApplicationKey: DeveloperApplicationKey;
  merchantCity: MerchantCity;
  merchantName: MerchantName;
  passPhrase: PassPhrase;
}

export interface Basic {
  valor: string;
  habilitado: boolean;
}

export interface ClientCertificate {
  valor: string;
  habilitado: boolean;
}

export interface ClientId {
  valor: string;
  habilitado: boolean;
}

export interface ClientSecret {
  valor: string;
  habilitado: boolean;
}

export interface DeveloperApplicationKey {
  valor: string;
  habilitado: boolean;
}

export interface MerchantCity {
  valor: string;
  habilitado: boolean;
}

export interface MerchantName {
  valor: string;
  habilitado: boolean;
}

export interface PassPhrase {
  valor: string;
  habilitado: boolean;
}

const getPixParametros = async (id: string): Promise<IChavePix> => {
  try {
    const { data } = await ApiPix().get<IChavePix>(`pixchaves/${id}`);
    return data;
  } catch (error: any) {
    throw new GenericException(error.response);
  }
};

const getContasBancarias = async (): Promise<any> => {
  try {
    const { data } = await Api().get<IContasBancarias[]>(
      `ContasBancarias?somentePermiteRecebimentoPix=true`,
    );

    return data;
  } catch (error: any) {
    throw new Error(error.response.data.ResultadoDaAcao.MensagensCombinadas);
  }
};

const getCredenciais = async (codigoBanco: string): Promise<any> => {
  try {
    const { data } = await ApiPix().get<any>(
      `PixChaves/Parametros/${codigoBanco}`,
    );
    return data;
  } catch (error: any) {
    throw new GenericException(error.response);
  }
};

const postChavePix = async (body: IChavePix): Promise<IResultOfAction<any>> => {
  try {
    const data = await ApiPix().post(`pixchaves`, body);

    const response: any = {
      sucesso: true,
      data: data,
    };

    return response as IResultOfAction<any>;
  } catch (error: any) {
    const result = responseError(error);
    result.mensagensCombinadas = error.response?.data;
    return result;
  }
};

const putPixKey = async (
  body: IChavePix,
  idPix: string,
): Promise<IResultOfAction<any>> => {
  try {
    const data = await ApiPix().put(`pixchaves/${idPix}`, body);

    const response: any = {
      sucesso: true,
      data: data,
    };

    return response as IResultOfAction<any>;
  } catch (error: any) {
    const result = responseError(error);
    result.mensagensCombinadas = error.response?.data;
    return result;
  }
};

const deletePixKey = async (id: string): Promise<void> => {
  try {
    await ApiPix().delete<string>(`pixchaves/${id}`);
  } catch (error: any) {
    throw new GenericException(error.response);
  }
};

export const newPixKeyService = {
  putPixKey,
  deletePixKey,
  postChavePix,
  getCredenciais,
  getPixParametros,
  getContasBancarias,
};
