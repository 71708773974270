import React from 'react';
import { Box, Button, Container, Paper } from '@mui/material';

interface IFooterProps {
  disabled?: boolean;
  onSave(): Promise<void> | void;
  onSaveAndNew?(): void;
  onCancel?(): void;
}

export const Footer: React.FC<IFooterProps> = ({
  disabled = false,
  onSave,
  onSaveAndNew,
  onCancel,
}) => {
  return (
    <Box
      position="fixed"
      right={0}
      bottom={0}
      left={0}
      zIndex={999}
    >
      <Paper elevation={4}>
        <Box
          marginLeft={9}
          style={{
            transition: 'all 0.25s',
          }}
        >
          <Container>
            <Box
              height="78px"
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
            >
              {onCancel && (
                <Box width={160}>
                  <Button
                    fullWidth
                    size="large"
                    color="primary"
                    variant="outlined"
                    onClick={onCancel}
                  >
                    Cancelar
                  </Button>
                </Box>
              )}
              <Box marginLeft={2} width={160}>
                <Button
                  fullWidth
                  size="large"
                  color="primary"
                  variant="contained"
                  disabled={disabled}
                  onClick={onSave}
                >
                  {onSaveAndNew ? 'Salvar e Voltar' : 'Salvar'}
                </Button>
              </Box>

              {onSaveAndNew && (
                <Box marginLeft={2} width={160}>
                  <Button
                    fullWidth
                    size="large"
                    color="primary"
                    disabled={disabled}
                    onClick={onSaveAndNew}
                  >
                    Salvar e Novo
                  </Button>
                </Box>
              )}
            </Box>
          </Container>
        </Box>
      </Paper>
    </Box>
  );
};
