import React, { useCallback } from 'react';
import {
  Box,
  Grid,
  List,
  AppBar,
  Toolbar,
  ListItem,
  useTheme,
  IconButton,
  Typography,
  ListItemIcon,
} from '@mui/material';
import { Menu } from '@mui/icons-material';

import { GVlogo } from 'shared/assets';
import { useTitle } from 'shared/hooks';
import { makeStyles } from '@mui/styles';
import { ModalFeedBack } from 'pages/feedBack/components/ModalFeedBack';

interface IHeaderProps {
  isOpen: boolean;
  buttonActive: boolean;
  handleDrawerOpen(): void;
  setButtonActive(value: boolean): void;
}

export const HeaderComponent: React.FC<IHeaderProps> = ({
  isOpen,
  buttonActive,
  handleDrawerOpen,
  setButtonActive,
}) => {
  // const [nome, setNome] = useState('');

  const theme = useTheme();

  const { title } = useTitle();

  const handleOpenMenu = useCallback(() => {
    handleDrawerOpen();

    setButtonActive(true);
  }, [handleDrawerOpen, setButtonActive]);

  const handleCloseMenu = useCallback(() => {
    handleDrawerOpen();

    setButtonActive(false);
  }, [handleDrawerOpen, setButtonActive]);

  const useStyles = makeStyles({
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      height: theme.spacing(8),
      boxShadow: 'none !important',
    },
    rightInfo: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      gap: theme.spacing(4),
    },
    menuButton: {
      marginRight: theme.spacing(0.5),
    },
    listItem: {
      height: theme.spacing(7),
      color: theme.palette.secondary.contrastText,
    },
    iconMenu: {
      color: theme.palette.secondary.contrastText,
    },
    imgLogo: {
      width: 125,
      objectFit: 'contain',
    },
  });

  const classes = useStyles();

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar style={{ paddingLeft: 0 }}>
        <Grid container>
          <Grid item xs={9}>
            <List>
              <ListItem className={classes.listItem}>
                <ListItemIcon>
                  <IconButton
                    edge="start"
                    className={classes.menuButton}
                    style={{
                      marginLeft: theme.spacing(0.5),
                      backgroundColor: buttonActive
                        ? theme.palette.divider
                        : 'transparent',
                    }}
                    onClick={isOpen ? handleCloseMenu : handleOpenMenu}
                  >
                    <Menu className={classes.iconMenu} />
                  </IconButton>
                </ListItemIcon>
                <img
                  src={GVlogo.default}
                  alt="Logo GVdasa"
                  className={classes.imgLogo}
                />

                <Box marginLeft={2}>
                  <Typography variant="h6">{title}</Typography>
                </Box>
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={3} className={classes.rightInfo}>
           <ModalFeedBack/>
            
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};
