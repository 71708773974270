import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Pagination,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTitle } from 'shared/hooks';
import { feedback } from 'shared/services';

import {
  BoletoService,
  IData,
  ISituacaoBoelto,
} from 'shared/services/api/cobrancas-boletos/cobrancasBoletos';
import { FiltroBoleto } from './components/FiltroDeBusca';
import { SkeletonBoletos } from './components/SkeletonBoletos';
import { makeStyles } from '@mui/styles';
import { ModalConsultaBoletos } from './components/ModalConsulta';

export const ListagemBoletos: React.FC = () => {
  const useStyles = makeStyles({
    pagadorHeader: {
      whiteSpace: 'nowrap',
      position: 'sticky',
      background: '#e0e0e0',
      textAlign: 'left',
    },
    pagadorBody: {
      whiteSpace: 'nowrap',
      position: 'sticky',
      left: 1,
      background: '#FFF',
    },
    noWrap: {
      whiteSpace: 'nowrap',
    },
  });

  const { setTitle } = useTitle();

  useEffect(() => {
    setTitle('Boletos');
  }, [setTitle]);

  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [take, setTake] = useState(10);
  const [skip, setSkip] = useState(1);
  const [situacao, setSituacao] = useState<ISituacaoBoelto[]>([]);
  const [aberto, setAberto] = useState(false);
  const [data, setData] = useState<IData[]>([]);
  const [totalRegistros, setTotalRegistros] = useState(0);
  const [filter, setFilter] = useState(false);
  const [inicioVencimentoData, setInicioVencimentoData] = useState('');
  const [finalVencimentoData, setFinalVencimentoData] = useState('');
  const [incioEmissaoData, setInicioEmissaoData] = useState('');
  const [finalEmissaoData, setFinalEmissaoData] = useState('');
  const [cpfPesquisa, setCpfPesquisa] = useState('');
  const [numeroTitulo, setNumeroTitulo] = useState('');

  const formatDate = (date: string) => {
    const convertedDate = new Date(date);

    if (date != '') {
      let month = '' + (convertedDate.getMonth() + 1);

      let day = '' + convertedDate.getDate();

      const year = convertedDate.getFullYear();

      if (month.length < 2) month = '0' + month;

      if (day.length < 2) day = '0' + day;

      return [year, month, day].join('-');
    } else {
      return '';
    }
  };

  useEffect(() => {
    if (filter) {
      setSkip(1);
    }
  }, [filter]);

  useEffect(() => {
    setIsLoading(true);
    BoletoService.getBoletos(
      formatDate(inicioVencimentoData),
      formatDate(finalVencimentoData),
      formatDate(incioEmissaoData),
      formatDate(finalEmissaoData),
      [situacao.map((item) => item.id).join(',')],
      cpfPesquisa,
      numeroTitulo,
      take,
      skip,
    )
      .then((response) => {
        if (response.sucesso) {
          setData(response.data?.data || []);
          setTotalRegistros(response.data?.qtdTotalDeRegistros || 0);
          feedback('Listagem de cobranças carregadas!', 'success');
        } else {
          feedback(
            response.mensagensCombinadas || 'Erro ao buscar cobranças',
            'error',
          );
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [take, skip, filter]);

  const handleChangePage = (page: number) => {
    setSkip(page);
  };

  return (
    <Grid direction="column" container>
      <Grid container item>
        <Grid container item>
          <Grid item xs>
            <Box marginTop={9}>
              <Box
                component={Paper}
                display="flex"
                justifyContent="space-between"
                alignItems={'center'}
                gap={2}
              >
                <Box p={2}>
                  <Typography variant={'h5'} color={'primary'}>
                    Listagem de boletos
                  </Typography>
                </Box>
                <Box p={2}>
                  <FiltroBoleto
                    trigger={filter}
                    openFiltro={aberto}
                    setFiltrar={setFilter}
                    setOpenFiltro={setAberto}
                    setSituacao={setSituacao}
                    setDataFinalEmissao={setFinalEmissaoData}
                    setDataInicioEmissao={setInicioEmissaoData}
                    setDataFinalVencimento={setFinalVencimentoData}
                    setDataInicioVencimento={setInicioVencimentoData}
                    setCpfPesquisa={setCpfPesquisa}
                    setNumeroTitulo={setNumeroTitulo}
                  />
                </Box>
              </Box>
              <Box>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          width="10%"
                          className={classes.pagadorHeader}
                        >
                          Número Titulo Cliente
                        </TableCell>
                        <TableCell className={classes.noWrap}>
                          Vencimento
                        </TableCell>
                        <TableCell className={classes.noWrap}>
                          Nome do Pagador
                        </TableCell>
                        <TableCell className={classes.noWrap}>
                          CPF/CNPJ
                        </TableCell>
                        <TableCell className={classes.noWrap}>
                          Emissão
                        </TableCell>
                        <TableCell className={classes.noWrap}>
                          Número Documento
                        </TableCell>
                        <TableCell className={classes.noWrap}>
                          Situação
                        </TableCell>
                        <TableCell className={classes.noWrap}>Banco</TableCell>
                        <TableCell className={classes.noWrap}>
                          Convênio
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    {isLoading ? (
                      <SkeletonBoletos />
                    ) : (
                      <>
                        {data.length <= 0 ? (
                          <Box
                            width={250}
                            display="flex"
                            style={{ position: 'relative', left: 10 }}
                          >
                            <Typography color="secondary">
                              <p>Nenhum registro encontrado...</p>
                            </Typography>
                          </Box>
                        ) : (
                          <TableBody>
                            {data.map((item, index) => (
                              <TableRow key={index}>
                                <Paper
                                  elevation={5}
                                  className={classes.pagadorBody}
                                >
                                  <TableCell>
                                    <Box alignItems="center" display="flex">
                                      <Box marginLeft={-0.7} marginRight={1}>
                                        <ModalConsultaBoletos
                                          id={item.idBoleto}
                                          item={item}
                                        />
                                      </Box>
                                      <Box>
                                        <Box>{item.numeroTituloCliente}</Box>
                                      </Box>
                                    </Box>
                                  </TableCell>
                                </Paper>

                                <TableCell className={classes.noWrap}>
                                  <Box>
                                    {String(item.dataVencimento)
                                      .substring(0, 10)
                                      .split('-')
                                      .reverse()
                                      .join('/')}
                                  </Box>
                                </TableCell>
                                <TableCell className={classes.noWrap}>
                                  <Box>{item.pagador.nome}</Box>
                                </TableCell>
                                <TableCell className={classes.noWrap}>
                                  <Box>{item.pagador.numeroInscricao}</Box>
                                </TableCell>
                                <TableCell className={classes.noWrap}>
                                  <Box>
                                    {String(item.dataEmissao)
                                      .substring(0, 10)
                                      .split('-')
                                      .reverse()
                                      .join('/')}
                                  </Box>
                                </TableCell>
                                <TableCell className={classes.noWrap}>
                                  <Box>{item.numeroDocumento}</Box>
                                </TableCell>
                                <TableCell className={classes.noWrap}>
                                  <Box>
                                    {item.situacao?.replaceAll('_', ' ')}
                                  </Box>
                                </TableCell>
                                <TableCell className={classes.noWrap}>
                                  <Box>{item.nomeBanco}</Box>
                                </TableCell>
                                <TableCell className={classes.noWrap}>
                                  <Box>{item.numeroConvenio}</Box>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        )}
                      </>
                    )}
                  </Table>
                </TableContainer>
                <Box
                  marginTop={2}
                  display={'flex'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent={'flex-start'}
                  >
                    {data.length > 0 && (
                      <Pagination
                        page={skip}
                        count={Math.ceil(totalRegistros / take)}
                        onChange={(e: any, page: any) => {
                          handleChangePage(page);
                        }}
                      />
                    )}
                  </Box>
                  {take && (
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent={'flex-start'}
                      gap={1}
                    >
                      <FormControl component="fieldset">
                        <Paper>
                          <FormControl
                            variant="outlined"
                            size="small"
                            fullWidth
                          >
                            <Select
                              id="rowsperpage-select"
                              value={take}
                              onChange={(e: any) =>
                                setTake(e.target.value as number)
                              }
                            >
                              {[10, 15, 20, 50].map((item) => (
                                <MenuItem key={item} value={item}>
                                  {item}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Paper>
                      </FormControl>
                      <Box>Linhas por página</Box>
                      {data.length > 0 && (
                        <Typography
                          variant="caption"
                          style={{ color: '#9E9E9E' }}
                        >
                          {take > totalRegistros ? totalRegistros : take} do
                          total de {totalRegistros} linhas
                        </Typography>
                      )}
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
