export const Environment = {
  LIMITE_DE_LINHAS: 10,
  URL_API:
    location.host.includes('localhost') || location.host.includes('-dev')
      ? `https://api-dev.gvdasa.com.br/pay/api/v1`
      : location.host.includes('-hml')
      ? `https://api-hml.gvdasa.com.br/pay/api/v1`
      : 'https://api.gvdasa.com.br/pay/api/v1',

  URL_GATEWAY:
    location.host.includes('localhost') || location.host.includes('-dev')
      ? `https://api-dev.gvdasa.com.br`
      : location.host.includes('-hml')
      ? `https://api-hml.gvdasa.com.br`
      : 'https://api.gvdasa.com.br',
  URL_ORIGIN_DEBUG: window.location.host.includes('localhost')
    ? 'https://area87centris2-dev.pay.gvdasa.com.br'
    : '',

  URL_WC_AGENDAMENTOS:
    process.env.REACT_APP_ENV === 'prod'
      ? 'https://wc-agendamentos.gvdasa.com.br'
      : process.env.REACT_APP_ENV === 'hml'
      ? 'https://wc-agendamentos-hml.gvdasa.com.br'
      : process.env.REACT_APP_ENV === 'dev'
      ? 'https://wc-agendamentos-dev.gvdasa.com.br'
      : 'https://wc-agendamentos-dev.gvdasa.com.br',

  URL_WC_CARTAO:
    process.env.REACT_APP_ENV === 'prod'
      ? 'https://wc-pay-cartao.gvdasa.com.br'
      : process.env.REACT_APP_ENV === 'hml'
      ? 'https://wc-pay-cartao-hml.gvdasa.com.br'
      : process.env.REACT_APP_ENV === 'dev'
      ? 'https://wc-pay-cartao-dev.gvdasa.com.br'
      : 'https://wc-pay-cartao-dev.gvdasa.com.br',

  URL_WC_PIX:
    process.env.REACT_APP_ENV === 'prod'
      ? 'https://wc-pay-pix.gvdasa.com.br'
      : process.env.REACT_APP_ENV === 'hml'
      ? 'https://wc-pay-pix-hml.gvdasa.com.br'
      : process.env.REACT_APP_ENV === 'dev'
      ? 'https://wc-pay-pix-dev.gvdasa.com.br'
      : 'https://wc-pay-pix-dev.gvdasa.com.br',

  URL_PAY_BOLETO:
    location.host.includes('localhost') || location.host.includes('-dev')
      ? `https://api-dev.gvdasa.com.br/paybh/api/v1`
      : location.host.includes('-hml')
      ? `https://api-hml.gvdasa.com.br/paybh/api/v1`
      : 'https://api.gvdasa.com.br/paybh/api/v1',

  URL_PAY_PIX:
    location.host.includes('localhost') || location.host.includes('-dev')
      ? `https://api-dev.gvdasa.com.br/paypix/api/v1`
      : location.host.includes('-hml')
      ? `https://api-hml.gvdasa.com.br/paypix/api/v1`
      : 'https://api.gvdasa.com.br/paypix/api/v1',

  URL_WC_FEEDABACK:
    process.env.REACT_APP_ENV === 'prod'
      ? 'https://wc-feedbacks.gvdasa.com.br'
      : process.env.REACT_APP_ENV === 'hml'
      ? 'https://wc-feedbacks-hml.gvdasa.com.br'
      : process.env.REACT_APP_ENV === 'dev'
      ? 'https://wc-feedbacks-dev.gvdasa.com.br'
      : 'https://wc-feedbacks-dev.gvdasa.com.br',
};
