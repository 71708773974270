import React from 'react';
import {
  CircularProgress,
  Container,
  Snackbar,
  Alert,
  AlertProps,
} from '@mui/material';

function AlertComp(props: AlertProps) {
  return <Alert elevation={6} variant="filled" {...props} />;
}

interface BasicSnackBarProps {
  isOpen: boolean;
  message: string;
  onClose?(): void;
  actionOnClick?(): void;
  type: 'error' | 'warning' | 'info' | 'success' | 'loading' | undefined;
}
export const BasicSnackBar: React.FC<BasicSnackBarProps> = ({
  isOpen,
  onClose,
  type,
}) => {
  setTimeout(
    () => {
      onClose && onClose();
    },
    type !== 'loading' ? 6000 : 99999999,
  );

  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={type !== 'loading' ? 6000 : 99999999}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      onClose={onClose}
    >
      <Container>
        <AlertComp
          icon={type !== 'loading' ? undefined : <CircularProgress size={20} />}
          severity={type !== 'loading' ? type : 'success'}
          onClose={onClose}
        >
          message
        </AlertComp>
      </Container>
    </Snackbar>
  );
};
