import React, {useRef, useEffect} from 'react';

interface IOptionsPayment {
    descricao: string; 
    numeroDeParcelas: number;
}

interface IFormCardPayment {
  cnpj: string;
  optionsPayment: IOptionsPayment[];
  totalPrice: number;
  originDocument: string;
  origemId: number;
  idTenant:string;
  token: string;
  collegePortion: string;
  onPaymentSuccess: (message: string) => void;
  onPaymentError: (message: string) => void;
  onCancelOperation: (cancel?: boolean) => void;
}

export const FormCardPayment: React.FC<IFormCardPayment> = ({
  idTenant,
  token,
  cnpj,
  optionsPayment,
  totalPrice,
  originDocument,
  origemId,
  onPaymentSuccess,
  onPaymentError,
  onCancelOperation,
  collegePortion
}) => {
  const elementRef = useRef<HTMLElement>(null);

  useEffect(() => {
    const listenerCancel = (event: any) => {
      onCancelOperation && onCancelOperation(event.detail);
    };
    const listenerPaymentSuccess = (event: any) => {
      onPaymentSuccess && onPaymentSuccess(event.detail);
    };
    const listenerPaymentError = (event: any) => {
      onPaymentError && onPaymentError(event.detail);
    };

    const ref = elementRef.current;
    if (ref) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      (elementRef.current as any)!.optionsPayment = optionsPayment;

      ref.addEventListener('paymentSuccess', listenerPaymentSuccess);
      ref.addEventListener('paymentError', listenerPaymentError);
      ref.addEventListener('cancelOperation', listenerCancel);
      return () => {
        ref?.removeEventListener('paymentSuccess', listenerPaymentSuccess);
        ref?.removeEventListener('paymentError', listenerPaymentError);
        ref?.removeEventListener('cancelOperation', listenerCancel);
      };
    }
  }, [onPaymentSuccess, onPaymentError, onCancelOperation, optionsPayment]);
  
  return React.createElement('form-card-payments', {
    ref: elementRef,
    cnpj: cnpj,
    'token': token,
    'id-Tenant': idTenant,
    'total-price': totalPrice,
    'origin-document': originDocument,
    'origem-Id': origemId,
    'liquidar-parcela-gvcollege': collegePortion
  });
};
