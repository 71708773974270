const convert = (file: File):Promise<string> => {
    return new Promise(function (resolve, reject) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
    });
}

export const encodeToBase64 = async (file: File):Promise<string> => {
    const base64 = await convert(file);
    return base64
}