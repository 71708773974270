import React, { useCallback, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  OutlinedInput,
  Typography,
} from '@mui/material';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { TransacoesService } from 'shared/services/api';
import { feedback } from 'shared/services';

interface Iprops {
  abrirCapturar: boolean;
  setOpenCapturar(value: boolean): void;
  numeroDocumento: string;
  guid: string | undefined;
}

export const CapturarTransacao: React.FC<Iprops> = ({
  guid,
  abrirCapturar,
  setOpenCapturar,
  numeroDocumento,
}) => {
  const handleCloseCapturar = () => {
    setOpenCapturar(false);
  };

  ////////// Diolog Autenticação ///////

  const [openAutenticacao, setOpenAutenticacao] = useState(false);

  const [numeroAuth, setNumeroAuth] = useState('');

  const handleOpenAutenticar = () => {
    setOpenAutenticacao(true);
  };

  const handleCloseAutenticar = () => {
    setOpenAutenticacao(false);
    setNumeroAuth('');
  };

  const handleNumeroAuth = useCallback((e: any) => {
    setNumeroAuth(e.target.value);
  }, []);

  const putCapturar = useCallback(async () => {
    TransacoesService.putCapturar(guid).then((response) => {
      if (response) {
        feedback(response.mensagens[0].Mensagem, 'error');
        handleCloseAutenticar();
      } else {
        feedback('Capturado com sucesso', 'success');
        handleCloseAutenticar();
        location.reload();
      }
    });
  }, [guid]);

  return (
    <>
      <Dialog
        maxWidth="md"
        open={abrirCapturar}
        onClose={handleCloseCapturar}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>Capturar Transação</DialogTitle>
        <DialogContent>
          <Typography>
            <p>
              O processo de captura deve ser realizado via GVcentris ou
              GVcollege. Utilize essa opção APENAS para exceções.
            </p>
            <p>A liquidação do título deve ser realizada MANUALMENTE no ERP.</p>
          </Typography>
        </DialogContent>
        <DialogActions style={{ marginTop: 20 }}>
          <Button
            variant="outlined"
            onClick={() => {
              handleCloseCapturar();
            }}
            color="primary"
          >
            NÃO, quero cancelar
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              handleOpenAutenticar(), handleCloseCapturar();
            }}
            color="primary"
            autoFocus
          >
            SIM, quero continuar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        maxWidth="sm"
        fullWidth
        open={openAutenticacao}
        onClose={handleCloseAutenticar}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle style={{ color: '#ff0000' }}>
          Você tem certeza?
        </DialogTitle>
        <DialogContent style={{ marginBottom: 15 }}>
          <b> Para confirmar a captura, digite o número de documento:</b>
        </DialogContent>
        <DialogContent style={{ marginBottom: 15 }}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel>Número do documento*</InputLabel>
            <OutlinedInput
              defaultValue={numeroAuth}
              label="Número do documento*"
              type="string"
              onChange={handleNumeroAuth}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={!(numeroAuth === numeroDocumento)}
            variant="contained"
            onClick={() => {
              putCapturar();
            }}
            color="primary"
            autoFocus
          >
            <Box marginTop={0.6} marginRight={0.5}>
              <MonetizationOnIcon fontSize="small" />
            </Box>
            Capturar
          </Button>

          <Button
            variant="outlined"
            onClick={handleCloseAutenticar}
            style={{ color: '#ff0000', borderColor: '#ff0000', height: 40 }}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
