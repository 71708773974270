import GenericException from 'shared/errors/GenericException';
import { Api, ApiBoleto } from 'shared/services/axios-config';
import {
  IResultOfAction,
  IResultadoDaAcao,
  responseErro,
  responseError,
} from 'shared/services/response-error/ResponseError';

export interface IConfigBoleto {
  qtdTotalDeRegistros: number;
  permiteIncluir: boolean;
  data: IDataBoleto[];
}

export interface IDataBoleto {
  id: string;
  convenio: string;
  agencia: string;
  descricao: string;
  descricaoContaBancaria: string;
  nomeBanco: string;
  cnpjEstabelecimento: string;
  nomeEstabelecimento: string;
  estadoCertificado: number;
  permiteEditar: boolean;
  permiteExcluir: boolean;
}
export interface IConfiguracoes {
  id: string;
  convenio: number;
  descricao: string;
  descricaoContaBancaria: string;
  nomeBanco: string;
  codigoBanco: string;
  cnpjEstabelecimento: string;
  nomeEstabelecimento: string;
  idContaErp: number;
  numeroCarteira: number;
  credenciaisConfig: ICredenciaisConfig;
}

export interface ICredenciaisConfig {
  developerApplicationId: IDeveloperApplicationId;
  clientId: IClientId;
  clientSecret: IClientSecret;
  basic: IBasic;
  xApiKey: IXApiKey;
  username: IUsername;
  password: IPassword;
  chavePix: IPixKey;
  tipoChavePix: IPixKeyType;
  horaProcessamento: IProcessTime;
}

export interface IPixKey {
  valor: string;
  habilitado: boolean;
}

export interface IPixKeyType {
  valor: string;
  habilitado: boolean;
}

export interface IProcessTime {
  valor: string;
  habilitado: boolean;
}

export interface IWorkspace {
  habilitado: boolean;
  opcoes: IWorkspaceValue[];
}

export interface IWorkspaceValue {
  id: string;
  descricao: string;
  ativo: boolean;
}

export interface IDeveloperApplicationId {
  valor: string;
  habilitado: boolean;
}

export interface IClientId {
  valor: string;
  habilitado: boolean;
}

export interface IClientSecret {
  valor: string;
  habilitado: boolean;
}

export interface IBasic {
  valor: string;
  habilitado: boolean;
}

export interface IXApiKey {
  valor: string;
  habilitado: boolean;
}

export interface IUsername {
  valor: string;
  habilitado: boolean;
}

export interface IPassword {
  valor: string;
  habilitado: boolean;
}

export interface IContasBancarias {
  IdContaBancaria: number;
  Ativo: boolean;
  CodigoBanco: string;
  NomeBanco: string;
  NumeroAgencia: string;
  NumeroConta: string;
  DescricaoConta: string;
  Estabelcimentos: IEstabelcimento[];
}

export interface IEstabelcimento {
  Id: number;
  Nome: string;
  CodigoEstruturado: string;
  CNPJ: string;
}

export interface IChaveConfig {
  id: string;
  convenio: string;
  contaBancariaId: number;
  contaBancaria: string;
  agencia: string;
  descricao: string;
  descricaoContaBancaria: string;
  nomeBanco: string;
  codigoBanco: string;
  cnpjEstabelecimento: string;
  nomeEstabelecimento: string;
  idContaErp: number;
  numeroCarteira: number;
  email: string;
  credenciaisConfig: ICredenciaisConfig;
  dataVencimentoCertificado: Date;
  dataVencimentoCertificadoDinamico: Date;
  possuiCertificadoDinamico: boolean;
}

export interface ITokenTemporario {
  valor: string;
  habilitado: boolean;
}

export interface ICredenciaisConfig {
  developerApplicationId: IDeveloperApplicationId;
  clientCertificate: IClientCertificate;
  clientId: IClientId;
  clientSecret: IClientSecret;
  tokenTemporario: ITokenTemporario;
  basic: IBasic;
  xApiKey: IXApiKey;
  username: IUsername;
  passPhrase: IPassPhrase;
  password: IPassword;
  chavePix: IPixKey;
  tipoChavePix: IPixKeyType;
  horaProcessamento: IProcessTime;
  workspace: IWorkspace;
  posto: IPosto;
  cooperativa: ICooperativa;
  clientIdBolecode: IClientIdBolecode;
  clientSecretBolecode: IClientSecretBolecode;
  tokenTemporarioBolecode: ITokenTemporarioBolecode;
  clientIdBoleto: IClientIdBoleto;
  clientSecretBoleto: IClientSecretBoleto;
  tokenTemporarioBoleto: ITokenTemporarioBoleto;
}

export interface IPassPhrase {
  valor: string;
  habilitado: boolean;
}

export interface IClientCertificate {
  valor: string;
  habilitado: boolean;
}

export interface IDeveloperApplicationId {
  valor: string;
  habilitado: boolean;
}

export interface IClientId {
  valor: string;
  habilitado: boolean;
}

export interface IClientSecret {
  valor: string;
  habilitado: boolean;
}

export interface IBasic {
  valor: string;
  habilitado: boolean;
}

export interface IXApiKey {
  valor: string;
  habilitado: boolean;
}

export interface IUsername {
  valor: string;
  habilitado: boolean;
}

export interface IPassword {
  valor: string;
  habilitado: boolean;
}

export interface IPosto {
  valor: string;
  habilitado: boolean;
}

export interface ICooperativa {
  valor: string;
  habilitado: boolean;
}

export interface IClientIdBolecode {
  valor: string;
  habilitado: boolean;
}

export interface IClientSecretBolecode {
  valor: string;
  habilitado: boolean;
}

export interface ITokenTemporarioBolecode {
  valor: string;
  habilitado: boolean;
}

export interface IClientIdBoleto {
  valor: string;
  habilitado: boolean;
}

export interface IClientSecretBoleto {
  valor: string;
  habilitado: boolean;
}

export interface ITokenTemporarioBoleto {
  valor: string;
  habilitado: boolean;
}

export interface IGerarCertificado {
  criaCertificado: true;
  atualizarCertificado: false;
}

const putBoletoKey = async (
  body: IChaveConfig,
  idBoleto: string,
): Promise<IResultOfAction<any>> => {
  try {
    const data = await ApiBoleto().put(`Configuracoes/${idBoleto}`, body);

    const response: any = {
      sucesso: true,
      data: data,
    };

    return response as IResultOfAction<any>;
  } catch (error: any) {
    const result = responseError(error);
    result.mensagensCombinadas = error.response?.data;
    return result;
  }
};

const getCredenciais = async (codigoBanco: string): Promise<any> => {
  try {
    const { data } = await ApiBoleto().get(
      `Configuracoes/${codigoBanco}/Credenciais`,
    );
    return data;
  } catch (error: any) {
    throw new GenericException(error.response);
  }
};

const getBoletosParametros = async (id: string): Promise<IChaveConfig> => {
  try {
    const { data } = await ApiBoleto().get(`Configuracoes/${id}`);
    return data;
  } catch (error: any) {
    throw new GenericException(error.response);
  }
};

const getBoleto = async (): Promise<IDataBoleto[]> => {
  try {
    const { data } = await ApiBoleto().get<IDataBoleto[]>(`Configuracoes`);
    return data;
  } catch (error: any) {
    throw new GenericException(error.response);
  }
};

const getContasBancarias = async (): Promise<IContasBancarias[]> => {
  try {
    const { data } = await Api().get(`ContasBancarias`);
    return data;
  } catch (error: any) {
    throw new Error(error.response.data.ResultadoDaAcao.MensagensCombinadas);
  }
};

const postBoletokey = async (
  body: IChaveConfig,
): Promise<IResultadoDaAcao<any>> => {
  try {
    const { data } = await ApiBoleto().post(`Configuracoes`, body);

    const response: any = {
      success: true,
      data: data,
    };

    return response as IResultadoDaAcao<any>;
  } catch (error: any) {
    return error.data.data;
  }
};

const deleteBoletoKey = async (id: string): Promise<void> => {
  try {
    await ApiBoleto().delete(`Configuracoes/${id}`);
  } catch (error: any) {
    throw new GenericException(error.response);
  }
};

const gerarCertificado = async (id: string): Promise<void> => {
  try {
    const payload = {
      idContaBancaria: id,
      criarCertificado: true,
      atualizarCertificado: false,
    };

    await ApiBoleto().post(`Configuracoes/CertificadoDinamico/`, payload);
  } catch (error: any) {
    throw new GenericException(error.response);
  }
};

export const BoletoListService = {
  getBoleto,
  getContasBancarias,
  getBoletosParametros,
  getCredenciais,
  putBoletoKey,
  postBoletokey,
  deleteBoletoKey,
  gerarCertificado,
};
