/* eslint-disable @typescript-eslint/no-explicit-any */
import GenericException from 'shared/errors/GenericException';
import { Api } from 'shared/services/axios-config';
import { IResultOfAction, responseError } from 'shared/services/response-error/ResponseError';

export interface IUsuario {
  Id: string;
  Login: string;
  Email: string;
  Perfil: string;
}

export interface IPermissaoUsuario {
  Id: string;
  Login: string;
  Email: string;
  PerfilUsuarioId: string;
  EstabelecimentosDoUsuario: string[];
}

export interface IPerfisUsuario {
  Id: string;
  Descricao: string;
}

export interface ILoginService {
  UrlLogin: string;
  client_id: string;
  client_secret: string;
}

export interface IPermissao {
  PermissaoUsuario: IPermissaoUsuario;
  PerfisUsuario: IPerfisUsuario[];
  LoginService: ILoginService;
  Estabelecimentos: string[];
}

export interface IPrepararPut {
  Id: string | undefined;
  Login: string | undefined;
  Email: string | undefined;
  IdLoginService: number | undefined;
  PerfilUsuarioId: string | undefined;
  Estabelecimentos: string[];
}

export interface IPrepararPost {
  Login: string | undefined;
  Email: string | undefined;
  IdLoginService: number | undefined;
  PerfilUsuarioId: string | undefined;
  Estabelecimentos: string[];
}

export interface Properties {
  additionalProp1: string;
  additionalProp2: string;
  additionalProp3: string;
}

export interface Subject {
  AuthenticationType: string;
  IsAuthenticated: boolean;
  Claims: any[];
  Label: string;
  Name: string;
  NameClaimType: string;
  RoleClaimType: string;
}

export interface Claim {
  Issuer: string;
  OriginalIssuer: string;
  Properties: Properties;
  Subject: Subject;
  Type: string;
  Value: string;
  ValueType: string;
}

export interface Mensagen {
  Mensagem: string;
  Campos: string[];
  Severidade: number;
}

export interface IDadosUsuario {
  Codigo: number;
  Ativo: boolean;
  ContaConfirmada: boolean;
  Login: string;
  Email: string;
}

const getValidarUsuario = async (
  login: string,
): Promise<IDadosUsuario | undefined> => {
  try {
    const { data } = await Api().get<IDadosUsuario>(
      `usuarios/ObterUsuarioLoginService?login=${login}`,
    );
    return data;
  } catch (error: any) {
    throw new GenericException(error.response);
  }
};

const getUsuario = async (
  page: number,
  limite: number,
  filtro: string,
): Promise<IUsuario[] | undefined> => {
  try {
    const { data } = await Api().get<IUsuario[]>(
      `usuarios?offset=${page}&limite=${limite}&filtro=${filtro}`,
    );
    return data;
  } catch (error: any) {
    throw new GenericException(error.response);
  }
};

const getPermissao = async (guid: string | null): Promise<IPermissao> => {
  try {
    const { data } = await Api().get<IPermissao>(`permissaoperfil/${guid}`);
    return data;
  } catch (error: any) {
    throw new GenericException(error.response);
  }
};

const getNovoPermissao = async (): Promise<IPermissao> => {
  try {
    const { data } = await Api().get<IPermissao>(
      `permissaoperfil/00000000-0000-0000-0000-000000000000`,
    );
    return data;
  } catch (error: any) {
    throw new GenericException(error.response);
  }
};

const putUsuario = async (body: IPrepararPut): Promise<IResultOfAction<any>> => {
  try {
    const response = await Api().put(`permissaoperfil`, body);
    return response.data;
  } catch (error: any) {
    const result = responseError(error);
    return result;
  }
};

const postUsuario = async (body: IPrepararPost): Promise<IResultOfAction<any>> => {
  try {
    const response = await Api().post(`permissaoperfil`, body);
    return response.data;
  } catch (error: any) {
    const result = responseError(error);
    return result;
  }
};

const deletePerfil = async (guid: string): Promise<void> => {
  try {
    await Api().delete<string>(`permissaoperfil/${guid}`);
  } catch (error: any) {
    throw new GenericException(error.response);
  }
};

export const UsuariosService = {
  getUsuario,
  getPermissao,
  deletePerfil,
  putUsuario,
  getValidarUsuario,
  getNovoPermissao,
  postUsuario,
};
