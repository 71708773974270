import { ESeveridadeDeErro } from 'shared/enums/ESeveridadeDeErro';

export interface IErrorMessage {
  Mensagem: string;
  MensagemId?: string | number;
  Severidade: ESeveridadeDeErro;
  SeveridadeId: number;
}

export interface IResultOfAction<T = undefined> {
  data?: T;
  mensagens: IErrorMessage[];
  mensagensCombinadas: string;
  severidade: ESeveridadeDeErro;
  severidadeId: number;
  sucesso: boolean;
}

export interface IResultadoDaAcao<T = undefined> {
  data?: T;
  message: string
  success: boolean;
}

export interface IRequestResult {
  Sucesso: boolean;
  Mensagens?: string[];
}

export const responseError = (err: any): IResultOfAction<any> => {
  const result: IResultOfAction<any> = {
    mensagens: [],
    mensagensCombinadas: '',
    severidade: ESeveridadeDeErro.error,
    severidadeId: 0,
    sucesso: false,
  };

  if (err.response?.data.ResultadoDaAcao) {
    err.response?.data.ResultadoDaAcao.Mensagens.forEach(
      (fieldError: IErrorMessage) => {
        result.mensagens?.push(fieldError);
      },
    );

    result.mensagensCombinadas = err.response?.data.ResultadoDaAcao.MensagensCombinadas || '';
  } else if (err.message === 'Network Error') {
    result.mensagens?.push({
      Mensagem: 'Certifique-se de estar conectado na internet.',
      Severidade: ESeveridadeDeErro.error,
      SeveridadeId: 0,
    });
  }

  return result;
};


export const responseErro = (err: any): IResultadoDaAcao<any> => {
  const result: IResultadoDaAcao<any> = {
    message: err.response?.data.message,
    success: err.response?.data.success,
  };

  return result;
};