import React from 'react';
import { Box, Grid, Divider, Skeleton } from '@mui/material';

export const ConfiguracoesSkeleton: React.FC = () => {
  return (
    <>
      <Grid spacing={2}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Skeleton height={90} />
          </Grid>
          <Grid item xs={3}>
            <Skeleton height={90} />
          </Grid>
          <Grid item xs={3}>
            <Skeleton height={90} />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid item xs={3}>
            <Skeleton height={50} width={80} variant={'text'} />
            <Skeleton height={90} />
          </Grid>
          <Grid item xs={6}>
            <Box>
              <Skeleton height={90} />
            </Box>
            <Box>
              <Skeleton height={90} />
            </Box>
          </Grid>
          <Box>
            <Divider variant={'fullWidth'} />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Grid item xs={3}>
            <Skeleton height={50} width={80} variant={'text'} />
            <Skeleton height={90} />
          </Grid>
          <Grid item xs={6}>
            <Box>
              <Skeleton height={90} />
            </Box>
          </Grid>
          <Box>
            <Divider variant={'fullWidth'} />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Grid item xs={3}>
            <Skeleton height={50} width={80} variant={'text'} />
            <Skeleton height={90} />
          </Grid>
          <Grid item xs={6}>
            <Box>
              <Skeleton height={90} />
            </Box>
          </Grid>
        </Grid>
        <Grid container item justifyContent="flex-end">
          <Grid item xs={2}>
            <Box>
              <Skeleton height={90} />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
