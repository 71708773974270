import React, { useCallback, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  OutlinedInput,
  Typography,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { TransacoesService } from 'shared/services/api';
import { feedback } from 'shared/services';

interface Iprops {
  abrirCancelar: boolean;
  setOpenCancelar(value: boolean): void;
  numeroDocumento: string | undefined;
  guid: string | undefined;
}

export const CancelarTransacao: React.FC<Iprops> = ({
  abrirCancelar,
  setOpenCancelar,
  numeroDocumento,
  guid,
}) => {
  const handleCloseCancelar = () => {
    setOpenCancelar(false);
  };

  //////////

  const [openAutenticacao, setOpenAutenticacao] = useState(false);
  const [numeroAuth, setNumeroAuth] = useState('');

  const handleOpenAutenticar = () => {
    setOpenAutenticacao(true);
  };

  const handleCloseAutenticar = () => {
    setOpenAutenticacao(false);
    setNumeroAuth('');
  };

  const handleNumeroAuth = useCallback((e: any) => {
    setNumeroAuth(e.target.value);
  }, []);

  const putCancelar = useCallback(async () => {
    TransacoesService.putCancelar(guid).then((response) => {
      if (response) {
        feedback(response.mensagens[0].Mensagem, 'error');
        handleCloseAutenticar();
      } else {
        feedback('Transação cancelada com sucesso', 'success');
        handleCloseAutenticar();
        location.reload();
      }
    });
  }, [guid]);

  return (
    <>
      <Dialog
        maxWidth="md"
        open={abrirCancelar}
        onClose={handleCloseCancelar}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>Cancelar Transação</DialogTitle>
        <DialogContent>
          <Typography>
            <p>
              O processo de CANCELAMENTO deve ser realizado via GVcentris ou
              GVcollege. Utilize essa opção APENAS para exceções.
            </p>
            <b>
              Importante: O cancelamento da transação libera o valor retido do
              cartão de crédito do cliente.
            </b>
            <p>
              Esta ação é exclusiva entre o GVpay e o adquirente, sem qualquer
              alteração da situação do título no ERP.
            </p>
          </Typography>
        </DialogContent>
        <DialogActions style={{ marginTop: 20 }}>
          <Button
            variant="outlined"
            onClick={handleCloseCancelar}
            color="primary"
          >
            NÃO, quero cancelar
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              handleCloseCancelar(), handleOpenAutenticar();
            }}
            color="primary"
            autoFocus
          >
            SIM, quero continuar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        maxWidth="sm"
        fullWidth
        open={openAutenticacao}
        onClose={handleCloseAutenticar}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle style={{ color: '#ff0000' }}>
          Você tem certeza?
        </DialogTitle>
        <DialogContent style={{ marginBottom: 15 }}>
          <b>
            Para confirmar o cancelamento da transação, digite o número de
            documento:
          </b>
        </DialogContent>
        <DialogContent style={{ marginBottom: 15 }}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel>Número do documento*</InputLabel>
            <OutlinedInput
              defaultValue={numeroAuth}
              label="Número do documento*"
              type="string"
              onChange={handleNumeroAuth}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={!(numeroAuth === numeroDocumento)}
            variant="contained"
            onClick={() => {
              putCancelar();
            }}
            color="primary"
            autoFocus
          >
            <Box marginTop={0.6} marginRight={0.5}>
              <CancelIcon fontSize="small" />
            </Box>
            Cancelar transação
          </Button>
          <Button
            variant="outlined"
            onClick={handleCloseAutenticar}
            style={{ color: '#ff0000', borderColor: '#ff0000', height: 40 }}
          >
            Desisitr
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
