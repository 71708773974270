import GenericException from 'shared/errors/GenericException';
import { Api } from 'shared/services/axios-config';
import {
  IErrorMessage,
  IResultOfAction,
  responseError,
} from 'shared/services/response-error/ResponseError';

export interface ILogsTransacao {
  CriadoEm: string;
  Etapa: string;
  EstruturaTransacao: string;
}

export interface ITransacao {
  Guid: string;
  DocumentoOrigem: string;
  NomeOrigem: string;
  TID: string;
  PaymentId: string;
  NumeroParcelas: string;
  Estabelecimento: string;
  NomeNatureza: string;
  NomeAdquirente: string;
  Valor: string;
  TipoTransacao: string;
  NomeTitularCartao: string;
  NomeSituacaoBaixa: string;
  ObservacaoBaixaManual: string;
  NumeroCartao: string;
  AtualizadoEm: string;
  NomeSituacao: string;
  UrlBandeiraCartao: string;
  LogsTransacao: ILogsTransacao[];
}

export interface ITransacoes {
  QtdRegistros: number;
  Transacoes: ITransacao[];
  QtdCapturadaNaoLiquidada: number;
}

export interface ITransacaoFiltro {
  documentoOrigem: string;
  origem: string;
  adquirente: string;
  nomeCartao: string;
  atualizadoEm: string;
  situacao: string;
  tid: string;
  valor: string;
  cartao: string;
  estabelecimento: string;
  tipoTransacao: string;
  somenteCapturadasNaoLiquidadas: boolean;
}

export interface IPatch {
  Guid: string;
  Observacao: string;
}

export interface ITransacoesValoresFiltros {
  Estabelecimentos: [
    {
      Id: string;
      Cnpj: string;
    },
  ];
  Situacoes: [
    {
      Id: number;
      Nome: string;
    },
  ];
  Adquirentes: [
    {
      Id: number;
      Nome: string;
    },
  ];
  Origens: [
    {
      Id: number;
      Nome: string;
    },
  ];
  Tipos: [
    {
      Id: number;
      Nome: string;
    },
  ];
}

const getTransacoesDetalhes = async (
  idTransacao: string,
): Promise<ITransacao | undefined> => {
  try {
    const { data } = await Api().get<ITransacao>(`transacoes/${idTransacao}`);
    return data;
  } catch (error: any) {
    throw new GenericException(error.response);
  }
};

const getTransacoes = async (
  take: number,
  skip: number,
  filtros: ITransacaoFiltro,
): Promise<IResultOfAction<ITransacoes>> => {
  try {
    const { data, status } = await Api().get(
      `/transacoes?documentoOrigem=${filtros.documentoOrigem || ''}&origem=${
        filtros.origem || ''
      }&adquirente=${filtros.adquirente || ''}&nomeCartao=${
        filtros.nomeCartao || ''
      }&atualizadoEm=${filtros.atualizadoEm || ''}&situacao=${
        filtros.situacao || ''
      }&valor=${filtros.valor || ''}&cartao=${
        filtros.cartao || ''
      }&estabelecimento=${filtros.estabelecimento || ''}&tipoTransacao=${
        filtros.tipoTransacao || ''
      }&somenteCapturadasNaoLiquidadas=${filtros.somenteCapturadasNaoLiquidadas}
      &tid=${filtros.tid}&take=${take || ''}&skip=${skip || ''}`,
    );

    if (data && status === 200) {
      const transacoesToReturn: ITransacoes = {
        Transacoes: data.Data.map((transacao: any) => ({
          ...transacao,
        })),
        QtdRegistros: data.QtdTotalDeRegistros || 0,
        QtdCapturadaNaoLiquidada: data.QtdCapturadaNaoLiquidada || 0,
      };

      const response: any = {
        sucesso: true,
        data: transacoesToReturn,
      };

      return response as IResultOfAction<any>;
    }

    const errorResponse: IResultOfAction<any> = {
      mensagens: [] as IErrorMessage[],
      mensagensCombinadas: data.ResultadoDaAcao.MensagensCombinadas,
      severidade: data.ResultadoDaAcao.Severidade,
      severidadeId: data.ResultadoDaAcao.SeveridadeId,
      sucesso: data.ResultadoDaAcao.Sucesso,
    };

    return errorResponse;
  } catch (error: any) {
    const result = responseError(error);
    return result;
  }
};

const getTransacoesFiltros = async (): Promise<
  IResultOfAction<ITransacoesValoresFiltros>
> => {
  try {
    const { data, status } = await Api().get(`/transacoes/filtro`);

    if (data && status === 200) {
      const filtrosToReturn: ITransacoesValoresFiltros = data;

      const response: any = {
        sucesso: true,
        data: filtrosToReturn,
      };

      return response as IResultOfAction<any>;
    }

    const errorResponse: IResultOfAction<any> = {
      mensagens: [] as IErrorMessage[],
      mensagensCombinadas: data.ResultadoDaAcao.MensagensCombinadas,
      severidade: data.ResultadoDaAcao.Severidade,
      severidadeId: data.ResultadoDaAcao.SeveridadeId,
      sucesso: data.ResultadoDaAcao.Sucesso,
    };

    return errorResponse;
  } catch (error: any) {
    const result = responseError(error);
    return result;
  }
};

const putCapturar = async (
  guid: string | undefined,
): Promise<IResultOfAction<any>> => {
  try {
    const response = await Api().put(`transacoes/credito/${guid}/capturar`);
    return response.data;
  } catch (error: any) {
    const result = responseError(error);
    return result;
  }
};

const putCancelar = async (
  guid: string | undefined,
): Promise<IResultOfAction<any>> => {
  try {
    const response = await Api().put(`transacoes/credito/${guid}/cancelar`);
    return response.data;
  } catch (error: any) {
    const result = responseError(error);
    return result;
  }
};

const patchLiquidar = async (
  guid: string,
  patch: IPatch,
): Promise<IResultOfAction<any>> => {
  try {
    const response = await Api().patch(
      `Transacoes/InformarLiquidacaoManual/${guid}`,
      patch,
    );
    return response.data;
  } catch (error: any) {
    const result = responseError(error);
    return result;
  }
};

export const TransacoesService = {
  putCancelar,
  putCapturar,
  patchLiquidar,
  getTransacoes,
  getTransacoesDetalhes,
  getTransacoesFiltros,
};
