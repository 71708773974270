/* eslint-disable react/react-in-jsx-scope */
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from '@mui/material';
import {
  IWorkspace,
  IWorkspaceValue,
} from 'shared/services/api/my-credentials/BoletoList';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => {
  return {
    disabledBackground: {
      backgroundColor: '#e1e1e1',
    },
  };
});

interface IProps {
  workspace: IWorkspace;
  backgroudStyle: string;
  convenio: string;
  isActive: boolean;
  isEdit: boolean;
  isError: boolean;
  handleErrorCheck: () => void;
}

export const WorkspaceContent: React.FC<IProps> = ({
  workspace,
  backgroudStyle,
  convenio,
  isActive,
  isEdit,
  isError,
  handleErrorCheck,
}) => {
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);
  const [workspaceState, setWorkspaceState] = useState<IWorkspace>({
    habilitado: false,
    opcoes: [],
  });
  const NEW_WORKSPACE_DEFAULT = {
    id: '',
    convenio: '',
    descricao: '',
  };
  const DEFAULT_WORKSPACE_VALUE = {
    id: '',
    descricao: '',
    ativo: true,
  };
  const [newWorkspace, setNewWorkspace] = useState(NEW_WORKSPACE_DEFAULT);
  const [activeWorkspace, setActiveWorkspace] = useState<IWorkspaceValue>(
    DEFAULT_WORKSPACE_VALUE,
  );

  useEffect(() => {
    if (isEdit) {
      setWorkspaceState(workspace);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspace]);

  const mockedPutWorkspace = (activeWorkspaceId: string) => {
    const newWorkspaceOptions = workspaceState.opcoes.map((option) =>
      option.id === activeWorkspaceId
        ? {
            ...option,
            ativo: true,
          }
        : {
            ...option,
            ativo: false,
          },
    );

    setWorkspaceState({
      ...workspaceState,
      opcoes: newWorkspaceOptions,
    });
  };

  const mockedPostWorkspace = () => {
    const newWorkspaceOptions = JSON.parse(
      JSON.stringify(workspaceState.opcoes),
    ) as IWorkspaceValue[];

    newWorkspaceOptions.push({
      id: Date.now().toString(),
      ativo: false,
      descricao: newWorkspace.descricao,
    } as IWorkspaceValue);

    setWorkspaceState({
      ...workspaceState,
      opcoes: newWorkspaceOptions,
    });
  };

  useEffect(() => {
    if (isError) {
      setActiveWorkspace(DEFAULT_WORKSPACE_VALUE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  const handleSelectChange = (selectedWorkspace: IWorkspaceValue) => {
    setActiveWorkspace({
      ...selectedWorkspace,
      ativo: true,
    });
    mockedPutWorkspace(selectedWorkspace.id);
  };

  const handleOpenModal = () => {
    if (isError) {
      handleErrorCheck();
    } else {
      setNewWorkspace({ ...newWorkspace, convenio: convenio });
      setOpenModal(true);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setNewWorkspace(NEW_WORKSPACE_DEFAULT);
  };

  const handleAddNewWorkspace = () => {
    mockedPostWorkspace();
    setOpenModal(false);
    setNewWorkspace(NEW_WORKSPACE_DEFAULT);
  };

  const workspaceModal = () => {
    if (openModal) {
      return (
        <Dialog open={openModal} onClose={handleCloseModal} maxWidth="xl">
          <DialogTitle marginBottom={1}>
            <Box display="flex" marginBottom={-2.5}>
              <Box alignSelf="center" marginTop={-0.5}>
                <Typography color="primary">
                  <h4>Adicionar workspace</h4>
                </Typography>
              </Box>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Box style={{ width: 400 }} marginTop={1}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel>Id</InputLabel>
                <OutlinedInput
                  label="Id"
                  type="string"
                  value={newWorkspace.id}
                  disabled={true}
                  className={classes.disabledBackground}
                  onChange={(e) => {
                    setNewWorkspace({
                      ...newWorkspace,
                      id: e.target.value,
                    });
                  }}
                ></OutlinedInput>
              </FormControl>
            </Box>
            <Box style={{ width: 400 }} marginTop={3}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel>Convênio</InputLabel>
                <OutlinedInput
                  label="Convênio"
                  value={newWorkspace.convenio}
                  onChange={(e) => {
                    if (!isNaN(Number(e.target.value))) {
                      setNewWorkspace({
                        ...newWorkspace,
                        convenio: String(e.target.value),
                      });
                    }
                  }}
                ></OutlinedInput>
              </FormControl>
            </Box>
            <Box style={{ width: 400 }} marginTop={3}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel>Descrição</InputLabel>
                <OutlinedInput
                  label="Descrição"
                  type="string"
                  value={newWorkspace.descricao}
                  onChange={(e) => {
                    setNewWorkspace({
                      ...newWorkspace,
                      descricao: e.target.value,
                    });
                  }}
                ></OutlinedInput>
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions style={{ marginBottom: 20, marginTop: 30 }}>
            <Box marginRight={1.5}>
              <Button
                variant="contained"
                color="error"
                style={{ height: 40, width: 120, marginRight: 10 }}
                onClick={handleCloseModal}
              >
                Cancelar
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{ height: 40, width: 120 }}
                onClick={handleAddNewWorkspace}
              >
                Salvar
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
      );
    }
  };

  return (
    <>
      {workspaceModal()}

      {isActive ? (
        <Grid container item xs>
          <Grid item xs>
            <Box
              paddingX={7}
              marginTop={2}
              display={'flex'}
              alignItems={'center'}
            >
              <FormControl
                variant="outlined"
                fullWidth
                className={backgroudStyle}
                onClick={() => {
                  if (isError) {
                    handleErrorCheck();
                  }
                }}
              >
                <InputLabel>workspaceId</InputLabel>
                <Select
                  disabled={isError}
                  label="workspaceId"
                  value={
                    activeWorkspace.id.length > 0 ? activeWorkspace.id : ''
                  }
                >
                  {workspaceState.opcoes.map((item) => (
                    <MenuItem
                      value={item.id}
                      key={item.id}
                      onClick={() => handleSelectChange(item)}
                    >
                      {item.id}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <IconButton>
                <Box onClick={handleOpenModal}>
                  <AddCircleIcon color="primary" />
                </Box>
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
    </>
  );
};
